// import { filter } from 'lodash';
// import { Icon } from '@iconify/react';
// import { useState, useEffect } from 'react';
// import plusFill from '@iconify/icons-eva/plus-fill';
// import axios from "axios"
// import {
//   Card,
//   Table,
//   Stack,
//   Button,
//   Checkbox,
//   TableRow,
//   TableBody,
//   TableCell,
//   Container,
//   Typography,
//   TableContainer,
//   TablePagination
// } from '@mui/material';
// import { format } from 'date-fns'; // Import date-fns for date formatting

// import Badge from '@material-ui/core/Badge';
// import { makeStyles } from '@material-ui/core/styles';
// import Page from '../../components/Page';
// import Scrollbar from '../../components/Scrollbar';
// import SearchNotFound from '../../components/SearchNotFound';
// import { UserListHead, UserListToolbar } from '../../components/_dashboard/user';
// import AffecterVoucherModal from "./AffecterVoucherModal";
// const useStyles = makeStyles((theme) => ({
//   root: {
//     display: 'flex',
//     '& > *': {
//       margin: theme.spacing(1),
//     },
//   },
//   dot: {
//     width: theme.spacing(2),
//     height: theme.spacing(2),
//     borderRadius: '50%',
//   },
//   green: {
//     backgroundColor: 'green',
//   },
//   red: {
//     backgroundColor: 'red',
//   },
// }));

// const TABLE_HEAD = [
//   { id: "voucherCode", label: "code du voucher" },
//   { id: "createdAt", label: "date de création" },
//   { id: "formation", label: "Formation " },
//   { id: "utilisateur", label: "Apprenant" },
//   { id: "updatedAt", label: "date de consommation" },
//   { id: "typeProd", label: "Type Produit " },
//   { id: "nomProp", label: "Nom propriétaire " },
//   { id: "reduction", label: "Reduction" },
//   { id: "expired", label: "Status Voucher " },
// ];

// function descendingComparator(a, b, orderBy) {
//   if (b[orderBy] < a[orderBy]) {
//     return -1;
//   }
//   if (b[orderBy] > a[orderBy]) {
//     return 1;
//   }
//   return 0;
// }

// function getComparator(order, orderBy) {
//   return order === 'desc'
//     ? (a, b) => descendingComparator(a, b, orderBy)
//     : (a, b) => -descendingComparator(a, b, orderBy);
// }

// function applySortFilter(array, comparator, query) {
//   const stabilizedThis = array?.map((el, index) => [el, index]);
//   stabilizedThis?.sort((a, b) => {
//     const order = comparator(a[0], b[0]);
//     if (order !== 0) return order;
//     return a[1] - b[1];
//   });
//   if (query) {
//     return filter(array, (_user) => _user.nom.toLowerCase().indexOf(query.toLowerCase()) !== -1);
//   }
//   return stabilizedThis?.map((el) => el[0]);
// }

// export default function User() {

//   const classes = useStyles();
//   const [page, setPage] = useState(0);
//   const [order, setOrder] = useState('asc');
//   const [selected, setSelected] = useState([]);
//   const [orderBy, setOrderBy] = useState('nom');
//   const [filterName, setFilterName] = useState('');
//   const [rowsPerPage, setRowsPerPage] = useState(10);
//   const [formateurs, setformateurs] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [callback, setCallback] = useState(false);
//   const [result, setResult] = useState(0);
//   const [modalVoucher, setModalVoucher] = useState(false);
//   const [selectedStudent, setselectedStudent] = useState("");
//   const [selectedProprietaire, setSelectedProprietaire] = useState('');

//   // Create a function to handle changes in the dropdown value
//   const handleProprietaireChange = (event) => {
//     setSelectedProprietaire(event.target.value);
//   };

//   // Modify the applySortFilter function to filter based on the selected value
//   function applySortFilter(array, comparator, query, proprietaireFilter) {
//     const stabilizedThis = array?.map((el, index) => [el, index]);
//     stabilizedThis?.sort((a, b) => {
//       const order = comparator(a[0], b[0]);
//       if (order !== 0) return order;
//       return a[1] - b[1];
//     });
//     if (query) {
//       return filter(array, (_user) => _user.nom.toLowerCase().indexOf(query.toLowerCase()) !== -1);
//     }
//     if (proprietaireFilter) {
//       return filter(array, (_user) => _user.nomProp.titre.toLowerCase() === proprietaireFilter.toLowerCase());
//     }
//     return stabilizedThis?.map((el) => el[0]);
//   }
//   useEffect(() => {
//     getVouchers();
//   }, [callback, page]);
//   function formatDate(dateString) {
//     const date = new Date(dateString);
//     return format(date, 'dd/MM/yyyy HH:mm', { timeZone: 'Africa/Tunis' }); // Use Tunisian timezone
//   }
//   const openModalVoucher = (id) => {
//     setselectedStudent(id);
//     setModalVoucher(true);
//     getVouchers()
//   };
//   const closeModalVoucher = () => {
//     setModalVoucher(false);
//   };

//   const getVouchers = async () => {
//     setLoading(true);
//     const res = await axios.get(process.env.REACT_APP_URL_BACKEND_DEV + `/voucherr`)
//     const sortedVouchers = res.data.result.sort((a, b) => {
//       return new Date(b.createdAt) - new Date(a.createdAt);
//     });

//     setformateurs(sortedVouchers);
//     setResult(sortedVouchers);
//     setLoading(false);
//   };
//   const handleDeleteUsers = async () => {
//     setLoading(true);
//     try {
//       await axios.delete(process.env.REACT_APP_URL_BACKEND_DEV + '/deleteallVoucher', { data: { userIds: selected } });
//       setSelected([]);
//     } catch (error) {
//       console.error(error);
//     } finally {
//       getVouchers();
//       setLoading(false);
//     }
//   };

//   const handleRequestSort = (event, property) => {
//     const isAsc = orderBy === property && order === 'asc';
//     setOrder(isAsc ? 'desc' : 'asc');
//     setOrderBy(property);
//   };

//   const handleSelectAllClick = (event) => {
//     if (event.target.checked) {
//       const newSelecteds = formateurs?.map((n) => n._id);
//       setSelected(newSelecteds);
//       return;
//     }
//     setSelected([]);
//   };
//   const handleClick = (event, nom) => {
//     const selectedIndex = selected.indexOf(nom);
//     let newSelected = [];
//     if (selectedIndex === -1) {
//       newSelected = newSelected.concat(selected, nom);
//     } else if (selectedIndex === 0) {
//       newSelected = newSelected.concat(selected.slice(1));
//     } else if (selectedIndex === selected?.length - 1) {
//       newSelected = newSelected.concat(selected.slice(0, -1));
//     } else if (selectedIndex > 0) {
//       newSelected = newSelected.concat(
//         selected.slice(0, selectedIndex),
//         selected.slice(selectedIndex + 1)
//       );
//     }
//     setSelected(newSelected);
//   };

//   const handleChangePage = (event, newPage) => {
//     setPage(newPage);
//   };

//   const handleChangeRowsPerPage = (event) => {
//     setRowsPerPage(parseInt(event.target.value, 5));
//     setPage(0);
//   };

//   const handleFilterByName = (event) => {
//     setFilterName(event.target.value);
//   };

//   const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - formateurs?.length) : 0;

//   const filteredUsers = applySortFilter(formateurs, getComparator(order, orderBy), filterName, selectedProprietaire);
//   const isUserNotFound = filteredUsers?.length === 0;
//   return (
//     <Page title="Dashboard | Fastuz">
//       <Container>
//         <Stack
//           direction="row"
//           alignItems="center"
//           justifyContent="space-between"
//           mb={5}
//         >
//           <Typography variant="h4" gutterBottom>
//             Liste des Vouchers
//           </Typography>

//           <Button
//             variant="contained"
//             onClick={openModalVoucher}
//             startIcon={<Icon icon={plusFill} />}
//           >
//             Créer un nouveau Voucher
//           </Button>
//         </Stack>

//         <Card>
//           <UserListToolbar
//             numSelected={selected.length}
//             filterName={filterName}
//             onFilterName={handleFilterByName}
//             handleDeleteUsers={handleDeleteUsers}

//           />
//           <Scrollbar>
//             <TableContainer sx={{ minWidth: 800 }}>
//               <Table>
//                 <UserListHead
//                   order={order}
//                   orderBy={orderBy}
//                   headLabel={TABLE_HEAD}
//                   rowCount={formateurs?.length}
//                   numSelected={selected?.length}
//                   onRequestSort={handleRequestSort}
//                   onSelectAllClick={handleSelectAllClick}
//                 />
//                 <TableBody>
//                   {filteredUsers
//                     ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
//                     ?.map((row) => {
//                       const {
//                         _id,
//                         utilisateur,
//                         formation,
//                         expired,
//                         typeProd,
//                         nomProp,
//                         createdAt,
//                         updatedAt,
//                         formateur,
//                         buyerBuisness,
//                         partenairesBusiness,
//                         reduction,
//                         voucherCode,
//                       } = row;
//                       const isItemSelected =
//                         selected.indexOf(_id) !== -1;
//                       return (

//                         <TableRow
//                           hover
//                           key={_id}
//                           tabIndex={-1}
//                           role="checkbox"
//                           selected={isItemSelected}
//                           aria-checked={isItemSelected}
//                         >
//                           <TableCell padding="checkbox">
//                             <Checkbox
//                               checked={isItemSelected}
//                               onChange={(event) => handleClick(event, _id)}
//                             />
//                           </TableCell>

//                           <TableCell component="th" scope="row" padding="none">
//                             <Stack direction="row" alignItems="center" >
//                               <Typography variant="subtitle2" noWrap>
//                                 {voucherCode}
//                               </Typography>
//                             </Stack>
//                           </TableCell>
//                           <TableCell align="center">{formatDate(createdAt)}</TableCell>
//                           <TableCell align="left">{formation?.titre}</TableCell>

//                           <TableCell align="left">{utilisateur?.nom} {utilisateur?.prenom}</TableCell>
//                           <TableCell align="center">
//                             {expired
//                               ? formatDate(updatedAt)
//                               : '-'
//                             }
//                           </TableCell>

//                           <TableCell align="left">
//               {typeProd?.titre} {formation?.typeFormation?.label}
//             </TableCell>
//                           {/* <TableCell align="left">{typeProd?.titre}</TableCell> */}

//                           <TableCell align="left">
//                             {nomProp?.titre === 'Formateur' ? (
//                               <>
//                                 <Typography component="span" style={{ fontWeight: 'bold' }}>
//                                   Formateur:
//                                 </Typography>
//                                 {` ${formateur?.nom} ${formateur?.prenom}`}
//                               </>
//                             ) : nomProp?.titre === 'Business' ? (
//                               <>
//                                 {partenairesBusiness ? (
//                                   <>
//                                     <Typography component="span" style={{ fontWeight: 'bold' }}>
//                                       Business partenaires
//                                     </Typography>
//                                     {` ${partenairesBusiness?.nom} ${partenairesBusiness?.prenom}`}
//                                   </>
//                                 ) : (
//                                   <>
//                                     <Typography component="span" style={{ fontWeight: 'bold' }}>
//                                       Business partenaires
//                                     </Typography>
//                                     {` ${buyerBuisness?.nom} ${buyerBuisness?.prenom}`}
//                                   </>
//                                 )}
//                               </>
//                             ) : (
//                               <Typography component="span" style={{ fontWeight: 'bold' }}>
//                                 Fastuz
//                               </Typography>
//                             )}
//                           </TableCell>


//                           <TableCell align="left">{reduction} %</TableCell>

//                           {expired === true ?
//                             <TableCell align="left">
//                               <div className={classes.root}>

//                                 <Badge
//                                   className={`${classes.dot} ${classes.red}`}
//                                   overlap="circle"
//                                   variant="dot"
//                                 />
//                                 <p style={{ marginTop: "5px", marginLeft: "5px" }}> Expirée </p>

//                               </div></TableCell> :

//                             <TableCell align="left">
//                               <div className={classes.root}>
//                                 <Badge
//                                   className={`${classes.dot} ${classes.green}`}
//                                   overlap="circle"
//                                   variant="dot"

//                                 />
//                                 <p style={{ marginTop: "5px", marginLeft: "5px" }}> Non expirée </p>

//                               </div></TableCell>}


//                         </TableRow>

//                       );
//                     })}
//                   {emptyRows > 0 && (
//                     <TableRow style={{ height: 53 * emptyRows }}>
//                       <TableCell colSpan={6} />
//                     </TableRow>
//                   )}
//                 </TableBody>
//                 {isUserNotFound && (
//                   <TableBody>
//                     <TableRow>
//                       <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
//                         <SearchNotFound searchQuery={filterName} />
//                       </TableCell>
//                     </TableRow>
//                   </TableBody>
//                 )}
//               </Table>
//             </TableContainer>
//           </Scrollbar>

//           <TablePagination
//             rowsPerPageOptions={[5, 10, 25]}
//             component="div"
//             count={formateurs?.length}
//             rowsPerPage={rowsPerPage}
//             page={page}
//             onPageChange={handleChangePage}
//             onRowsPerPageChange={handleChangeRowsPerPage}

//           />
//         </Card>
//         <AffecterVoucherModal
//           getUsers={getVouchers}
//           studentId={selectedStudent}
//           toggleModal={closeModalVoucher}
//           modal={modalVoucher}
//         />

//       </Container>

//     </Page>

//   );
// }



import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { useState, useEffect } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import axios from "axios"
import {
  Card,
  Table,
  Stack,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  Container,
  Typography,
  TableContainer,
  TablePagination
} from '@mui/material';
import { format } from 'date-fns'; // Import date-fns for date formatting
import { Input } from 'antd';

import Badge from '@material-ui/core/Badge';
import { makeStyles } from '@material-ui/core/styles';
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import { UserListHead, UserListToolbar } from '../../components/_dashboard/user';
import AffecterVoucherModal from "./AffecterVoucherModal";
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  dot: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    borderRadius: '50%',
  },
  green: {
    backgroundColor: 'green',
  },
  red: {
    backgroundColor: 'red',
  },
}));

const TABLE_HEAD = [
  { id: "voucherCode", label: "code du voucher" },
  { id: "createdAt", label: "date de création" },
  { id: "formation", label: "Formation " },
  { id: "utilisateur", label: "Apprenant" },
  { id: "updatedAt", label: "date de consommation" },
  { id: "typeProd", label: "Type Produit " },
  { id: "nomProp", label: "Nom propriétaire " },
  { id: "reduction", label: "Reduction" },
  { id: "expired", label: "Status Voucher " },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.nom.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis?.map((el) => el[0]);
}

export default function User() {

  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('nom');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [formateurs, setformateurs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [callback, setCallback] = useState(false);
  const [result, setResult] = useState(0);
  const [modalVoucher, setModalVoucher] = useState(false);
  const [selectedStudent, setselectedStudent] = useState("");
  const [selectedProprietaire, setSelectedProprietaire] = useState('');


  

  // Create a function to handle changes in the dropdown value
  const handleProprietaireChange = (event) => {
    setSelectedProprietaire(event.target.value);
  };
  const [filterValues, setFilterValues] = useState({
    voucherCode: '',
    createdAt: '',
    formation: '',
    utilisateur: '',
    updatedAt: '',
    typeProd: '',
    nomProp: '',
    reduction: '',
    expired: '',
  });
  const [searchVoucherCode, setSearchVoucherCode] = useState('');
  const [searchCreatedAt, setSearchCreatedAt] = useState('');
  const [searchFormation, setSearchFormation] = useState('');
  const [searchUtilisateur, setSearchUtilisateur] = useState('');
  const [searchUpdatedAt, setSearchUpdatedAt] = useState('');
  const [searchTypeProd, setSearchTypeProd] = useState('');
  const [searchNomProp, setSearchNomProp] = useState('');
  const [searchReduction, setSearchReduction] = useState('');
  const [searchExpired, setSearchExpired] = useState('');
  
  // Apply sorting and filtering
  function applySortFilter(array, comparator) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis?.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
  
    // Apply filters for each column
    const filteredArray = stabilizedThis?.map((el) => el[0]).filter((user) => {
      // Ensure the data exists before trying to search
      const voucherCode = user.voucherCode?.toLowerCase() || '';
      const createdAt = formatDate(user.createdAt)?.toLowerCase() || '';
      const formationTitre = user.formation?.titre?.toLowerCase() || '';
      const utilisateurFullName = `${user.utilisateur?.nom || ''} ${user.utilisateur?.prenom || ''}`.toLowerCase();
      const updatedAt = user.updatedAt ? formatDate(user.updatedAt)?.toLowerCase() : '';
      const typeProdTitre = user.formation?.typeFormation?.label.toLowerCase() || '';
      const nomPropTitre = user.nomProp?.titre?.toLowerCase() || '';
      const reduction = String(user.reduction || '').toLowerCase();
      const expired = String(user.expired || '').toLowerCase();
  
      return (
        voucherCode.includes(searchVoucherCode.toLowerCase()) &&
        createdAt.includes(searchCreatedAt.toLowerCase()) &&
        formationTitre.includes(searchFormation.toLowerCase()) &&
        utilisateurFullName.includes(searchUtilisateur.toLowerCase()) &&
        updatedAt.includes(searchUpdatedAt.toLowerCase()) &&
        typeProdTitre.includes(searchTypeProd.toLowerCase()) &&
        nomPropTitre.includes(searchNomProp.toLowerCase()) &&
        reduction.includes(searchReduction) &&
        expired.includes(searchExpired.toLowerCase())
      );
    });
  
    return filteredArray;
  }
  

  useEffect(() => {
    getVouchers();
  }, [callback, page]);
  function formatDate(dateString) {
    const date = new Date(dateString);
    return format(date, 'dd/MM/yyyy HH:mm', { timeZone: 'Africa/Tunis' }); // Use Tunisian timezone
  }
  const openModalVoucher = (id) => {
    setselectedStudent(id);
    setModalVoucher(true);
    getVouchers()
  };
  const closeModalVoucher = () => {
    setModalVoucher(false);
  };

  const getVouchers = async () => {
    setLoading(true);
    const res = await axios.get(process.env.REACT_APP_URL_BACKEND_DEV + `/voucherr`)
    const sortedVouchers = res.data.result.sort((a, b) => {
      return new Date(b.createdAt) - new Date(a.createdAt);
    });

    setformateurs(sortedVouchers);
    setResult(sortedVouchers);
    setLoading(false);
  };
  const handleDeleteUsers = async () => {
    setLoading(true);
    try {
      await axios.delete(process.env.REACT_APP_URL_BACKEND_DEV + '/deleteallVoucher', { data: { userIds: selected } });
      setSelected([]);
    } catch (error) {
      console.error(error);
    } finally {
      getVouchers();
      setLoading(false);
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = formateurs?.map((n) => n._id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };
  const handleClick = (event, nom) => {
    const selectedIndex = selected.indexOf(nom);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, nom);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected?.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 5));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - formateurs?.length) : 0;
  const handleFilterChange = (event, column) => {
    setFilterValues({
      ...filterValues,
      [column]: event.target.value,
    });
  
    // You also need to update individual filter states based on the column being updated
    switch (column) {
      case 'voucherCode':
        setSearchVoucherCode(event.target.value);
        break;
      case 'createdAt':
        setSearchCreatedAt(event.target.value);
        break;
      case 'formation':
        setSearchFormation(event.target.value);
        break;
      case 'utilisateur':
        setSearchUtilisateur(event.target.value);
        break;
        case 'typeProd':
          setSearchTypeProd(event.target.value);
          break;
      // Continue for other filters
      default:
        break;
    }
  };
  
  const filteredUsers = applySortFilter(formateurs, getComparator(order, orderBy), filterName, selectedProprietaire);
  const isUserNotFound = filteredUsers?.length === 0;
  return (
    <Page title="Dashboard | Fastuz">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Liste des Vouchers
          </Typography>

          <Button
            variant="contained"
            onClick={openModalVoucher}
            startIcon={<Icon icon={plusFill} />}
          >
            Créer un nouveau Voucher
          </Button>
        </Stack>

        <Card>
          <UserListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
            handleDeleteUsers={handleDeleteUsers}

          />
          <Scrollbar>
          <TableContainer sx={{ minWidth: 1200 }}>
              <Table >
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={formateurs?.length}
                  numSelected={selected?.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
<TableHead>
  <TableRow>
    {/* Checkbox for selecting all rows */}
    <TableCell padding="checkbox">
      {/* <Checkbox
        indeterminate={selected.length > 0 && selected.length < formateurs.length}
        checked={formateurs.length > 0 && selected.length === formateurs.length}
        onChange={handleSelectAllClick}
      /> */}
    </TableCell>

    {TABLE_HEAD.map((headCell) => (
      <TableCell key={headCell.id}>
        {/* Render input fields only for the specified columns */}
        {['voucherCode', 'formation', 'utilisateur', 'typeProd'].includes(headCell.id) ? (
          <Input
            type="text"
            placeholder={` ${headCell.label}`}
            value={filterValues[headCell.id]} // Current filter value from state
            onChange={(e) => handleFilterChange(e, headCell.id)} // Handle changes in input
            style={{ marginTop: '8px', width: '100%' }}
          />
        ) : (
          // For columns without filters, just render an empty cell
          <div style={{ height: "30px" }}></div>
        )}
      </TableCell>
    ))}
  </TableRow>
</TableHead>


  <TableBody>
    {filteredUsers
      ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      ?.map((row) => {
        const {
          _id,
          utilisateur,
          formation,
          expired,
          typeProd,
          nomProp,
          createdAt,
          updatedAt,
          formateur,
          buyerBuisness,
          partenairesBusiness,
          reduction,
          voucherCode,
        } = row;

        const isItemSelected = selected.indexOf(_id) !== -1;

        return (
          <TableRow
            hover
            key={_id}
            tabIndex={-1}
            role="checkbox"
            selected={isItemSelected}
            aria-checked={isItemSelected}
          >
            {/* Checkbox for each row */}
            <TableCell padding="checkbox">
              <Checkbox
                checked={isItemSelected}
                onChange={(event) => handleClick(event, _id)}
              />
            </TableCell>

            {/* Data Cells */}
            <TableCell component="th" scope="row" padding="none">
              <Stack direction="row" alignItems="center">
                <Typography variant="subtitle2" noWrap>
                  {voucherCode}
                </Typography>
              </Stack>
            </TableCell>

            <TableCell align="center">{formatDate(createdAt)}</TableCell>

            
            <TableCell size="medium" align="left" >{formation?.titre}</TableCell>
            <TableCell align="left">
            {utilisateur?.nom ? `${utilisateur?.nom} ${utilisateur?.prenom}` : '-'}
              {/* {`${utilisateur?.nom} ${utilisateur?.prenom}`} */}
              
              </TableCell>

            <TableCell align="center">
              {expired ? formatDate(updatedAt) : '-'}
            </TableCell>

            <TableCell align="left">
              {typeProd?.titre} {formation?.typeFormation?.label}
            </TableCell>

            <TableCell align="left">
              {nomProp?.titre === 'Formateur' ? (
                <>
                  <Typography component="span" style={{ fontWeight: 'bold' }}>
                    Formateur:
                  </Typography>
                  {` ${formateur?.nom} ${formateur?.prenom}`}
                </>
              ) : nomProp?.titre === 'Business' ? (
                <>
                  {partenairesBusiness ? (
                    <>
                      <Typography component="span" style={{ fontWeight: 'bold' }}>
                        Business partenaires:
                      </Typography>
                      {` ${partenairesBusiness?.nom} ${partenairesBusiness?.prenom}`}
                    </>
                  ) : (
                    <>
                      <Typography component="span" style={{ fontWeight: 'bold' }}>
                        Business partenaires:
                      </Typography>
                      {` ${buyerBuisness?.nom} ${buyerBuisness?.prenom}`}
                    </>
                  )}
                </>
              ) : (
                <Typography component="span" style={{ fontWeight: 'bold' }}>
                  Fastuz
                </Typography>
              )}
            </TableCell>

            <TableCell align="left">{reduction} %</TableCell>

            {/* Expired status */}
            {expired === true ? (
              <TableCell align="left">
                <div className={classes.root}>
                  <Badge className={`${classes.dot} ${classes.red}`} overlap="circle" variant="dot" />
                  <p style={{ marginTop: '5px', marginLeft: '5px' }}> Expirée </p>
                </div>
              </TableCell>
            ) : (
              <TableCell align="left">
                <div className={classes.root}>
                  <Badge className={`${classes.dot} ${classes.green}`} overlap="circle" variant="dot" />
                  <p style={{ marginTop: '5px', marginLeft: '5px' }}> Non expirée </p>
                </div>
              </TableCell>
            )}
          </TableRow>
        );
      })}
  </TableBody>
</Table>

            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={formateurs?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}

          />
        </Card>
        <AffecterVoucherModal
          getUsers={getVouchers}
          studentId={selectedStudent}
          toggleModal={closeModalVoucher}
          modal={modalVoucher}
        />

      </Container>

    </Page>

  );
}


