// import { useFormik } from 'formik';
// import { ajoutModuleAPI } from '../../utils/api';
// import React, {  useState } from "react";
// import * as yup from "yup";
// import {  useNavigate, useParams } from 'react-router-dom';
// import {
//   Button,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogTitle,
// } from "@mui/material";
// import {
//   Input,
// } from "@material-ui/core";


// const validationSchema = yup.object({
//     titre_modules: yup.string().required("Titre obligatoire"),
    
//   });
// export default function AddFormations({ modal, toggleModal, getData } ) {
  
//   let [showSuccess, setShowSuccess] = useState(false);
//   let [showError, setShowError] = useState(false);
//   const navigate = useNavigate();
//     const {idFormation} = useParams();
   
//     const formik = useFormik({
//       initialValues: {
//         titre_modules: "",
        
//       },
//       validationSchema: validationSchema,
//       onSubmit: async (values) => {
 

//         const obj = {
//           ...values,
          
          
//         };
  
     
//         const  data  = await ajoutModuleAPI(obj, idFormation);
        

//         data.data.status ? setShowError(true)  : setShowSuccess(true);
     

//         await navigate(`/contenuformations/${idFormation}`)
//         getData();

//         clearInputAndClose();
//       },
//     });
 
//     const clearInputAndClose = () => {
//       toggleModal();
//     };
     
//   return (

//   <>

//   <Dialog
//         open={modal}
//         onClose={clearInputAndClose}
//         aria-labelledby="alert-dialog-title"
//         aria-describedby="alert-dialog-description"
//       >
//         <DialogTitle id="alert-dialog-title">
//           {"Ajouter Module"}
//         </DialogTitle>
//         <br></br>
//         <DialogContent style={{ height: "100px", width: "600px" }}>

//           <Input
//           fullWidth
//             name="titre_modules"
//                 label="Nom du module"
//                 id="titre_modules"
//                 placeholder="Nom du module"
//                 error={!!(formik.touched?.titre_modules && formik.errors?.titre_modules)}
//                 helperText={formik.touched?.titre_modules && formik.errors?.titre_modules}
//                 {...formik.getFieldProps("titre_modules")}
        
//           />
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={clearInputAndClose}>annuler</Button>
//           <Button
//             fullWidth
           
           
//             disabled={!formik.isValid}
//             onClick={async () => {
//               await formik.handleSubmit();

//             }}
//             type="button"
//           >
//             Ajouter
//           </Button>
//         </DialogActions>
//       </Dialog>
      
//       </>


//   );
// }


import { useFormik } from 'formik';
import { ajoutModuleAPI } from '../../utils/api';
import React, { useState } from "react";
import * as yup from "yup";
import { useNavigate, useParams } from 'react-router-dom';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import {
  Input,
} from "@material-ui/core";
import { ToastContainer, toast } from 'react-toastify';

const configToastr = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  newestOnTop: false,
  closeOnClick: true,
  rtl: false,
  pauseOnFocusLoss: true,
  draggable: true,
  pauseOnHover: true,
};

const validationSchema = yup.object({
  titre_modules: yup.string().required("Titre obligatoire"),
});

export default function AddFormations({ modal, toggleModal, getData }) {
  let [showSuccess, setShowSuccess] = useState(false);
  let [showError, setShowError] = useState(false);
  const navigate = useNavigate();
  const { idFormation } = useParams();

  const formik = useFormik({
    initialValues: {
      titre_modules: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const obj = {
        ...values,
      };

      try {
        const data = await ajoutModuleAPI(obj, idFormation);

        if (data.data.status) {
          setShowError(true);
          toast.error("Erreur lors de l'ajout du module");
        } else {
          setShowSuccess(true);
          toast.success("Module ajouté avec succès");
          formik.resetForm(); // Reset the form to its initial state
          toggleModal();
        }

        await navigate(`/contenuformations/${idFormation}`);
        getData();
      } catch (error) {
        console.error("Error:", error);
        toast.error("Une erreur s'est produite");
      }
    },
  });

  return (
    <>
      <Dialog
        open={modal}
        onClose={toggleModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Ajouter Module"}</DialogTitle>
        <DialogContent style={{ height: "100px", width: "600px" }}>
          <Input
            fullWidth
            name="titre_modules"
            label="Nom du module"
            id="titre_modules"
            placeholder="Nom du module"
            error={!!(formik.touched?.titre_modules && formik.errors?.titre_modules)}
            helperText={formik.touched?.titre_modules && formik.errors?.titre_modules}
            {...formik.getFieldProps("titre_modules")}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleModal}>Annuler</Button>
          <Button
            fullWidth
            disabled={!formik.isValid}
            onClick={async () => {
              await formik.handleSubmit();
            }}
            type="button"
          >
            Ajouter
          </Button>
        </DialogActions>
      </Dialog>
      <ToastContainer {...configToastr} />
    </>
  );
}
