import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRouter } from 'next/router';
import { AddVoucher } from "../../redux/voucherSlice";
import { useDispatch, useSelector } from "react-redux";
import Cookies from 'js-cookie';

function SuccessPage() {
    const containerStyle = {
        textAlign: 'center',
        padding: '40px 0',
        background: '#EBF0F5',
    };

    const cardStyle = {
        background: 'white',
        padding: '60px',
        borderRadius: '4px',
        boxShadow: '0 2px 3px #C8D0D8',
        display: 'inline-block',
    };

    const checkmarkContainerStyle = {
        borderRadius: '200px',
        height: '200px',
        width: '200px',
        background: '#F8FAF5',
        margin: '0 auto',
    };

    const checkmarkStyle = {
        color: '#9ABC66',
        fontSize: '100px',
        lineHeight: '200px',
        marginLeft: '-15px',
    };

    const headingStyle = {
        color: '#88B04B',
        fontFamily: '"Nunito Sans", "Helvetica Neue", sans-serif',
        fontWeight: '900',
        fontSize: '40px',
        marginBottom: '100px',
    };
    const errorIconContainerStyle = {
        borderRadius: '200px',
        height: '200px',
        width: '200px',
        background: '#F8FAF5',
        margin: '0 auto',
    };

    const errorIconStyle = {
        color: 'red',
        fontSize: '100px',
        lineHeight: '200px',
        marginLeft: '-15px',
    };

    const headingStyleError = {
        color: 'red',
        fontFamily: '"Nunito Sans", "Helvetica Neue", sans-serif',
        fontWeight: '900',
        fontSize: '40px',
        marginBottom: '100px',
    };


    const paragraphStyle = {
        color: '#404F5E',
        fontFamily: '"Nunito Sans", "Helvetica Neue", sans-serif',
        fontSize: '20px',
        margin: '0',
    };
    const router = useRouter();

    const [formation, setFormation] = useState([])
    const [etat, setEtat] = useState(null);
    const [Message, setMessage] = useState(undefined);
    const navigate = useNavigate();
    const urlSearchParams = new URLSearchParams(window.location.search);
    let [numCodes, setNumCodes] = useState(''); // Initialize with an empty string or an appropriate initial value
    let [selectedFormation, setSelectedFormation] = useState("");
    let [selectedProd, setSelectedProd] = useState("");
    const dispatch = useDispatch();
    const [generatedVouchers, setGeneratedVouchers] = useState([]);  
    const token = Cookies.get("tokenAdmin")
    const [loading, setLoading] = useState(true); // Initialize with true to show the overlay

    let nvPrix = urlSearchParams.get('nvPrix');
    const orderId = urlSearchParams.get('orderId');
    numCodes = urlSearchParams.get('numCodes');
    selectedProd = urlSearchParams.get('selectedProd');
    selectedFormation = urlSearchParams.get('selectedFormation');
    const selectedVoucher = urlSearchParams.get('selectedVoucher');

    const userConnected = useSelector((state) => state.auth.CurrentUser);
    const listFactures = userConnected?.mesFactures;

    const  checkFactures = listFactures?.every((item) => {
        return item?.OrderId !== orderId;
    });
    console.log(orderId)
console.log(listFactures)
 console.log(orderId)
    useEffect(() => {
        const getData = async () => {
            setLoading(true); // Hide the overlay when data is available

            if(checkFactures ){

                let apiUrl;
                if (selectedVoucher) {
                    console.log(true)
                  apiUrl = `${process.env.REACT_APP_URL_BACKEND_DEV}/testEtatVoucherCommande?orderId=${orderId}&nvPrix=${nvPrix}&numCodes=${numCodes}&selectedFormation=${selectedFormation}&selectedVoucher=${selectedVoucher}`;
                } else {
                    console.log(false)
                  apiUrl = `${process.env.REACT_APP_URL_BACKEND_DEV}/testEtatVoucherCommande?orderId=${orderId}&nvPrix=${nvPrix}&numCodes=${numCodes}&selectedFormation=${selectedFormation}`;
                }
                const response = await axios.post(apiUrl, null, {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  });
                  if (response.status === 200) {
                    setEtat(response.data);
                    setMessage(response.data.data.ErrorMessage);
          
                    // Set the generated vouchers from the response
                    setGeneratedVouchers(response.data.generatedVouchers);
                    setLoading(false); // Hide the overlay when data is available

                  }
            }
        
        }
        getData();
      }, [checkFactures]);
      

      useEffect(() => {
        const getData = async () => {
            setLoading(true); // Hide the overlay when data is available


               
                const response = await axios.get(process.env.REACT_APP_URL_BACKEND_DEV+ "/getAllFactureBuisnessBuy", {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  });
               
            
        
        }
        getData();
      }, []);
      
console.log("Message", Message)
return (
    <div style={containerStyle}>
        <div style={cardStyle}>
            {Message === "Success" ?
              <>
              <div style={checkmarkContainerStyle}>
                  <i style={checkmarkStyle}>✓</i>
              </div>
              <h1 style={headingStyle}>Paiement Effectué avec succées</h1>
              <p style={paragraphStyle}>Félicitations {userConnected?.nom} {userConnected?.prenom} ! Votre paiement pour les vouchers a été traité avec succès.</p>
          </>
          :

          <>
              <div style={errorIconContainerStyle}>
                  <i style={errorIconStyle}>✗</i>
              </div>
              <h1 style={headingStyle}>Echec de paiement</h1>
              <p style={paragraphStyle}>Veuillez réessayer ultérieurement ou contacter votre banque.;<br /> please try again later.</p>

          </>

            }

        </div>
    </div>
);
}

export default SuccessPage;


