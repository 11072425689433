// import { merge } from "lodash";
// import ReactApexChart from "react-apexcharts";

// import { Box, Card, CardHeader } from "@mui/material";

// import { fNumber } from "../../../utils/formatNumber";

// import { BaseOptionChart } from "../../charts";
// import { useEffect, useState } from "react";
// import axios from "axios";


// export default function AppConversionRates() {
//   const [makingApiCall, setMakingApiCall] = useState(true);
//   const [fetchedData, setFetchedData] = useState([]);

//   useEffect(() => {
//     let mount = true;
//     if (mount) {
//       setMakingApiCall(true);
//       axios
//         .get(process.env.REACT_APP_URL_BACKEND_DEV + "/AppNumberCertifFormations")
//         .then((response) => {
//           setFetchedData(response.data.scores);
//         })
//         .finally(() => {
//           setMakingApiCall(false);
//         });
//     }
//     return () => (mount = false);
//   }, []);

//   const CHART_DATA = [{ data: fetchedData.map((f) => f.scoreCount) }];
//   console.log("fetchdata",fetchedData)


//   const chartOptions = merge(BaseOptionChart(), {
//     tooltip: {
//       marker: { show: false },

//       y: {
//         formatter: (seriesName) => fNumber(seriesName),
//         title: {
//           formatter: () => "Nb certificats: ", 
//         },
//         value: {
//           formatter: (val) => val.toFixed(2), 
//         },
//       },
    
//     },
//     plotOptions: {
//       bar: { horizontal: true, barHeight: "28%", borderRadius: 2 },
//     },
//     xaxis: {
//       categories: fetchedData.map((f) => f.formationTitle),
//     },
//   });




//   return (
//     <Card>
//       <CardHeader title="Nombre des apprenants certifiés par formation" subheader="" />
//       <Box sx={{ mx: 3 }} dir="ltr">
//         {!makingApiCall && (
//           <ReactApexChart
//             type="bar"
//             series={CHART_DATA}
//             options={chartOptions}
//             height={392}
//           />
//         )}
//       </Box>
//     </Card>
//   );
// }
import { merge } from "lodash";
import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { Box, Card, CardHeader, CircularProgress, Typography } from "@mui/material";
import { fNumber } from "../../../utils/formatNumber";
import { BaseOptionChart } from "../../charts";
import axios from "axios";

export default function AppConversionRates() {
  const [loading, setLoading] = useState(true);
  const [fetchedData, setFetchedData] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    let mount = true;
    if (mount) {
      setLoading(true);
      axios
        .get(process.env.REACT_APP_URL_BACKEND_DEV + "/AppNumberCertifFormations")
        .then((response) => {
          setFetchedData(response.data.scores);
        })
        .catch((error) => {
          setError(error.message); // Set error state if network error occurs
        })
        .finally(() => {
          setLoading(false);
        });
    }
    return () => (mount = false);
  }, []);

  const CHART_DATA = [{ data: fetchedData.map((f) => f.scoreCount) }];

  const chartOptions = merge(BaseOptionChart(), {
    tooltip: {
      marker: { show: false },
      y: {
        formatter: (seriesName) => fNumber(seriesName),
        title: {
          formatter: () => "Nb certificats: ",
        },
        value: {
          formatter: (val) => val.toFixed(2),
        },
      },
    },
    plotOptions: {
      bar: { horizontal: true, barHeight: "28%", borderRadius: 2 },
    },
    xaxis: {
      categories: fetchedData.map((f) => f.formationTitle),
    },
  });

  return (
    <Card>
      <CardHeader title="Nombre des apprenants certifiés par formation" subheader="" />
      <Box sx={{ mx: 3 }} dir="ltr">
        {loading ? (
          <CircularProgress />
        ) : error ? (
          <Typography color="error">{error}</Typography> // Display error message if network error occurs
        ) : (
          <ReactApexChart
            type="bar"
            series={CHART_DATA}
            options={chartOptions}
            height={392}
          />
        )}
      </Box>
    </Card>
  );
}
