import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { getAllCategoriesAPI } from "../utils/api";
import axios from "axios";
const Url_Back_End =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_URL_BACKEND
    : process.env.REACT_APP_URL_BACKEND_DEV;
const initialState = {
  listeCategories: [],
  status: "idle",
  statusUpdate: "idle",
};

export const getAllCategories = createAsyncThunk(
  "categories/getAllCategories",
  async () => {
    try {
      const response = await getAllCategoriesAPI();

      return response.data.categories;
    } catch (err) {
    }

  }
);

export const updateCategories = createAsyncThunk(
  "categories/updateCategories",
  async (updateCategories) => {
    const promise = await axios
      .put(
        Url_Back_End + "/updateCategorie/" + updateCategories._id,
        updateCategories
      )

      .then((response) => {
        const data = response.data;
        return data;
      });

    const data = await promise;
    return data;
  }
);

export const categoriesSlice = createSlice({
  name: "categories",
  initialState,
  reducers: {},

  extraReducers: {
    [getAllCategories.pending]: (state, action) => {
      state.status = "loading";
    },
    [getAllCategories.fulfilled]: (state, action) => {
      state.status = "idle";
      state.listeCategories = action.payload;
    },

    [updateCategories.pending]: (state, action) => {
      state.statusUpdate = "loading";
    },
    [updateCategories.fulfilled]: (state, action) => {
      state.statusUpdate = "success";
    },
  },
});

export default categoriesSlice.reducer;
