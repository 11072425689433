import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {  Card, CardBody, Row } from "reactstrap";
import moment from "moment";
import { DeleteCategorieAPI } from "../../utils/api";
import { Icon } from "@iconify/react";
import plusFill from '@iconify/icons-eva/plus-fill';
import { useNavigate } from 'react-router-dom';
import { Button, Stack, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import ReactTable from 'react-table-v6';
import Swal from "sweetalert2";
import AddCategorie from "./NouveauCategorie"
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import axios from "axios";
import UpdateCategorie from "./DetailsCategorie"
function Partenaire() {
  let [show, setShow] = useState(false);
  const [page, setPage] = useState(0);
  const pageSize = 10;
  const [partners, setPartners]= useState([])
  const [selectedContact, setSelectedContact] = useState({});

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [update, setUpdate] = React.useState(false);

  const handleClickOpenUpdate = (partner) => {
    setSelectedContact(partner);

    setUpdate(true);
  };

  const handleCloseUpdate = () => {
    setUpdate(false);
  };
  let [modal, setModal] = useState(false);
  let [type, setType] = useState("");
  const toggleModal = () => setModal(!modal);
  let [selectedPartner, setSelectedPartner] = useState({});
  const listPartners = useSelector((state) => state.reglage.listPartners);
  const [loading, setLoading] = React.useState(false);
console.log(partners)
  console.log(listPartners)
  const pageCount = Math.ceil(partners.length / pageSize);
  const currentPageData = partners.slice(page * pageSize, (page + 1) * pageSize);
  const dispatch = useDispatch();
  const navigate = useNavigate();

 

     const statsColumns = [
      {
        Header: "Icon",
        accessor: "logo",
        style: { textAlign: "center" },
        Cell: ({ original }) => {
          return (
            <div className="avatar-icon-wrapper mr-3 avatar-icon-xl btn-hover-shine">
              <img
                src={original?.logo}
                alt="logo-partner"
                width={"50%"}
                height={"50%"}
              />
            </div>
          );
        },
      },
      {
        Header: "Libelle",
        accessor: "nom",
        style: { wordBreak: "break-all" },
      },
  
      {
        Header: "Date de création",
        accessor: "createdAt",
       
        Cell: ({ value }) => {
          return (
            <span style={{ wordBreak: "break-all" }}>
              {moment(value).locale("fr").format("DD/MM/YYYY")}
            </span>
          );
        },
        filterMethod: (filter, row) => {
          const date = moment(row[filter.id]).format("DD/MM/YYYY");
          return date.includes(filter.value);
        },
        defaultSortDesc: true,

      },
      {
        Header: "Actions",
        style: { textAlign: "center" },
        Cell: ({ original }) => {
          return (
            <div className="d-block w-100 text-center">
                    <LoadingButton
onClick={() => handleClickOpenUpdate(original)}                    >
                      Modifier
                    </LoadingButton>
           
       
                  
          <LoadingButton
                  
              
                  onClick={ async() => {

                    Swal.fire({
                          title: "Êtes-vous sûr de vouloir supprimer ?",
                          icon: "warning",
                          showCancelButton: true,
                          cancelButtonText: "Annuler",
                  
                          confirmButtonColor: "#0d3077",
                          cancelButtonColor: "#d33",
                          confirmButtonText: "Supprimer partenaire!",
                        }).then(async (result) => {
                          if (result.isConfirmed) {
                            await     DeleteCategorieAPI(original?._id)
                            getPartners()
                            // dispatch(getListeOfTeachers());
                            // dispatch(getAllFormationsFormateur())                             
                            .then(async (res) => {
                                if (res?.status === 200) {
                    
                                  Swal.fire("Categorie supprimé!");
                                  // await getData()
                                } else {
                                  Swal.fire(
                                    "Categorie supprimé!"
                                  );
                                  getPartners();
                                }
                              });
                          }
                        });
                  
                  
                  
                  
                  
                  
                  }}
                >
                  Supprimer 
                </LoadingButton>
                </div>
          );
        },
      },
 
    ];

  const getPartners = async () => {
    setLoading(true);
    const res = await axios.get(
      process.env.REACT_APP_URL_BACKEND_DEV +`/categorie`
    );
    console.log(res)
    setPartners(res.data.categorie);
    setLoading(false);
  };
  useEffect(() => {
  
    getPartners();
  }, []);


  return (
    <div>
      <Card className="card-hover-shadow-2x mb-3">

      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Catégories
          </Typography>
          <Button
            variant="contained"
            onClick={handleClickOpen}
            startIcon={<Icon icon={plusFill} />}
          >
            Ajouter categorie
          </Button>
        </Stack>
        <CardBody>
          <Row>
            
            <ReactTable
    data={partners}
    nextText={"Suivant"}
    previousText={"Précedent"}
    rowsText={"Lignes"}
    columns={statsColumns}
    defaultPageSize={10}
    filterable={true}
    noDataText="No data is available"
    defaultSorted={[{id :"createdAt" , desc : true}]}

/>
     
          </Row>
        </CardBody>
      </Card>
   

        <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Ajouter une catégorie"}
        </DialogTitle>
        <DialogContent>
          <AddCategorie handleClose={handleClose} getPartners={getPartners}/>
        </DialogContent>

      </Dialog>

      <Dialog
        open={update}
        onClose={handleCloseUpdate}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Modifier un partenaire"}
        </DialogTitle>
        <DialogContent>
          <UpdateCategorie selectedContact={selectedContact} handleCloseUpdate={handleCloseUpdate} getPartners={getPartners}/>
        </DialogContent>
      
      </Dialog>

    </div>
  );
}

export default Partenaire;


