import React, { useEffect } from "react";
import { Card, CardBody, CardHeader } from "reactstrap";
import ReactTable from "react-table-v6";
import moment from "moment";

export default function TableauConnexions(props) {
  useEffect(() => {
  }, [props]);
  const dureeDeTemps = (dateD, dateF) => {
    const a = moment(dateF);
    const b = moment(dateD);
    const diffM = a.diff(b, "minutes");
    const diffH = a.diff(b, "hours");
    const diffD = a.diff(b, "days");
    if (diffD !== 0) {
      return diffD + " jours";
    } else if (diffH !== 0) {
      return diffH + " heures";
    } else return diffM + " minutes";
  };
  // const calculateDurations = (data) => {
  //   return data.map((row) => {
  //     const dateD = row.dateConnexion;
  //     const dateF = row.dateDeconnexion;
  //     const b = moment(dateD);
  //     const a = moment(dateF);
  //     const diffM = a.diff(b, "minutes");
  //     if (diffM === 0) {
  //       return "+ 8 heures";
  //     } else if (diffM >= 60) {
  //       const hours = Math.floor(diffM / 60);
  //       const minutes = diffM % 60;
  //       return `${hours} heure(s) ${minutes} minute(s)`;
  //     } else {
  //       return `${diffM} minute(s)`;
  //     }
  //   });
  // };
  const calculateDurations = (data) => {
    return data.map((row) => {
      const dateD = moment(row.dateConnexion);
      const dateF = moment(row.dateDeconnexion);
      const diff = dateF.diff(dateD, "minutes");
      
      if (diff === 0) {
        return "+ 8 heures";
      } else if (diff >= 60) {
        const days = Math.floor(diff / (60 * 24));
        const hours = Math.floor((diff % (60 * 24)) / 60);
        const minutes = diff % 60;
        return `${days > 0 ? `${days} jour(s) ` : ''}${hours > 0 ? `${hours} heure(s) ` : ''}${minutes} minute(s)`;
      } else {
        return `${diff} minute(s)`;
      }
    });
  };
  
  const temoignagesColumns = [
    {
      Header: "Apprenants",
      id: "nomPrenom",
  accessor: row => `${row.idEtudiant?.nom} ${row.idEtudiant?.prenom}`,
  filterMethod: (filter, row) =>
    row.nomPrenom.toLowerCase().includes(filter.value.toLowerCase()),
      Cell: (row) => {
        return (
          <div className="d-block w-100 text-center">
            {row.original.idEtudiant?.nom} {row.original.idEtudiant?.prenom}
          </div>
        );
      },
      
    },
   
    // {
    //   Header: "adresse IP",
    //   accessor: "adresseIP",
    //   filterMethod: (filter, row) =>
    // row[filter.id].toLowerCase().includes(filter.value.toLowerCase()),
    //   style: { wordBreak: "break-all" },
    // },

    {
      Header: "Date de connexion",
      accessor: "dateConnexion",
      Cell: ({ value }) => {
        return (
          <span style={{ wordBreak: "break-all" }}>
            {moment(value).locale("fr").format("DD/MM/YYYY : HH:mm")}
          </span>
        );
      },
      filterMethod: (filter, row) => {
        const date = moment(row[filter.id]).format("DD/MM/YYYY : HH:mm");
        return date.includes(filter.value);
      },
      defaultSortDesc: true,

    },

    {
      Header: "Date de deconnexion",
      accessor: "dateDeconnexion",
      Cell: ({ value }) => {
        return (
          <span style={{ wordBreak: "break-all" }}>
            {moment(value).locale("fr").format("DD/MM/YYYY : HH:mm")}
          </span>
        );
      },
      filterMethod: (filter, row) => {
        const date = moment(row[filter.id]).format("DD/MM/YYYY : HH:mm");
        return date.includes(filter.value);
      },
    },
    {
      Header: "Durée",
      id: "duree",
      accessor: "original", // Pass the entire row to the accessor function
      Cell: (row) => {
        const duration = calculateDurations([row.original])[0]; // Calculate duration for the current row
        return (
          <div className="d-block w-100 text-center">
            <div className="mb-2 mr-2 badge badge-pill badge-primary">
              {duration}
            </div>
          </div>
        );
      },
    },
    // {
    //   Header: "Durée",
    //   id: "duree",
    //   accessor: row => `${row.dateConnexion} ${row.dateDeconnexion}`,
    //   filterMethod: (filter, row) => {
    //     const duration = dureeDeTemps(
    //       row.dateConnexion,
    //       row.dateDeconnexion
    //     );
    //     return duration.includes(filter.value);
    //   },
    //   Cell: (row) => {
    //     return (
    //       <div className="d-block w-100 text-center">
    //         <div className="mb-2 mr-2 badge badge-pill badge-primary">
    //           {dureeDeTemps(
    //             row.original.dateConnexion,
    //             row.original.dateDeconnexion
    //           )}
    //         </div>
    //       </div>
    //     );
    //   },
    // },
    
    {
      Header: "Région",
      accessor: "region",
      filterMethod: (filter, row) =>
    row[filter.id].toLowerCase().includes(filter.value.toLowerCase()),
      style: { wordBreak: "break-all" },
      Cell: (row) => {
        return (
          <div className="d-block w-100 text-center">
            {row.original.region ? row.original.region : "Non renseigné"}
          </div>
        );
      },
    },
    
    {
      Header: "Opérateur",
      accessor: "operateur",
      filterMethod: (filter, row) =>
    row[filter.id].toLowerCase().includes(filter.value.toLowerCase()),
      style: { wordBreak: "break-all" },
      Cell: (row) => {
        return (
          <div className="d-block w-100 text-center">
            {row.original.operateur ? row.original.operateur : "Non renseigné"}
          </div>
        );
      },
    },

    {
      Header: "Appareil",
      accessor: "appareil",
      filterMethod: (filter, row) =>
    row[filter.id].toLowerCase().includes(filter.value.toLowerCase()),
      style: { wordBreak: "break-all" },
    },

    {
      Header: "Navigateur",
      accessor: "navigateur",
      filterMethod: (filter, row) =>
    row[filter.id].toLowerCase().includes(filter.value.toLowerCase()),
      style: { wordBreak: "break-all" },
      Cell: (row) => (
        <div className="d-block w-100 text-center">
          <div className="mb-2 mr-2 badge badge-pill badge-danger">
            {row.row.navigateur}
          </div>
        </div>
      ),
    },
    
  ];
  return (
    <div>
      <Card>
        <CardHeader className="card-header-tab">
          <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
            <i className="header-icon pe-7s-plug icon-gradient bg-ripe-malin">
              {" "}
            </i>
            Liste des connexions des apprenants
          </div>
        </CardHeader>
        <CardBody>
          <ReactTable
            data={props.arrayConnexions}
            nextText={"Suivant"}
            previousText={"Précedent"}
            rowsText={"Lignes"}
            columns={temoignagesColumns}
            defaultPageSize={10}
            noDataText="Pas de données à prévisualiser pour le moment"
            filterable
            defaultSorted={[{id :"createdAt" , desc : true}]}

          />
        </CardBody>
      </Card>
    </div>
  );
}
