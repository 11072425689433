import plusFill from '@iconify/icons-eva/plus-fill';
// material
import {
  Card,
  Stack,
  Button,
  Container,
  Typography,
} from '@mui/material';
import { CardBody, Col, Row } from "reactstrap";

// components
import Page from '../../components/Page';
import { useNavigate } from 'react-router-dom';

import axios from "axios";
// ----------------------------------------------------------------------
import { useEffect, useState, useRef } from "react";
import ReactTable from 'react-table-v6'
import { useDispatch, useSelector } from "react-redux";
import {
  getAllFormationsFormateur

} from "../../redux/formationSlice";
import { Link, useParams } from "react-router-dom";
import "react-table-v6/react-table.css";
import Swal from "sweetalert2";

// ----------------------------------------------------------------------
import { Icon } from '@iconify/react';

// material
import { SupprimerLicenceAPI, desactiveFormation } from '../../utils/api';
import { LoadingButton } from '@mui/lab';
import Cookies from 'js-cookie';
const API_BASE = process.env.REACT_APP_URL_BACKEND_DEV;

export default function Formations() {

  const token = Cookies.get("tokenAdmin")
  const [listFormations, setListFormations] = useState([])


  useEffect(() => {

    getMoreData();
  }, []);
  const getMoreData = async () => {

    await axios.get(API_BASE + `/getAllLicences`,)
      .then(response => {
        setListFormations(response.data.Licences)
      })
      .catch(error => {
        console.error(error);
      });
  }
  

  const columns = [
    {
      Header: "Titre du logiciel",
      accessor: "titre",
      filterMethod: (filter, row) =>
        row[filter.id].toLowerCase().includes(filter.value.toLowerCase()),
      Cell: ({ original }) => {
        return (
          <span style={{ wordBreak: "break-all", alignItems: "center" }}>

            {original.titre}
          </span>
        );
      },
    },
    {
      Header: "Prix",
      accessor: "prix",
      Cell: ({original}) => {
        return <div style={{ display: "flex", justifyContent: "center" }}>
        {original.prix > 0 ? (
          <div>{original.prix} TND</div>
        ) : (
          <div>Free </div>
        )}
        </div>
      },
    },
{
      Header: "Icon",
      accessor: "logo",
      style: { textAlign: "center" },
      Cell: ({ original }) => {
        return (
          <div className="avatar-icon-wrapper mr-3 avatar-icon-xl btn-hover-shine">
            <img
              src={original?.logo}
              alt="logo-partner"
              width={"50%"}
              height={"50%"}
            />
          </div>
        );
      },
    },
    {
      Header: "Actions",
      filterable: false,
      sortable: false,
      width: 280,
      Cell: (row) => {
        return (
          <>
            <Link to={"/dashboard/detailLicence/" + row.original?._id}>  
             <LoadingButton>
            Details logiciels
          </LoadingButton> </Link>
            <LoadingButton

              onClick={async () => {

                Swal.fire({
                  title: "Êtes-vous sûr de vouloir supprimer ce logiciels?",
                  icon: "warning",
                  showCancelButton: true,
                  cancelButtonText: "Annuler",

                  confirmButtonColor: "#0d3077",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Supprimer logiciel!",
                }).then(async (result) => {
                  if (result.isConfirmed) {
                    await SupprimerLicenceAPI(row.original._id);

                    // dispatch(getListeOfTeachers());
                    await axios.get(API_BASE + `/getAllLicences`).then(async (res) => {
                      if (res.status === 200) {

                        Swal.fire("Logiciel supprimé!");

                        await getMoreData()
                      } else {
                        Swal.fire(
                          "Logiciel supprimé!"
                        );
                      }
                    });
                  }
                });




              }}
            >
              Supprimer
            </LoadingButton>

          </>
        );
      },
    },

  ];

  const navigate = useNavigate();



  return (
    <Page title="Dashboard | Fastuz">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
          Logiciels
          </Typography>
          <Button
            variant="contained"
            onClick={() => navigate("/dashboard/addLicence")}
            startIcon={<Icon icon={plusFill} />}
          >
            Ajouter logiciels
          </Button>
        </Stack>

        <Row>
          <Col md="12">
            <Card className="main-card mb-3">
              <CardBody>
                <ReactTable
                  data={listFormations}
                  nextText={"Suivant"}
                  previousText={"Précedent"}
                  rowsText={"Lignes"}
                  columns={columns}
                  defaultPageSize={5}
                  filterable={true}
                  noDataText="No data is available"
                /* overiding the filter methode */
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>

    </Page>

  );
}

