// import React, { useEffect, useState } from "react";
// import {  CardBody, Col, Row } from "reactstrap";
// import "antd/dist/antd.css"; 
// import { useFormik } from "formik";
// import * as yup from "yup";
// import { useDispatch } from "react-redux";
// import { useParams } from "react-router-dom";
// import { getCourseById } from "../../redux/courseSlice";
// import {  Stack, Container } from '@mui/material';
// import { UploadeFileAPI, UpdateCoursAPI } from "../../utils/api";
// import {  TextField } from "@material-ui/core";
// import { CKEditor } from "ckeditor4-react";
// import FileUploader from "../../assets/FileUploader";
// import getBase64 from "../../utils/getBase64";
// import {  useNavigate } from 'react-router-dom';
// import { styled } from '@mui/material/styles';
// import { LoadingButton } from '@mui/lab';

// const ContentStyle = styled('div')(({ theme }) => ({
//   maxWidth: 950,
//   margin: 'auto',
//   display: 'flex',
//   flexDirection: 'column',
//   justifyContent: 'center',
//   padding: theme.spacing(12, 0)
// }));
// const validationSchema = yup.object({
//   titre_cours: yup.string().required("Titre obligatoire"),

// });

// export default function DetailsFormations() {
//   const dispatch = useDispatch();
//   const { idCourse } = useParams();

//   const navigate = useNavigate();

//   const [loading, setloading] = useState(true);

//   const [description, setDescription] = useState("");
//   const [videos, setVideos] = useState(null);
//   const [videoPreview, setVideoPreview] = useState(null);
//   const [file, setFile] = useState(null);
//   const [filePreview, setFilePreview] = useState(null);

//   const handleDescription = (e) => {
//     let data = e.editor.getData();
//     setDescription(data);
//   };

//   useEffect(() => {
//     if (file) getBase64(file).then((url) => setFilePreview(url));
//     else setFilePreview(null);

//   }, [file]);
//   useEffect(() => {
//     if (videos) getBase64(videos).then((url) => setVideoPreview(url));
//     else setVideoPreview(null);
//   }, [videos]);
//   useEffect(() => {
//     setloading(true);
//     dispatch(getCourseById(idCourse))
    
//     .then((response) => {
//       setDescription(response.payload.description);
//       setloading(false);
//       if (response.payload.file) setFilePreview(response.payload.file);

//       if (response.payload.videos)
//         setVideoPreview(response.payload.videos);
//       formik.setValues({
//         titre_cours: response.payload.titre_cours,
        
//       });
     
//     });
//   }, [idCourse]);

//   const formik = useFormik({
//     initialValues: {
//       titre_cours: "",
      
//     },
//     enableReinitialize: true,
//     validationSchema: validationSchema,
//     onSubmit: async (values) => {

//       const Updateobj = {
//         _id: idCourse,
//         ...values,
//          description,
//       };

//       if (file) {
//         const fileRes = await UploadeFileAPI(file, "file ");
        
   
//         Updateobj.file =  fileRes.data.location;;
//       }
//       if (videos) {
//         const videoRes = await UploadeFileAPI(videos, "videoFormation");
//         Updateobj.videos = videoRes.data.location;;
//       }

//        await UpdateCoursAPI(Updateobj);

    
//       await navigate(-1)

//     },
//   });

 
 

//   return (
//     <div>
    

//   <Container maxWidth="l">
//     <ContentStyle>
//       <CardBody>
//         <Row>
//         <Stack spacing={2}>
//           <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} justifyContent="center" >
          
      

//           <Col md={12} className="mt-5">
//             <label><b>Vidéo </b></label>
//             {!videos && !videoPreview && (
//               <FileUploader
//                 fileTypes={["video/mp4", "video/webm"]}
//                 setFileToUpload={setVideos}
//                 title="Vidéo "
//                 original=""
//               ></FileUploader>
//             )}
//             {videoPreview && (
//               <div>
//               <br></br>

//                 <video controls controlsList="nodownload" src={videoPreview} width="100%"></video>

//                 <LoadingButton
                  
//                   onClick={() => {
//                     setVideoPreview(null);
//                     setVideos(null);
//                   }}
//                 >
//                 Réinitialiser Video

//                 </LoadingButton>
//               </div>
//             )}
//           </Col>

//           </Stack>
        
//           <Col md={6}>
//             <TextField
//               fullWidth
//               name="titre_cours"
//               label="Titre "
//               id="titre_cours"
//               placeholder="Titre cours"
//               error={!!(formik.touched.titre && formik.errors.titre)}
//               helperText={formik.touched.titre && formik.errors.titre}
//               {...formik.getFieldProps("titre_cours")}
//             />
         
        
//           </Col>
//           <Col md={12} className="mb-5">
//             <label><b>Pdf </b></label>
           
//             {!file && !filePreview && (
//               <FileUploader
//                 fileTypes={["application/pdf"]}
//                 setFileToUpload={setFile}
//                 title="pdf"
//                 original=""
//               ></FileUploader>
//             )}
//             {filePreview && (
//               <div>
//                 <a href={filePreview} > file_pdf</a>

//                 <LoadingButton
                  
//                   onClick={() => {
//                     setFilePreview(null);
//                     setFile(null);
//                   }}
//                 >
//                 Réinitialiser pdf

//                 </LoadingButton>
//               </div>
//             )}
//           </Col>
//           <Col md={12} className="mt-5">

//           <label>Description</label>

//           {!loading && (
//               <CKEditor initData={description} onChange={handleDescription} />
//             )}            </Col>
           
//           </Stack>

//         </Row>
      
//       </CardBody>
//       <br></br>
//         <LoadingButton
         
//            fullWidth
//             size="medium"
//             type="button"
//             disabled={!formik.isValid}
//             variant="contained"
         
//           onClick={async () => {
//             await formik.handleSubmit();
//           }}
//         >
        
//           Modifier cours
//         </LoadingButton>
   
  

// </ContentStyle>
     
//      </Container>
//     </div>
//   );
// }


import React, { useEffect, useState } from "react";
import {  CardBody, Col, Row } from "reactstrap";
import "antd/dist/antd.css"; 
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { getCourseById } from "../../redux/courseSlice";
import {  Stack, Container } from '@mui/material';
import { UploadeFileAPI, UpdateCoursAPI } from "../../utils/api";
import {  TextField } from "@material-ui/core";
import { CKEditor } from "ckeditor4-react";
import FileUploader from "../../assets/FileUploader";
import getBase64 from "../../utils/getBase64";
import {  useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import { ToastContainer, toast } from 'react-toastify';

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 950,
  margin: 'auto',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));
const validationSchema = yup.object({
  titre_cours: yup.string().required("Titre obligatoire"),

});

const configToastr = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  newestOnTop: false,
  closeOnClick: true,
  rtl: false,
  pauseOnFocusLoss: true,
  draggable: true,
  pauseOnHover: true,
};
export default function DetailsFormations() {
  const dispatch = useDispatch();
  const { idCourse } = useParams();

  const navigate = useNavigate();

  const [loading, setloading] = useState(true);

  const [description, setDescription] = useState("");
  const [videos, setVideos] = useState(null);
  const [videoPreview, setVideoPreview] = useState(null);
  const [file, setFile] = useState(null);
  const [filePreview, setFilePreview] = useState(null);

  const handleDescription = (e) => {
    let data = e.editor.getData();
    setDescription(data);
  };

  useEffect(() => {
    if (file) getBase64(file).then((url) => setFilePreview(url));
    else setFilePreview(null);

  }, [file]);
  useEffect(() => {
    if (videos) getBase64(videos).then((url) => setVideoPreview(url));
    else setVideoPreview(null);
  }, [videos]);
  useEffect(() => {
    setloading(true);
    dispatch(getCourseById(idCourse))
    
    .then((response) => {
      setDescription(response.payload.description);
      setloading(false);
      if (response.payload.file) setFilePreview(response.payload.file);

      if (response.payload.videos)
        setVideoPreview(response.payload.videos);
      formik.setValues({
        titre_cours: response.payload.titre_cours,
        
      });
     
    });
  }, [idCourse]);

  const formik = useFormik({
    initialValues: {
      titre_cours: "",
      
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (values) => {

      const Updateobj = {
        _id: idCourse,
        ...values,
         description,
      };

      if (file) {
        const fileRes = await UploadeFileAPI(file, "file ");
        
   
        Updateobj.file =  fileRes.data.location;;
      }
      if (videos) {
        const videoRes = await UploadeFileAPI(videos, "videoFormation");
        Updateobj.videos = videoRes.data.location;;
      }
      setloading(true)

      try {
        const data = await UpdateCoursAPI(Updateobj);
        ;
        if (data.data.status) {
          toast.error("Une erreur s'est produite lors de la modification du cours");
        } else {
          setloading(false)

          toast.success("Cours modifié avec succès");
        }
        setTimeout(async () => {

          await navigate(-1);

        }, 1000);
      } catch (error) {
        console.error("Error:", error);
        toast.error("Une erreur s'est produite");
      }

    },
  });

 
 

  return (
    <div>
    

  <Container maxWidth="l">
    <ContentStyle>
      <CardBody>
        <Row>
        <Stack spacing={2}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} justifyContent="center" >
          
      

          <Col md={12} className="mt-5">
            <label><b>Vidéo </b></label>
            {!videos && !videoPreview && (
              <FileUploader
                fileTypes={["video/mp4", "video/webm"]}
                setFileToUpload={setVideos}
                title="Vidéo "
                original=""
              ></FileUploader>
            )}
            {videoPreview && (
              <div>
              <br></br>

                <video controls controlsList="nodownload" src={videoPreview} width="100%"></video>

                <LoadingButton
                  
                  onClick={() => {
                    setVideoPreview(null);
                    setVideos(null);
                  }}
                >
                Réinitialiser Video

                </LoadingButton>
              </div>
            )}
          </Col>

          </Stack>
        
          <Col md={6}>
            <TextField
              fullWidth
              name="titre_cours"
              label="Titre "
              id="titre_cours"
              placeholder="Titre cours"
              error={!!(formik.touched.titre && formik.errors.titre)}
              helperText={formik.touched.titre && formik.errors.titre}
              {...formik.getFieldProps("titre_cours")}
            />
         
        
          </Col>
          <Col md={12} className="mb-5">
            <label><b>Pdf </b></label>
           
            {!file && !filePreview && (
              <FileUploader
                fileTypes={["application/pdf"]}
                setFileToUpload={setFile}
                title="pdf"
                original=""
              ></FileUploader>
            )}
            {filePreview && (
              <div>
                <a href={filePreview} > file_pdf</a>

                <LoadingButton
                  
                  onClick={() => {
                    setFilePreview(null);
                    setFile(null);
                  }}
                >
                Réinitialiser pdf

                </LoadingButton>
              </div>
            )}
          </Col>
          <Col md={12} className="mt-5">

          <label>Description</label>

          {!loading && (
              <CKEditor initData={description} onChange={handleDescription} />
            )}            </Col>
           
          </Stack>

        </Row>
      
      </CardBody>
      <br></br>
        <LoadingButton
         
           fullWidth
            size="medium"
            type="button"
            disabled={!formik.isValid}
            variant="contained"
         
          onClick={async () => {
            await formik.handleSubmit();
          }}
        >
        
          Modifier cours
        </LoadingButton>
   
  

</ContentStyle>
     
     </Container>
     <ToastContainer {...configToastr} />

    </div>
  );
}
