
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Alert, Spinner } from 'reactstrap';
import Link from 'next/link';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import { getAllFormations } from "../../redux/formationSlice";
import Select from "react-select";
import { ajoutFormationAPI, ResetFormationAPI, UploadeFileAPI } from '../../utils/api';

import { Stack, TextField, IconButton, InputAdornment } from '@mui/material';

import { LoadingButton } from '@mui/lab';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Page from "../../components/Page"
import { Row, Col, FormGroup,Button } from "reactstrap"; 
import FileUploader from "../../assets/FileUploader"
import getBase64 from "../../utils/getBase64"
import { AddDepartementAPI } from '../../utils/api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from "js-cookie";
import SweetAlert from 'react-bootstrap-sweetalert';

const INITIAL_USER = {
  titre: ''
};
const validationSchema = yup.object({
  titre: yup.string().required('Titre obligatoire'),

});
const RegisterFormateur = ({handleClose, getPartners}) => {
  const dispatch = useDispatch();
const [selectedFormation, setSelectedFormation]= useState("")
const [users, setUsers]= useState([])
const [logo, setlogo] = useState(null);
const [coverPreview, setCoverPreview] = useState(null);
const navigate = useNavigate();
const [showPassword, setShowPassword] = useState(false);
const [showPassword1, setShowPassword1] = useState(false);
const [partners, setPartners] = useState([]);
const userConnected = useSelector((state) => state.auth.CurrentUser);

const token = Cookies.get("tokenAdmin");

const [user, setUser] = React.useState(INITIAL_USER);
const [disabled, setDisabled] = React.useState(true);
const [loading, setLoading] = React.useState(false);
const [error, setError] = React.useState('');
const onDismiss = () => setError(false);
const [listFormation, setListFormations] = useState([]);

React.useEffect(() => {
  const isUser = Object.values(user).every((el) => Boolean(el));
  isUser ? setDisabled(false) : setDisabled(true);
}, [user]);

let [showSuccess, setShowSuccess] = useState(false);
let [showError, setShowError] = useState(false);


console.log("usercc",userConnected)

    // useEffect(() => {
    //   if (logo) getBase64(logo).then((url) => setCoverPreview(url));
    //   else setCoverPreview(null);
    // }, [logo]);

  const formik = useFormik({
    

        initialValues: {
        titre: "",
        
        
      },
    validationSchema: validationSchema,
         onSubmit: async (values) => {
           const obj = {
           ...values,
         };
      //     if (logo) {
      //     const coverRes = await UploadeFileAPI(logo, "coverFormation");
      //     obj.logo = coverRes.data.location;;
      //  }
      if (formik.isValid) {
        try {
      const data = await axios.post(
        process.env.REACT_APP_URL_BACKEND_DEV + "/ajouterdept",

        obj,

        {
          headers: {
            Authorization: `Bearer ${token}`,
            withCredentials: true
          },
        }
      );
      data.data.status ? setShowError(true) : setShowSuccess(true);
console.log("data.data",data.data)
              // const response = await  AddDepartementAPI(obj) 
            
            
                        
              } catch (error) {
                setError(error.response.data.message);
              } finally {
                getPartners()
                setLoading(false);
              }
            }
       

      },
  });

return (
    <Page title="User | Minimal-UI">
    <div>
      <Alert color="danger" isOpen={error ? true : false} toggle={onDismiss}>
        {error}
      </Alert>
      <ToastContainer />

      <form>
        <Stack spacing={3}>


                 <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} justifyContent="center" >
        {/* <Col md={12}>
            {alert && (
              <Alert color="danger">
                {alert === "photo" && "Merci d'inserer une image"}
                {alert === "description" && "Merci d'inserer une description"}
              </Alert>
            )}
          </Col>
          <Col md={12} >
            {!logo && (
              <FileUploader
                fileTypes={["image/png", "image/jpg", "image/jpeg"]}
                setFileToUpload={setlogo}
                title="Icon"
                original=""
                
              ></FileUploader>
            )}
            {logo && (
              <div className="cover-preview" onClick={() => setlogo(null)}>
                <img src={coverPreview} width="100%" alt='' />
                <i className="lnr-trash"></i>
              </div>
            )}
          </Col> */}
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              type="text"
              label="Titre"
              name="titre"
              error={formik.touched.titre && formik.errors.titre}
              helperText={formik.errors.titre}
              {...formik.getFieldProps('titre')}
            />

          </Stack>
    
           

         <LoadingButton
            fullWidth
            size="large"
            type="button"
            disabled={!formik.isValid}
            variant="contained"
            onClick={async () => {
              await formik.handleSubmit();
            }}
          >
            Ajouter Département
            {loading ? <Spinner color="success" /> : ''}
          </LoadingButton>
        </Stack>
      </form>
      
      <SweetAlert
          show={showSuccess}
          title="Ajout avec succées"
          type="success"
          onConfirm={() => {
            setShowSuccess(false);
          }}
        />

        <SweetAlert
          show={showError}
          title="Un problème est survenue !"
          type="error"
          onConfirm={() => {
            setShowError(false);
          }}
        />
    </div>
    </Page>
  );
};

export default RegisterFormateur;