import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {  Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import moment from "moment";
import { DeleteConditionUFormateurAPI } from "../../utils/api";
import { Icon } from "@iconify/react";
import plusFill from '@iconify/icons-eva/plus-fill';
import { useNavigate } from 'react-router-dom';
import { Button, Stack, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import DialogContentText from '@mui/material/DialogContentText';
import ReactTable from 'react-table-v6';

import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import AddCategorie from "./NouveauCondition"
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import axios from "axios";
import UpdateCategorie from "./DetailsCondition"
import ContratVente from "../../pages/ContratVenteFormateur/ContratVente"

function Partenaire() {
  let [show, setShow] = useState(false);
  const [page, setPage] = useState(0);
  const pageSize = 10;
  const [condition, setCondition]= useState([])
  const [selectedCondition, setSelectedCondition] = useState({});

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [update, setUpdate] = React.useState(false);

  const handleClickOpenUpdate = (partner) => {
    setSelectedCondition(partner);

    setUpdate(true);
  };

  const handleCloseUpdate = () => {
    setUpdate(false);
  };
  let [modal, setModal] = useState(false);
  let [type, setType] = useState("");
  const toggleModal = () => setModal(!modal);
  let [selectedPartner, setSelectedPartner] = useState({});

  const [loading, setLoading] = React.useState(false);


 

     const statsColumns = [
    
      {
        Header: "Titre contrat",
        accessor: "titre",
        style: { display:"flex",justifyContent:"center",wordBreak: "break-all" },
      },
     

      {
        Header: "Actions",
        style: { textAlign: "center" },
        Cell: ({ original }) => {
          return (
            <div className="d-block w-100 text-center">
                    <LoadingButton
onClick={() => handleClickOpenUpdate(original)}                    >
                      Modifier
                    </LoadingButton>
           
       
                  
          <LoadingButton
                  
              
                  onClick={ async() => {

                    Swal.fire({
                          title: "Êtes-vous sûr de vouloir supprimer ?",
                          icon: "warning",
                          showCancelButton: true,
                          cancelButtonText: "Annuler",
                  
                          confirmButtonColor: "#0d3077",
                          cancelButtonColor: "#d33",
                          confirmButtonText: "Supprimer contrat de vente!",
                        }).then(async (result) => {
                          if (result.isConfirmed) {
                            await     DeleteConditionUFormateurAPI(original?._id)
                            getPartners()
                            // dispatch(getListeOfTeachers());
                            // dispatch(getAllFormationsFormateur())                             
                            .then(async (res) => {
                                if (res?.status === 200) {
                    
                                  Swal.fire("Contrat de vente supprimé!");
                                  // await getData()
                                } else {
                                  Swal.fire(
                                    "Contrat de vente supprimé!"
                                  );
                                  getPartners();
                                }
                              });
                          }
                        });
                  
                  
                  
                  
                  
                  
                  }}
                >
                  Supprimer 
                </LoadingButton>
                </div>
          );
        },
      },
 
    ];

  const getPartners = async () => {
    setLoading(true);
    const res = await axios.get(
      process.env.REACT_APP_URL_BACKEND_DEV +`/conditionUFormateur`
    );
    console.log(res)
    setCondition(res.data.condition);
    setLoading(false);
  };
  useEffect(() => {
  
    getPartners();
  }, []);


  return (
    <div>
      <Card className="card-hover-shadow-2x mb-3">

      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Condition d'utilisation formateur
          </Typography>
          {condition?.length === 0 ?   <Button
            variant="contained"
            onClick={handleClickOpen}
            startIcon={<Icon icon={plusFill} />}
          >
            Ajouter condition d'utilisation
          </Button> : ("")}
        
        </Stack>
        <CardBody>
          <Row>
            
            <ReactTable
    data={condition}
    columns={statsColumns}
    defaultPageSize={1}
    noDataText="No data is available"
/>
     
          </Row>
        </CardBody>
      </Card>
   

        <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Ajouter un contrat"}
        </DialogTitle>
        <DialogContent>
          <AddCategorie handleClose={handleClose} getPartners={getPartners}/>
        </DialogContent>

      </Dialog>

      <Dialog
        open={update}
        onClose={handleCloseUpdate}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Modifier le condition d'utilisation"}
        </DialogTitle>
        <DialogContent>
          <UpdateCategorie selectedCondition={selectedCondition} handleCloseUpdate={handleCloseUpdate} getPartners={getPartners}/>
        </DialogContent>
      
      </Dialog>
      <br></br><br></br>
      <ContratVente/>
    </div>
  );
}

export default Partenaire;


