import React, { useEffect, useState } from "react";
import { Button, CardBody, CardFooter, CardTitle, Col, Row } from "reactstrap";
import "antd/dist/antd.css"; // or 'antd/dist/antd.less'
import { CloudUploadOutlined } from "@ant-design/icons";
import { useFormik } from "formik";
import * as yup from "yup";
import { Upload, Alert } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getFormationsById } from "../../redux/formationSlice";
import SweetAlert from "sweetalert-react";
import { Stack, Container } from '@mui/material';

import { UpdatePourcentageRevenuAPI } from "../../utils/api";
import { FormHelperText, TextField, MenuItem, Select } from "@material-ui/core";
import { CKEditor } from "ckeditor4-react";
import FileUploader from "../../assets/FileUploader";
import getBase64 from "../../utils/getBase64";
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import { getFormateurById } from "../../redux/formateurSlice";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 950,
  margin: 'auto',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));
const validationSchema = yup.object({
  pourcentageRevenus: yup.number().required('pourcentage Revenus obligatoire'),

});

export default function DetailsPartner({ handleCloseUpdate, getUsers, selectedFormateur }) {
  const dispatch = useDispatch();
  const idFormateur = selectedFormateur?._id;

  const navigate = useNavigate();


  let [fileList, setFileList] = useState([]);
  let [alerteShow, setAlerteShow] = useState(false);
  const [alerteNoImage, setalerteNoImage] = useState(false);
  const [logo, setlogo] = useState(null);
  const [coverPreview, setCoverPreview] = useState(null);
  const [loading, setloading] = useState(true);
  const [error, setError] = React.useState('');


  const [sc, setsc] = useState(true);
  const [update, setUpdate] = React.useState(false);





  useEffect(() => {
    setloading(true);
    dispatch(getFormateurById(idFormateur)).then((response) => {
      setloading(false);


      formik.setValues({
        pourcentageRevenus: response.payload?.pourcentageRevenus,


      });

      //SetImageFormation(response.payload.icon);
    });
  }, [idFormateur]);

  const formik = useFormik({
    initialValues: {
      pourcentageRevenus: 30,


    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (values) => {


      const Updateobj = {
        _id: idFormateur,
        ...values,
      };
      console.log(Updateobj)

      try {
        setError('');

        const response = await UpdatePourcentageRevenuAPI(Updateobj)
        if (response.data.status) {
          toast.error('Erreur de saisie !', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        } else {
          toast.success('Type produit modifié avec succées!', {
            position: 'top-right',
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          setTimeout(() => {
            handleCloseUpdate();
          }, 1000);

        }
      } catch (error) {
        setError(error.response.data.message);
      } finally {
        getUsers()
      }


    },
  });

  return (
    <div>


      <Container maxWidth="l">
        <ContentStyle>
          <CardBody>
            <ToastContainer />

            <Row>
              <Stack spacing={2}>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} justifyContent="center" >




                </Stack>

                <Col md={6}>
                <TextField
                fullWidth
                type="number"
                label="Pourcentage Revenus"
                name="pourcentageRevenus"
                error={formik.touched.valeurTVA && formik.errors.pourcentageRevenus}
                helperText={formik.errors.pourcentageRevenus}
                {...formik.getFieldProps('pourcentageRevenus')}
              />
        
                </Col>


              </Stack>

            </Row>

          </CardBody>
          <br></br>
          <LoadingButton
            fullWidth
            size="large"
            type="button"
            disabled={!formik.isValid}
            variant="contained"
            onClick={async () => {
              await formik.handleSubmit();
            }}
          >


            Confirmer la mise à jour
          </LoadingButton>


        </ContentStyle>

      </Container>
    </div>
  );
}