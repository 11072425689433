
import React, { useEffect, useState } from "react";
import {  CardBody,  Col, Row } from "reactstrap";
import "antd/dist/antd.css"; 
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getLicenceById } from "../../redux/licenceSlice";
import {  Stack, Container } from '@mui/material';
import { UploadeFileAPI, modifierLicenceAPI } from "../../utils/api";
import {  TextField } from "@material-ui/core";
import Select from "react-select";
import { CKEditor } from "ckeditor4-react";
import FileUploader from "../../assets/FileUploader";
import getBase64 from "../../utils/getBase64";
import {  useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import axios from "axios"
import { getAllLicences } from "../../redux/formationSlice";
import EditIcon from '@mui/icons-material/Edit';
import { getCategorie } from "../../redux/categorieSlice";
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 950,
  margin: 'auto',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));
const validationSchema = yup.object({
  titre: yup.string().required("Titre obligatoire"),
  prix: yup.number().required("Prix obligatoire"),

});
export default function DetailsFormations() {
  const dispatch = useDispatch();
  const { idLicence } = useParams();
  const navigate = useNavigate();
  console.log("idFormation",idLicence)
  const [cover, setCover] = useState(null);
  const [loading, setloading] = useState(true);
  const userConnected = useSelector((state) => state.auth.CurrentUser);
  const [description, setDescription] = useState("");
  const [video, setVideo] = useState(null);
  const [videoPreview, setVideoPreview] = useState(null);
  const [selectedLicence, setSelectedLicence] = useState("");
  const [selectedLicencee, setSelectedLicencee] = useState("");
  const [selectedDuration, setselectedDuration] = useState("");
  const [selectedDurationn, setselectedDurationn] = useState("");
  const [logoProduit, setlogoProduit] = useState(null);
  const [logoProduitPreview, setLogoProduitPreview] = useState(null);
  const [logo, setlogo] = useState(null);
  const [coverPreview, setCoverPreview] = useState(null);
const [file, setFile] = useState(null);
const [filePreview, setFilePreview] = useState(null);

useEffect(() => {
  if (file) getBase64(file).then((url) => setFilePreview(url));
  else setFilePreview(null);
}, [file]);    

useEffect(() => {
  if (logo) getBase64(logo).then((url) => setCoverPreview(url));
  else setCoverPreview(null);
  

}, [logo]);

useEffect(() => {
  setloading(true);
  dispatch(getLicenceById(idLicence)).then((response) => {
    if (response.payload?.file) {
      setFilePreview(response.payload?.file);
    }
    if (response.payload.logo) setCoverPreview(response.payload.logo);

    setloading(false);

    formik.setValues({
      titre: response.payload?.titre,
      prix: response.payload?.prix,

    });
  });
}, [idLicence]);
  console.log("logoProduit", logoProduit)
  console.log("logoProduitPreview",logoProduitPreview)

  const formik = useFormik({
    initialValues: {
      titre: "",
      prix: "",


    },
    
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const Updateobj = {
        _id: idLicence,
        ...values,
      


      };
    
      if (file) {
  
        const coverRes = await UploadeFileAPI(file, "file");
        Updateobj.file = coverRes.data.location;
      
      }
      if (logo) {
  
        const coverRes = await UploadeFileAPI(logo, "coverFormation");
        Updateobj.logo = coverRes.data.location;
        console.log("logooo",coverRes)
      }
    
       await modifierLicenceAPI(Updateobj);
       if (userConnected.role==="admin"){
        await navigate("/dashboard/licence")
      }else {
        await navigate("/dashboard/licence")
      }
    },
  });
  return (
    <div>
    
  <Container maxWidth="l">
    <ContentStyle>
      <CardBody>
        <Row>
        <Stack spacing={2}>
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} justifyContent="center" >
          
          <Col md={12} className="mb-5">
            <label><b>Icon</b></label>
           
            {!logo && !coverPreview && (
              <FileUploader
                fileTypes={["image/png", "image/jpg", "image/jpeg"]}
                setFileToUpload={setlogo}
                title="Icon"
                original=""
              ></FileUploader>
            )}
            {coverPreview && (
              <div
                className="cover-preview"
                onClick={() => {
                  setCoverPreview(null);
                  setlogo(null);
                }}
              >
               <br></br>
                <img src={logo ? coverPreview : coverPreview } width="100%" alt="" />
                <i className="lnr-trash"></i>
              </div>
            )}
          </Col>



          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} justifyContent="center" >
          <Col md={6} className="mb-3">
  <input
    type="file"
    accept=".zip"
    onChange={(e) => {
      const selectedFile = e.target.files[0];
      if (selectedFile) {
        setFile(selectedFile);
        setFilePreview(URL.createObjectURL(selectedFile));
      }
    }}
  />
<br></br>
  {filePreview && (
    <div>
      <a href={filePreview} download={file?.name || "file.zip"}>
        Download {file?.name || "Logiciel"}
      </a>
    </div>
  )}

  {!filePreview && file && (
    <div>
      Default File: {file?.name || "No file selected"}
    </div>
  )}
</Col>



          </Stack>
          <TextField
              style={{ marginTop: "15px" }}
              fullWidth
              type="number"
              name="prix"
              id="prix"
              label="Prix du logiciel"
              placeholder="TND"
              error={!!(formik.touched.prix && formik.errors.prix)}
              helperText={formik.touched.prix && formik.errors.prix}
              {...formik.getFieldProps("prix")}
            />
          <Col md={6}>
            <TextField
              fullWidth
              name="titre"
              label="Titre"
              id="titre"
              placeholder="Titre"
              error={!!(formik.touched.titre && formik.errors.titre)}
              helperText={formik.touched.titre && formik.errors.titre}
              {...formik.getFieldProps("titre")}
            />
           
        
          </Col>
       
         </Stack>
        
        </Row>
      
      </CardBody>
      <br></br>
        <LoadingButton
         
           fullWidth
            size="medium"
            type="button"
            disabled={!formik.isValid}
            variant="contained"
         
          onClick={async () => {
            await formik.handleSubmit();
          }}
        >
        
          Confirmer la mise à jour
        </LoadingButton>
  
</ContentStyle>
     
     </Container>
    </div>
  );
}

