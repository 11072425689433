// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-datepicker {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border-radius: 4px;
    border: 1px solid #c4c4c4;
    background-color: #fff;
    color: #495057;
    margin-top: 10px;
  }
  
  .custom-datepicker:hover {
    border-color: #1976d2;
  }
  
  .react-datepicker__day--selected {
    background-color: #1976d2;
    color: white;
  }
  
  .react-datepicker__header {
    background-color: #1976d2;
    color: white;
  }`, "",{"version":3,"sources":["webpack://./src/pages/Vouchers/DatePickerStyles.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,eAAe;IACf,kBAAkB;IAClB,yBAAyB;IACzB,sBAAsB;IACtB,cAAc;IACd,gBAAgB;EAClB;;EAEA;IACE,qBAAqB;EACvB;;EAEA;IACE,yBAAyB;IACzB,YAAY;EACd;;EAEA;IACE,yBAAyB;IACzB,YAAY;EACd","sourcesContent":[".custom-datepicker {\n    width: 100%;\n    padding: 10px;\n    font-size: 16px;\n    border-radius: 4px;\n    border: 1px solid #c4c4c4;\n    background-color: #fff;\n    color: #495057;\n    margin-top: 10px;\n  }\n  \n  .custom-datepicker:hover {\n    border-color: #1976d2;\n  }\n  \n  .react-datepicker__day--selected {\n    background-color: #1976d2;\n    color: white;\n  }\n  \n  .react-datepicker__header {\n    background-color: #1976d2;\n    color: white;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
