import React, { useEffect, useState } from "react";
import { Button, CardBody, CardFooter, CardTitle, Col, Row } from "reactstrap";
import "antd/dist/antd.css"; // or 'antd/dist/antd.less'
import { CloudUploadOutlined } from "@ant-design/icons";
import { useFormik } from "formik";
import * as yup from "yup";
import { Upload, Alert } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getFormationsById } from "../../redux/formationSlice";
import SweetAlert from "sweetalert-react";
import {  Stack, Container } from '@mui/material';

import { UploadeFileAPI, UpdatePartnerTechAPI } from "../../utils/api";
import { FormHelperText, TextField, MenuItem, Select } from "@material-ui/core";
import { CKEditor } from "ckeditor4-react";
import FileUploader from "../../assets/FileUploader";
import getBase64 from "../../utils/getBase64";
import {  useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import { getPartnerTechById } from "../../redux/partnerTechSlice";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 950,
  margin: 'auto',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));
const validationSchema = yup.object({
  nom: yup.string().required("Nom obligatoire"),
  // description: yup.string().required("Description obligatoir"),

});

export default function DetailsPartner({ handleCloseUpdate, getPartners, selectedContact}) {
  const dispatch = useDispatch();
  const  idPartner  = selectedContact?._id; 

  const navigate = useNavigate();


  let [fileList, setFileList] = useState([]);
  let [alerteShow, setAlerteShow] = useState(false);
  const [alerteNoImage, setalerteNoImage] = useState(false);
  const [logo, setlogo] = useState(null);
  const [coverPreview, setCoverPreview] = useState(null);
  const [loading, setloading] = useState(true);
  const [error, setError] = React.useState('');


  const [sc, setsc] = useState(true);
  const [update, setUpdate] = React.useState(false);



  useEffect(() => {
    if (logo) getBase64(logo).then((url) => setCoverPreview(url));
    else setCoverPreview(null);
    

  }, [logo]);
 
  useEffect(() => {
    setloading(true);
    dispatch(getPartnerTechById(idPartner)).then((response) => {
      setloading(false);

      if (response.payload.logo) setCoverPreview(response.payload.logo);
   
      formik.setValues({
        nom: response.payload.nom,
        
      });
     
      //SetImageFormation(response.payload.icon);
    });
  }, [idPartner]);

  const formik = useFormik({
    initialValues: {
      nom: "",
     
      
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
    

      const Updateobj = {
        _id: idPartner,
        ...values,
      };
      console.log(Updateobj)
      
      if (logo) {
  
        const coverRes = await UploadeFileAPI(logo, "coverFormation");
        Updateobj.logo = coverRes.data.location;
        console.log("logooo",coverRes)
      }
      try {
        setError('');

        const response = await  UpdatePartnerTechAPI(Updateobj) 
        if ( response.data.status) {
          toast.error('Erreur de saisie !', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });   
        } else {
          toast.success('Partenaire modifié avec succées!', {
            position: 'top-right',
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          setTimeout(() => {
            handleCloseUpdate();
          }, 1000);                

        }
      } catch (error) {
        setError(error.response.data.message);
      } finally {
        getPartners()
      }

      
    },
  });

  return (
    <div>


  <Container maxWidth="l">
    <ContentStyle>
      <CardBody>
      <ToastContainer />

        <Row>
        <Stack spacing={2}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} justifyContent="center" >
          
          <Col md={12} className="mb-5">
            <label><b>Couverture</b></label>
           
            {!logo && !coverPreview && (
              <FileUploader
                fileTypes={["image/png", "image/jpg", "image/jpeg"]}
                setFileToUpload={setlogo}
                title="Couverture"
                original=""
              ></FileUploader>
            )}
            {coverPreview && (
              <div
                className="cover-preview"
                onClick={() => {
                  setCoverPreview(null);
                  setlogo(null);
                }}
              >
               <br></br>
                <img src={logo ? coverPreview : coverPreview } width="100%" alt="" />
                <i className="lnr-trash"></i>
              </div>
            )}
          </Col>



          </Stack>
        
          <Col md={6}>
            <TextField
              fullWidth
              name="nom"
              label="Nom"
              id="nom"
              placeholder="Nom"
              error={!!(formik.touched.nom && formik.errors.nom)}
              helperText={formik.touched.nom && formik.errors.nom}
              {...formik.getFieldProps("nom")}
            />
      
         
        
          </Col>
      
        
          </Stack>

        </Row>
      
      </CardBody>
      <br></br>
      <LoadingButton
            fullWidth
            size="large"
            type="button"
            disabled={!formik.isValid}
            variant="contained"
            onClick={async () => {
              await formik.handleSubmit();
            }}
          >
         
        
          Confirmer la mise à jour
        </LoadingButton>
  

</ContentStyle>
     
     </Container>
    </div>
  );
}