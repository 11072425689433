import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
 
  getAllDiscussionByIdAPI,

} from "../utils/api";

const initialState = {
  listediscus: [],
  status: "idle",
  discusSelected: {},
};

export const getallDiscussions = createAsyncThunk(
  "discus/getallDiscussions",
  async (idFormation) => {
    try {
      const response = await getAllDiscussionByIdAPI(idFormation);
      return response.data.result;
    } catch (err) {
    }
  }
);



export const reviewSlice = createSlice({
  name: "discus",
  initialState,
  reducers: {},

  extraReducers: {
    [getallDiscussions.pending]: (state, action) => {
      state.status = "loading";
    },
    [getallDiscussions.fulfilled]: (state, action) => {
      state.status = "idle";
      state.listediscus = action.payload;
    },

  
 
  },
});

export default reviewSlice.reducer;
