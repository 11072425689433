import { Icon } from '@iconify/react';
// material
import { alpha, styled } from '@mui/material/styles';
import { Card, Typography } from '@mui/material';
// utils
import { fShortenNumber } from '../../../utils/formatNumber';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Cookies from 'js-cookie';
import axios from 'axios'
// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  padding: theme.spacing(5, 0),
  color: theme.palette.primary.darker,
  backgroundColor: theme.palette.primary.lighter
}));

const IconWrapperStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
  color: theme.palette.primary.dark,
  backgroundImage: `linear-gradient(135deg, ${alpha(theme.palette.primary.dark, 0)} 0%, ${alpha(
    theme.palette.primary.dark,
    0.24
  )} 100%)`
}));



export default function AppWeeklySales() {
  const [formations, setformations] = useState(0);
  const token= Cookies.get("tokenAdmin")

  useEffect(() => {
   
    getMoreData();
  }, []);
  const getMoreData = async () => {
 
    await axios.get(process.env.REACT_APP_URL_BACKEND_DEV + `/getAllFormationsFormateur`, 
      {
        headers: { Authorization: `Bearer ${token}`, 
        withCredentials: true
      },
    })
      .then(response => {
        setformations(response.data.formations.length)  
   })
      .catch(error => {
      });
  }
  return (
    <RootStyle>
      <IconWrapperStyle>
        <Icon icon="ant-design:book-outlined" width={24} height={24} />
      </IconWrapperStyle>
      <Typography variant="h3">{fShortenNumber(formations)}</Typography>
      <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
        Formations
      </Typography>
    </RootStyle>
  );
}
