
import {
  Avatar,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { MultiSelect } from "react-multi-select-component";
import styles from "./admin.module.css";
import React, { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";

function ListeStudentWithQuizz(quiz) {
  const [selected, setSelected] = useState([]);
  const [arrayAvancement, setarrayAvancement] = useState([]);
  const [arrayStudents, setarrayStudents] = useState([]);

  const [loading, setloading] = useState(true);
  useEffect(() => {
    axios
      .post(process.env.REACT_APP_URL_BACKEND_DEV+ "/scorecourse", {
        arrayStudents: selected,
      })
      .then((res) => {
        setloading(true);
        setarrayAvancement(res.data.data);
      });
   
  }, [selected]);
  useEffect(() => {
    axios.get(process.env.REACT_APP_URL_BACKEND_DEV +"/getAllStudents").then((res) => {
      setloading(true);
      setarrayStudents(res.data.students);
    });
  }, []);

  const options = arrayStudents?.map((student) => {
    return {
      label: student.nom + " " + student.prenom,
      value: student._id,
    };
  });

  return (
    <div>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={5}
      >
        <Typography variant="h4" gutterBottom>
        </Typography>
      </Stack>
      <MultiSelect
        options={options}
        value={selected}
        onChange={setSelected}
        labelledBy="Select"
      />
      <table className={styles.table}>
        <thead>
          <tr>
            <td>Nom et prenom</td>
            <td>Date passage du quizz</td>
            <td>Titre quizz</td>
            <td>Titre formation</td>
            <td>Score</td>
          </tr>
        </thead>
        <tbody>
          {!loading ? (
            <>
              <Skeleton />
              <Skeleton animation="wave" />
              <Skeleton animation={false} />
            </>
          ) : (
            arrayAvancement.map((quiz) => (
              <tr key={quiz._id}>
                <td>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Avatar
                      src={
                         quiz.etudiant?.photo
                      }
                      alt="avatar"
                      style={{ marginRight: "1em" }}
                    />
                    <span>
                      {quiz?.etudiant?.nom + " " + quiz.etudiant?.prenom}
                    </span>
                  </div>
                </td>
                <td>
                  {moment(quiz.createdAt)
                    .locale("en")
                    .format("DD/MM/YYYY : HH:mm")}
                </td>
                <td>{quiz.quizCourse?.name}</td>

                <td>{quiz.formation?.titre}</td>
                <td>
                  {quiz?.score} <span>%</span>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>
    </div>
  );
}

export default ListeStudentWithQuizz;