import { Icon } from '@iconify/react';

import { alpha, styled } from '@mui/material/styles';
import { Card, Typography } from '@mui/material';

import { fShortenNumber } from '../../../utils/formatNumber';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { GetAllVoucherExpired, GetAllVoucherByStudent } from '../../../redux/voucherSlice';


const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  padding: theme.spacing(5, 0),
  color: theme.palette.warning.darker,
  backgroundColor: theme.palette.warning.lighter
}));

const IconWrapperStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
  color: theme.palette.warning.dark,
  backgroundImage: `linear-gradient(135deg, ${alpha(theme.palette.warning.dark, 0)} 0%, ${alpha(
    theme.palette.warning.dark,
    0.24
  )} 100%)`
}));



export default function AppItemOrders() {
  const [vouchers, setvouchers] = useState(0);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(GetAllVoucherExpired()).then((result) => {
      setvouchers(result.payload);
    });
  }, [dispatch]);
  return (
    <RootStyle>
      <IconWrapperStyle>
        <Icon icon="ant-design:barcode-outlined" width={24} height={24} />
      </IconWrapperStyle>
      <Typography variant="h3">{fShortenNumber(vouchers)}</Typography>
      <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
        Vouchers expirés
      </Typography>
    </RootStyle>
  );
}
