// import React, { useEffect, useState } from "react";
// import "antd/dist/antd.css"; 
// import { useFormik } from "formik";
// import * as yup from "yup";
// import { useDispatch } from "react-redux";
// import { getModuleById } from "../../redux//moduleSlice";
// import { UpdateModuleAPI } from "../../utils/api";
// import {
//   Button,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogTitle,
// } from "@mui/material";
// import {
//   Input,
// } from "@material-ui/core";

// const validationSchema = yup.object({
//   titre_modules: yup.string().required("Titre obligatoire"),

// });

// export default function DetailsFormations({ modal, toggleModal, selectedContact, getData }) {
//   const dispatch = useDispatch();
//   const [name, setName] = useState(selectedContact?.titre_modules);
//   const [loading, setloading] = useState(true);
//   const [values, setValues] = useState(true);
//   const clearInputAndClose = () => {
//     toggleModal();
//   };
//   useEffect(() => {
//     setloading(true);
//     dispatch(getModuleById(selectedContact._id)).then((response) => {
//       setloading(false);


//       formik.setValues({
//         titre_modules: response.payload?.titre_modules,

//       }


//       );


//       if (selectedContact != null)
//         setValues({
//           ...selectedContact
//         })
//     });
//   }, [selectedContact._id]);

//   const formik = useFormik({
//     initialValues: {
//       titre_modules: "",

//     },
//     enableReinitialize: true,
//     validationSchema: validationSchema,
//     onSubmit: async (values) => {

//       const Updateobj = {
//         _id: selectedContact._id,
//         ...values,
//       };
    
//       await UpdateModuleAPI(Updateobj);
//       clearInputAndClose();
//       getData();


//     },
//   });




//   return (
//     <div>

//       <Dialog
//         open={modal}
//         onClose={clearInputAndClose}
//         aria-labelledby="alert-dialog-title"
//         aria-describedby="alert-dialog-description"
//       >
//         <DialogTitle id="alert-dialog-title">
//           {"Modifier Module"}
//         </DialogTitle>
//         <br></br>
//         <DialogContent style={{ height: "100px", width: "600px" }}>
     

//           <Input
//             fullWidth
//             name="titre_modules"
//             label="Titre"
//             id="titre_modules"
//             placeholder="Titre"
//             value={name}
//             error={!!(formik.touched?.titre_modules && formik.errors?.titre_modules)}
//             helperText={formik.touched?.titre_modules && formik.errors?.titre_modules}
//             {...formik.getFieldProps("titre_modules")}

//           />

//         </DialogContent>
//         <DialogActions>
//           <Button onClick={clearInputAndClose}>annuler</Button>





//           <Button
//             fullWidth


//             disabled={!formik.isValid}
//             onClick={async () => {
//               await formik.handleSubmit();
//             }}
//             type="button"
//           >
//             Confirmer la mise à jour
//           </Button>
//         </DialogActions>
//       </Dialog>
//     </div>
//   );
// }

import React, { useEffect, useState } from "react";
import "antd/dist/antd.css"; 
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { getModuleById } from "../../redux//moduleSlice";
import { UpdateModuleAPI } from "../../utils/api";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import {
  Input,
} from "@material-ui/core";
import { ToastContainer, toast } from 'react-toastify';

const validationSchema = yup.object({
  titre_modules: yup.string().required("Titre obligatoire"),

});
const configToastr = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  newestOnTop: false,
  closeOnClick: true,
  rtl: false,
  pauseOnFocusLoss: true,
  draggable: true,
  pauseOnHover: true,
};
export default function DetailsFormations({ modal, toggleModal, selectedContact, getData }) {
  const dispatch = useDispatch();
  const [name, setName] = useState(selectedContact?.titre_modules);
  const [loading, setloading] = useState(true);
  const [values, setValues] = useState(true);
  const clearInputAndClose = () => {
    toggleModal();
  };
  useEffect(() => {
    setloading(true);
    dispatch(getModuleById(selectedContact._id)).then((response) => {
      setloading(false);


      formik.setValues({
        titre_modules: response.payload?.titre_modules,

      }


      );


      if (selectedContact != null)
        setValues({
          ...selectedContact
        })
    });
  }, [selectedContact._id]);

  const formik = useFormik({
    initialValues: {
      titre_modules: "",

    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (values) => {

      const Updateobj = {
        _id: selectedContact._id,
        ...values,
      };
    
      await UpdateModuleAPI(Updateobj);
      clearInputAndClose();

      getData();
      toast.success("Module modifié avec succès");


    },
  });




  return (
    <div>

      <Dialog
        open={modal}
        onClose={clearInputAndClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Modifier Module"}
        </DialogTitle>
        <br></br>
        <DialogContent style={{ height: "100px", width: "600px" }}>
     

          <Input
            fullWidth
            name="titre_modules"
            label="Titre"
            id="titre_modules"
            placeholder="Titre"
            value={name}
            error={!!(formik.touched?.titre_modules && formik.errors?.titre_modules)}
            helperText={formik.touched?.titre_modules && formik.errors?.titre_modules}
            {...formik.getFieldProps("titre_modules")}

          />

        </DialogContent>
        <DialogActions>
          <Button onClick={clearInputAndClose}>annuler</Button>





          <Button
            fullWidth


            disabled={!formik.isValid}
            onClick={async () => {
              await formik.handleSubmit();
            }}
            type="button"
          >
            Confirmer la mise à jour
          </Button>
        </DialogActions>
      </Dialog>
      <ToastContainer {...configToastr} />

    </div>
  );
}