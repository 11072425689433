
import { styled } from '@mui/material/styles';
import {   Container } from '@mui/material';
import Page from '../../components/Page';
import { RegisterFormateur } from '../../../src/components/authentication/register';



const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  flexDirection: 'column',
  // justifyContent: 'center',
  padding: theme.spacing(1, 0)
}));


// ----------------------------------------------------------------------

export default function Register() {

  
  return (
    <Page title="Dashboard | Fastuz">
     

      <Container>
        <ContentStyle>
         

          <RegisterFormateur />
          
        </ContentStyle>
      </Container>
    </Page>
  );
}