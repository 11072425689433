import React from "react";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Card,
  Container
} from '@mui/material';
import Page from '../../components/Page';
import CodeVerification from "./CodeVerification";
import "antd/dist/antd.css"; 
import FormulaireProfile from './FormulaireProfile';

export default function Profile() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => ( isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Page title="Dashboard | Fastuz">
      <Container
         component="div"
              transitionName="TabsAnimation"
              transitionAppear={true}
              transitionAppearTimeout={0}
              transitionEnter={false}
              transitionLeave={false}
      >


      <Card>
      

       

<div>
     
      <Accordion isOpen Typography expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Typography sx={{ width: '33%', flexShrink: 0 }}>Profile</Typography>
          <Typography sx={{ color: 'text.secondary' }}>
          Modifier profil
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <FormulaireProfile />

          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Typography sx={{ width: '33%', flexShrink: 0 }}>
            Mot de passe
          </Typography>
          <Typography sx={{ color: 'text.secondary' }}>
           Changer mot de passe
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <CodeVerification/>
          </Typography>
        </AccordionDetails>
      </Accordion>
      
    </div>



          
        </Card>
      </Container>
    </Page>
  );
}
