import { merge } from "lodash";
import ReactApexChart from "react-apexcharts";

import { useTheme, styled } from "@mui/material/styles";
import { Card, CardHeader, List } from "@mui/material";

import { fNumber } from "../../../utils/formatNumber";

import { getListeOfStudents } from "./../../../redux/authSlice";
import { BaseOptionChart } from "../../charts";
import { getAllFormationsPublier } from "../../../redux/formationSlice";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import axios from "axios"
const CHART_HEIGHT = 372;
const LEGEND_HEIGHT = 72;

const ChartWrapperStyle = styled("div")(({ theme }) => ({
  height: CHART_HEIGHT,
  marginTop: theme.spacing(5),
  "& .apexcharts-canvas svg": { height: CHART_HEIGHT },
  "& .apexcharts-canvas svg,.apexcharts-canvas foreignObject": {
    overflow: "visible",
  },
  "& .apexcharts-legend": {
    height: LEGEND_HEIGHT,
    alignContent: "center",
    position: "relative !important",
    borderTop: `solid 1px ${theme.palette.divider}`,
    top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`,
  },
}));



export default function AppCurrentVisits() {
  const theme = useTheme();

  const [formations, setformations] = useState([]);
  const [students, setStudents] = useState([]);
  const [enrolledNumber, setenrolledNumber] = useState([]);
  const [makingApiCall, setMakingApiCall] = useState(false);

  const getNumberOfStudentsEnrolled = async () => {
    axios
      .get(process.env.REACT_APP_URL_BACKEND_DEV + "/getAllEnrolledFormationsD"
      )
      .then((response) => {
       
        setenrolledNumber(response.data.result);
        console.log("response.data.result", response.data.result)
      })
   
    return enrolledNumber;
  };
  const dispatch = useDispatch();
  useEffect(() => {
    if (formations?.length !== 0 && students.length !== 0) {
      getNumberOfStudentsEnrolled();
    }
  }, [formations, students]);

  useEffect(() => {
    setMakingApiCall(true);
    dispatch(getAllFormationsPublier())
      .then((result) => {
        setformations(result.payload);
        dispatch(getListeOfStudents()).then((res) => {
          setStudents(res.payload);
        });
      })
      .finally(() => {
        setMakingApiCall(false);
      });
  }, [dispatch]);


  const getRandomColor = (title) => {
    var letters = "0123456789ABCDEF";
    var color = "#";
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const chartOptions = merge(BaseOptionChart(), {
    colors: formations?.map((formation) => getRandomColor(formation.titre)),
    labels: formations?.map((formation) => formation.titre),
    stroke: { colors: [theme.palette.background.paper] },
    legend: { floating: true, horizontalAlign: "center" },
    dataLabels: { enabled: true, dropShadow: { enabled: false } },
    tooltip: {
      fillSeriesColor: false,
      y: {
        formatter: (seriesName) => fNumber(seriesName) + " inscrits",
        title: {
          formatter: (seriesName) => `#${seriesName}`,
        },
      },
    },
    plotOptions: {
      pie: { donut: { labels: { show: false } } },
    },
  });
  return (
    <Card>
      <CardHeader title="Pourcentage apprenants inscrits" />
      {!makingApiCall ? (
        <ChartWrapperStyle dir="ltr">
          <ReactApexChart
            type="pie"
            series={
              enrolledNumber.length
                ? enrolledNumber.map((enrolled) => enrolled.number)
                : [0, 0]
            }
            options={chartOptions}
            height={280}
          />
        </ChartWrapperStyle>
      ) : (
        <span>Loading...</span>
      )}
    </Card>
  );
}
