import axios from "axios";
export const Url_Back_End = process.env.REACT_APP_URL_BACKEND_DEV;
axios.defaults.withCredentials = true;
export const loginAPI = (utilisateur) => {
  return axios
    .post(Url_Back_End + "/admin/login", utilisateur)
    .catch();
};

export const registerAPI = (utilisateur) => {
  return axios
    .post(Url_Back_End + "/admin/register", utilisateur)
    .catch();
};


export const UploadeFileAPI = async (file, folder) => {
  const formData = new FormData();
  formData.append("file-upload", file);

  const response = await axios
    .post(Url_Back_End + "/uploade_image", formData)
    .catch();
  return response.data;
};

export const modifierLicenceAPI = (formation) => {
  return axios
    .put(Url_Back_End+"/licenceModif/" + formation._id, formation)
    .catch();
};



export const getUserByToken = (token) => {
  return axios
    .post(Url_Back_End + "/admin/verifToken", { token: token })
    .catch();
};

export const UpdateProfile = (profile) => {
  return axios
    .post(Url_Back_End + "/updateProfile", profile)

    .catch();
};

export const VerifyCodePassword = (dataCode) => {
  return axios
    .post(Url_Back_End + "/VerifyCode", dataCode)

    .catch();
};

export const UpdatePassword = (passwords) => {
  return axios
    .post(Url_Back_End + "/updatePassword", passwords)

    .catch();
};

export const SendCodeResetPassword = (email) => {
  return axios
    .post(Url_Back_End + "/forgotpassword", { email: email })

    .catch();
};

export const SendCodeResetPasswordD = (email) => {
  return axios
    .post(Url_Back_End + "/forgot-passwordD", { email: email })

    .catch();
};
export const ResetFormationAPI = (idFormation) => {
  return axios
    .put(Url_Back_End + "/formation/reset/" + idFormation)
    .catch();
};
export const ajoutFormationAPI = (formation) => {

  return axios
    .post(Url_Back_End + "/formation", formation)
    .catch();
};
export const SupprimerFormationAPI = (idFormation) => {
  return axios
    .delete(Url_Back_End + "/formation/" + idFormation)
    .catch();
};
export const getFormationByIdAPI = (idFormation) => {
  return axios
    .get(Url_Back_End +"/formation/" + idFormation)
    .catch();
};
export const getAllFormationArchiveAPI = () => {
  return axios
    .get(Url_Back_End + "/formation/archive")
    .catch();
};
export const getAllFormationAPI = () => {
  return axios
    .get(Url_Back_End + "/formation")
    .catch();
};

export const getAllFormationsPublierAPI = () => {
  return axios
    .get(Url_Back_End + "/getAllFormationsApprenant")
    .catch();
};

export const getAllFormationsFormateurAPI = () => {
  return axios
    .get(Url_Back_End + "/getAllFormationsFormateur")
    .catch();
};

export const getFormateurByIdAPI = (idFormateur) => {
  return axios
    .get(Url_Back_End + "/getFormateurById/" + idFormateur)
    .catch((err) => console.log("Erreur :", err.response.status));
};


export const AddPartnerTechAPI = (obj) => {
  return axios
    .post(Url_Back_End + "/partnerTech/ajouter", obj)
    .catch((err) => console.log("Erreur :", err.response.status));
};

export const UpdatePartnerTechAPI = (obj) => {
  return axios
    .put(Url_Back_End + "/partnerTech/" + obj._id, obj)
    .catch((err) => console.log("Erreur :", err.response.status));
};



export const DeletePartnerTechAPI = (id) => {
  return axios
    .post(Url_Back_End + "/partnerTech/supprimer/", { id: id })
    .catch((err) => console.log("Erreur :", err.response.status));
};

export const getPartnerTechByIdAPI = (idPartner) => {
  return axios
    .get(Url_Back_End + "/partner_detailsTech/" + idPartner)
    .catch((err) => console.log("Erreur :", err.response.status));
};

export const GetAllPartnersTechAPI = () => {
  return axios
    .get(Url_Back_End + "/partner")
    .catch((err) => console.log("Erreur :", err.response.status));
};

export const SupprimerLicenceeAPI = (idLicence) => {
  return axios
    .delete(Url_Back_End + "/licence/" + idLicence)
    .catch((err) => console.log("error :", err.response.status));
};



export const getFormationByFormateurAPI = () => {
  return axios
    .get(Url_Back_End + "/getAllFormationsFormateur")
    .catch();
};


export const PusblishedAPI = () => {
  return axios
    .get(Url_Back_End + "/getAllFormationsApprenant")
    .catch();
};
export const getFormationAPI = () => {
  return axios
    .get(Url_Back_End + "/formations")
    .catch();
};
export const modifierFormationAPI = (formation) => {
  return axios
    .put(Url_Back_End+"/formation/" + formation._id, formation)
    .catch();
};


export const getAllCourseAPI = (idFormation) => {
  return axios
    .get(Url_Back_End + "/course/" + idFormation)
    .catch();
};
export const ajoutCourseAPI = (idModule, module) => {
  return axios
    .post(Url_Back_End + "/course/" + idModule, module)
    .catch();
};

export const UpdateCoursAPI = (course) => {
  return axios
    .put(Url_Back_End + "/course/" + course._id, course)
    .catch();
};

export const getCourseByIdAPI = (idCourse) => {
  return axios
    .get(Url_Back_End + "/course_details/" + idCourse)
    .catch();
};

export const SupprimerCourseAPI = (idCourse) => {
  return axios
    .delete(Url_Back_End + "/course_details/" + idCourse)
    .catch();
};

export const SupprimerUser = (idUser) => {
  return axios
    .delete(Url_Back_End + "/etudiant/desactive/" + idUser)
    .catch();
};
export const SupprimerUserr = () => {
  return axios
    .post(Url_Back_End + "/etudiant/addvoucher")
    .catch();
};

export const desactiveFormation = (idFormation) => {
  return axios
    .delete(Url_Back_End + "/desactiveFormation/" + idFormation)
    .catch();
};

export const SupprimerFormateur = (idUser) => {
  return axios
    .delete(Url_Back_End + "/deleteuser/" + idUser)
    .catch();
};
export const getAllEtudiantAPI = () => {
  return axios
    .get(Url_Back_End + "/students")
    .catch();
};

export const getAllAcheteurAPI = () => {
  return axios
    .get(Url_Back_End + "/getAllAcheteur")
    .catch();
};

export const getAllApprenantsByFormateurAPI = () => {
  return axios
    .get(Url_Back_End + "/getAllApprenantsByFormateur")
    .catch();
};
export const getAllUsersAPI = () => {
  return axios
    .get(Url_Back_End + "/allUsers")
    .catch();
};
export const getAllEtudiantEnrolledAPI = () => {
  return axios
    .get(Url_Back_End + "/enrolledS")
    .catch();
};

export const getAllFormateurs = () => {
  return axios
    .get(Url_Back_End + "/formateurs")
    .catch();
};
// Partie partenaires

export const AddPartnerAPI = (obj) => {
  return axios
    .post(Url_Back_End + "/partner/ajouter", obj)
    .catch();
};

export const UpdatePartnerAPI = (obj) => {
  return axios
    .put(Url_Back_End + "/partner/" + obj._id, obj)
    .catch();
};



export const DeletePartnerAPI = (id) => {
  return axios
    .post(Url_Back_End + "/partner/supprimer/", { id: id })
    .catch();
};

export const GetAllPartnersAPI = () => {
  return axios
    .get(Url_Back_End + "/partner")
    .catch();
};
export const getStudentByIdAPI = (idPartner) => {
  return axios
    .get(Url_Back_End + "/partner_details/" + idPartner)
    .catch();
};
export const getPartnerByIdAPI = (idPartner) => {
  return axios
    .get(Url_Back_End + "/partner_details/" + idPartner)
    .catch();
};
// Partie partenaires



/* ---------------------------|| Voucher || ------------------------------- */
/* -------------------------------------------------------------------------------- */
export const getAllVoucherAPI = () => {
  return axios
    .get(Url_Back_End + "/voucher/" )
    .catch();
};

export const getAllVoucherExpiredAPI = () => {
  return axios
    .get(Url_Back_End + "/getAllVoucherExpired/" )
    .catch();
};

export const getAllVoucherAPII = () => {
  return axios
    .get(Url_Back_End + "/voucherr/" )
    .catch();
};

export const getAllVoucherByStudentAPI = ( idStudent) => {
  return axios
    .get(Url_Back_End + "/voucher/byStudent/" + idStudent)
    .catch();
};

export const UpdateVoucherAPI = (voucher) => {
  return axios
    .put(Url_Back_End + "/voucher/" + voucher._id, voucher)
    .catch();
};

export const AddVoucherAPI = (voucher) => {
  return axios
    .post(Url_Back_End + "/AddVoucher/" ,voucher)
    .catch();
};

export const SupprimerVoucherAPI = (idVoucher) => {
  return axios
    .delete(Url_Back_End + "/voucher/" + idVoucher)
    .catch();
};

export const SuppVoucher = (idVoucher) => {
  return axios
    .delete(Url_Back_End + "/DeleteAll/" )
    .catch();
};


export const getAllTeachersAPI = () => {
  return axios
    .get(Url_Back_End + "/formateurs")
    .catch();
};

export const loginFormateurAPI = (formateur) => {
  return axios
    .post(Url_Back_End + "/formateur/login", formateur)
    .catch();
};

export const SupprimerFormateurAPI = (idFormateur) => {
  return axios
    .delete(Url_Back_End + "/formateur/delete/" + idFormateur)
    .catch();
};
export const UpdateFormateurProfile = (profile) => {
  return axios
    .post(Url_Back_End + "/formateur/updateProfile", profile)

    .catch();
};


export const getFormateurByToken = (token) => {
  return axios
    .post(Url_Back_End + "/formateur/verifToken", { token: token })
    .catch();
};

export const UpdateFormateurPassword = (passwords) => {
  return axios
    .post(Url_Back_End + "/formateur/updatePassword", passwords)

    .catch();
};

export const getAllCommentsAPI = () => {
  return axios
    .get(Url_Back_End + "/review")
    .catch();
};

export const SupprimerReviewAPI = (idReview) => {
  return axios
    .delete(Url_Back_End + "/review/" + idReview)
    .catch();
};


//jobs


export const ajoutJobAPI = (job) => {
  return axios
    .post(Url_Back_End + "/job", job)
    .catch();
};
export const SupprimerJobAPI = (idJob) => {
  return axios
    .delete(Url_Back_End + "/job/" + idJob)
    .catch();
};
export const getJobByIdAPI = (idJob) => {
  return axios
    .get(Url_Back_End + "/job/" + idJob)
    .catch();
};

export const getAllJobsAPI = () => {
  return axios
    .get(Url_Back_End + "/jobs")
    .catch();
};

export const modifierJObAPI = (job) => {
  return axios
    .put(Url_Back_End + "/job/" + job._id, job)
    .catch();
};


export const getAllCandidatAPI = (idCandidat) => {
  return axios
    .get(Url_Back_End + "/candidates/" + idCandidat)
    .catch();
};
export const ajoutCandidatAPI = (candidat, idCandidat) => {
  return axios
    .post(Url_Back_End + "/candidat/" + idCandidat, candidat)
    .catch();
};


export const getCandidatByIdAPI = (idCandidat) => {
  return axios
    .get(Url_Back_End + "/candidatbyid/" + idCandidat)
    .catch();
};

export const SupprimerCandidatAPI = (idCandidat) => {
  return axios
    .delete(Url_Back_End + "/candidat/" + idCandidat)
    .catch();
};


export const getAllDiscussionAPI = () => {
  return axios
    .get(Url_Back_End + "/discussion")
    .catch();
};
export const getAllDiscussionByIdAPI = (idFormation) => {
  return axios
    .get(Url_Back_End + "/discussion/" + idFormation)
    .catch();
};

export const SupprimerDiscussionAPI = (idDisc) => {
  return axios
    .delete(Url_Back_End + "/discussion/" + idDisc)
    .catch();
};


//modulesss


export const getAllModuleAPI = (idFormation) => {
  return axios
    .get(Url_Back_End + "/module/" + idFormation)
    .catch();
};
export const ajoutModuleAPI = (formation, idFormation) => {
  return axios
    .post(Url_Back_End + "/module/" + idFormation, formation)
    .catch();
};

export const UpdateModuleAPI = (module) => {
  return axios
    .put(Url_Back_End + "/module/" + module._id, module)
    .catch();
};

export const getModuleByIdAPI = (idmodule) => {
  return axios
    .get(Url_Back_End + "/moduleDetails/" + idmodule)
    .catch();
};

export const SupprimerModuleAPI = (idmodule) => {
  return axios
    .delete(Url_Back_End + "/module/" + idmodule)
    .catch();
};



export const getAllFormationsByFormateurAPI = () => {
  return axios
    .get(Url_Back_End + "/formationbyformateur")
    .catch();
};


export const getAllContactAPI = () => {
  return axios
    .get(Url_Back_End + "/contacts")
    .catch();
};

export const getContactByIdAPI = (idContact) => {
  return axios
    .get(Url_Back_End + "/contact/" + idContact)
    .catch();
};
export const SupprimerContactAPI = (idContact) => {
  return axios
    .delete(Url_Back_End + "/contact/" + idContact)
    .catch();
};









export const disableAccountAPI = (utilisateur) => {
  return axios
    .post(Url_Back_End + "/updateUtilisateur", {
      ...utilisateur,
      desactive: true,
    })
    .catch();
};
export const enableAccountAPI = (utilisateur) => {
  return axios
    .post(Url_Back_End + "/updateUtilisateur", {
      ...utilisateur,
      desactive: false,
    })
    .catch();
};
// Partie Login , réinitialisation du mot de passe et profile

// Partie référenceur

export const getAllReferentsAPI = () => {
  return axios
    .get(Url_Back_End + "/getAllReferenceur")
    .catch();
};

export const ajoutReferentAPI = (utilisateur) => {
  return axios
    .post(Url_Back_End + "/registerUtilisateur", {
      ...utilisateur,
      role: "référenceur",
      username: utilisateur.email,
    })
    .catch();
};

export const supprimerReferentAPI = ({ email }) => {
  return axios
    .post(Url_Back_End + "/removeUtilisateur", {
      email,
    })
    .catch();
};

// Partie référenceur

// Partie Formateur

export const getAllFormateursAPI = () => {
  return axios
    .get(Url_Back_End + "/formateurs")
    .catch();
};

export const getAllBusinessAPI = () => {
  return axios
    .get(Url_Back_End + "/business")
    .catch();
};
export const getAllFormateursExternsAPI = () => {
  return axios
    .get(Url_Back_End + "/getAllFormateurExtern")
    .catch();
};
export const getListeOfStudentEnrolledAPI = () => {
  return axios
    .get(Url_Back_End + "/getListEnrolledStudents")
    .catch();
};

export const ajoutFormateurAPI = (utilisateur) => {
  return axios
    .post(Url_Back_End + "/registerUtilisateur", {
      ...utilisateur,
      role: "formateur",
      username: utilisateur.email,
    })
    .catch();
};
export const modifierFormateurAPI = (utilisateur) => {
  return axios
    .put(Url_Back_End + "/updateUtilisateur/" + utilisateur.id, {
      ...utilisateur,
    })
    .catch();
};
// Partie Formateur

// Partie Administrateur
export const modifierAdminAPI = (utilisateur) => {
  return axios
    .put(Url_Back_End + "/updateUtilisateur/" + utilisateur.id, {
      ...utilisateur,
    })
    .catch();
};

export const ajoutAdminAPI = (utilisateur) => {
  return axios
    .post(Url_Back_End + "/registerUtilisateur", {
      ...utilisateur,
      role: "admin",
      username: utilisateur.email,
    })
    .catch();
};

export const getAllAdminsAPI = () => {
  return axios
    .get(Url_Back_End + "/getAllAdmin")
    .catch();
};

// Partie Administrateur

// Partie Catégorie
export const ajoutCategorieAPI = (categorie) => {
  return axios
    .post(Url_Back_End + "/addCategorie", {
      ...categorie,
    })
    .catch();
};

export const getAllCategoriesAPI = () => {
  return axios
    .get(Url_Back_End + "/getAllCategorie")
    .catch();
};

export const supprimerCategorieAPI = (idCategorie) => {
  return axios
    .post(Url_Back_End + "/removeCategorie", {
      idCategorie,
    })
    .catch();
};

// Partie Catégorie

// Partie Sous Catégorie

export const ajoutSousCategorieAPI = (SousCategorie) => {
  return axios
    .post(Url_Back_End + "/addSousCategorie", {
      ...SousCategorie,
    })
    .catch();
};

export const supprimerSousCategorieAPI = (idsousCategorie) => {
  return axios
    .delete(Url_Back_End + "/removeSousCategorie/" + idsousCategorie)
    .catch();
};

export const getAllSousCategoriesAPI = () => {
  return axios
    .get(Url_Back_End + "/getAllSousCategorie")
    .catch();
};

// Partie Sous Catégorie

// Partie Uploade File



// Partie Uploade File

// Partie Etudiant

export const ajoutEtudiantAPI = (Etudiant) => {
  return axios
    .post(Url_Back_End + "/addEtudiant", {
      ...Etudiant,
    })
    .catch();
};

export const supprimerEtudiantAPI = (idEtudiant) => {
  return axios
    .delete(Url_Back_End + "/removeEtudiant/" + idEtudiant)
    .catch();
};


export const FindEtudiantByIdAPI = (idEtudiant) => {
  return axios
    .get(Url_Back_End + "/FindEtudiantById/" + idEtudiant)
    .catch();
};

export const ModifierEtudiantAPI = (newEtudiant) => {
  return axios
    .put(Url_Back_End + "/updateEtudiant/" + newEtudiant._id, newEtudiant)
    .catch();
};
export const disableEtudiantAPI = (utilisateur) => {
  return axios
    .put(Url_Back_End + "/updateEtudiant/" + utilisateur._id, {
      ...utilisateur,
      desactive: true,
    })
    .catch();
};
export const enableEtudiantAPI = (utilisateur) => {
  return axios
    .put(Url_Back_End + "/updateEtudiant/" + utilisateur._id, {
      ...utilisateur,
      desactive: false,
    })
    .catch();
};
export const resetStudentConnexion = (idUtilisateur) => {
  return axios
    .put(Url_Back_End + "/resetStatusEtudiant/" + idUtilisateur)
    .catch();
};

// Partie Etudiant

//Partie leçon
export const AjouterLeconAPI = (lecon) => {
  return axios
    .post(Url_Back_End + "/lecon/", lecon)
    .catch();
};
export const ModifierLeconApi = (lecon) => {
  return axios
    .put(Url_Back_End + "/lecon/" + lecon._id, lecon)
    .catch();
};
export const getLeconByIdAPI = (id) => {
  return axios
    .get(Url_Back_End + "/lecon/" + id)
    .catch();
};
export const disableLeconAPI = (id) => {
  return axios
    .delete(Url_Back_End + "/lecon/" + id)
    .catch();
};
export const restoreLeconAPI = (id) => {
  return axios
    .post(Url_Back_End + "/lecon/" + id + "/restore")
    .catch();
};
export const ModifierLeconsOrdreApi = (lecons) => {
  return axios
    .put(Url_Back_End + "/lecons/", { lecons })
    .catch();
};
//Partie leçon

export const getAllTypesOfFormationAPI = () => {
  return axios
    .get(Url_Back_End + "/allFormation")
    .catch();
};

export const getAllFormationByFormateurAPI = (idFormateur) => {
  return axios
    .post(Url_Back_End + "/formationByFormateur", { idFormateur: idFormateur })
    .catch();
};


export const getAllFormationsExterneAPI = () => {
  return axios
    .get(Url_Back_End + "/formation/externe")
    .catch();
};

export const getProgressionFormationByIdAPI = (idFormation) => {
  return axios
    .get(Url_Back_End + "/all/avancement-formation/" + idFormation)
    .catch();
};


export const AffecterEtudiantAuFormationAPI = (obj) => {
  return axios
    .put(Url_Back_End + "/formation/affecter/" + obj.idFormation, obj)
    .catch();
};

export const SupprimerEtudiantDuFormationAPI = (obj) => {
  return axios
    .put(Url_Back_End + "/formation/supprimerEtudiant/" + obj.idFormation, obj)
    .catch();
};

export const getFormationBySousCategoriePagination = (idSousCategorie) => {
  return axios
    .get(
      Url_Back_End + "/getFormationBySousCategoriePagination/" + idSousCategorie
    )
    .catch();
};
export const getFormationByCategorie = (idCategorie) => {
  return axios
    .get(Url_Back_End + "/formationByCategorie/" + idCategorie)
    .catch();
};

//Partie formation


//partie chat

export const getAllMessagesChat = (formationId, userId, timestamp = 0) => {
  return axios
    .get(
      `${Url_Back_End}/messages/${formationId}/${userId}`
    )
    .catch();
};

export const getUnseenMessageFormateur = (idFormateur) => {
  return axios
    .get(`${Url_Back_End}/messages/unseenFormateur/${idFormateur}`)
    .catch();
};

export const markMessageAsSeen = (object) => {
  return axios
    .put(`${Url_Back_End}/messages/markSeent/${object.idMessage}`, {
      type: object.type,
    })
    .catch();
};

export const markMessageAsSeenOnFocus = (object) => {
  return axios
    .put(`${Url_Back_End}/messages/markSeentOnFocus/`, {
      idFormation: object.idFormation,
      idEtudiant: object.idEtudiant,
    })
    .catch();
};

// Partie Témoignage

export const AddTemoignageAPI = (obj) => {
  return axios
    .post(Url_Back_End + "/temoignage/ajouter", obj)
    .catch();
};

export const UpdateTemoignageAPI = (obj) => {
  return axios
    .put(Url_Back_End + "/temoignage/update/" + obj.id, obj)
    .catch();
};

export const DeleteTemoignageAPI = (id) => {
  return axios
    .post(Url_Back_End + "/temoignage/supprimer/", { id: id })
    .catch();
};
// Partie Témoignage

// Partie Statistiques

export const AddStatAPI = (obj) => {
  return axios
    .post(Url_Back_End + "/stats/ajouter", obj)
    .catch();
};

export const UpdateStateAPI = (obj) => {
  return axios
    .put(Url_Back_End + "/stats/update/" + obj.id, obj)
    .catch();
};

export const DeleteStatAPI = (id) => {
  return axios
    .post(Url_Back_End + "/stats/supprimer/", { id: id })
    .catch();
};

export const GetAllStatAPI = () => {
  return axios
    .get(Url_Back_End + "/stats")
    .catch();
};


export const AddInfoGeneraleAPI = (obj) => {
  return axios
    .post(Url_Back_End + "/infoGenerale/ajouter", obj)
    .catch();
};

export const UpdateInfoGeneraleAPI = (obj) => {
  return axios
    .put(Url_Back_End + "/infoGenerale/update/" + obj.id, obj)
    .catch();
};

export const GetInfoGeneraleAPI = () => {
  return axios
    .get(Url_Back_End + "/infoGenerale")
    .catch();
};



export const GetConexionByUserIdAPI = (obj) => {
  return axios
    .post(Url_Back_End + "/connexions", obj)
    .catch();
};



export const AddSpecialiteAPI = (obj) => {
  return axios
    .post(Url_Back_End + "/specialite/ajouter", obj)
    .catch();
};

export const UpdateSpecialiteAPI = (obj) => {
  return axios
    .put(Url_Back_End + "/specialite/update/" + obj.id, obj)
    .catch();
};

export const DeleteSpecialiteAPI = (id) => {
  return axios
    .delete(Url_Back_End + "/specialite/supprimer/" + id)
    .catch();
};

export const GetAllSpecialitesAPI = () => {
  return axios
    .get(Url_Back_End + "/specialite")
    .catch();
};
export const GetSpecialiteByIdAPI = (id) => {
  return axios
    .get(Url_Back_End + "/specialite/findById" + id)
    .catch();
};

export const addEvenementAPI = (evenement) => {
  return axios
    .post(Url_Back_End + "/addEvenement", evenement)
    .catch();
};
export const removeEvenementAPI = (evenementId) => {
  return axios
    .delete(Url_Back_End + "/removeEvenement/" + evenementId)
    .catch();
};
export const getAllEvenementAPI = (idFormateur, start, end) => {
  return axios
    .get(
      Url_Back_End + `/getAllEvenement/${idFormateur}?start=${start}&end=${end}`
    )
    .catch();
};
export const getEvenementByEtudiantAPI = (idEtudiant) => {
  return axios
    .get(Url_Back_End + `/getEventByEtudiant/${idEtudiant}`)
    .catch();
};
export const updateEvenementAPI = (evenement) => {
  return axios
    .put(Url_Back_End + `/updateEvenement/${evenement._id}`, evenement)
    .catch();
};

export const updateStatusEvenementAPI = (idEvenemnt) => {
  return axios
    .put(Url_Back_End + `/changeEtatEvent/${idEvenemnt}`)
    .catch();
};

export const addDisponibiliteAPI = (disponibilite) => {
  return axios
    .post(Url_Back_End + "/addDisponibilite", disponibilite)
    .catch();
};
export const removeDisponibiliteAPI = (disponibiliteId) => {
  return axios
    .delete(Url_Back_End + "/removeDisponibilite/" + disponibiliteId)
    .catch();
};
export const getAllDisponibiliteAPI = (idFormateur, start, end) => {
  return axios
    .get(
      Url_Back_End +
        `/getAllDisponibilite/${idFormateur}?start=${start}&end=${end}`
    )
    .catch();
};
export const updateDisponibiliteAPI = (disponibilite) => {
  return axios
    .put(
      Url_Back_End + `/updateDisponibilite/${disponibilite._id}`,
      disponibilite
    )
    .catch();
};

export const sendEmailPlanificationAPI = (planification) => {
  return axios
    .post(Url_Back_End + `/send-planification-etudiant`, planification)
    .catch();
};

export const ajouterChapitreAPI = (chapitre) => {
  return axios
    .post(Url_Back_End + `/chapitre`, chapitre)
    .catch();
};
export const modifierChapitreAPI = (chapitre) => {
  return axios
    .put(Url_Back_End + `/chapitre`, chapitre)
    .catch();
};


export const addCGVAPI = (cgv) => {
  return axios
    .post(Url_Back_End + `/cgv/ajouter`, cgv)
    .catch();
};
export const updateCGVAPI = (cgv) => {
  return axios
    .put(Url_Back_End + `/cgv/update/${cgv._id}`, cgv)
    .catch();
};
export const getCGVAPI = () => {
  return axios
    .get(Url_Back_End + `/cgv`)
    .catch();
};



export const addReglementInterieurAPI = (reglement) => {
  return axios
    .post(Url_Back_End + `/reglement/ajouter`, reglement)
    .catch();
};
export const updateReglementInterieurAPI = (reglement) => {
  return axios
    .put(Url_Back_End + `/reglement/update/${reglement._id}`, reglement)
    .catch();
};
export const getReglementInterieurAPI = () => {
  return axios
    .get(Url_Back_End + `/reglement`)
    .catch();
};


export const addMentionLegaleAPI = (mention) => {
  return axios
    .post(Url_Back_End + `/mention/ajouter`, mention)
    .catch();
};
export const updateMentionLegaleAPI = (mention) => {
  return axios
    .put(Url_Back_End + `/mention/update/${mention._id}`, mention)
    .catch();
};
export const getMentionLegaleAPI = () => {
  return axios
    .get(Url_Back_End + `/mention`)
    .catch();
};



export const addPolitiqueAPI = (politique) => {
  return axios
    .post(Url_Back_End + `/politique/ajouter`, politique)
    .catch();
};
export const updatePolitiqueAPI = (politique) => {
  return axios
    .put(Url_Back_End + `/politique/update/${politique._id}`, politique)
    .catch();
};
export const getPolitiqueAPI = () => {
  return axios
    .get(Url_Back_End + `/politique`)
    .catch();
};


export const getDemandesSéancesAPI = () => {
  return axios
    .get(Url_Back_End + `/getAllDemandes`)
    .catch();
};
export const changeEtatDemandesSéancesAPI = (obj) => {
  return axios
    .put(Url_Back_End + `/changeEtatDemande/${obj._id}`, obj)
    .catch();
};

export const CheckFormateurAvailabilityAPI = (obj) => {
  return axios
    .get(
      Url_Back_End +
        `/verifDosponibiliteFormateur/${obj.idFormateur}?start=${obj.start}&end=${obj.end}`
    )
    .catch();
};



export const addNewCentreCertificationAPI = (newCentre) => {
  return axios
    .post(Url_Back_End + "/centreCertificateur", newCentre)
    .catch();
};

export const deleteCentreCertificationAPI = (id) => {
  return axios
    .delete(Url_Back_End + "/centreCertificateur/" + id)
    .catch();
};
export const getAllCentreCertificationAPI = () => {
  return axios
    .get(Url_Back_End + "/centreCertificateur/")
    .catch();
};
export const updateCentreCertificationAPI = (updatedCentre) => {
  return axios
    .put(
      Url_Back_End + "/centreCertificateur/" + updatedCentre._id,
      updatedCentre
    )
    .catch();
};
export const getCentreCertificationByIdAPI = (id) => {
  return axios
    .get(Url_Back_End + "/centreCertificateur/" + id)
    .catch();
};
export const getNbrCertificationByCentreAPI = (id) => {
  return axios
    .get(Url_Back_End + "/Certification/findByCentre/" + id)
    .catch();
};


export const addNewCertificationAPI = (newCertification) => {
  return axios
    .post(Url_Back_End + "/Certification", newCertification)
    .catch();
};

export const deleteCertificationAPI = (id) => {
  return axios
    .delete(Url_Back_End + "/Certification/" + id)
    .catch();
};
export const getAllCertificationsAPI = () => {
  return axios
    .get(Url_Back_End + "/Certification/")
    .catch();
};
export const updateCertificationAPI = (updatedCertification) => {
  return axios
    .put(
      Url_Back_End + "/Certification/" + updatedCertification._id,
      updatedCertification
    )
    .catch();
};
export const getCertificationByIdAPI = (id) => {
  return axios
    .get(Url_Back_End + "/Certification/" + id)
    .catch();
};


export const affecterCertifToFormationAPI = (newCertifFormation) => {
  return axios
    .post(Url_Back_End + "/Certif-Formation", newCertifFormation)
    .catch();
};
export const updateCertifToFormationAPI = (updatedCertifFormation) => {
  return axios
    .put(
      Url_Back_End + "/Certif-Formation/" + updatedCertifFormation._id,
      updatedCertifFormation
    )
    .catch();
};
export const findCertificationByFormationAPI = (idFormation) => {
  return axios
    .get(Url_Back_End + "/Certif-Formation/" + idFormation)
    .catch();
};

export const GetAllConnexionsAPI = () => {
  return axios
    .get(Url_Back_End + "/connexions")
    .catch();
};

export const GetAllConnexionsFormateursAPI = () => {
  return axios
    .get(Url_Back_End + "/connexionsFormateurs")
    .catch();
};

export const getAllLicenceAPI = () => {
  return axios
    .get(Url_Back_End +"/getAllLicences")
    .catch();
};

export const getLicenceByIdAPI = (idLicence) => {
  return axios
    .get(Url_Back_End + "/getLicenceById/" + idLicence)
    .catch();
};

export const SupprimerLicenceAPI = (idLicence) => {
  return axios
    .delete(Url_Back_End + "/licence/" + idLicence)
    .catch((err) => console.log("error :", err.response.status));
};

// Partie Question

export const ajoutQuestionAPI = (Question) => {
  return axios
    .post( Url_Back_End +"/addQuestion", {
      ...Question,
    })
    .catch();
};

export const modifierQuestionAPI = (Question) => {
  return axios
    .post(Url_Back_End +"/updateQuestion", {
      ...Question,
    })
    .catch();
};

export const ajoutQuizzAPI = (Quizz, formationId) => {
  return axios
    .post(Url_Back_End +`/addQuizz/${formationId}`, {
      ...Quizz,
    })
    .catch();
};

export const modifierQuizzAPI = (Quizz) => {
  return axios
    .post( Url_Back_End +"/updateQuizz", {
      ...Quizz,
    })
    .catch();
};

export const getAllQuizzAPI = () => {
  return axios
    .get (Url_Back_End +"/getAllQuizz")
    .catch();
};

export const getQuizzByIdAPI = (idQuizz) => {
  return axios
    .get( Url_Back_End +"/FindQuizzById/" + idQuizz)
    .catch();
};

export const ajoutCertiffAPI = (Quizz, formationId) => {
  return axios
    .post(Url_Back_End +`/addCertiff/${formationId}`, {
      ...Quizz,
    })
    .catch();
};

export const modifierCertiffAPI = (Quizz) => {
  return axios
    .post( Url_Back_End +"/updateCertiff", {
      ...Quizz,
    })
    .catch();
};

export const getAllCertiffAPI = () => {
  return axios
    .get (Url_Back_End +"/getAllCertiff")
    .catch();
};

export const getCertiffByIdAPI = (idQuizz) => {
  return axios
    .get( Url_Back_End +"/FindCertiffById/" + idQuizz)
    .catch();
};

export const ajoutquizCourseAPI = (Quizz, formationId) => {
  return axios
    .post(Url_Back_End +`/addquizCourse/${formationId}`, {
      ...Quizz,
    })
    .catch();
};

export const modifierquizCourseAPI = (Quizz) => {
  return axios
    .post( Url_Back_End +"/updatequizCourse", {
      ...Quizz,
    })
    .catch();
};

export const getAllquizCourseAPI = () => {
  return axios
    .get (Url_Back_End +"/getAllquizCourse")
    .catch();
};

export const getquizCourseByIdAPI = (idQuizz) => {
  return axios
    .get( Url_Back_End + "/FindquizCourseById/" + idQuizz)
    .catch();
};

export const GetAllCategorieAPI = () => {
  return axios
    .get(Url_Back_End + "/categorie")
    .catch((err) => console.log("Erreur :", err.response.status));
};

export const getCategorieByIdAPI = (idCategorie) => {
  return axios
    .get(Url_Back_End + "/categorie_details/" + idCategorie)
    .catch((err) => console.log("Erreur :", err.response.status));
};

export const DeleteCategorieAPI = (id) => {
  return axios
    .post(Url_Back_End + "/categorie/supprimer/", { id: id })
    .catch((err) => console.log("Erreur :", err.response.status));
};

export const AddCategorieAPI = (obj) => {
  return axios
    .post(Url_Back_End + "/categorie/ajouter", obj)
    .catch((err) => console.log("Erreur :", err.response.status));
};

export const UpdateCategorieAPI = (obj) => {
  return axios
    .put(Url_Back_End + "/categorie/" + obj._id, obj)
    .catch((err) => console.log("Erreur :", err.response.status));
};





export const GetAllContratVAPI = () => {
  return axios
    .get(Url_Back_End + "/contratV")
    .catch((err) => console.log("Erreur :", err.response.status));
};
export const getContratVeByIdAPI = (idCategorie) => {
  return axios
    .get(Url_Back_End + "/getContrat/" + idCategorie)
    .catch((err) => console.log("Erreur :", err.response.status));
};
export const DeleteContratVAPI = (id) => {
  return axios
    .post(Url_Back_End + "/contratV/supprimer/", { id: id })
    .catch((err) => console.log("Erreur :", err.response.status));
};
export const AddContratVAPI = (obj) => {
  return axios
    .post(Url_Back_End + "/contratV/ajouter", obj)
    .catch((err) => console.log("Erreur :", err.response.status));
};

export const UpdateContratVAPI = (obj) => {
  return axios
    .put(Url_Back_End + "/contratV/update/" + obj._id, obj)
    .catch((err) => console.log("Erreur :", err.response.status));
};




export const GetAllConditionUAPI = () => {
  return axios
    .get(Url_Back_End + "/conditionU")
    .catch((err) => console.log("Erreur :", err.response.status));
};

export const DeleteConditionUAPI = (id) => {
  return axios
    .post(Url_Back_End + "/conditionU/supprimer", { id: id })
    .catch((err) => console.log("Erreur :", err.response.status));
};
export const AddConditionUAPI = (obj) => {
  return axios
    .post(Url_Back_End + "/conditionU/ajouter", obj)
    .catch((err) => console.log("Erreur :", err.response.status));
};

export const UpdateConditionUAPI = (obj) => {
  return axios
    .put(Url_Back_End + "/conditionU/update/" + obj._id, obj)
    .catch((err) => console.log("Erreur :", err.response.status));
};
export const getConditionUeByIdAPI = (idCategorie) => {
  return axios
    .get(Url_Back_End + "/getCondition/" + idCategorie)
    .catch((err) => console.log("Erreur :", err.response.status));
};









export const GetAllContratVFormateurAPI = () => {
  return axios
    .get(Url_Back_End + "/contratVFormateur")
    .catch((err) => console.log("Erreur :", err.response.status));
};
export const getContratVFormateureByIdAPI = (idCategorie) => {
  return axios
    .get(Url_Back_End + "/getContratFormateur/" + idCategorie)
    .catch((err) => console.log("Erreur :", err.response.status));
};
export const DeleteContratVFormateurAPI = (id) => {
  return axios
    .post(Url_Back_End + "/contratVFormateur/supprimer/", { id: id })
    .catch((err) => console.log("Erreur :", err.response.status));
};
export const AddContratVFormateurAPI = (obj) => {
  return axios
    .post(Url_Back_End + "/contratVFormateur/ajouter", obj)
    .catch((err) => console.log("Erreur :", err.response.status));
};

export const UpdateContratVFormateurAPI = (obj) => {
  return axios
    .put(Url_Back_End + "/contratVFormateur/update/" + obj._id, obj)
    .catch((err) => console.log("Erreur :", err.response.status));
};


export const desactiveReglement = (idFormation) => {
  return axios
    .delete(Url_Back_End + "/desactiveReglement/" + idFormation)
    .catch();
};


export const GetAllConditionUFormateurAPI = () => {
  return axios
    .get(Url_Back_End + "/conditionUFormateur")
    .catch((err) => console.log("Erreur :", err.response.status));
};

export const DeleteConditionUFormateurAPI = (id) => {
  return axios
    .post(Url_Back_End + "/conditionUFormateur/supprimer", { id: id })
    .catch((err) => console.log("Erreur :", err.response.status));
};
export const AddConditionUFormateurAPI = (obj) => {
  return axios
    .post(Url_Back_End + "/conditionUFormateur/ajouter", obj)
    .catch((err) => console.log("Erreur :", err.response.status));
};

export const UpdateConditionUFormateurAPI = (obj) => {
  return axios
    .put(Url_Back_End + "/conditionUFormateur/update/" + obj._id, obj)
    .catch((err) => console.log("Erreur :", err.response.status));
};
export const getConditionUFormateureByIdAPI = (idCategorie) => {
  return axios
    .get(Url_Back_End + "/getConditionFormateur/" + idCategorie)
    .catch((err) => console.log("Erreur :", err.response.status));
};


// Partie partenairesIndustriels

export const AddPartnerIndustrielAPI = (obj) => {
  return axios
    .post(Url_Back_End + "/PartIndustriel/ajouter", obj)
    .catch();
};

export const UpdatePartnerIndustrielAPI = (obj) => {
  return axios
    .put(Url_Back_End + "/PartIndustriel/" + obj._id, obj)
    .catch();
};



export const DeletePartnerIndustrielAPI = (id) => {
  return axios
    .post(Url_Back_End + "/PartIndustriel/supprimer/", { id: id })
    .catch();
};

export const GetAllPartnersIndustrielsAPI = () => {
  return axios
    .get(Url_Back_End + "/PartIndustriel")
    .catch();
};
export const getPartnerIndustrielsByIdAPI = (idPartner) => {
  return axios
    .get(Url_Back_End + "/PartIndustriel_details/" + idPartner)
    .catch();
};

// Partie partenairesPartIndustriels


// Partie partenairesInstitutionnel

export const AddPartnerInstitutionnelAPI = (obj) => {
  return axios
    .post(Url_Back_End + "/PartInstitutionnel/ajouter", obj)
    .catch();
};

export const UpdatePartnerInstitutionnelAPI = (obj) => {
  return axios
    .put(Url_Back_End + "/PartInstitutionnel/" + obj._id, obj)
    .catch();
};



export const DeletePartnerInstitutionnelAPI = (id) => {
  return axios
    .post(Url_Back_End + "/PartInstitutionnel/supprimer/", { id: id })
    .catch();
};

export const GetAllPartnersInstitutionnelsAPI = () => {
  return axios
    .get(Url_Back_End + "/PartInstitutionnel")
    .catch();
};

export const getPartnerInstitutionnelByIdAPI = (idPartner) => {
  return axios
    .get(Url_Back_End + "/PartInstitutionnel_details/" + idPartner)
    .catch();
};
// Partie partenairesPartInstitutionnels


// typeRecc

export const GetAllTypeRecAPI = () => {
  return axios
    .get(Url_Back_End + "/typeRec")
    .catch((err) => console.log("Erreur :", err.response.status));
};

export const getTypeRecByIdAPI = (idCategorie) => {
  return axios
    .get(Url_Back_End + "/typeRec_details/" + idCategorie)
    .catch((err) => console.log("Erreur :", err.response.status));
};

export const DeleteTypeRecAPI = (id) => {
  return axios
    .post(Url_Back_End + "/typeRec/supprimer/", { id: id })
    .catch((err) => console.log("Erreur :", err.response.status));
};

export const AddTypeRecAPI = (obj) => {
  return axios
    .post(Url_Back_End + "/typeRec/ajouter", obj)
    .catch((err) => console.log("Erreur :", err.response.status));
};

export const UpdateTypeRecAPI = (obj) => {
  return axios
    .put(Url_Back_End + "/typeRec/" + obj._id, obj)
    .catch((err) => console.log("Erreur :", err.response.status));
};

// typerecc


export const GetAllTypeProdAPI = () => {
  return axios
    .get(Url_Back_End + "/typeProd")
    .catch((err) => console.log("Erreur :", err.response.status));
};

export const getTypeProdByIdAPI = (idCategorie) => {
  return axios
    .get(Url_Back_End + "/typeProd_details/" + idCategorie)
    .catch((err) => console.log("Erreur :", err.response.status));
};

export const DeleteTypeProdAPI = (id) => {
  return axios
    .post(Url_Back_End + "/typeProd/supprimer/", { id: id })
    .catch((err) => console.log("Erreur :", err.response.status));
};

export const AddTypeProdAPI = (obj) => {
  return axios
    .post(Url_Back_End + "/typeProd/ajouter", obj)
    .catch((err) => console.log("Erreur :", err.response.status));
};

export const UpdateTypeProdAPI = (obj) => {
  return axios
    .put(Url_Back_End + "/typeProd/" + obj._id, obj)
    .catch((err) => console.log("Erreur :", err.response.status));
};

export const GetAllNomPropAPI = () => {
  return axios
    .get(Url_Back_End + "/nomProp")
    .catch((err) => console.log("Erreur :", err.response.status));
};

export const getNomPropByIdAPI = (idCategorie) => {
  return axios
    .get(Url_Back_End + "/nomProp_details/" + idCategorie)
    .catch((err) => console.log("Erreur :", err.response.status));
};

export const DeleteNomPropAPI = (id) => {
  return axios
    .post(Url_Back_End + "/nomProp/supprimer/", { id: id })
    .catch((err) => console.log("Erreur :", err.response.status));
};

export const AddNomPropAPI = (obj) => {
  return axios
    .post(Url_Back_End + "/nomProp/ajouter", obj)
    .catch((err) => console.log("Erreur :", err.response.status));
};

export const UpdateNomPropAPI = (obj) => {
  return axios
    .put(Url_Back_End + "/nomProp/" + obj._id, obj)
    .catch((err) => console.log("Erreur :", err.response.status));
};



export const GetAllPubliciteAPI = () => {
  return axios
    .get(Url_Back_End + "/publicite")
    .catch((err) => console.log("Erreur :", err.response.status));
};

export const getPubliciteByIdAPI = (idCategorie) => {
  return axios
    .get(Url_Back_End + "/publicite_details/" + idCategorie)
    .catch((err) => console.log("Erreur :", err.response.status));
};

export const DeletePubliciteAPI = (id) => {
  return axios
    .post(Url_Back_End + "/publicite/supprimer/", { id: id })
    .catch((err) => console.log("Erreur :", err.response.status));
};

export const AddPubliciteAPI = (obj) => {
  return axios
    .post(Url_Back_End + "/publicite/ajouter", obj)
    .catch((err) => console.log("Erreur :", err.response.status));
};

export const UpdatePubliciteAPI = (obj) => {
  return axios
    .put(Url_Back_End + "/publicite/" + obj._id, obj)
    .catch((err) => console.log("Erreur :", err.response.status));
};
export const desactivePublicite = (idFormation) => {
  return axios
    .delete(Url_Back_End + "/desactivePublicite/" + idFormation)
    .catch();
};



//typeFormation


export const GetAllTypeFormationAPI = () => {
  return axios
    .get(Url_Back_End + "/typeFormation")
    .catch((err) => console.log("Erreur :", err.response.status));
};

export const getTypeFormationByIdAPI = (idCategorie) => {
  return axios
    .get(Url_Back_End + "/typeFormation_details/" + idCategorie)
    .catch((err) => console.log("Erreur :", err.response.status));
};

export const DeleteTypeFormationAPI = (id) => {
  return axios
    .post(Url_Back_End + "/typeFormation/supprimer/", { id: id })
    .catch((err) => console.log("Erreur :", err.response.status));
};

export const AddTypeFormationAPI = (obj) => {
  return axios
    .post(Url_Back_End + "/typeFormation/ajouter", obj)
    .catch((err) => console.log("Erreur :", err.response.status));
};

export const UpdateTypeFormationAPI = (obj) => {
  return axios
    .put(Url_Back_End + "/typeFormation/" + obj._id, obj)
    .catch((err) => console.log("Erreur :", err.response.status));
};

//Impot

export const GetAllImpotsAPI = () => {
  return axios
    .get(Url_Back_End + "/getImpots")
    .catch((err) => console.log("Erreur :", err.response.status));
};

export const getImpotByIdAPI = (idCategorie) => {
  return axios
    .get(Url_Back_End + "/impots_details/" + idCategorie)
    .catch((err) => console.log("Erreur :", err.response.status));
};


export const AddImpotAPI = (obj) => {
  return axios
    .post(Url_Back_End + "/addImpot", obj)
    .catch((err) => console.log("Erreur :", err.response.status));
};

export const UpdateImpotAPI = (obj) => {
  return axios
    .put(Url_Back_End + "/updateImpot/" + obj._id, obj)
    .catch((err) => console.log("Erreur :", err.response.status));
};

export const UpdatePourcentageRevenuAPI = (obj) => {
  return axios
    .put(Url_Back_End + "/updatePourcentageRevenus/" + obj._id, obj)
    .catch((err) => console.log("Erreur :", err.response.status));
};