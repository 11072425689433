import React from 'react';
import { Alert, Spinner } from 'reactstrap';
import Link from 'next/link';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { handleLogin } from '../../../utils/AuthStorage';
import { Stack, TextField, IconButton, InputAdornment } from '@mui/material';
import { useState, useEffect } from 'react';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { LoadingButton } from '@mui/lab';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { registerAPI } from '../../../utils/api';
import { ToastContainer, toast } from 'react-toastify';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import parse from "html-react-parser";
import { MenuItem } from '@mui/material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800, // Set the fixed width
  maxHeight: '80vh', // Set the maximum height to allow scrolling
  overflowY: 'auto', // Enable vertical scrolling when content exceeds the maximum height
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const INITIAL_USER = {
  nom: '',
  prenom: '',
  email: '',
  password: '',
  confirmPassword: '',
  accepte: false, // Update accepte to be a boolean

};

const configToastr = {
  position: 'top-right',
  autoClose: 5000,
  hideProgressBar: false,
  newestOnTop: false,
  closeOnClick: true,
  rtl: false,
  pauseOnFocusLoss: true,
  draggable: true,
  pauseOnHover: true
};
const validationSchema = yup.object({
  nom: yup.string().required('Nom obligatoire'),
  prenom: yup.string().required('Prenom obligatoire'),
  email: yup.string().email('Tapez une adresse email valide').required('Adresse email obligatoire'),
  accepte: yup.boolean().oneOf([true], 'Veuillez accepter les conditions'), // Update accepte to be a boolean
  // role: yup.string().required('Role obligatoire'),
  // patente: yup.string().when('role', {
  //   is: 'business',
  //   then: yup.string().required('Patente obligatoire'),
  //   otherwise: yup.string() // Add any other validation if needed
  // }),
  telephone: yup.string().required('Numéro télephone obligatoire'),
  adresse: yup.string().required('Adresse obligatoire'),

  password: yup.string().required('Mot de passe obligatoire'),
  passwordConfirmation: yup.string().required('Mot de passe obligatoire').oneOf([yup.ref('password'), null], 'Les mots de passe doivent être identiques'),
});
const RegisterForm = () => {
  const formik = useFormik({
    initialValues: {
      nom: '',
      prenom: '',
      email: '',
      accepte: false,
      password: '',
      passwordConfirmation: '',
      // role: '',
      // patente: '',
      adresse: '',
      telephone: ''
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      //validateur image necessaire

      if (formik.isValid) {
        try {
          setLoading(true);
          setError('');

          const response = await registerAPI({
            nom: values.nom,
            prenom: values.prenom,
            email: values.email,
            password: values.password,
            accepte: values.accepte,
            // role: values.role,
            // patente: values.role === 'business' ? values.patente : null,
            telephone: values.telephone,
            adresse: values.adresse,
          });
          if (response.data.isEmailUsed) {
            toast.error('Email deja utilisé ');
          } else {
            navigate('/login');
          }
          // handleLogin(response.data.token);
        } catch (error) {
          setError(error.response.data.message);
        } finally {
          setLoading(false);
        }
      }
    }
  });
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false); const [scroll, setScroll] = React.useState('paper');

  const handleShowPassword1 = () => {
    setShowPassword1((show) => !show);
  };

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };




  const [user, setUser] = React.useState(INITIAL_USER);
  const [disabled, setDisabled] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState('');
  const [conditionU, setConditionU] = React.useState([]);

  const onDismiss = () => setError(false);

  React.useEffect(() => {
    const isUser = Object.values(user).every((el) => Boolean(el));
    isUser ? setDisabled(false) : setDisabled(true);
  }, [user]);

  useEffect(() => {

    axios
      .get(process.env.REACT_APP_URL_BACKEND_DEV + "/conditionUFormateur")
      .then((result) => {
        setConditionU(result.data.condition);

      });
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser((prevState) => ({ ...prevState, [name]: value }));
  };

  return (
    <div>
      <Alert color="danger" isOpen={error ? true : false} toggle={onDismiss}>
        {error}
      </Alert>

      <form>
        <Stack spacing={3}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              type="text"
              label="Nom"
              name="nom"
              error={formik.touched.nom && formik.errors.nom}
              helperText={formik.errors.nom}
              {...formik.getFieldProps('nom')}
            />
            <TextField
              fullWidth
              type="text"
              label="Prenom"
              name="prenom"
              error={formik.touched.prenom && formik.errors.prenom}
              helperText={formik.errors.prenom}
              {...formik.getFieldProps('prenom')}
            />
          </Stack>
          <TextField
            label="Email addresse"
            name="email"
            type="email"
            error={formik.touched.email && formik.errors.email}
            helperText={formik.errors.email}
            {...formik.getFieldProps('email')}
          />
          <TextField
            label="Adresse"
            name="adresse"
            error={formik.touched.adresse && formik.errors.adresse}
            helperText={formik.errors.adresse}
            {...formik.getFieldProps('adresse')}
          />
          <Stack >

            <PhoneInput
              country={"tn"}
              name="telephone"
              enableSearch
              inputClass="w-100"
              inputStyle={{
                border: "1px solid #f5f5f5",
                background: "#f5f5f5",
                width: "100%", 
              }}
              dropdownStyle={{
                height: "150px",
              }}
              value={formik.values.telephone}
              onChange={(value) => formik.setFieldValue('telephone', value)}
              placeholder="Enter phone number"
            />

          </Stack>
          {/* <TextField
            select
            label="Role"
            name="role"
            value={formik.values.role}
            onChange={formik.handleChange}
            error={formik.touched.role && formik.errors.role}
            helperText={formik.errors.role}
          >
            <MenuItem value="formateur">Formateur</MenuItem>
            <MenuItem value="business">Business</MenuItem>
          </TextField>
          {formik.values.role === "business" && (
            <TextField
              fullWidth
              type="text"
              label="Patente"
              name="patente"
              value={formik.values.patente}
              onChange={formik.handleChange}
              error={formik.touched.patente && formik.errors.patente}
              helperText={formik.errors.patente}
            />
          )} */}
          <TextField
            type={showPassword ? 'text' : 'password'}
            label="Mot de passe"
            name="password"
            error={formik.touched.password && formik.errors.password}
            helperText={formik.errors.password}
            {...formik.getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />

          <TextField
            type={showPassword1 ? 'text' : 'password'}
            label="Confirmer mot de passe"
            name="confirmPassword"
            error={formik.touched.passwordConfirmation && formik.errors.passwordConfirmation}
            helperText={formik.errors.passwordConfirmation}
            {...formik.getFieldProps('passwordConfirmation')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword1} edge="end">
                    <Icon icon={showPassword1 ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          <p className="description">
            <input
              type="checkbox"
              checked={formik.values.accepte}
              onChange={(e) => formik.setFieldValue('accepte', e.target.checked)} // Toggle the boolean value of accepte
              name="accepte"
              id="accepte"
              required
            />
            <label >


              J'accepte{" "}
              <a href="https://fastuz.com/reglements" target="_blank" rel="noopener noreferrer">
                les conditions d'utilisation formateur
              </a>{" "}
              et{" "}
              <a href="https://fastuz.com/reglements" target="_blank" rel="noopener noreferrer">
                la politique de confidentialité
              </a>{" "}
              de Fastuz


              {/* <Button onClick={handleOpen}> */}

              {/* </Button> */}
            </label>
          </p>

          <LoadingButton
            fullWidth
            size="large"
            type="button"
            disabled={!formik.isValid}
            variant="contained"
            onClick={async () => {
              await formik.handleSubmit();
            }}
          >
            S'inscrire
            {loading ? <Spinner color="success" /> : ''}
          </LoadingButton>
          <ToastContainer  {...configToastr} />

        </Stack>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Conditions d'utilisation
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              {conditionU &&
                conditionU.length > 0 &&
                conditionU.map((item) => (
                  item.contenu && parse(item.contenu)

                ))}
            </Typography>
          </Box>
        </Modal>
      </form>
    </div>
  );
};

export default RegisterForm;