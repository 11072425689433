import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
 
  getAllCommentsAPI,

} from "../utils/api";

const initialState = {
  listereviews: [],
  status: "idle",
  reviewSelected: {},
};

export const getAllComments = createAsyncThunk(
  "comments/getAllComments",
  async () => {
    try {
      const response = await getAllCommentsAPI();
      return response.data.result;
    } catch (err) {
    }
  }
);



export const reviewSlice = createSlice({
  name: "reviews",
  initialState,
  reducers: {},

  extraReducers: {
    [getAllComments.pending]: (state, action) => {
      state.status = "loading";
    },
    [getAllComments.fulfilled]: (state, action) => {
      state.status = "idle";
      state.listereviews = action.payload;
    },

  
 
  },
});

export default reviewSlice.reducer;
