import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { AddVoucherAPI, getAllVoucherAPI,getAllVoucherExpiredAPI, getAllVoucherAPII, getAllVoucherByStudentAPI, getUserByToken, loginAPI, SupprimerVoucherAPI, UpdateVoucherAPI } from "../utils/api";

const initialState = {
  
  status: "idle",
  vouchers: [],
  vouchersByStudent: [],


};

export const AddVoucher = createAsyncThunk(
  "Voucher/AddVoucher",
  async (voucher) => {
    try {
      const response = await AddVoucherAPI(voucher);

      return response.data.data;
    } catch (err) {
    }

  }
);

export const UpdateVoucher = createAsyncThunk(
  "Voucher/UpdateVoucher",
  async (voucher) => {
    try {
      const response = await UpdateVoucherAPI(voucher);

      return response.data.result;
    } catch (err) {
    }

  }
);

export const DeleteVoucher = createAsyncThunk(
  "Voucher/DeleteVoucher",
  async (id) => {
    try {
      const response = await SupprimerVoucherAPI(id);

      return response.data;
    } catch (err) {
    }

  }
);


export const SuppVoucher = createAsyncThunk(
  "Voucher/SuppVoucher",
  async (id) => {
    try {
      const response = await SuppVoucher();

      return response.data;
    } catch (err) {
    }

  }
);


export const GetAllVoucher = createAsyncThunk(
    "Voucher/GetAllVoucher",
    async () => {
      try {
        const response = await getAllVoucherAPI();
  
        return response.data.result;
      } catch (err) {
      }
  
    }
  );
  export const GetAllVoucherExpired = createAsyncThunk(
    "Voucher/GetAllVoucherExpired",
    async () => {
      try {
        const response = await getAllVoucherExpiredAPI();
  
        return response.data.length;
      } catch (err) {
      }
  
    }
  );

  export const GetAllVoucherr = createAsyncThunk(
    "Voucher/GetAllVoucher",
    async () => {
      try {
        const response = await getAllVoucherAPII();
  
        return response.data.result;
      } catch (err) {
      }
  
    }
  );


  export const GetAllVoucherByStudent = createAsyncThunk(
    "Voucher/GetAllVoucherByStudent",
    async (id) => {
      try {
        const response = await getAllVoucherByStudentAPI(id);
  
        return response.data.result;
      } catch (err) {
      }
  
    }
  );

export const voucherSlice = createSlice({
  name: "Voucher",
  initialState,
  reducers: {},

  extraReducers: {
    
    [GetAllVoucher.pending]: (state, action) => {
      state.status = "loading";
    },
    [GetAllVoucher.fulfilled]: (state, action) => {
      state.status = "sucess";
      state.vouchers = action.payload;
    },
    [GetAllVoucherExpired.pending]: (state, action) => {
      state.status = "loading";
    },
    [GetAllVoucherExpired.fulfilled]: (state, action) => {
      state.status = "sucess";
      state.length = action.payload;
    },
    [GetAllVoucherByStudent.pending]: (state, action) => {
      state.status = "loading";
    },
    [GetAllVoucherByStudent.fulfilled]: (state, action) => {
      state.status = "sucess";

      state.vouchersByStudent = action.payload;
    },
  },
});

export default voucherSlice.reducer;
