import { Icon } from '@iconify/react';
import pieChart2Fill from '@iconify/icons-eva/pie-chart-2-fill';
import peopleFill from '@iconify/icons-eva/people-fill';
import settings2fill from '@iconify/icons-eva/settings-2-fill';
import textfill from '@iconify/icons-eva/text-fill';
import giftfill from '@iconify/icons-eva/gift-fill';
import fileTextFill from '@iconify/icons-eva/file-text-fill';
import infoFill from '@iconify/icons-eva/info-fill';


const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const sidebarConfigFormateur = [
  {
    title: 'Dashboard',
    path: '/formateur/dashboard',
    icon: getIcon(pieChart2Fill)
  },
  {
    title: "Historiques de commandes",
    path: "/formateur/factureFormateur",
    icon: <Icon icon="nimbus:money" width="35" height="35" />,
  },
  {
    title: 'Profil',
    path: '/profile',
    icon: getIcon(settings2fill)
  },
  {
    title: 'Apprenants',
    path: '/formateur/utilisateurFormateur',
    icon: getIcon(peopleFill)
  },

  {
    title: 'Formations',
    path: '/formateur/formations',
    icon: getIcon(textfill)
  },
  // {
  //   title: 'Réservation Formation présentiel',
  //   path: '/formateur/reservations',
  //   icon: getIcon(fileTextFill),
  // },
  {
    title: 'Vouchers',
    path: '/formateur/voucherFormateur',
    icon: getIcon(giftfill)
  },
  {
    title: 'Mes revenus',
    path: '/formateur/revenusFormateur',
    icon: <Icon icon="tabler:coin-filled" width="35" height="35" />
  },
  {
    title: 'Détails revenus',
    path: '/formateur/detailsRevenus',
    icon: getIcon(infoFill) 
  },
  
];

export default sidebarConfigFormateur;
