import { Icon } from '@iconify/react';

import { alpha, styled } from '@mui/material/styles';
import { Card, Typography } from '@mui/material';

import { fShortenNumber } from '../../../utils/formatNumber';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { GetAllVoucherExpired, GetAllVoucherByStudent } from '../../../redux/voucherSlice';
import axios from "axios"
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  padding: theme.spacing(5, 0),
  color: theme.palette.warning.darker,
  backgroundColor: theme.palette.warning.lighter
}));

const IconWrapperStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
  color: theme.palette.warning.dark,
  backgroundImage: `linear-gradient(135deg, ${alpha(theme.palette.warning.dark, 0)} 0%, ${alpha(
    theme.palette.warning.dark,
    0.24
  )} 100%)`
}));



export default function AppItemOrders() {
  const [certifs, setCertifs] = useState(0);
  useEffect(() => {
  
    axios
      .get(process.env.REACT_APP_URL_BACKEND_DEV + "/scoreCertifs")
      .then((result) => {
        setCertifs(result.data.data);
     
      });
  }, []);
  console.log(certifs)
  return (
    <RootStyle>
      <IconWrapperStyle>
        <WorkspacePremiumIcon width={24} height={24} />
      </IconWrapperStyle>
      <Typography variant="h3">{fShortenNumber(certifs)}</Typography>
      <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>

      Certificats obtenus

      </Typography>
    </RootStyle>
  );
}
