import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import {
  affecterCertifToFormationAPI,
  findCertificationByFormationAPI,
  updateCertifToFormationAPI,
} from "../utils/api";

const initialState = {
  loading: "false",
  selectedCertifFormation: null,
};

export const affecterCertifToFormation = createAsyncThunk(
  "CertifFormation/affecterCertifToFormation",
  async (obj) => {
    try {
      const response = await affecterCertifToFormationAPI(obj);

      return response.data;
    } catch (err) {
      const customError = {
        name: "Custom axios error",
        message: err.response.data.message,
        status: err.response.data.status,
      };
      throw customError;
    }

    // The value we return becomes the `fulfilled` action payload
  }
);
export const updateCertifToFormation = createAsyncThunk(
  "CertifFormation/updateCertifToFormation",
  async (obj) => {
    try {
      const response = await updateCertifToFormationAPI(obj);

      return response.data;
    } catch (err) {
      const customError = {
        name: "Custom axios error",
        message: err.response.data.message,
        status: err.response.data.status,
      };
      throw customError;
    }

    // The value we return becomes the `fulfilled` action payload
  }
);
export const findCertificationByFormation = createAsyncThunk(
  "CertifFormation/findCertificationByFormation",
  async (id) => {
    try {
      const response = await findCertificationByFormationAPI(id);

      return response.data.res;
    } catch (err) {
    }

  }
);

export const certifFormationSlice = createSlice({
  name: "CertifFormation",
  initialState,
  reducers: {},

  extraReducers: {
    [affecterCertifToFormation.pending]: (state) => {
      state.loading = true;
    },
    [affecterCertifToFormation.fulfilled]: (state) => {
      state.status = false;
    },
    [affecterCertifToFormation.rejected]: (state) => {
      state.status = false;
    },
    [findCertificationByFormation.pending]: (state) => {
      state.loading = true;
    },
    [findCertificationByFormation.fulfilled]: (state, action) => {
      state.status = false;
      state.selectedCertifFormation = action.payload;
    },
    [findCertificationByFormation.rejected]: (state) => {
      state.status = false;
    },
    [updateCertifToFormation.pending]: (state) => {
      state.loading = true;
    },
    [updateCertifToFormation.fulfilled]: (state, action) => {
      state.status = false;
    },
    [updateCertifToFormation.rejected]: (state) => {
      state.status = false;
    },
  },
});

export default certifFormationSlice.reducer;
