import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAllFormateursAPI, getAllFormateursExternsAPI } from "../utils/api";

const initialState = {
  listeFormateurs: [],
  listeFormateursExterns: [],
  status: "idle",
};

export const getAllFormateurs = createAsyncThunk(
  "formateurs/getAllFormateurs",
  async () => {
    try {
      const response = await getAllFormateursAPI();
      return response.data.formateurs;
    } catch (err) {
    }

  }
);
export const getAllFormateursExterns = createAsyncThunk(
  "formateurs/getAllFormateursExterns",
  async () => {
    try {
      const response = await getAllFormateursExternsAPI();
      return response.data.formateurs;
    } catch (err) {
    }

  }
);

export const referentsSlice = createSlice({
  name: "formateurs",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getAllFormateurs.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getAllFormateurs.fulfilled, (state, action) => {
        state.status = "idle";
        state.listeFormateurs = action.payload;
      })
      .addCase(getAllFormateursExterns.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getAllFormateursExterns.fulfilled, (state, action) => {
        state.status = "idle";
        state.listeFormateursExterns = action.payload;
      });
  },
});

export default referentsSlice.reducer;
