import { Icon } from "@iconify/react";
import { useRef, useState } from "react";

import trash2Outline from "@iconify/icons-eva/trash-2-outline";
import moreVerticalFill from "@iconify/icons-eva/more-vertical-fill";
// material
import {
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
  DialogContentText,
} from "@mui/material";
import {  SupprimerUserr } from "../../../utils/api";
import { useDispatch, useSelector } from "react-redux";
import { getListeOfStudents } from "../../../redux/authSlice";

// ----------------------------------------------------------------------

export default function UserMoreMenu({ student }) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  let [show, setShow] = useState(false);

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Icon icon={moreVerticalFill} width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: "100%" },
        }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MenuItem sx={{ color: "text.secondary" }}>
          <ListItemIcon>
            <Icon icon={trash2Outline} width={24} height={24} />
          </ListItemIcon>
          <ListItemText
            primary={student.desactive ? "Réactiver" : "Désactiver"}
            primaryTypographyProps={{ variant: "body2" }}
            onClick={async () => {
              // pour ouvrir le dialogue
              setShow(true);
            }}
          />
        </MenuItem>
      </Menu>

      {/* Dialogue de cofirmation */}
      <Dialog
        open={show}
        onClose={() => setShow(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Confirmer votre choix"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Voulez vous vraiment{" "}
            {student.desactive ? " réactiver" : "désactiver"} le compte de cet
            étudiant ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShow(false)}>annuler</Button>
          <Button
            onClick={async () => {
              await SupprimerUserr(student.email);

              dispatch(getListeOfStudents());
              setShow(false);
            }}
            type="button"
          >
            Confirmer
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
