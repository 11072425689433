import React, { useEffect, useState } from "react";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import axios from 'axios';
import {Grid} from "@mui/material";
import { useParams } from "react-router-dom";
import Certificats from "./CertificatUser"
import moment from "moment";

export default function SimpleAccordion() {

    const { idUser } = useParams();
    const [formation, setFormation] = useState([]);

    useEffect(() => {
   
      getMoreData();
    }, []);
    const getMoreData = async () => {
      try {
        // Utilisez await pour obtenir la réponse de l'API
        const response = await axios.get(process.env.REACT_APP_URL_BACKEND_DEV + `/getUserByidBusiness/${idUser}`);
    
        // Destructurer les données de la réponse
        const { enrolled = [], enrolledPriv = [] } = response.data.users;
    
        // Ajouter une propriété `source` pour différencier `enrolled` de `enrolledPriv`
        const formattedEnrolled = enrolled.map((item) => ({ ...item, source: 'enrolled' }));
        const formattedEnrolledPriv = enrolledPriv.map((item) => ({ ...item, source: 'enrolledPriv' }));
    
        // Combiner les deux tableaux
        const combinedFormations = [...formattedEnrolled, ...formattedEnrolledPriv];
    
        // Mettre à jour l'état avec les formations combinées
        setFormation(combinedFormations);
      } catch (error) {
        console.error(error);
      }
    };
    
  console.log("formation", formation)
  return (
    <div>
      <br />
    
      {formation?.map((user) => (
  <Accordion key={user.formationId}>
    <AccordionSummary
      aria-controls="panel1a-content"
      id="panel1a-header"
      expandIcon={<ExpandMoreIcon />}

    >
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
         <Grid xs={8}>
          <Typography>{user?.formationId?.titre}   </Typography>
        </Grid>
        <Grid xs={2}>
        <b>Date enrolled :</b> {user?.dateEnrolled ? moment(user?.dateEnrolled).locale("fr").format("DD/MM/YYYY HH:mm ") : ""}
        </Grid>
        <Grid item xs={2}>
          <b>Type Formation :</b> {user?.source === 'enrolled' ? 'Formation Fastuz' : 'Formation Privée'}
        </Grid>
      </Grid>
    </AccordionSummary>
    <AccordionDetails>

  <Certificats idFormation={user?.formationId} key={user._id} />


 


</AccordionDetails>
  </Accordion>
))}
  </div>
  );
}


