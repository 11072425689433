import React from "react";
import { Dropzone, FileItem } from "@dropzone-ui/react";

export default function DropzoneComponent({ files, setFiles }) {
  const updateFiles = (incommingFiles) => {
    setFiles(incommingFiles);
  };
  return (
    <Dropzone
      onChange={updateFiles}
      value={files}
      footer={false}
      header={false}
      maxFiles={5}
      maxFileSize={5 * 1024 * 1024} //5mb
      behaviour={"replace"}
      localization={"FR-fr"}
      accept="image/*,application/pdf
      "
    >
      {files.map((file) => (
        <FileItem {...file} preview key={file.id} />
      ))}
    </Dropzone>
  );
}
