
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Alert, Spinner } from 'reactstrap';
import Link from 'next/link';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import Select from "react-select";
import {  UploadeFileAPI } from '../../utils/api';
import { CKEditor } from "ckeditor4-react";

import { Stack, TextField, IconButton, InputAdornment } from '@mui/material';

import { LoadingButton } from '@mui/lab';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Page from "../../components/Page"
import { Row, Col, FormGroup,Button } from "reactstrap"; 
import FileUploader from "../../assets/FileUploader"
import getBase64 from "../../utils/getBase64"
import { AddConditionUAPI } from '../../utils/api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const RegisterFormateur = ({handleClose, getPartners}) => {
  const dispatch = useDispatch();

  const [contenu, setContenu] = useState("");
  const [alert, setAlert] = useState(false);

  const handleDescription = (e) => {
    let data = e.editor.getData();

    setContenu(data);
  };

const [disabled, setDisabled] = React.useState(true);
const [loading, setLoading] = React.useState(false);
const [error, setError] = React.useState('');
const onDismiss = () => setError(false);
const [listFormation, setListFormations] = useState([]);






  const formik = useFormik({
    

        initialValues: {
        titre: "",
        
      },
  
         onSubmit: async (values) => {
           const obj = {
           ...values,
           contenu
         };
     
          if (formik.isValid) {
            try {
              setLoading(true);
              setError('');
    
              const response = await  AddConditionUAPI(obj) 
              if (response.data.status) {
                toast.error('Erreur de saisie !', {
                  position: 'top-right',
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                });   
              } else {
                toast.success('Contrat de vente ajouté avec succées!', {
                  position: 'top-right',
                  autoClose: 10000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                });
                setTimeout(() => {
                  handleClose();
                }, 1000);                

              }
            } catch (error) {
              setError(error.response.data.message);
            } finally {
              getPartners()
              setLoading(false);
            }
          }

      },
  });

return (
    <Page title="User | Minimal-UI">
    <div>
      <Alert color="danger" isOpen={error ? true : false} toggle={onDismiss}>
        {error}
      </Alert>
      <ToastContainer />

      <form>
        <Stack spacing={3}>
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} justifyContent="center" >
          <Col md={12}>
            {alert && (
              <Alert color="danger">
                {alert === "contenu" && "Merci d'inserer la description"}
              </Alert>
            )}
          </Col>
     
      
          </Stack>

          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              type="text"
              label="Libelle"
              name="titre"
              error={formik.touched.titre && formik.errors.titre}
              helperText={formik.errors.titre}
              {...formik.getFieldProps('titre')}
            />
            </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <Col md={12} className="mb-3">
            <FormGroup>
              <label>Description</label>
              <CKEditor  initData={contenu} onChange={handleDescription} />
            </FormGroup>
          </Col>

          </Stack>
    
           

        
         <LoadingButton
            fullWidth
            size="large"
            type="button"
            disabled={!formik.isValid}
            variant="contained"
            onClick={async () => {
              await formik.handleSubmit();
            }}
          >
            Ajouter condition d'utilisation
            {loading ? <Spinner color="success" /> : ''}
          </LoadingButton>
        </Stack>
      </form>
    </div>
    </Page>
  );
};

export default RegisterFormateur;