// import { useFormik } from 'formik';

// import { ajoutCourseAPI, UploadeFileAPI } from '../../utils/api';

// import {  Stack, Container } from '@mui/material';

// import { TextField } from "@material-ui/core";
// import React, { useEffect, useState } from "react";
// import { Row, Col, FormGroup, Alert } from "reactstrap";
// import * as yup from "yup";
// import FileUploader from "../../assets/FileUploader";
// import {  useNavigate, useParams } from 'react-router-dom';

// import getBase64 from '../../utils/getBase64';
// import { CKEditor } from "ckeditor4-react";
// import { styled } from '@mui/material/styles';
// import SweetAlert from 'react-bootstrap-sweetalert';
// import { LoadingButton } from '@mui/lab';


// const ContentStyle = styled('div')(({ theme }) => ({
//   maxWidth: 950,
//   margin: 'auto',
//   display: 'flex',
//   flexDirection: 'column',
//   justifyContent: 'center',
//   padding: theme.spacing(12, 0)
// }));

// const validationSchema = yup.object({
//     titre_cours: yup.string().required("Titre obligatoire"),
    
//   });
// export default function AddFormations( ) {
  
//   let [showSuccess, setShowSuccess] = useState(false);
//   let [showError, setShowError] = useState(false);
//   const navigate = useNavigate();
//   const [photo, setPhoto] = useState(null);

//     const [videos, setVideos] = useState(null);
//     const [videoPreview, setVideoPreview] = useState(null);
//     const [filePreview, setFilePreview] = useState(null);
//     const [file, setFile] = useState(null);

//     const [description, setDescription] = useState("");
//     const {idModule} = useParams();
//     const handleDescription = (e) => {
//       let data = e.editor.getData();
  
//       setDescription(data);
//     };

//     const [alert, setAlert] = useState(false);

//     useEffect(() => {
//       if (videos) getBase64(videos).then((url) => setVideoPreview(url));
//       else setVideoPreview(null);
//     }, [videos]);
//     useEffect(() => {
//       if (file) getBase64(file).then((url) => setFilePreview(url));
//       else setFilePreview(null);
//     }, [file]);
   
 
  
//     useEffect(() => {
//       if (photo && description) setAlert(false);
//     }, [photo, description]);
//     const formik = useFormik({
//       initialValues: {
//         titre: "",
//         prix: 0,
        
//       },
//       validationSchema: validationSchema,
//       onSubmit: async (values) => {
    

//         const obj = {
//           ...values,
//           description,
          
//         };
//         if (file) {
//           const fileRes = await UploadeFileAPI(file, "coverFormation");
         
//           obj.file = fileRes.data.location;;
//         }
   
//         if (videos) {
//           const videoRes = await UploadeFileAPI(videos, "videoFormation");
         
//           obj.videos = videoRes.data.location;;
//         }
//         const  data  = await ajoutCourseAPI(idModule, obj);
        
//         data.data.status ? setShowError(true)  : setShowSuccess(true);
//         await navigate(-1)
//       },
//     });
 
      
     
//   return (

     
  


//   <Container maxWidth="l">
//     <ContentStyle>

   

   
//       <div className="form-wizard-content">
//         <Row form>
//         <Stack spacing={2}>
//           <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} justifyContent="center" >
//           <Col md={12}>
//             {alert && (
//               <Alert color="danger">
//                 {alert === "photo" && "Merci d'inserer une image"}
//                 {alert === "description" && "Merci d'inserer une description"}
//               </Alert>
//             )}
//           </Col>
          
//           <Col md={6} className="mb-3">
//             {!file && !filePreview && (
//               <FileUploader
              
//                 fileTypes={["application/pdf"]}
//                 setFileToUpload={setFile}
//                 title="file"
//                 original=""
//               ></FileUploader>
//             )}
//             {filePreview && (
//               <div >
                
//                 <a href={filePreview} > file </a>
//                 <LoadingButton
                 
//                   onClick={() => {
//                     setFilePreview(null);
//                     setFile(null);
//                   }}
//                 >
//                   modifier le pdf
//                 </LoadingButton>
//               </div>
//             )}
//           </Col>
      
//           <Col md={6} className="mb-3">
//             {!videos && !videoPreview && (
//               <FileUploader
              
//                 fileTypes={["video/mp4", "video/webm"]}
//                 setFileToUpload={setVideos}
//                 title="Vidéo introductive"
//                 original=""
//               ></FileUploader>
//             )}
//             {videoPreview && (
//               <div >
                
//                 <video controls controlsList="nodownload"  src={videoPreview} width="100%"></video>
//                 <LoadingButton
                 
//                   onClick={() => {
//                     setVideoPreview(null);
//                     setVideos(null);
//                   }}
//                 >
//                   Réinitialiser Video
//                 </LoadingButton>
//               </div>
//             )}
//           </Col>
//           </Stack>
//           <Col md={6}>
//             <FormGroup>
//               <TextField
//                 fullWidth
//                 name="titre_cours"
//                 label="Titre"
//                 id="titre_cours"
//                 placeholder="Titre"
//                 error={!!(formik.touched.titre_cours && formik.errors.titre_cours)}
//                 helperText={formik.touched.titre_cours && formik.errors.titre_cours}
//                 {...formik.getFieldProps("titre_cours")}
//               />
//             </FormGroup>
//           </Col>
       
//           <Col md={12} className="mb-3">
//             <FormGroup>
//               <label>Description</label>
//               <CKEditor  initData={description} onChange={handleDescription} />
//             </FormGroup>
//           </Col>

        
    
//           </Stack>
      
//         </Row>
//       </div>
//       <br></br>
//       <LoadingButton
//             fullWidth
//             size="medium"
//             type="button"
//             disabled={!formik.isValid}
//             variant="contained"
//             onClick={async () => {
//               await formik.handleSubmit();
//             }}
//           >
//             Ajouter Cours
//           </LoadingButton>
    
//           <SweetAlert
//         show={showSuccess}
//         title="Ajout avec succées"
//         type="success"
//         onConfirm={() => {
//           setShowSuccess(false);
//         }}
//       />

//       <SweetAlert
//         show={showError}
//         title="Un problème est survenue !"
//         type="error"
//         onConfirm={() => {
//           setShowError(false);
//         }}
//       />
//           </ContentStyle>
     
//           </Container>

 
 
//   );
// }


import { useFormik } from 'formik';
import { ajoutCourseAPI, UploadeFileAPI } from '../../utils/api';
import { Stack, Container } from '@mui/material';
import { TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Row, Col, FormGroup, Alert } from "reactstrap";
import * as yup from "yup";
import FileUploader from "../../assets/FileUploader";
import { useNavigate, useParams } from 'react-router-dom';
import getBase64 from '../../utils/getBase64';
import { CKEditor } from "ckeditor4-react";
import { styled } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import { ToastContainer, toast } from 'react-toastify';

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 950,
  margin: 'auto',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

const validationSchema = yup.object({
  titre_cours: yup.string().required("Titre obligatoire"),
});

const configToastr = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  newestOnTop: false,
  closeOnClick: true,
  rtl: false,
  pauseOnFocusLoss: true,
  draggable: true,
  pauseOnHover: true,
};

export default function AddFormations() {

  let [showSuccess, setShowSuccess] = useState(false);
  let [showError, setShowError] = useState(false);
  const navigate = useNavigate();
  const [photo, setPhoto] = useState(null);
  const [loading, setLoading] = useState(false);
  const [videos, setVideos] = useState(null);
  const [videoPreview, setVideoPreview] = useState(null);
  const [filePreview, setFilePreview] = useState(null);
  const [file, setFile] = useState(null);

  const [description, setDescription] = useState("");
  const { idModule } = useParams();
  const handleDescription = (e) => {
    let data = e.editor.getData();

    setDescription(data);
  };

  const [alert, setAlert] = useState(false);

  useEffect(() => {
    if (videos) getBase64(videos).then((url) => setVideoPreview(url));
    else setVideoPreview(null);
  }, [videos]);
  useEffect(() => {
    if (file) getBase64(file).then((url) => setFilePreview(url));
    else setFilePreview(null);
  }, [file]);

  useEffect(() => {
    if (photo && description) setAlert(false);
  }, [photo, description]);

  const formik = useFormik({
    initialValues: {
      titre: "",
      prix: 0,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const obj = {
        ...values,
        description,
      };

      if (file) {
        const fileRes = await UploadeFileAPI(file, "coverFormation");
        obj.file = fileRes.data.location;
      }

      if (videos) {
        const videoRes = await UploadeFileAPI(videos, "videoFormation");
        obj.videos = videoRes.data.location;
      }
      setLoading(true);
      try {
        const data = await ajoutCourseAPI(idModule, obj);
        if (data.data.status) {
          toast.error("Une erreur s'est produite lors de l'ajout du cours");
        } else {
          setLoading(false)

          toast.success("Cours ajouté avec succès");
        }
        setTimeout(async () => {

          await navigate(-1);

        }, 2000);
      } catch (error) {
        console.error("Error:", error);
        toast.error("Une erreur s'est produite");
      }
    },
  });

  return (
    <div>
      <Container maxWidth="l">
        <ContentStyle>


          <div className="form-wizard-content">
            <Row form>
              <Stack spacing={2}>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} justifyContent="center" >
                  <Col md={12}>
                    {alert && (
                      <Alert color="danger">
                        {alert === "photo" && "Merci d'inserer une image"}
                        {alert === "description" && "Merci d'inserer une description"}
                      </Alert>
                    )}
                  </Col>

                  <Col md={6} className="mb-3">
                    {!file && !filePreview && (
                      <FileUploader

                        fileTypes={["application/pdf"]}
                        setFileToUpload={setFile}
                        title="file"
                        original=""
                      ></FileUploader>
                    )}
                    {filePreview && (
                      <div >

                        <a href={filePreview} > file </a>
                        <LoadingButton

                          onClick={() => {
                            setFilePreview(null);
                            setFile(null);
                          }}
                        >
                          modifier le pdf
                        </LoadingButton>
                      </div>
                    )}
                  </Col>

                  <Col md={6} className="mb-3">
                    {!videos && !videoPreview && (
                      <FileUploader

                        fileTypes={["video/mp4", "video/webm"]}
                        setFileToUpload={setVideos}
                        title="Vidéo introductive"
                        original=""
                      ></FileUploader>
                    )}
                    {videoPreview && (
                      <div >

                        <video controls controlsList="nodownload" src={videoPreview} width="100%"></video>
                        <LoadingButton

                          onClick={() => {
                            setVideoPreview(null);
                            setVideos(null);
                          }}
                        >
                          Réinitialiser Video
                        </LoadingButton>
                      </div>
                    )}
                  </Col>
                </Stack>
                <Col md={6}>
                  <FormGroup>
                    <TextField
                      fullWidth
                      name="titre_cours"
                      label="Titre"
                      id="titre_cours"
                      placeholder="Titre"
                      error={!!(formik.touched.titre_cours && formik.errors.titre_cours)}
                      helperText={formik.touched.titre_cours && formik.errors.titre_cours}
                      {...formik.getFieldProps("titre_cours")}
                    />
                  </FormGroup>
                </Col>

                <Col md={12} className="mb-3">
                  <FormGroup>
                    <label>Description</label>
                    <CKEditor initData={description} onChange={handleDescription} />
                  </FormGroup>
                </Col>



              </Stack>

            </Row>          </div>

          <br />
          <LoadingButton
            fullWidth
            size="medium"
            type="button"
            disabled={!formik.isValid}
            variant="contained"
            onClick={async () => {
              await formik.handleSubmit();
            }}
          >
            Ajouter Cours
          </LoadingButton>
        </ContentStyle>
      </Container>
      <ToastContainer {...configToastr} />

    </div>
  );
}