import { Box, Grid, Container, Typography } from '@mui/material';
import Page from '../components/Page';
import {
  AppNewUsers,
  AppBugReports,
  AppWeeklySales,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppCurrentSubject,
  AppConversionRates,
  AppApprenantsRates,
  AppNumberCertifFormations,
  AppItemOrders
} from '../components/_dashboardFormateur/app';


export default function DashboardApp() {
  return (
    <Page title="Dashboard | Fastuz">
      <Container maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">Dashboard Fastuz</Typography>
        </Box>
        <Grid container spacing={3}>
        
          <Grid item xs={12} sm={6} md={3}>
            <AppNewUsers />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppWeeklySales />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppBugReports />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppItemOrders />
          </Grid>
     
          <Grid item xs={12} md={6} lg={12}>
            <AppWebsiteVisits />
          </Grid>


          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentVisits />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppApprenantsRates />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppNumberCertifFormations />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentSubject />
          </Grid>

          <Grid item xs={12} md={6} lg={12}>
            <AppConversionRates />
          </Grid>

   
        </Grid>

     
      </Container>
    </Page>
  );
}
