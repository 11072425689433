import { merge } from "lodash";
import ReactApexChart from "react-apexcharts";
// material
import { Box, Card, CardHeader } from "@mui/material";
// utils
import { fNumber } from "../../../utils/formatNumber";
//
import { BaseOptionChart } from "../../charts";
import { useEffect, useState } from "react";
import axios from "axios";
import Cookies from 'js-cookie';

// ----------------------------------------------------------------------

export default function AppConversionRates() {
  const [makingApiCall, setMakingApiCall] = useState(true);
  const [fetchedData, setFetchedData] = useState([]);
  const token= Cookies.get("tokenAdmin")


  useEffect(() => {
    let mount = true;
    if (mount) {
      setMakingApiCall(true);
      axios
        .get(process.env.REACT_APP_URL_BACKEND_DEV + "/getAllEnrolledFormationsFormateur", 
        {
          headers: { Authorization: `Bearer ${token}`, 
          withCredentials: true
        },}
        )
        .then((response) => {
         
          setFetchedData(response.data.result);
        })
        .finally(() => {
          setMakingApiCall(false);
        });
    }
    return () => (mount = false);
  }, []);

  

const CHART_DATA = [{ data: fetchedData.map((f) => f.enrolledBy) }];
const chartOptions = merge(BaseOptionChart(), {
  tooltip: {
    marker: { show: false },

    y: {
      formatter: (seriesName) => fNumber(seriesName),
      title: {
        formatter: () => "Nb apprenants: ", 
      },
      value: {
        formatter: (val) => val.toFixed(2),
      },
    },
  
  },
  plotOptions: {
    bar: { horizontal: true, barHeight: "28%", borderRadius: 2 },
  },
  xaxis: {
    categories: fetchedData.map((f) => f.titre),
    labels: {
      style: {
        textOverflow: 'none',
        whiteSpace: 'normal',
        lineHeight: '1.2'
      },
  
    }
  }
});

  return (
    <Card>
      <CardHeader title="Nombre d'apprenants par formation" subheader="" />
      <Box sx={{ mx: 3 }} dir="ltr">
        {!makingApiCall && (
          <ReactApexChart
            type="bar"
            series={CHART_DATA}
            options={chartOptions}
            height={392}
          />
        )}
      </Box>
    </Card>
  );
}

