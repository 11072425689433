import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
 getAllModuleAPI,
 getModuleByIdAPI,
 SupprimerModuleAPI
} from "../utils/api";

const initialState = {
  listeModules: [],
  status: "idle",
  moduleSelected: {},
};

export const getAllModules = createAsyncThunk(
  "module/getAllModules",
  async (idFormation) => {
     try {
       const response = await getAllModuleAPI(idFormation);
       return response.data.result;
     } catch (err) {
     }
   }
);

export const getModuleById = createAsyncThunk(
  "module/getModuleById",
  async (idModule) => {
    try {
      const response = await getModuleByIdAPI(idModule);
      return response.data.result;
    } catch (err) {
    }
  }
);

export const removeModule = createAsyncThunk(
  "module/removeModule",
  async (id) => {
    const response = await SupprimerModuleAPI(id);
    return response.data;
  }
);

export const moduleSlice = createSlice({
  name: "module",
  initialState,
  reducers: {},

  extraReducers: {
    [getAllModules.pending]: (state, action) => {
      state.status = "loading";
    },
    [getAllModules.fulfilled]: (state, action) => {
      state.status = "idle";
      state.listeModules = action.payload;
    },
     [getModuleById.pending]: (state, action) => {
       state.statusUpdate = "loading";
     },
     [getModuleById.fulfilled]: (state, action) => {
       state.statusUpdate = "success";
       state.moduleSelected = action.payload;
     },

 
  
 
  },
});

export default moduleSlice.reducer;
