
import { useState, useEffect } from "react";
import axios from "axios";

import {

  Container,

} from "@mui/material";
// components
import Page from "../../components/Page";

import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import ReactTable from "react-table-v6";

import { Button, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";

import moment from "moment";




export default function User() {

  const [apprenants, setApprenants] = useState([]);


  useEffect(() => {

    getApprenants();
  }, []);
  const getApprenants = async () => {
    try {
      const res = await axios.get(
        process.env.REACT_APP_URL_BACKEND_DEV + `/etudiant/getActiveStudentsEnrolled`
      );

      // Sort the data based on the enrolled date (assuming enrolled is an array of dates)
      const sortedData = res.data.students.sort((a, b) => {
        // Change 'enrolled' to your specific property that holds the enrollment date
        return new Date(a.enrolled[0].dateEnrolled) - new Date(b.enrolled[0].dateEnrolled);
      });

      setApprenants(sortedData);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const columns = [
    {
      Header: "Nom et Prénom",
      id: "nomPrenom",
      accessor: row => `${row.nom} ${row.prenom}`,
      filterMethod: (filter, row) =>
        row.nomPrenom.toLowerCase().includes(filter.value.toLowerCase()),
      Cell: ({ original }) => {
        return (
          <span style={{ wordBreak: "break-all" }}>
            <Link to={"/detailsUser/" + original._id}>
              {original.nom} {original.prenom}
            </Link>
          </span>
        );
      },
    },
    {
      Header: "Email",
      accessor: "email",
      Cell: ({ original }) => {
        return <div>{original?.email} </div>
      },

    },
    {
      Header: "Date Enrolled",
      accessor: "enrolled",
      id: "firstEnrollmentDate",
      Cell: ({ original }) => {
        const firstEnrollmentDateTime = original.enrolled.length > 0 ? original.enrolled[0].dateEnrolled : null;
        return (
          <span style={{ wordBreak: "break-all" }}>
                        {firstEnrollmentDateTime ? moment(firstEnrollmentDateTime).locale("fr").format("DD/MM/YYYY") : "No Enrollment"}

            {/* {firstEnrollmentDateTime ? moment(firstEnrollmentDateTime).locale("fr").format("DD/MM/YYYY HH:mm") : "No Enrollment"} */}
          </span>
        );
      },
      filterMethod: (filter, row) => {
        const firstEnrollmentDateTime = row.enrolled.length > 0 ? moment(row.enrolled[0].dateEnrolled).format("DD/MM/YYYY HH:mm") : "";
        return firstEnrollmentDateTime.includes(filter.value);
      },
      
      width: 180, // Adjust the width as needed
    },
 
    {
      Header: "Nombre de formations",
      accessor: "enrolled",
      Cell: ({ original }) => {
        return <div style={{ display: "flex", justifyContent: "center" }}>
  {original?.enrolled.length > 0 ? (
    <div>{original?.enrolled.length}</div>
  ) : (
    <div>Pas de formations</div>
  )}
</div>

   
        
      },

    },

    {
      Header: "Nombre de certificats",
      accessor: "mesCertificats",
      Cell: ({ original }) => {
        return  <div style={{ display: "flex", justifyContent: "center" }}>
        {original?.mesCertificats.length > 0 ? (
          <div>{original?.mesCertificats.length}</div>
        ) : (
          <div>Pas de certificats</div>
        )}
      </div>
        
        
        
      },

    },




  ];


  return (
    <Page title="Dashboard | Fastuz">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Apprenants
          </Typography>

        </Stack>


        <CardBody>
          <Row>

            <ReactTable
              data={apprenants}
              nextText={"Suivant"}
              previousText={"Précedent"}
              rowsText={"Lignes"}
              columns={columns}
              defaultPageSize={10}
              filterable={true}
              noDataText="No data is available"
              defaultSorted={[{id :"createdAt" , desc : true}]}

            /* overiding the filter methode */
            />


          </Row>
        </CardBody>
      </Container>
    </Page>
  );
}


