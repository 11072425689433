// import PropTypes from 'prop-types';
// // material
// import { visuallyHidden } from '@mui/utils';
// import { Box, Checkbox, TableRow, TableCell, TableHead, TableSortLabel } from '@mui/material';

// // ----------------------------------------------------------------------

// UserListHeadDesactive.propTypes = {
//   order: PropTypes.oneOf(['asc', 'desc']),
//   orderBy: PropTypes.string,
//   rowCount: PropTypes.number,
//   headLabel: PropTypes.array,
//   numSelected: PropTypes.number,
//   onRequestSort: PropTypes.func,
//   onSelectAllClick: PropTypes.func
// };

// export default function UserListHeadDesactive({
//   order,
//   orderBy,
//   rowCount,
//   headLabel,
//   numSelected,
//   onRequestSort,
//   onSelectAllClick
// }) {
//   const createSortHandler = (property) => (event) => {
//     onRequestSort(event, property);
//   };

//   return (
//     <TableHead style={{backgroundColor:"#f7f7f7"}}>
//       <TableRow>
//         <TableCell padding="checkbox">
//           <Checkbox
//             indeterminate={numSelected > 0 && numSelected < rowCount}
//             checked={rowCount > 0 && numSelected === rowCount}
//             onChange={onSelectAllClick}
//           />
//         </TableCell>
//         {headLabel.map((headCell) => (
//           <TableCell
//             key={headCell.id}
//             align={headCell.alignRight ? 'right' : 'left'}
//             sortDirection={orderBy === headCell.id ? order : false}
//           >
//             <TableSortLabel
//               hideSortIcon
//               active={orderBy === headCell.id}
//               direction={orderBy === headCell.id ? order : 'asc'}
//               onClick={createSortHandler(headCell.id)}
//             >
//               {headCell.label}
//               {orderBy === headCell.id ? (
//                 <Box sx={{ ...visuallyHidden }}>
//                   {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
//                 </Box>
//               ) : null}
//             </TableSortLabel>
//           </TableCell>
//         ))}
//       </TableRow>
//     </TableHead>
//   );
// }
import PropTypes from 'prop-types';
// material
import { visuallyHidden } from '@mui/utils';
import { Box, Checkbox, TableRow, TableCell, TableHead, TableSortLabel, Grid } from '@mui/material';

// ----------------------------------------------------------------------

UserListHeadDesactive.propTypes = {
  order: PropTypes.oneOf(['asc', 'desc']),
  orderBy: PropTypes.string,
  rowCount: PropTypes.number,
  headLabel: PropTypes.array,
  numSelected: PropTypes.number,
  onRequestSort: PropTypes.func,
  onSelectAllClick: PropTypes.func
};

export default function UserListHeadDesactive({
  order,
  orderBy,
  rowCount,
  headLabel,
  numSelected,
  onRequestSort,
  onSelectAllClick,
  handleSelectAllPages,
  handleSelectAllCurrentPage,
  selectAllPages,
  selectAllCurrentPage,
}) {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead >
      
      <TableRow>
        <TableCell colSpan={headLabel.length > 0 ? headLabel.length : 1}>
          <Box display="flex" justifyContent="space-between">
            <Box>
              <Checkbox
                checked={selectAllCurrentPage}
                onChange={handleSelectAllCurrentPage}
              />
              <label>Sélectionner la page actuelle</label>
            </Box>
            <Box>
              <Checkbox
                checked={selectAllPages}
                onChange={handleSelectAllPages}
              />
              <label>Sélectionner tous les apprenants</label>
            </Box>
          </Box>
        </TableCell>
      </TableRow>
      <TableRow>
        {headLabel.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.alignRight ? 'right' : 'left'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              hideSortIcon
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box sx={{ ...visuallyHidden }}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
