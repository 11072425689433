import Router from './routes';
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';
import ScrollToTop from './components/ScrollToTop';
import { BaseOptionChartStyle } from './components/charts/BaseOptionChart';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getCurrentUserByToken } from './redux/authSlice';
import { getCookie } from './utils/AuthStorage';
import "antd/dist/antd.less";

// ----------------------------------------------------------------------

export default function App() {
  const [tokenn, setTokenn] = useState('');
 
  const dispatch = useDispatch();
  useEffect(() => {
    const token = getCookie('tokenAdmin');

    if (token !== '' && token !== undefined) {
     dispatch(getCurrentUserByToken(token)).then((result) => {
      setTokenn(result.payload);
    });


    }
  }, [dispatch]);

  return (
    <ThemeConfig>
      <ScrollToTop />
      <GlobalStyles />
      <BaseOptionChartStyle /> 
      <Router />
    </ThemeConfig>
  );
}
