import React, { useEffect, useState } from "react";
import { Button, CardBody, CardFooter, CardTitle, Col, Row } from "reactstrap";
import "antd/dist/antd.css"; // or 'antd/dist/antd.less'
import { CloudUploadOutlined } from "@ant-design/icons";
import { useFormik } from "formik";
import * as yup from "yup";
import { Upload, Alert } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getFormationsById } from "../../redux/formationSlice";
import SweetAlert from "sweetalert-react";
import {  Stack, Container } from '@mui/material';

import { UploadeFileAPI, UpdateTypeProdAPI } from "../../utils/api";
import { FormHelperText, TextField, MenuItem, Select } from "@material-ui/core";
import { CKEditor } from "ckeditor4-react";
import FileUploader from "../../assets/FileUploader";
import getBase64 from "../../utils/getBase64";
import {  useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import { getTypeProdById } from "../../redux/typeProdSlice";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 950,
  margin: 'auto',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));
const validationSchema = yup.object({
  titre: yup.string().required("Titre obligatoire"),
  // description: yup.string().required("Description obligatoir"),

});

export default function DetailsPartner({ handleCloseUpdate, getPartners, selectedContact}) {
  const dispatch = useDispatch();
  const  idPartner  = selectedContact?._id; 

  const navigate = useNavigate();


  let [fileList, setFileList] = useState([]);
  let [alerteShow, setAlerteShow] = useState(false);
  const [alerteNoImage, setalerteNoImage] = useState(false);
  const [logo, setlogo] = useState(null);
  const [coverPreview, setCoverPreview] = useState(null);
  const [loading, setloading] = useState(true);
  const [error, setError] = React.useState('');


  const [sc, setsc] = useState(true);
  const [update, setUpdate] = React.useState(false);




 
  useEffect(() => {
    setloading(true);
    dispatch(getTypeProdById(idPartner)).then((response) => {
      setloading(false);

   
      formik.setValues({
        titre: response.payload?.titre,
        
      });
     
      //SetImageFormation(response.payload.icon);
    });
  }, [idPartner]);

  const formik = useFormik({
    initialValues: {
      titre: "",
     
      
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
    

      const Updateobj = {
        _id: idPartner,
        ...values,
      };
      console.log(Updateobj)
     
      try {
        setError('');

        const response = await  UpdateTypeProdAPI(Updateobj) 
        if ( response.data.status) {
          toast.error('Erreur de saisie !', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });   
        } else {
          toast.success('Type produit modifié avec succées!', {
            position: 'top-right',
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          setTimeout(() => {
            handleCloseUpdate();
          }, 1000);                

        }
      } catch (error) {
        setError(error.response.data.message);
      } finally {
        getPartners()
      }

      
    },
  });

  return (
    <div>


  <Container maxWidth="l">
    <ContentStyle>
      <CardBody>
      <ToastContainer />

        <Row>
        <Stack spacing={2}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} justifyContent="center" >
         



          </Stack>
        
          <Col md={6}>
            <TextField
              fullWidth
              name="titre"
              label="Titre"
              id="titre"
              placeholder="titre"
              error={!!(formik.touched.titre && formik.errors.titre)}
              helperText={formik.touched.titre && formik.errors.titre}
              {...formik.getFieldProps("titre")}
            />
      
         
        
          </Col>
      
        
          </Stack>

        </Row>
      
      </CardBody>
      <br></br>
      <LoadingButton
            fullWidth
            size="large"
            type="button"
            disabled={!formik.isValid}
            variant="contained"
            onClick={async () => {
              await formik.handleSubmit();
            }}
          >
         
        
          Confirmer la mise à jour
        </LoadingButton>
  

</ContentStyle>
     
     </Container>
    </div>
  );
}