import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { getAllQuizzAPI, getQuizzByIdAPI } from "../utils/api";
const initialState = {
  listeQuizz: [],
  status: "idle",
  statusUpdate: "idle",
  quizzSelected: {},
  quizzSelectedEntre: {},
  quizzSelectedSortie: {},
  quizzSelectedEvaluation: {},
  listQuestions: [],
};

export const getAllQuizz = createAsyncThunk("quizz/getAllQuizz", async () => {
  try {
    const response = await getAllQuizzAPI();

    return response.data.quizz;
  } catch (err) {
  }

});


export const getQuizzById = createAsyncThunk(
  "quizz/getQuizzById",
  async (idQuizz) => {
    try {
      const response = await getQuizzByIdAPI(idQuizz);

      return response.data.result;
    } catch (err) {
    }

  }
);

export const getQuizzEvaluationById = createAsyncThunk(
  "quizz/getQuizzEvaluationById",
  async (idQuizz) => {
    try {
      const response = await getQuizzByIdAPI(idQuizz);

      return response.data.result;
    } catch (err) {
    }

  }
);
export const getQuizzEntreById = createAsyncThunk(
  "quizz/getQuizzEntreById",
  async (idQuizz) => {
    try {
      const response = await getQuizzByIdAPI(idQuizz);

      return response.data.result;
    } catch (err) {
    }

  }
);
export const getQuizzSortieById = createAsyncThunk(
  "quizz/getQuizzSortieById",
  async (idQuizz) => {
    try {
      const response = await getQuizzByIdAPI(idQuizz);

      return response.data.result;
    } catch (err) {
    }

  }
);

export const updateArrayQuestions = createAsyncThunk(
  "quizz/updateArrayQuestions",
  async (arrayQuestion) => {
    try {
      return arrayQuestion;
    } catch (err) {
    }

  }
);

export const quizzSlice = createSlice({
  name: "quizz",
  initialState,
  reducers: {},

  extraReducers: {
    [getAllQuizz.pending]: (state, action) => {
      state.status = "loading";
    },
    [getAllQuizz.fulfilled]: (state, action) => {
      state.status = "success";
      state.listeQuizz = action.payload;
    },
    [getQuizzById.pending]: (state, action) => {
      state.statusUpdate = "loading";
    },
    [getQuizzById.fulfilled]: (state, action) => {
      state.statusUpdate = "success";
      state.quizzSelected = action.payload;
    },
    [getQuizzEntreById.pending]: (state, action) => {
      state.statusUpdate = "loading";
    },
    [getQuizzEntreById.fulfilled]: (state, action) => {
      state.statusUpdate = "success";
      state.quizzSelectedEntre = action.payload;
    },
    [getQuizzSortieById.pending]: (state, action) => {
      state.statusUpdate = "loading";
    },
    [getQuizzSortieById.fulfilled]: (state, action) => {
      state.statusUpdate = "success";
      state.quizzSelectedSortie = action.payload;
    },
    [getQuizzEvaluationById.pending]: (state, action) => {
      state.statusUpdate = "loading";
    },
    [getQuizzEvaluationById.fulfilled]: (state, action) => {
      state.statusUpdate = "success";
      state.quizzSelectedEvaluation = action.payload;
    },
    [updateArrayQuestions.pending]: (state, action) => {
      state.statusUpdate = "loading";
    },
    [updateArrayQuestions.fulfilled]: (state, action) => {
      state.statusUpdate = "success";
      state.listQuestions = action.payload;
    },
  },
});

export default quizzSlice.reducer;
