

import dynamic from 'next/dynamic';
import { useState } from 'react';
import Cookies from "js-cookie";
import { useParams } from "react-router-dom";
import { useSelector } from 'react-redux';

// Dynamically import the JitsiMeeting component with SSR disabled
const JitsiMeet =
 dynamic(() => import('../../components/googleMeet/googleMeet'), { ssr: false });

// pages/index.js


export default function Home() {
  const userConnected = useSelector((state) => state.auth.CurrentUser);
  console.log("userConnected", userConnected)

  const { id } = useParams();
  console.log("idddd", id)
  return (
    <div>
      <JitsiMeet userConnected={userConnected} id={id} />
    </div>
  );
}
