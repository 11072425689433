
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Alert, Spinner } from 'reactstrap';

import { useNavigate } from 'react-router-dom';


import { Stack, TextField, IconButton, InputAdornment } from '@mui/material';

import { LoadingButton } from '@mui/lab';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Page from "../../components/Page"
import { AddImpotAPI } from '../../utils/api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const INITIAL_USER = {
  valeurTVA: '',
  valeurRS: ''

};
const validationSchema = yup.object({
  valeurTVA: yup.number().required('Valeur TVA obligatoire'),
  valeurRS: yup.number().required('Valeur RS obligatoire'),


});
const RegisterFormateur = ({ handleClose, getPartners }) => {


  const [user, setUser] = React.useState(INITIAL_USER);
  const [disabled, setDisabled] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState('');
  const onDismiss = () => setError(false);
  const [listFormation, setListFormations] = useState([]);

  React.useEffect(() => {
    const isUser = Object.values(user).every((el) => Boolean(el));
    isUser ? setDisabled(false) : setDisabled(true);
  }, [user]);





  const formik = useFormik({


    initialValues: {
      valeurTVA: "",
      valeurRS: ""
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const obj = {
        ...values,
      };

      if (formik.isValid) {
        try {
          setLoading(true);
          setError('');

          const response = await AddImpotAPI(obj)
          if (response.data.status) {
            toast.error('Erreur de saisie !', {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
          } else {
            toast.success('Type produit ajouté avec succées!', {
              position: 'top-right',
              autoClose: 10000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
            setTimeout(() => {
              handleClose();
            }, 1000);

          }
        } catch (error) {
          setError(error.response.data.message);
        } finally {
          getPartners()
          setLoading(false);
        }
      }

    },
  });

  return (
    <Page title="User | Minimal-UI">
      <div>
        <Alert color="danger" isOpen={error ? true : false} toggle={onDismiss}>
          {error}
        </Alert>
        <ToastContainer />

        <form>
          <Stack spacing={3}>



            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <TextField
                fullWidth
                type="number"
                label="Valeur TVA"
                name="valeurTVA"
                error={formik.touched.valeurTVA && formik.errors.valeurTVA}
                helperText={formik.errors.valeurTVA}
                {...formik.getFieldProps('valeurTVA')}
              />
                <TextField
                fullWidth
                type="number"
                label="Valeur RS"
                name="valeurRS"
                error={formik.touched.valeurRS && formik.errors.valeurRS}
                helperText={formik.errors.valeurRS}
                {...formik.getFieldProps('valeurRS')}
              />

            </Stack>




            <LoadingButton
              fullWidth
              size="large"
              type="button"
              disabled={!formik.isValid}
              variant="contained"
              onClick={async () => {
                await formik.handleSubmit();
              }}
            >
              Ajouter type produit
              {loading ? <Spinner color="success" /> : ''}
            </LoadingButton>
          </Stack>
        </form>
      </div>
    </Page>
  );
};

export default RegisterFormateur;