import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import trash2Fill from '@iconify/icons-eva/trash-2-fill';

import Swal from "sweetalert2";

import { styled } from '@mui/material/styles';
import {
  Toolbar,
  Tooltip,
  IconButton,
  Typography,
  OutlinedInput,
} from '@mui/material';

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3)
}));



// ----------------------------------------------------------------------

UserListToolbar.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func
};

export default function UserListToolbar({ numSelected, handleDeleteUsers }) {

  return (
    <RootStyle
      sx={{
        ...(numSelected > 0 && {
          color: 'primary.main',
          bgcolor: 'primary.lighter'
        })
      }}
    >
      {numSelected > 0 ? (
        <Typography component="div" variant="subtitle1">
          {numSelected} selected
        </Typography>
      ) : (
        ""   
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton 

onClick={ async() => {

  Swal.fire({
        title: "Êtes-vous sûr de vouloir supprimer ?",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Annuler",

        confirmButtonColor: "#0d3077",
        cancelButtonColor: "#d33",
        confirmButtonText: "Supprimer!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          await     handleDeleteUsers()
                             
          .then(async (res) => {
              if (res?.status === 200) {
  
                Swal.fire("Supprimé avec succées!");
  
              } else {
                Swal.fire(
                  "Supprimé avec succées!"
                );
              }
            });
        }
      });






}}

        
          
         >
            <Icon icon={trash2Fill} />
          </IconButton>
        </Tooltip>
      ) : (
     ""
      )}
    </RootStyle>
  );
}
