// import React, { useEffect, useState } from "react";
// import Accordion from '@mui/material/Accordion';
// import AccordionSummary from '@mui/material/AccordionSummary';
// import AccordionDetails from '@mui/material/AccordionDetails';
// import Typography from '@mui/material/Typography';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import { useNavigate, useParams } from "react-router-dom";
// import axios from 'axios';
// import DeleteIcon from '@mui/icons-material/Delete';
// import ModeEditIcon from "@mui/icons-material/ModeEdit";
// import { IconButton, Grid, Button } from "@mui/material";
// import { useStyle } from "./styles";
// import Courses from "./../Course/Courses"
// import { SupprimerModuleAPI } from '../../utils/api';
// import Swal from "sweetalert2";
// import QuizCourse from "../Course/QuizCourse/AllQuestions"
// import AddModule from "../Modules/AddModule"
// import DetailsModule from "../Modules/DetailsModule"
// export default function SimpleAccordion() {

//   const { idFormation } = useParams();
//   const [formation, setFormation] = useState([]);
//   const [modules, setModules] = useState([]);
//   const [selectedContact, setSelectedContact] = useState({});
//   const [loading, setLoading] = useState(false);
//   const [modalVoucher, setModalVoucher] = useState(false);
//   const [modalVoucherUpdate, setModalVoucherUpdate] = useState(false);

//   const navigate = useNavigate();
//   const closeModalVoucher = () => {
//     setModalVoucher(false);
//   };
//   const openModalVoucher = () => {
//     setModalVoucher(true);
//   };

//   const closeModalVoucherUpdate = () => {
//     setModalVoucherUpdate(false);
//   };
//   const openModalVoucherUpdate = (contact) => {
//     setSelectedContact(contact);

//     setModalVoucherUpdate(true)
//   };
//   const getData = async () => {
//     if (idFormation) {
//       setLoading(true);
//       await axios.get(
//         process.env.REACT_APP_URL_BACKEND_DEV + `/formation/${idFormation}`
//       )
//         .then((res) => {
//           if (res.status === 200) {

//             setFormation(res.data);
//             setModules(res.data.modules);
//             setLoading(false);
//           }
//         });
//     }
//   };
//   useEffect(() => {
//     getData();
//   }, [idFormation]);



//   const classes = useStyle();




//   return (
//     <div>
//       <br />
//       <span className={classes.link}>

//         <h3 className={classes.linktxt}>
//           <Button
//             style={{
//               textTransform: "none",
//             }}
//             color="secondary"
//             onClick={openModalVoucher}

//           >
//             Ajouter un chapitre
//           </Button>
//         </h3>
//       </span>

//       {
//         modules.map((module) => (
//           <Accordion key={(module._id)}>
//             <AccordionSummary
//               expandIcon={<ExpandMoreIcon />}
//               aria-controls="panel1a-content"
//               id="panel1a-header"
//             >

//               <Grid
//                 container
//                 direction="row"
//                 justifyContent="space-between"
//                 alignItems="center"
//               >
//                 <Grid xs={11}>
//                   <Typography>{module?.titre_modules}</Typography>
//                 </Grid>
//                 <Grid xs >
//                   <div flexDirection='row-reverse'>
//                     <IconButton onClick={() => openModalVoucherUpdate(module)}  >
//                       <ModeEditIcon color="primary" />
//                     </IconButton>
//                     <IconButton
//                       onClick={() => {
//                         Swal.fire({
//                           title: "Êtes-vous sûr?",
//                           text: "pour supprimer ce cours",
//                           icon: "warning",
//                           showCancelButton: true,
//                           confirmButtonColor: "#0d3077",
//                           cancelButtonColor: "#d33",
//                           confirmButtonText: "Oui, supprimez-le !",
//                         }).then(async (result) => {
//                           if (result.isConfirmed) {
//                             await SupprimerModuleAPI(module?._id);
//                             getData();

//                             await navigate(`/dashboard/contenuformations/${idFormation}`)

//                               .then(async (res) => {
//                                 if (res.status === 200) {

//                                   Swal.fire("Deleted!", "Your file has been deleted.", "success");

//                                 } else {
//                                   Swal.fire({
//                                     icon: "error",
//                                     title: "Oops...",
//                                     text: "Something went wrong!",
//                                   });
//                                 }
//                               });
//                           }
//                         });

//                       }}
//                       color="error"
//                     >
//                       <DeleteIcon color="primary" />
//                     </IconButton>
//                   </div>
//                 </Grid>


//               </Grid>
//             </AccordionSummary>
//             <AccordionDetails>


//               <Accordion>



//                 <Courses modules={module} key={module._id} />


//                 <DetailsModule

//                   toggleModal={closeModalVoucherUpdate}
//                   modal={modalVoucherUpdate}
//                   recordEdit={module}
//                   key={module._id}
//                   selectedContact={selectedContact}
//                   getData={getData}

//                 />
//               </Accordion>



//               <Accordion>
//                 <AccordionSummary
//                   expandIcon={<ExpandMoreIcon />}
//                   aria-controls="panel2a-content"
//                   id="panel2a-header"
//                 >
//                   <Typography>Quiz fin de module</Typography>
//                 </AccordionSummary>
//                 <AccordionDetails>
//                   <QuizCourse modules={module} key={module._id} />
//                 </AccordionDetails>
//               </Accordion>
//             </AccordionDetails>
//           </Accordion>
//         ))
//       }

//       <AddModule

//         toggleModal={closeModalVoucher}
//         modal={modalVoucher}
//         getData={getData}

//       />
//     </div>
//   );
// }

import React, { useEffect, useState } from "react";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useNavigate, useParams } from "react-router-dom";
import axios from 'axios';
import DeleteIcon from '@mui/icons-material/Delete';
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { IconButton, Grid, Button } from "@mui/material";
import { useStyle } from "./styles";
import Courses from "./../Course/Courses"
import { SupprimerModuleAPI } from '../../utils/api';
import Swal from "sweetalert2";
import QuizCourse from "../Course/QuizCourse/AllQuestions"
import AddModule from "../Modules/AddModule"
import DetailsModule from "../Modules/DetailsModule"
export default function SimpleAccordion() {

  const { idFormation } = useParams();
  const [formation, setFormation] = useState([]);
  const [modules, setModules] = useState([]);
  const [selectedContact, setSelectedContact] = useState({});
  const [loading, setLoading] = useState(false);
  const [modalVoucher, setModalVoucher] = useState(false);
  const [modalVoucherUpdate, setModalVoucherUpdate] = useState(false);

  const navigate = useNavigate();
  const closeModalVoucher = () => {
    setModalVoucher(false);
  };
  const openModalVoucher = () => {
    setModalVoucher(true);
  };

  const closeModalVoucherUpdate = () => {
    setModalVoucherUpdate(false);
  };
  const openModalVoucherUpdate = (contact) => {
    setSelectedContact(contact);

    setModalVoucherUpdate(true)
  };
  const getData = async () => {
    if (idFormation) {
      setLoading(true);
      try {
        const res = await axios.get(
          process.env.REACT_APP_URL_BACKEND_DEV + `/formation/${idFormation}`
        );
  
        if (res.status === 200) {
          setFormation(res.data);
          setModules(res.data.modules);
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle the error appropriately, e.g., show an error message to the user
      }
    }
  };
  
  useEffect(() => {
    getData();
  }, [idFormation]);



  const classes = useStyle();




  return (
    <div>
      <br />
      <span className={classes.link}>

        <h3 className={classes.linktxt}>
          <Button
            style={{
              textTransform: "none",
            }}
            color="secondary"
            onClick={openModalVoucher}

          >
            Ajouter un chapitre
          </Button>
        </h3>
      </span>

      {
        modules.map((module) => (
          <Accordion key={(module._id)}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >

              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid xs={11}>
                  <Typography>{module?.titre_modules}</Typography>
                </Grid>
                <Grid xs >
                  <div flexDirection='row-reverse'>
                    <IconButton onClick={() => openModalVoucherUpdate(module)}  >
                      <ModeEditIcon color="primary" />
                    </IconButton>
                    <IconButton
                  onClick={async () => {
                    const result = await Swal.fire({
                      title: "Êtes-vous sûr?",
                      text: "pour supprimer ce cours",
                      icon: "warning",
                      showCancelButton: true,
                      confirmButtonColor: "#0d3077",
                      cancelButtonColor: "#d33",
                      confirmButtonText: "Oui, supprimez-le !",
                    });
                  
                    if (result.isConfirmed) {
                      try {
                        await SupprimerModuleAPI(module?._id);
                        await getData();
                        await navigate(`/contenuformations/${idFormation}`);
                  
                        Swal.fire("Supprimé!", "Le module est supprimé", "success");
                      } catch (error) {
                        console.error("Erreur:", error);
                        Swal.fire({
                          icon: "erreur",
                          title: "Oops...",
                          text: "Un problème est survenu !",
                        });
                      }
                    }
                  }}
                  color="error"
                  
                    >
                      <DeleteIcon color="primary" />
                    </IconButton>
                  </div>
                </Grid>


              </Grid>
            </AccordionSummary>
            <AccordionDetails>


              <Accordion>



                <Courses modules={module} key={module._id} />


                <DetailsModule

                  toggleModal={closeModalVoucherUpdate}
                  modal={modalVoucherUpdate}
                  recordEdit={module}
                  key={module._id}
                  selectedContact={selectedContact}
                  getData={getData}

                />
              </Accordion>



              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography>Quiz fin de module</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <QuizCourse modules={module} key={module._id} />
                </AccordionDetails>
              </Accordion>
            </AccordionDetails>
          </Accordion>
        ))
      }

      <AddModule

        toggleModal={closeModalVoucher}
        modal={modalVoucher}
        getData={getData}

      />
    </div>
  );
}