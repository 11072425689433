import React, { useEffect, useState } from "react";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';

import axios from 'axios';

import {Card, Grid} from "@mui/material";
import { useParams } from "react-router-dom";

export default function SimpleAccordion() {

    const { idUser } = useParams();
    const [formation, setFormation] = useState([]);

  useEffect(() => {
   
    getMoreData();
  }, []);
  const getMoreData = async () => {
 
    // await axios.get(process.env.REACT_APP_URL_BACKEND_DEV + `/getUserByid/${idUser}`, 
    await axios.get(  process.env.REACT_APP_URL_BACKEND_DEV +`/getUserByid/${idUser}`, 

    )
    
      .then(response => {
        setFormation(response.data.users.mesLicences)  
  })
      .catch(error => {
        console.error(error);
      });
  }
// console.log(formation)
  return (
    <div>
      <br />
    <br/>
    <br/>
      {formation?.map((user) => (
  <Accordion
   key={user._id}
   >
    <Card>
    <AccordionSummary
      aria-controls="panel1a-content"
      id="panel1a-header"
    >
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid xs={11}>
          <Typography>{user.titre}</Typography>
        </Grid>
        <Grid xs>
          {/* Ajoutez ici tout contenu supplémentaire pour le composant AccordionSummary */}
        </Grid>
      </Grid>
    </AccordionSummary>
    </Card>
  </Accordion>
   ))}
  </div>
  );
}


