import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Input, } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AddVoucher } from "../../redux/voucherSlice";
import axios from "axios"
import Select from "react-select";
import { Row, Col, FormGroup, Alert } from "reactstrap";
import { getTypeProd } from '../../redux/typeProdSlice';
import { getNomProp } from '../../redux/nomPropSlice';
import { useNavigate } from 'react-router-dom';
import Cookies from "js-cookie";
import { SignalCellularNullOutlined } from "@mui/icons-material";

const configToastr = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  newestOnTop: false,
  closeOnClick: true,
  rtl: false,
  pauseOnFocusLoss: true,
  draggable: true,
  pauseOnHover: true,
};

const AffecterVoucherModal = ({ modal, toggleModal, getUsers }) => {

  const [numCodes, setNumCodes] = useState("");
  const [reduction, setReduction] = useState(0);
  const [selectedProd, setSelectedProd] = useState("");
  const [selectedFormation, setSelectedFormation] = useState("");
  const [vouchersF, setVoucherF] = useState([]);
  const [discountedPrice, setDiscountedPrice] = useState(0);
  console.log(selectedFormation)
  const [partners, setPartners] = useState("");
  const [url, setUrl] = useState("");
  const navigate = useNavigate();
  const token = Cookies.get("tokenAdmin");
  const [isFormationSelectDisabled, setIsFormationSelectDisabled] = useState(true);
  const [selectedVoucherFormationID, setSelectedVoucherFormationID] = useState(null);
  const [selectedVoucher, setSelectedVoucher] = useState(null)
  console.log("SelectedVoucher", selectedVoucher)
  const [formations, setFormations] = useState([]);
  const [prixF, setPrixF] = useState([])
  const nom = useSelector((state) => state.nomProp.listNomProp);
  // const formateur = useSelector((state) => state);
  const [formateur, setformateur] = useState([]); // New state for formateurs
  const getUserss = async () => {
    const res = await axios.get(
      process.env.REACT_APP_URL_BACKEND_DEV + `/formateurs`
    );
    setformateur(res.data.formateurs);
  };
  const price = selectedFormation.prix * numCodes;
  const prixFinal = price - reduction
  const getPartners = async () => {
    const res = await axios.get(
      process.env.REACT_APP_URL_BACKEND_DEV + `/partner`
    );
    setPartners(res.data.partners);
  };

  const getFormation = async () => {
    const res = await axios.get(
      process.env.REACT_APP_URL_BACKEND_DEV + `/getAllFormationsApprenant`
    );
    setFormations(res.data.formations);
  };
  const getAllApprenantsByFormateur = async () => {
    const res = await axios.get(process.env.REACT_APP_URL_BACKEND_DEV + `/VoucherByBuisness`,
      {
        headers: { Authorization: `Bearer ${token}` },
        withCredentials: true
      }
    );
    setVoucherF(res.data.result);
  };

  useEffect(() => {
    getAllApprenantsByFormateur();
  }, []);


  const handleMainDropdownChange = (selectedOption) => {
    clearInput()
    setSelectedFormation(selectedOption);
    const formationID = selectedOption.value;
    setSelectedVoucherFormationID(null);
    const matchingVoucherCodes = vouchersF.filter(voucherCode => voucherCode.formation._id === formationID);

    if (matchingVoucherCodes.length > 0) {
      setSelectedVoucherFormationID(matchingVoucherCodes);
    }
  };


  useEffect(() => {
    setDiscountedPrice(null); // Reset discountedPrice
  }, [selectedFormation]);

  const [selectedVoucherCodeID, setSelectedVoucherCodeID] = useState(null);



  useEffect(() => {
    getUserss();
    getPartners();
    getFormation()
  }, []);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getTypeProd());
  }, [dispatch]);

  const handleAcheterVoucher = async () => {
    try {
      let apiUrl;
      if (selectedVoucher) {
        apiUrl =
          process.env.REACT_APP_URL_BACKEND_DEV +
          `/achetervoucher?nvPrix=${prixFinal}&prixVoucher=${discountedPrice}&numCodes=${numCodes}&selectedFormation=${selectedFormation.value}&selectedVoucher=${selectedVoucher.value}`;
      } else {
        apiUrl =
          process.env.REACT_APP_URL_BACKEND_DEV +
          `/achetervoucher?nvPrix=${prixFinal}&prixVoucher=${discountedPrice}&numCodes=${numCodes}&selectedFormation=${selectedFormation.value}`;
      }
      const response = await axios.post(apiUrl, { withCredentials: true });
      console.log("reponse", response)
      if (response.status === 200) {
        const formUrl = response.data.formUrl;
        // Check if formUrl is valid before using it
        if (formUrl) {
          // Use the formUrl directly for redirection
          window.location.href = formUrl;
        } else {
          console.error("Invalid formUrl in the response.");
        }
      } else {
        console.error("Response status is not 200.");
      }
    } catch (error) {
      console.error("Error in handleAcheterVoucher:", error.message);
    }
  };

  const clearInputAndClose = () => {
    setNumCodes("");
    setReduction("");
    setSelectedVoucherCodeID(null); // Reset selected voucher code when formation changes

    toggleModal();
    getUsers()
  };
  const clearInput = () => {
    setReduction(0);
    setSelectedVoucherCodeID(null);
    setSelectedVoucher(null) // Reset selected voucher code when formation changes
  };


  const handleVoucherSelection = (selectedOption) => {
    setSelectedVoucher(selectedOption)

    const selectedVoucherID = selectedOption.value;
    const selectedVoucher = vouchersF.find(voucher => voucher._id === selectedVoucherID);

    setSelectedVoucherCodeID(selectedVoucherID); // Update selected voucher code ID
    if (selectedVoucher) {
      const reduction = selectedVoucher.reduction; // Assuming reduction is a percentage
      const PrixReduction = price * (reduction / 100);
      setReduction(PrixReduction);

      // Calculate the discounted price
      const newPrice = price - PrixReduction;
      setDiscountedPrice(PrixReduction);
    } else {
    }
  };

  return (
    <>
      <Dialog
        open={modal}
        onClose={clearInputAndClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Affectation Voucher"}
        </DialogTitle>
        <br></br>
        <DialogContent style={{ height: "350px", width: "600px" }}>
          <Input
            placeholder="Choisir un nombre de voucher"
            style={{ marginTop: "1em" }}
            fullWidth
            value={numCodes}
            onChange={(e) => {
              const value = e.target.value;
              if (/^[0-9]*$/.test(value) || value === '') {
                setNumCodes(value);
                setIsFormationSelectDisabled(value === '');
              } else {
                toast.error("Veuillez saisir uniquement des chiffres.");
              }
            }}
          />

          <br></br>
          <br></br>

          <FormGroup>
            <div style={{ overflowY: "auto" }}>
              <Select
                placeholder="Choisir formation"
                options={formations.map((formation) => ({
                  value: formation._id,
                  label: formation.titre,
                  prix: formation.prix,
                }))}
                onChange={handleMainDropdownChange}
                menuPosition="fixed"
                isDisabled={isFormationSelectDisabled} // Add this line
              />

            </div>
          </FormGroup>

          <br></br>

          {selectedVoucherFormationID && (
            <FormGroup>
              <Select
                options={selectedVoucherFormationID.map(code => ({
                  value: code._id,
                  label: code.voucherCode,
                }))}
                onChange={handleVoucherSelection}
              />
            </FormGroup>

          )}
          {selectedFormation && (
            <div style={{ marginTop: "1em" }}>
              <strong>Prix:</strong> {price} TND
            </div>
          )}

          {selectedFormation && discountedPrice !== null && (
            <div style={{ marginTop: "1em" }}>
              <strong>Prix total de réduction :</strong> {discountedPrice.toFixed(2)} TND
            </div>
          )}
          {selectedFormation && (
            <div style={{ marginTop: "1em" }}>
              <strong>Prix Final :</strong>


              {prixFinal} TND


            </div>
          )}

        </DialogContent>
        {selectedFormation && (

          <DialogActions>
            <Button onClick={clearInputAndClose}>annuler</Button>
            <Button
              onClick={handleAcheterVoucher}

              type="button"
            >
              Acheter vouchers
            </Button>
          </DialogActions>
        )}
      </Dialog>
      <ToastContainer

        {...configToastr}
      />
    </>
  );
};

export default AffecterVoucherModal;

