import React, { useEffect, useState } from "react";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import axios from 'axios';
import {Grid} from "@mui/material";
import { useParams } from "react-router-dom";
import Certificats from "./CertificatUser"
import moment from "moment";

export default function SimpleAccordion() {

    const { idUser } = useParams();
    const [formation, setFormation] = useState([]);

    useEffect(() => {
   
      getMoreData();
    }, []);
    const getMoreData = async () => {
   
      // await axios.get(process.env.REACT_APP_URL_BACKEND_DEV + `/getUserByid/${idUser}`, 
      await axios.get(  process.env.REACT_APP_URL_BACKEND_DEV +`/getUserByid/${idUser}`, 
  
      )
      
        .then(response => {
          setFormation(response.data.users.enrolled)  
    })
        .catch(error => {
          console.error(error);
        });
    }

  console.log("formation", formation)
  return (
    <div>
      <br />
    
      {formation?.map((user) => (
  <Accordion key={user.formationId}>
    <AccordionSummary
      aria-controls="panel1a-content"
      id="panel1a-header"
      expandIcon={<ExpandMoreIcon />}

    >
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
         <Grid xs={10}>
          <Typography>{user?.formationId?.titre}   </Typography>
        </Grid>
        <Grid xs={2}>
        <b>Date enrolled :</b> {user?.dateEnrolled ? moment(user?.dateEnrolled).locale("fr").format("DD/MM/YYYY HH:mm ") : ""}
        </Grid>
      </Grid>
    </AccordionSummary>
    <AccordionDetails>

  <Certificats idFormation={user?.formationId} key={user._id} />


 


</AccordionDetails>
  </Accordion>
))}
  </div>
  );
}


