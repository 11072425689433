import React from "react";
import Loader from "react-loaders";
import LoadingOverlay from "react-loading-overlay-ts";

export default function Overlay({ enabled, children }) {
  return (
    <LoadingOverlay
      tag="div"
      active={enabled}
      styles={{
        overlay: (base) => ({
          ...base,
          background: "#fff",
          opacity: 0.8,
        }),
      }}
      spinner={<Loader color="primary" active type="ball-spin-fade-loader" />}
    >
      {children}
    </LoadingOverlay>
  );
}
