import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import {
  GetAllTypeRecAPI, getTypeRecByIdAPI,
} from "../utils/api";


const initialState = {

  listTypeRec: [],
  status: "idle",
  TypeRecselected: {},
};


export const getTypeRec = createAsyncThunk("Categorie/getCategorie", async () => {
  try {
    const response = await GetAllTypeRecAPI();
    return response.data.categorie;
  } catch (err) {
    console.log("Erreur : ", err.response);
  }

  // The value we return becomes the `fulfilled` action payload
});



export const getTypeRecById = createAsyncThunk(
  "Categorie/getCategorieById",
  async (idCategorie) => {
    try {
      const response = await getTypeRecByIdAPI(idCategorie);
      return response.data.result;
    } catch (err) {
      console.log("Erreur : ", err.response);
    }
  }
);

export const TypeRecSlice = createSlice({
  name: "TypeRec",
  initialState,
  reducers: {},

  extraReducers: {
   
    [getTypeRec.pending]: (state, action) => {
      state.status = "loading";
    },
    [getTypeRec.fulfilled]: (state, action) => {
      state.status = "success";
      state.listTypeRec = action.payload;
    },
    [getTypeRecById.pending]: (state, action) => {
      state.statusUpdate = "loading";
    },
    [getTypeRecById.fulfilled]: (state, action) => {
      state.statusUpdate = "success";
      state.TypeRecselected = action.payload;
    },
  },
});

export default TypeRecSlice.reducer;
