
import { useState, useEffect } from "react";
import axios from "axios";
import { useDispatch } from 'react-redux';

import {

  Container,

} from "@mui/material";
// components
import Page from "../../components/Page";

import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import ReactTable from "react-table-v6";

import { Button, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";

import moment from "moment";
import { LoadingButton } from '@mui/lab';
import Swal from "sweetalert2";
import Cookies from 'js-cookie';




export default function User() {

  const [apprenants, setApprenants] = useState([]);
  const [formations, setFormations] = useState([]);
  const token= Cookies.get("tokenAdmin")

  const dispatch = useDispatch();

  useEffect(() => {
    getFormations()
    getApprenants();
  }, []);

  const getApprenants = async () => {
    try {
      const res = await axios.get(
        process.env.REACT_APP_URL_BACKEND_DEV + `/getAllReservationsByFormateur`, {
          headers: { Authorization: `Bearer ${token}`, withCredentials: true },
        }
      );
      console.log("rees", res);
  
      // Group formations by formation ID and user ID
      const groupedFormations = {};
      res.data.formationF?.forEach((formation) => {
        const key = `${formation.formation._id}_${formation.etudiant._id}`;
        if (!groupedFormations[key] || formation.achat) {
          groupedFormations[key] = formation;
        }
      });
  
      // Convert the object back to an array
      const filteredFormations = Object.values(groupedFormations);
  
      setApprenants(filteredFormations);
      console.log("apprenants", apprenants);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  
  

const list= apprenants?.etudiant?.enrolledPres
console.log("apprenants",apprenants)

  const getFormations = async () => {
    const res = await axios.get(
      process.env.REACT_APP_URL_BACKEND_DEV + `/getAllFormationsFormateur`, {
        headers: { Authorization: `Bearer ${token}`, 
        withCredentials: true
      },}
    );
    console.log("formateurformation",res)

    setFormations(res.data.formations);

  };
  const [nameFilterValue, setNameFilterValue] = useState("all");

  const columns = [
   
    {
      Header: "Apprenants",
      id: "nomPrenom",
      style: { textAlign: "center" },
      width: 200,
      accessor: (row) => `${row?.etudiant?.nom} ${row?.etudiant?.prenom}`,
      Cell: ({ original }) => {
        return (
          <span style={{ wordBreak: "break-all" }}>
            {original?.etudiant?.nom}{" "}
            {original?.etudiant?.prenom}
          </span>
        );
      },
      filterMethod: (filter, row) => {
        if (filter.value === "all") {
          return true;
        }
        return row.nomPrenom.toLowerCase().includes(filter.value.toLowerCase())
      },
    },
    {
      Header: "Email",
      accessor: "email",
      Cell: ({ original }) => {
        return <div>{original?.etudiant?.email} </div>
      },
    },
  
    {
      Header: "Date de réservation",
      id: "dateChoisi",
      style: { textAlign: "center" },
      width: 200,
      accessor: (row) => `${row.dateChoisi}`,
      Cell: ({ original }) => {
        return (
          <span style={{ wordBreak: "break-all" }}>
            {original?.dateChoisi}{" "}
          </span>
        );
      },
      filterMethod: (filter, row) => {
        if (filter.value === "all") {
          return true;
        }
        // Replace the condition with your specific logic for date filtering
        return row.dateChoisi.includes(filter.value);
      },
    
      // Filter: ({ filter, onChange }) => {
      //   const uniqueDates = Array.from(new Set(apprenants.map(item => `${item.dateChoisi}`)));
      //   return (
      //     <select
      //       style={{ width: "100%", fontSize: "1rem" }}
      //       onChange={(event) => onChange(event.target.value)}
      //       value={filter ? filter.value : "all"}
      //     >
      //       <option value="all">Tous</option>
      //       {uniqueDates.map((date, index) => (
      //         <option key={index} value={date}>
      //           {console.log("ggggg", date)}
      //           {date}
      //         </option>
      //       ))}
      //     </select>
      //   );
      // },
    },
    
    {
      Header: "Reserve/Achat",
      accessor: "achat",
      style: { textAlign: "center" },
      width: 150,
      Cell: ({ original }) => {
        const typeText = original?.achat === true ? "Achat" : "Simple réservation"; // Correction ici
        return <b>{typeText}</b>;
      },
      filterMethod: (filter, row) => {
        if (filter.value === "all") {
          return true;
        }
        if (filter.value === "CB") {
          return row[filter.id] === true; // Correction ici
        }
        if (filter.value === "voucher") {
          return row[filter.id] === false; // Correction ici
        }
        return false;
      },
      Filter: ({ filter, onChange }) => {
        return (
          <select
            style={{ width: "100%", fontSize: "1rem" }}
            onChange={(event) => onChange(event.target.value)}
            value={filter ? filter.value : "all"}
          >
            <option value="all">Filtrer</option>
            <option value="CB">Carte Bancaire</option>
            <option value="voucher">Réservation</option> // Correction ici
          </select>
        );
      },
    },

    {
      Header: "Formations",
      accessor: "formation",
      width: 200,
      Cell: ({ original }) => {
        console.log("originalform", original)
        return <div>{original?.formation?.titre}</div>;
      },
      filterMethod: (filter, row) => {
        if (filter.value === "all") {
          return true;
        }
        return row.formation.titre === filter.value;
      },
      Filter: ({ filter, onChange }) => {
        return (
          <select
            style={{ width: "100%", fontSize: "1rem" }}
            onChange={(event) => onChange(event.target.value)}
            value={filter ? filter.value : "all"}
          >
            <option value="all">Tous</option>
            {formations.map((item) => (
              <option key={item._id} value={item.titre}>
                {item.titre}
              </option>
            ))}
          </select>
        );
      },
    },
    // {
    //   Header: "Actions",
    //   Cell: ({ original }) => {
    //     return <div>

    //       <LoadingButton

    //         onClick={async () => {

    //           Swal.fire({
    //             title: `Voulez vous vraiment annuler cette réservation`,
    //             icon: "warning",
    //             showCancelButton: true,
    //             cancelButtonText: "Annuler",

    //             confirmButtonColor: "#0d3077",
    //             cancelButtonColor: "#d33",
    //             confirmButtonText: `confirmer`,
    //           }).then(async (result) => {
    //             if (result.isConfirmed) {
    //               await axios.delete(process.env.REACT_APP_URL_BACKEND_DEV + `/deleteReservation/${original._id}`); 

    //               await axios.get(process.env.REACT_APP_URL_BACKEND_DEV + `/getAllFormationsReserve`)

    //               .then(async (res) => {
    //                   if (res.status === 200) {

    //                     Swal.fire("Réservation annulée");

    //                     const reservedFormations = res.data.formations.filter(formation => formation.reserve);

    //     // Update the state with the new data
    //     setApprenants(reservedFormations);
    //                   } else {
    //                     Swal.fire(
    //                       `Erreur`, 
    //                     );
    //                   }
    //                 });
    //             }
    //           });



    //         }}
    //       >
    //         Annuler réservation
    //       </LoadingButton>
    //     </div>
    //   },
    // },


    //       },

    //     },


    // },




  ];


  return (
    <Page title="Dashboard | Fastuz">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Formations réservées
          </Typography>

        </Stack>


        <CardBody>
          <Row>

            <ReactTable
              data={apprenants}
              nextText={"Suivant"}
              previousText={"Précedent"}
              rowsText={"Lignes"}
              columns={columns}
              defaultPageSize={10}
              filterable={true}
              noDataText="No data is available"
              defaultSorted={[{ id: "createdAt", desc: true }]}

            /* overiding the filter methode */
            />


          </Row>
        </CardBody>
      </Container>
    </Page>
  );
}


