import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { getAllEtudiantAPI, getAllEtudiantEnrolledAPI,FindEtudiantByIdAPI,getAllAcheteurAPI, getUserByToken, loginAPI, getAllUsersAPI,getAllApprenantsByFormateurAPI, getAllTeachersAPI, getAllFormateursAPI,getListeOfStudentEnrolledAPI, getAllBusinessAPI } from "../utils/api";


const initialState = {
  CurrentUser: {},
  status: "idle",
  statusLogin: "noLogin",
  messageLogin: "",
  students: [],
};

export const LoginMethode = createAsyncThunk(
  "Auth/LoginMethode",
  async (utilisateur) => {
    try {
      const response = await loginAPI(utilisateur);

      return response.data;
    } catch (err) {
    }

  }
);

export const getAllFormateurs = createAsyncThunk(
  "formateurs/getAllFormateurs",
  async () => {
    try {
      const response = await getAllFormateursAPI();
      return response.data.formateurs;
    } catch (err) {
    }

  }
);


export const getCurrentUserByToken = createAsyncThunk(
  "Auth/getCurrentUserByToken",
  async (token) => {
    try {
      const response = await getUserByToken(token);

      return response.data.user
    } catch (err) {
    }

  }
);
export const getEtudiantById = createAsyncThunk(
  "etudiants/getEtudiantById",
  async (idEtudiant) => {
    try {
      const response = await FindEtudiantByIdAPI(idEtudiant);
     
      return response.data.etudiant;
    } catch (err) {
    }

  }
);
export const getAllBusiness = createAsyncThunk(
  "formateurs/getAllFormateurs",
  async () => {
    try {
      const response = await getAllBusinessAPI();
      return response.data.formateurs;
    } catch (err) {
    }

  }
);
export const getListeOfStudents = createAsyncThunk(
  "Auth/getListeOfStudents",
  async () => {
    try {
      const response = await getAllEtudiantAPI();

      return response.data.students;
    } catch (err) {
    }
  }
);
export const getListeOfStudentEnrolled = createAsyncThunk(
  "Auth/getListeOfStudentEnrolled",
  async () => {
    try {
      const response = await getListeOfStudentEnrolledAPI();

      return response.data.students;
    } catch (err) {
    }
  }
);
export const getListeOfAcheteur = createAsyncThunk(
  "Auth/getListeOfAcheteur",
  async () => {
    try {
      const response = await getAllAcheteurAPI();

      return response.data.length;
    } catch (err) {
    }
  }
);

export const getAllApprenantsByFormateur = createAsyncThunk(
  "Auth/getAllApprenantsByFormateur",
  async () => {
    try {
      const response = await getAllApprenantsByFormateurAPI();

      return response.data.students;
    } catch (err) {
    }
  }
);


export const getListeOfUsers = createAsyncThunk(
  "Auth/getListeOfUsers",
  async () => {
    try {
      const response = await getAllUsersAPI();
      return response.data.users;
    } catch (err) {
    }

  }
);

export const getListeOfTeachers = createAsyncThunk(
  "Auth/getListeOfTeachers",
  async () => {
    try {
      const response = await getAllTeachersAPI();

      return response.data.formateurs;
    } catch (err) {
    }
  }
);


export const getListeOfStudentsEnrolled = createAsyncThunk(
  "Auth/getListeOfUsersEnroled",
  async () => {
    try {
      const response = await getAllEtudiantEnrolledAPI();

      return response.data.students;
    } catch (err) {
    }

  }
);

export const authSlice = createSlice({
  name: "Auth",
  initialState,
  reducers: {},

  extraReducers: {
    [LoginMethode.pending]: (state, action) => {
      state.status = "loading";
    },
    [LoginMethode.fulfilled]: (state, action) => {
      state.status = "success";

      if (action.payload.statue === false) {
        state.statusLogin = "Not Logined";
        state.messageLogin = action.payload.message;
      } else {
        state.statusLogin = "Logined";
        state.CurrentUser = action.payload.user;
      }
    },
    [getCurrentUserByToken.pending]: (state, action) => {
      state.status = "loading";
    },
    [getCurrentUserByToken.fulfilled]: (state, action) => {
      state.status = "sucess";
      state.statusLogin = "Logined";
      state.CurrentUser = action.payload;
    },
    [getListeOfStudents.pending]: (state, action) => {
      state.status = "loading";
    },
    [getListeOfStudents.fulfilled]: (state, action) => {
      state.status = "sucess";

      state.students = action.payload;
    },
    [getListeOfTeachers.pending]: (state, action) => {
      state.status = "loading";
    },

    [getListeOfAcheteur.pending]: (state, action) => {
      state.status = "loading";
    },
    [getListeOfAcheteur.fulfilled]: (state, action) => {
      state.status = "sucess";

      state.length = action.payload;
    },

    [getListeOfTeachers.fulfilled]: (state, action) => {
      state.status = "sucess";

      state.formateurs = action.payload;
    },
    [getListeOfUsers.pending]: (state, action) => {
      state.status = "loading";
    },
    [getListeOfUsers.fulfilled]: (state, action) => {
      state.status = "sucess";

      state.users = action.payload;
    },
    [getAllApprenantsByFormateur.pending]: (state, action) => {
      state.status = "loading";
    },
    [getAllApprenantsByFormateur.fulfilled]: (state, action) => {
      state.status = "sucess";

      state.users = action.payload;
    },
    [getListeOfStudentsEnrolled.pending]: (state, action) => {
      state.status = "loading";
    },
    [getListeOfStudentsEnrolled.fulfilled]: (state, action) => {
      state.status = "sucess";

      state.students = action.payload;
    },
    [getEtudiantById.pending]: (state, action) => {
      state.status = "loading";
    },
    [getEtudiantById.fulfilled]: (state, action) => {
      state.status = "success";
        state.students = action.payload;
    },

  },
});

export default authSlice.reducer;