import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  addNewCertificationAPI,
  deleteCertificationAPI,
  getAllCertificationsAPI,
  getCertificationByIdAPI,
  updateCertificationAPI,
} from "../utils/api";

const initialState = {
  certification: { loading: false, data: [] },
  selectedCertification: { loading: false, certification: {} },
};

export const getAllCertifications = createAsyncThunk(
  "Certification/getAllCertifications",
  async () => {
    try {
      const response = await getAllCertificationsAPI();

      return response.data.res;
    } catch (err) {
      const customError = {
        name: "Custom axios error",
        message: err.response.data.message,
        status: err.response.data.status,
      };
      throw customError;
    }

  }
);

export const getCertificationById = createAsyncThunk(
  "Certification/getCertificationById",
  async (id) => {
    try {
      const response = await getCertificationByIdAPI(id);

      return response.data.res;
    } catch (err) {
      const customError = {
        name: "Custom axios error",
        message: err.response.data.message,
        status: err.response.data.status,
      };
      throw customError;
    }

  }
);

export const addNewCertification = createAsyncThunk(
  "Certification/addNewCertification",
  async (newCentre) => {
    try {
      const response = await addNewCertificationAPI(newCentre);

      return response.data;
    } catch (err) {
      const customError = {
        name: "Custom axios error",
        message: err.response.data.message,
        status: err.response.data.status,
      };
      throw customError;
    }
  }
);

export const deleteCertification = createAsyncThunk(
  "Certification/deleteCertification",
  async (id) => {
    try {
      const response = await deleteCertificationAPI(id);

      return response.data.res;
    } catch (err) {
      const customError = {
        name: "Custom axios error",
        message: err.response.data.message,
        status: err.response.data.status,
      };
      throw customError;
    }
  }
);

export const updateCertification = createAsyncThunk(
  "Certification/updateCertification",
  async (updatedCentre) => {
    try {
      const response = await updateCertificationAPI(updatedCentre);

      return response.data;
    } catch (err) {
      const customError = {
        name: "Custom axios error",
        message: err.response.data.message,
        status: err.response.data.status,
      };
      throw customError;
    }
  }
);

export const CertificationSlice = createSlice({
  name: "Certification",
  initialState,
  reducers: {},

  extraReducers: {
    [getAllCertifications.pending]: (state) => {
      state.certification.loading = true;
    },
    [getAllCertifications.fulfilled]: (state, action) => {
      state.certification.loading = false;
      state.certification.data = action.payload;
    },
    [getAllCertifications.rejected]: (state) => {
      state.certification.loading = false;
    },
    [addNewCertification.pending]: (state) => {
      state.certification.loading = true;
    },
    [addNewCertification.fulfilled]: (state) => {
      state.certification.loading = false;
    },
    [addNewCertification.rejected]: (state) => {
      state.certification.loading = false;
    },
    [updateCertification.pending]: (state) => {
      state.certification.loading = true;
    },
    [updateCertification.fulfilled]: (state) => {
      state.certification.loading = false;
    },
    [updateCertification.rejected]: (state) => {
      state.certification.loading = false;
    },
    [deleteCertification.pending]: (state) => {
      state.certification.loading = true;
    },
    [deleteCertification.fulfilled]: (state) => {
      state.certification.loading = false;
    },
    [deleteCertification.rejected]: (state) => {
      state.certification.loading = false;
    },
    [getCertificationById.pending]: (state) => {
      state.selectedCertification.loading = true;
    },
    [getCertificationById.fulfilled]: (state, action) => {
      state.selectedCertification.loading = false;
      state.selectedCertification.certification = action.payload;
    },
    [getCertificationById.rejected]: (state) => {
      state.selectedCertification.loading = false;
    },
  },
});

export default CertificationSlice.reducer;
