import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import {
  GetAllContratVAPI,getContratVeByIdAPI
} from "../utils/api";


const initialState = {

  listContratV: [],
  status: "idle",
  contratSelected: {},
};


export const GetAllContratV = createAsyncThunk("Contrat/GetAllContratVAPI", async () => {
  try {
    const response = await GetAllContratVAPI();
    return response.data.contrat;
  } catch (err) {
    console.log("Erreur : ", err.response);
  }

  // The value we return becomes the `fulfilled` action payload
});



export const getContratVById = createAsyncThunk(
  "Contrat/getContratVeByIdAPI",
  async (idCategorie) => {
    try {
      const response = await getContratVeByIdAPI(idCategorie);
      return response.data.result;
    } catch (err) {
      console.log("Erreur : ", err.response);
    }
  }
);

export const contratSlice = createSlice({
  name: "ContratV",
  initialState,
  reducers: {},

  extraReducers: {
   
    [GetAllContratV.pending]: (state, action) => {
      state.status = "loading";
    },
    [GetAllContratV.fulfilled]: (state, action) => {
      state.status = "success";
      state.listContratV = action.payload;
    },
    [getContratVById.pending]: (state, action) => {
      state.statusUpdate = "loading";
    },
    [getContratVById.fulfilled]: (state, action) => {
      state.statusUpdate = "success";
      state.contratSelected = action.payload;
    },
  },
});

export default contratSlice.reducer;
