import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import {
  GetAllTypeFormationAPI, getTypeFormationByIdAPI,
} from "../utils/api";


const initialState = {

  listTypeFormation: [],
  status: "idle",
  TypeFormationselected: {},
};


export const getTypeFormation = createAsyncThunk("Type/getTypeFormation", async () => {
  try {
    const response = await GetAllTypeFormationAPI();
    console.log("response",response)
    return response.data.categorie;
  } catch (err) {
    console.log("Erreur : ", err.response);
  }

  // The value we return becomes the `fulfilled` action payload
});



export const getTypeFormationById = createAsyncThunk(
  "Type/getTypeFormationById",
  async (idCategorie) => {
    try {
      const response = await getTypeFormationByIdAPI(idCategorie);
      return response.data.result;
    } catch (err) {
      console.log("Erreur : ", err.response);
    }
  }
);

export const TypeFormationSlice = createSlice({
  name: "type",
  initialState,
  reducers: {},

  extraReducers: {
   
    [getTypeFormation.pending]: (state, action) => {
      state.status = "loading";
    },
    [getTypeFormation.fulfilled]: (state, action) => {
      state.status = "success";
      state.listTypeFormation = action.payload;
    },
    [getTypeFormationById.pending]: (state, action) => {
      state.statusUpdate = "loading";
    },
    [getTypeFormationById.fulfilled]: (state, action) => {
      state.statusUpdate = "success";
      state.TypeFormationselected = action.payload;
    },
  },
});

export default TypeFormationSlice.reducer;
