import React, { useState, useEffect } from "react";
import { Card, Container, Typography, Stack, Button } from "@mui/material";
import { CardBody, Col, Row } from "reactstrap";
import Page from "../../components/Page";
import axios from "axios";
import ReactTable from "react-table-v6";
import { Link } from "react-router-dom";
import "react-table-v6/react-table.css";
import Cookies from "js-cookie";
import moment from "moment";
import { Icon } from "@iconify/react";
import plusFill from "@iconify/icons-eva/plus-fill";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { useSelector } from "react-redux";

export default function Formations() {
  const token = Cookies.get("tokenAdmin");
  const [listFormations, setListFormations] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(moment().month() + 1);
  const [selectedYear, setSelectedYear] = useState("");
  const [valeurTVA, setValeurTVA] = useState(0)
  const [valeurRS, setValeurRS] = useState(0)
  const userConnected = useSelector((state) => state.auth.CurrentUser);
  console.log("userconnected", userConnected)

  useEffect(() => {
    getMoreData();
    getImpots()
  }, []);

  const getMoreData = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_URL_BACKEND_DEV + `/getRevenusForFormateur`,
        {
          headers: { Authorization: `Bearer ${token}` },
          withCredentials: true,
        }
      );
      setListFormations(response.data.result);
      // Extracting unique years from the list of formations
      const uniqueYears = [...new Set(response.data.result.map((item) => moment(item.date, 'M/YYYY').format('YYYY')))];
      setSelectedYear(uniqueYears[0] || ''); // Set the default selected year to the first one or empty string if there's none
    } catch (error) {
      console.error(error);
    }
  };
  console.log(listFormations)
  const getImpots = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_URL_BACKEND_DEV + `/getImpots`
      );
      setValeurTVA(response.data.result[0].valeurTVA);
      setValeurRS(response.data.result[0].valeurRS);


    } catch (error) {
      console.error(error);
    }
  };

  const navigate = useNavigate();

  const handleMonthChange = (selectedOption) => {
    setSelectedMonth(selectedOption.value);
  };

  const handleYearChange = (selectedOption) => {
    setSelectedYear(selectedOption.value);
  };

  const filteredFormations = listFormations.filter(
    (formation) =>
      moment(formation.date, "M/YYYY").format("M") === selectedMonth.toString() &&
      moment(formation.date, "M/YYYY").format("YYYY") === selectedYear
  );

  const monthsOptions = moment.months().map((month, index) => ({
    value: index + 1,
    label: month
  }));

  const sortedYears = [...new Set(listFormations.map(formation => moment(formation.date, "M/YYYY").format("YYYY")))].sort((a, b) => parseInt(a) - parseInt(b)); // Sort the years in ascending order
  const yearsOptions = sortedYears.map((year) => ({
    value: year,
    label: year
  }));


  const [selectedDate, setSelectedDate] = useState(new Date()); // Initialize with the current date
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  console.log("filteredFormations", filteredFormations)
  const months = moment.months(); // List of all months
  const totalMontantHT = listFormations.reduce((total, formation) => total + (formation.prixWithReduction * (100 - valeurTVA) / 100), 0);
  const totalRevenusBrut = listFormations.reduce((total, formation) => total + ((formation.prixWithReduction * (100 - valeurTVA) / 100) * (userConnected.pourcentageRevenus / 100)), 0);
  const totalMontantNet = listFormations.reduce((total, formation) => total + (((formation.prixWithReduction * (100 - valeurTVA) / 100) * (userConnected.pourcentageRevenus / 100)) * (100 - valeurRS) / 100), 0);
  const columns = [
    {
      Header: <div style={{ whiteSpace: 'pre-line' }}>Titre formation</div>,

      accessor: "titre",
      width: 220,
      filterMethod: (filter, row) =>
        row[filter.id].toLowerCase().includes(filter.value.toLowerCase()),
      Cell: ({ original }) => {
        console.log(original.freeV)
        return (
          <span style={{ wordBreak: "break-all", whiteSpace: 'pre-line' }}>
            <Link to={"/contenuformations/" + original.formation._id}>
              "{original.formation.titre}"
            </Link>
          </span>
        );
      },
    },
 
    {
      Header: <div style={{ whiteSpace: 'pre-line' }}>Nombre d'apprenants</div>,

      accessor: "count",
      width: 100,
      Cell: ({ original }) => {
        return (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div>{original?.etudiants.length}</div>

          </div>
        );
      },
    },
    {
      Header: <div style={{ whiteSpace: 'pre-line' }}>Montant de la formation (TTC)</div>,

      accessor: "prix",
      Cell: ({ original }) => {
        return (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div>{original.formation?.prix} TND</div>
          </div>
        );
      },
    },
    {
      Header: <div style={{ whiteSpace: 'pre-line' }}>Acheté par virement</div>,

      accessor: "freeV",
      width: 100,
      Cell: ({ original }) => {
        return (
          <div style={{ display: "flex", justifyContent: "center" }}>
            {console.log("teest",original)}
            {original?.freeV ?
            <div>false</div>:
            <div> true</div>}

          </div>
        );
      },
    },
    {
      Header: <div style={{ whiteSpace: 'pre-line' }}>Discount (%)</div>,

      accessor: "reduction",
      width: 100,
      Cell: ({ original }) => {
        return (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div>{original?.reduction} %</div>
          </div>
        );
      },
    },
    {
      Header: <div style={{ whiteSpace: 'pre-line' }}>Montant payé par formation (TTC)</div>,

      accessor: "prix",
      Cell: ({ original }) => {
        return (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div>
              {original.formation?.prix *
                (100 - original?.reduction) /
                100}{" "}
            </div>
          </div>
        );
      },
    },
    {
      Header: <div style={{ whiteSpace: 'pre-line' }}>Montant Total reçu (TTC)</div>,
      width: 130,
      accessor: "prix",
      Cell: ({ original }) => {
        return (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div>
              {original.prixWithReduction}
            </div>
          </div>
        );
      },
      Footer: (
        <div style={{ display: "flex", justifyContent: "center", whiteSpace: 'pre-line' }}>
          <div> Montant Total: {`\n`}{listFormations.reduce((total, formation) => total + formation.prixWithReduction, 0)}TND</div>
        </div>
      ),
    },
    {
      Header: <div style={{ whiteSpace: 'pre-line' }}>Montant Total HT ( TVA{valeurTVA}%)</div>,
      width: 130,
      accessor: "prix",
      Cell: ({ original }) => {
        return (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div>
              {(original.prixWithReduction * (100 - valeurTVA) / 100).toFixed(3)}{" "}
            </div>
          </div>
        );
      },

      Footer: (
        <div style={{ display: "flex", justifyContent: "center", whiteSpace: 'pre-line' }}>
          <div>Montant HT: {`\n`} {totalMontantHT.toFixed(3)} TND</div>
        </div>
      ),
    },
    {
      Header: <div style={{ whiteSpace: 'pre-line' }}>Mes revenus Brut ({userConnected.pourcentageRevenus} %)</div>,
      width: 130,

      accessor: "prix",
      Cell: ({ original }) => {
        return (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div>
              {((original.prixWithReduction * (100 - valeurTVA) / 100) * (userConnected.pourcentageRevenus / 100)).toFixed(3)}{" "}
            </div>
          </div>
        );
      },

      Footer: (
        <div style={{ display: "flex", justifyContent: "center", whiteSpace: 'pre-line' }}>
          <div> Total brut: {`\n`}{totalRevenusBrut.toFixed(3)} TND</div>
        </div>
      ),
    },
    {
      Header: <div style={{ whiteSpace: 'pre-line' }}>Montant Net après RS ({valeurRS}%) </div>,
      width: 150,
      accessor: "prix",
      Cell: ({ original }) => {
        return (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div>
              {(((original.prixWithReduction * (100 - valeurTVA) / 100) * (userConnected.pourcentageRevenus / 100)) * ((100 - valeurRS) / 100)).toFixed(3)}{" "}
            </div>
          </div>
        );
      },
      Footer: (
        <div style={{ display: "flex", justifyContent: "center", whiteSpace: 'pre-line' }}>
          <div> Total après RS:
            {`\n`}
            {totalMontantNet.toFixed(3)} TND</div>
        </div>
      ),
    },

  ];

  // Filter formations based on the selected month and year

  return (
    <Page title="Dashboard | Fastuz">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Mes Revenus
          </Typography>
          <div style={{ display: "flex" }}>
            <Select
              value={{ value: selectedMonth, label: moment().month(selectedMonth - 1).format("MMMM") }}
              onChange={handleMonthChange}
              options={monthsOptions}
            />
            <div style={{ marginLeft: "5px" }}>
              <Select
                value={{ value: selectedYear, label: selectedYear }}
                onChange={handleYearChange}
                options={yearsOptions}
              />
            </div>

          </div>
        </Stack>

        <Row>
          <Col md="12">
            <Card className="main-card mb-3">
              <CardBody>
                <ReactTable
                  data={filteredFormations}
                  nextText={"Suivant"}
                  previousText={"Précedent"}
                  rowsText={"Lignes"}
                  columns={columns}
                  defaultPageSize={5}
                  filterable={true}
                  noDataText="No data is available"
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Page>
  );
}



