// // import { merge } from "lodash";
// // import ReactApexChart from "react-apexcharts";
// // import { Card, CardHeader, Box } from "@mui/material";
// // import { BaseOptionChart } from "../../charts";
// // import { useEffect, useState } from "react";
// // import { useDispatch } from "react-redux";
// // import { getAllFormations } from "../../../redux/formationSlice";
// // import { getListeOfStudents } from "../../../redux/authSlice";


// // export default function AppWebsiteVisits({ item, type }) {
// //   const [arraymonths] = useState([
// //     "Janvier",
// //     "Février",
// //     "Mars",
// //     "Avril",
// //     "May",
// //     "Juin",
// //     "Juillet",
// //     "Aout",
// //     "September",
// //     "Octobre",
// //     "Novembre", 
// //     "Decembre"

// //   ]);

// //   const [arrayDateFormation, setarrayDateFormation] = useState([]);
// //   const [arrayDateStudents, setarrayDateStudents] = useState([]);



// //   const PrepareNumberPerArrayOfMonth = async (
// //     arrayNameMonth,
// //     arrayFormation,
// //     type
// //   ) => {
// //     let array = [];
// //     arrayNameMonth.forEach((element, index) => {
// //       var number = PrepareNumberPerMonth(arrayFormation, index);

// //       array.push(number);
// //     });
// //     type === "formation"
// //       ? setarrayDateFormation(array)
// //       : setarrayDateStudents(array);

// //     return array;
// //   };
// //   const PrepareNumberPerMonth = (array, month) => {
// //     let number = 0;
// //     array.forEach((formation) => {
// //       const date = new Date(formation.createdAt);
// //       if (date.getMonth() === month) {
// //         number++;
// //       }
// //     });

// //     return number;
// //   };

// //   const [makingApiCall, setMakingApiCall] = useState(false);

// //   const dispatch = useDispatch();

// //   useEffect(() => {
// //     setMakingApiCall(true);
// //     dispatch(getListeOfStudents()).then((result) => {
// //       PrepareNumberPerArrayOfMonth(arraymonths, result.payload, "students");
// //       dispatch(getAllFormations()).then(async (res) => {
// //         await PrepareNumberPerArrayOfMonth(
// //           arraymonths,
// //           res.payload,
// //           "formation"
// //         );
// //         setMakingApiCall(false);
// //       });
// //     });
// //   }, [dispatch]);

// //   const chartOptions = merge(BaseOptionChart(), {
// //     stroke: { width: [0, 2, 3] },
// //     plotOptions: { bar: { columnWidth: "11%", borderRadius: 4 } },
// //     fill: { type: ["solid", "gradient", "solid"] },
// //     labels: [
// //       "2023-01-01",
// //       "2023-02-01",
// //       "2023-03-01",
// //       "2023-04-01",
// //       "2023-05-01",
// //       "2023-06-01",
// //       "2023-07-01",
// //       "2023-08-01",
// //       "2023-09-01",
// //       "2023-10-01",
// //       "2023-11-01",
// //       "2023-12-01",
// //       "2024-01-01",

// //     ],
// //     xaxis: { type: "datetime" },
// //     tooltip: {
// //       shared: true,
// //       intersect: false,
// //       x: {
// //         format: "MMM",
// //       },
// //       y: {
// //         formatter: (y) => {
// //           if (typeof y !== "undefined") {
// //             return `${y.toFixed(0)} item(s)`;
// //           }
// //           return y;
// //         },
// //       },
// //     },
// //   });

// //   return (
// //     <Card>
// //       <CardHeader
// //         subheader="Formations ajoutées / Nombre d'inscription par mois"
// //       />
// //       <Box sx={{ p: 3, pb: 1 }} dir="ltr">
// //         {!makingApiCall ? (
// //           <ReactApexChart
// //             type="line"
// //             series={[
// //               {
// //                 name: "Nombre d'inscription",
// //                 type: "column",
// //                 data: arrayDateStudents,
// //               },
              
// //               {
// //                 name: "Formations ajoutées",
// //                 type: "area",
// //                 data: arrayDateFormation,
// //               },
// //             ]}
// //             options={chartOptions}
// //             height={364}
// //           />
// //         ) : (
// //           <span>Loading...</span>
// //         )}
// //       </Box>

// //     </Card>
// //   );
// // }



// import { merge } from "lodash";
// import ReactApexChart from "react-apexcharts";
// import { Card, CardHeader, Box } from "@mui/material";
// import { BaseOptionChart } from "../../charts";
// import { useEffect, useState } from "react";
// import { useDispatch } from "react-redux";
// import { getAllFormations } from "../../../redux/formationSlice";
// import { getListeOfStudents,getListeOfStudentEnrolled } from "../../../redux/authSlice";


// export default function AppWebsiteVisits({ item, type }) {
//   const [arraymonths] = useState([
//     "Janvier",
//     "Février",
//     "Mars",
//     "Avril",
//     "May",
//     "Juin",
//     "Juillet",
//     "Aout",
//     "September",
//     "Octobre",
//     "Novembre", 
//     "Decembre",
    

//   ]);

//   const [arrayDateFormation, setarrayDateFormation] = useState([]);
//   const [arrayDateStudents, setarrayDateStudents] = useState([]);
//   const [arrayDateStudentsEnrolled, setarrayDateStudentsEnrolled] = useState([]);



//   const PrepareNumberPerArrayOfMonth = async (
//     arrayNameMonth,
//     arrayFormation,
//     type
//   ) => {
//     let array = [];
//     arrayNameMonth.forEach((element, index) => {
//       var number = PrepareNumberPerMonth(arrayFormation, index);

//       array.push(number);
//     });
//     type === "formation"
//       ? setarrayDateFormation(array)
//       : setarrayDateStudents(array);

//     return array;
//   };
//   const PrepareNumberPerMonth = (array, month) => {
//     let number = 0;
//     array.forEach((formation) => {
//       const date = new Date(formation.createdAt);
//       if (date.getMonth() === month) {
//         number++;
//       }
//     });

//     return number;
//   };

//   const PrepareNumberPerArrayOfMonthEnrolled = async (
//     arrayNameMonth,
//     arrayEnrolledStudents
//   ) => {
//     let array = [];
//     arrayNameMonth.forEach((element, index) => {
//       var number = PrepareNumberPerMonthEnrolled(arrayEnrolledStudents, index);
  
//       array.push(number);
//     });
  
//     setarrayDateStudentsEnrolled(array);
  
//     return array;
//   };
  
//   const PrepareNumberPerMonthEnrolled = (array, month) => {
//     let number = 0;

//     array.forEach((formation) => {
//       formation.enrolled.forEach((enrollment) => {
//         const date = new Date(enrollment.dateEnrolled);
//         console.log("enrollment.dateEnrolleddate", enrollment.dateEnrolled);

//         if (date.getMonth() === month) {
//           number++;
//         }
//       });
//     });
//     return number;
//   };
  

//   const [makingApiCall, setMakingApiCall] = useState(false);

//   const dispatch = useDispatch();

//   useEffect(() => {
//     setMakingApiCall(true);
//     dispatch(getListeOfStudents()).then((result) => {
//       PrepareNumberPerArrayOfMonth(arraymonths, result.payload, "students");
//       dispatch(getAllFormations()).then(async (res) => {
//         await PrepareNumberPerArrayOfMonth(
//           arraymonths,
//           res.payload,
//           "formation"
//         );
//         setMakingApiCall(false);
//       });
//     });
//   }, [dispatch]);

//   useEffect(() => {
//     setMakingApiCall(true);
//     dispatch(getListeOfStudentEnrolled()).then((result) => {
//       PrepareNumberPerArrayOfMonthEnrolled(arraymonths, result.payload);
//       dispatch(getListeOfStudents()).then(async (res) => {
//         await PrepareNumberPerArrayOfMonth(
//           arraymonths,
//           res.payload,
//           "students"
//         );
//         dispatch(getAllFormations()).then(async (res) => {
//           await PrepareNumberPerArrayOfMonth(
//             arraymonths,
//             res.payload,
//             "formation"
//           );
//           setMakingApiCall(false);
//         });
//       });
//     });
//   }, [dispatch]);
  

//   const chartOptions = merge(BaseOptionChart(), {
//     stroke: { width: [0, 2, 3] },
//     plotOptions: { bar: { columnWidth: "11%", borderRadius: 4 } },
//     fill: { type: ["solid", "gradient", "solid"] },
//     labels: [
//       "2023-01-01",
//       "2023-02-01",
//       "2023-03-01",
//       "2023-04-01",
//       "2023-05-01",
//       "2023-06-01",
//       "2023-07-01",
//       "2023-08-01",
//       "2023-09-01",
//       "2023-10-01",
//       "2023-11-01",
//       "2023-12-01",
//       "2024-01-01",
//     ],
//     xaxis: { type: "datetime" },
//     tooltip: {
//       shared: true,
//       intersect: false,
//       x: {
//         format: "MMM",
//       },
//       y: {
//         formatter: (y) => {
//           if (typeof y !== "undefined") {
//             return `${y.toFixed(0)} item(s)`;
//           }
//           return y;
//         },
//       },
//     },
//   });

//   return (
//     <Card>
//       <CardHeader
//         subheader="Formations ajoutées / Nombre d'inscription /Nombre d'apprenants par mois"
//       />
//       <Box sx={{ p: 3, pb: 1 }} dir="ltr">
//         {!makingApiCall ? (
//        <ReactApexChart
//        type="line"
//        series={[
//          {
//            name: "Nombre d'inscription",
//            type: "column",
//            data: arrayDateStudents,
//          },
//          {
//            name: "Formations ajoutées",
//            type: "area",
//            data: arrayDateFormation,
//          },
//          {
//            name: "Nombre d'apprenants",
//            type: "line",
//            data: arrayDateStudentsEnrolled,
//          },
//        ]}
//        options={chartOptions}
//        height={364}
//      />
     
//         ) : (
//           <span>Loading...</span>
//         )}
//       </Box>

//     </Card>
//   );
// }
import { merge } from "lodash";
import ReactApexChart from "react-apexcharts";
import { Card, CardHeader, Box } from "@mui/material";
import { BaseOptionChart } from "../../charts";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getAllFormations } from "../../../redux/formationSlice";
import { getListeOfStudents } from "../../../redux/authSlice";


export default function AppWebsiteVisits({ item, type }) {
  const [arraymonths] = useState([
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "May",
    "Juin",
    "Juillet",
    "Aout",
    "September",
    "Octobre",
    "Novembre", 
    "Decembre"

  ]);

  const [arrayDateFormation, setarrayDateFormation] = useState([]);
  const [arrayDateStudents, setarrayDateStudents] = useState([]);



  const PrepareNumberPerArrayOfMonth = async (
    arrayNameMonth,
    arrayFormation,
    type
  ) => {
    let array = [];
    arrayNameMonth.forEach((element, index) => {
      var number = PrepareNumberPerMonth(arrayFormation, index);

      array.push(number);
    });
    type === "formation"
      ? setarrayDateFormation(array)
      : setarrayDateStudents(array);

    return array;
  };
  const PrepareNumberPerMonth = (array, month) => {
    let number = 0;
    array.forEach((formation) => {
      const date = new Date(formation.createdAt);
      if (date.getFullYear() === 2024 && date.getMonth() === month) {
        number++;
      }
    });
  
    return number;
  };
  

  const [makingApiCall, setMakingApiCall] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    setMakingApiCall(true);
    dispatch(getListeOfStudents()).then((result) => {
      PrepareNumberPerArrayOfMonth(arraymonths, result.payload, "students");
      dispatch(getAllFormations()).then(async (res) => {
        await PrepareNumberPerArrayOfMonth(
          arraymonths,
          res.payload,
          "formation"
        );
        setMakingApiCall(false);
      });
    });
  }, [dispatch]);

  const chartOptions = merge(BaseOptionChart(), {
    stroke: { width: [0, 2, 3] },
    plotOptions: { bar: { columnWidth: "11%", borderRadius: 4 } },
    fill: { type: ["solid", "gradient", "solid"] },
    labels: [
      "2024-01-01",
      "2024-02-01",
      "2024-03-01",
      "2024-04-01",
      "2024-05-01",
      "2024-06-01",
      "2024-07-01",
      "2024-08-01",
      "2024-09-01",
      "2024-10-01",
      "2024-11-01",
      "2024-12-01",
      "2025-01-01",
    ],
    xaxis: { type: "datetime" },
    tooltip: {
      shared: true,
      intersect: false,
      x: {
        format: "MMM",
      },
      y: {
        formatter: (y) => {
          if (typeof y !== "undefined") {
            return `${y.toFixed(0)} item(s)`;
          }
          return y;
        },
      },
    },
  });

  return (
    <Card>
      <CardHeader
        subheader="Formations ajoutées / Nombre d'inscription /Nombre d'apprenants par mois"
        />
      <Box sx={{ p: 3, pb: 1 }} dir="ltr">
        {!makingApiCall ? (
          <ReactApexChart
            type="line"
            series={[
              {
                name: "Nombre d'inscription",
                type: "column",
                data: arrayDateStudents,
              },
              
              {
                name: "Formations ajoutées",
                type: "area",
                data: arrayDateFormation,
              },
            ]}
            options={chartOptions}
            height={364}
          />
        ) : (
          <span>Loading...</span>
        )}
      </Box>

    </Card>
  );
}
