// import { filter } from 'lodash';
// import { useState, useEffect } from 'react';
// import axios from "axios"
// import {
//   Card,
//   Table,
//   Stack,
//   Checkbox,
//   TableRow,
//   TableBody,
//   TableCell,
//   Container,
//   Typography,
//   TableContainer,
//   TablePagination,
// } from '@mui/material';
// import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
// import Page from '../../components/Page';
// import Scrollbar from '../../components/Scrollbar';
// import SearchNotFound from '../../components/SearchNotFound';
// // import { UserListHeadDesactive, UserListToolbarDesactive } from '../../components/_dashboard/user';
//  import UserListToolbarDesactive from '../../components/_dashboard/user/UserListToolbarDesactive';
//  import UserListHeadDesactive from '../../components/_dashboard/user/UserListHeadDesactive';
// import { Input } from 'antd';
// import { format } from 'date-fns'; 

// const TABLE_HEAD = [
//   { id: "", label: "" },

//   { id: "nomPrenom", label: "Nom et prénom" },
//   { id: "email", label: "Email" },
//   { id: "createdAt", label: "date de création" },
// ];

// export default function User() {

//   const [page, setPage] = useState(0);
//   const [order, setOrder] = useState('asc');
//   const [selected, setSelected] = useState([]);
//   const [orderBy, setOrderBy] = useState('nom');
//   const [filterName, setFilterName] = useState('');
//   const [rowsPerPage, setRowsPerPage] = useState(300);
//   const [formateurs, setformateurs] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [selectedProprietaire, setSelectedProprietaire] = useState('');
//   const [filterEmail, setFilterEmail] = useState('');
//   const handleFilterByName = (event, filterType) => {
//     const { value } = event.target;
//     if (filterType === 'name') {
//       setFilterName(value);
//     } else if (filterType === 'email') {
//       setFilterEmail(value);
//     }
//   };
  
//   function formatDate(dateString) {
//     const date = new Date(dateString);
//     return format(date, 'dd/MM/yyyy HH:mm', { timeZone: 'Africa/Tunis' }); 
//   }
//   useEffect(() => {

//     getAllStudent();
//   }, []);
//   const getAllStudent = async () => {
//     try {
//       const res = await axios.get(
//         process.env.REACT_APP_URL_BACKEND_DEV + `/etudiant/getAllStudent`
//       );

//       const sortedVouchers = res.data.students.sort((a, b) => {
//         return new Date(b.createdAt) - new Date(a.createdAt);
//       });
  
//       setformateurs(sortedVouchers);
//     } catch (error) {
//       console.error(error);
//     }


//   };
//   const getAllStudentDesactive = async () => {
//     try {
//       const res = await axios.get(
//         process.env.REACT_APP_URL_BACKEND_DEV + `/etudiant/getStudentDesactive`
//       );

//       const sortedVouchers = res.data.students.sort((a, b) => {
//         return new Date(b.createdAt) - new Date(a.createdAt);
//       });
  
//       setformateurs(sortedVouchers);
//     } catch (error) {
//       console.error(error);
//     }


//   };

//   const getStudentsCertif = async () => {
//     try {
//       const res = await axios.get(
//         process.env.REACT_APP_URL_BACKEND_DEV + `/etudiant/getStudentsCertif`
//       );

//       const sortedVouchers = res.data.students.sort((a, b) => {
//         return new Date(b.createdAt) - new Date(a.createdAt);
//       });
  
//       setformateurs(sortedVouchers);
//     } catch (error) {
//       console.error(error);
//     }


//   };
//   const getStudentsNotCertif = async () => {
//     try {
//       const res = await axios.get(
//         process.env.REACT_APP_URL_BACKEND_DEV + `/etudiant/getStudentsNotCertif`
//       );

//       const sortedVouchers = res.data.students.sort((a, b) => {
//         return new Date(b.createdAt) - new Date(a.createdAt);
//       });
  
//       setformateurs(sortedVouchers);
//     } catch (error) {
//       console.error(error);
//     }


//   };


//   const getAllStudentActive = async () => {
//     try {
//       const res = await axios.get(
//         process.env.REACT_APP_URL_BACKEND_DEV + `/etudiant/getStudentActive`
//       );

//       const sortedVouchers = res.data.students.sort((a, b) => {
//         return new Date(b.createdAt) - new Date(a.createdAt);
//       });
  
//       setformateurs(sortedVouchers);
//     } catch (error) {
//       console.error(error);
//     }


//   };
//   const getActiveStudentsEnrolled = async () => {
//     try {
//       const res = await axios.get(
//         process.env.REACT_APP_URL_BACKEND_DEV + `/etudiant/getActiveStudentsEnrolled`
//       );

//       const sortedVouchers = res.data.students.sort((a, b) => {
//         return new Date(b.createdAt) - new Date(a.createdAt);
//       });
  
//       setformateurs(sortedVouchers);
//     } catch (error) {
//       console.error(error);
//     }


//   };
//   const [filterStatus, setFilterStatus] = useState('all'); 


//   const handleFilterStatusChange = (event) => {
//     setFilterStatus(event.target.value);
//     if (event.target.value === 'inactive') {
//       getAllStudentDesactive(); 
//     }
//    else if (event.target.value === 'active') {
//       getAllStudentActive(); 
//     } 
//     else if (event.target.value === 'enrolled') {
//       getActiveStudentsEnrolled(); 
//     }
//     else if (event.target.value === 'certifie') {
//       getStudentsCertif(); 
//     } 
//     else if (event.target.value === 'Nocertifie') {
//       getStudentsNotCertif(); 
//     }  
//     else {
//       getAllStudent(); 
//     }
//   };
//   function applySortFilter(array, query, proprietaireFilter,nameQuery, emailQuery) {
//     const stabilizedThis = array?.map((el, index) => [el, index]);
 
//     if (nameQuery) {
//       return filter(array, (_user) => _user.nom.toLowerCase().indexOf(nameQuery.toLowerCase()) !== -1);
//     }
//     if (emailQuery) {
//       return filter(array, (_user) => _user.email.toLowerCase().indexOf(emailQuery.toLowerCase()) !== -1);
//     }
//     if (query) {
//       return filter(array, (_user) => _user.nom.toLowerCase().indexOf(query.toLowerCase()) !== -1);
//     }
//     if (proprietaireFilter) {
//       return filter(array, (_user) => _user.nomProp.titre.toLowerCase() === proprietaireFilter.toLowerCase());
//     }
//     return stabilizedThis?.map((el) => el[0]);
//   }


//   const handleDeleteUsers = async (emailSubject, emailBody) => {
//     setLoading(true);
//     try {
//       const res = await axios.post(process.env.REACT_APP_URL_BACKEND_DEV + '/etudiant/SendEmailForNotActiveCompte', {
//         data: {
//           userIds: selected,
//           emailSubject: emailSubject,
//           emailBody: emailBody
//         }
//       });
//       setSelected([]);
//       console.log("res", res);
//     } catch (error) {
//       console.error(error);
//     } finally {
//       setLoading(false);
//     }
//   };
  

//   const handleRequestSort = (event, property) => {
//     const isAsc = orderBy === property && order === 'asc';
//     setOrder(isAsc ? 'desc' : 'asc');
//     setOrderBy(property);
//   };
//   const [selectAllCurrentPage, setSelectAllCurrentPage] = useState(false);
//   const [selectAllPages, setSelectAllPages] = useState(false);

//   // Function to handle selecting/deselecting all students on the current page
//   const handleSelectAllCurrentPage = (event) => {
//     if (event.target.checked) {
//       const newSelected = filteredUsers
//         .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
//         .map((user) => user._id);
//       setSelected(newSelected);
//     } else {
//       setSelected([]);
//     }
//     setSelectAllCurrentPage(event.target.checked);
//   };

//   // Function to handle selecting/deselecting all students across all pages
//   const handleSelectAllPages = (event) => {
//     if (event.target.checked) {
//       const allStudentsIds = formateurs.map((user) => user._id);
//       setSelected(allStudentsIds);
//     } else {
//       setSelected([]);
//     }
//     setSelectAllPages(event.target.checked);
//     setSelectAllCurrentPage(false); // Uncheck the current page select all checkbox
//   };
//   const handleSelectAllClick = (event) => {
//     if (event.target.checked) {
//       const newSelecteds = formateurs?.map((n) => n._id);
//       setSelected(newSelecteds);
//       return;
//     }
//     setSelected([]);
//   };
//   const handleClick = (event, id) => {
//     const selectedIndex = selected.indexOf(id);
//     let newSelected = [];

//     if (selectedIndex === -1) {
//       newSelected = newSelected.concat(selected, id);
//     } else if (selectedIndex === 0) {
//       newSelected = newSelected.concat(selected.slice(1));
//     } else if (selectedIndex === selected.length - 1) {
//       newSelected = newSelected.concat(selected.slice(0, -1));
//     } else if (selectedIndex > 0) {
//       newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
//     }

//     setSelected(newSelected);
//   };

//   const handleChangePage = (event, newPage) => {
//     setPage(newPage);
//   };

//   const handleChangeRowsPerPage = (event) => {
//     setRowsPerPage(parseInt(event.target.value, 5));
//     setPage(0);
//   };

//   const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - formateurs?.length) : 0;

//   const filteredUsers = applySortFilter(formateurs, filterName, selectedProprietaire);
//   const isUserNotFound = filteredUsers?.length === 0;
//   useEffect(() => {
//     // Update the selected items based on the filtered list of students when the status filter changes
//     if (filterStatus === 'inactive') {
//       const allInactiveIds = formateurs
//         .filter((user) => user.status === 'inactive')
//         .map((user) => user._id);
//       setSelected(allInactiveIds);
//     } else if (filterStatus === 'active') {
//       const allActiveIds = formateurs
//         .filter((user) => user.status === 'active')
//         .map((user) => user._id);
//       setSelected(allActiveIds);
//     } else if (filterStatus === 'enrolled') {
//       const allEnrolledIds = formateurs
//         .filter((user) => user.status === 'enrolled')
//         .map((user) => user._id);
//       setSelected(allEnrolledIds);
//     }
//     else if (filterStatus === 'certifie') {
//       const allCertifieIds = formateurs
//         .filter((user) => user.status === 'certifie')
//         .map((user) => user._id);
//       setSelected(allCertifieIds);
//     }
//     else if (filterStatus === 'Nocertifie') {
//       const allNocertifieIds = formateurs
//         .filter((user) => user.status === 'Nocertifie')
//         .map((user) => user._id);
//       setSelected(allNocertifieIds);
//     }
//     else {
//       setSelected([]); // Set selected to an empty array for 'All' status
//     }
//   }, [filterStatus, formateurs]);
// console.log("selected",selected)

//   return (
//     <Page title="Dashboard | Fastuz">
//       <Container>
        
//         <Stack
//           direction="row"
//           alignItems="center"
//           justifyContent="space-between"
//           mb={5}
//         >
//           <Typography variant="h4" gutterBottom>
//           Envoyer Email
//           </Typography>
       
         
//         </Stack>
       
//         <FormControl sx={{ minWidth:"100%" }}>
//             <InputLabel id="filter-status-label">Filter Status</InputLabel>
//             <Select
//               labelId="filter-status-label"
//               id="filter-status"
//               value={filterStatus}
//               label="Filter Status"
//               onChange={handleFilterStatusChange}
//             >
//               <MenuItem value="all">All</MenuItem>
//               <MenuItem value="inactive">Inactif (Compte desactivé)</MenuItem>
//               <MenuItem value="active">Inscrit (Compte Activé)</MenuItem>
//               <MenuItem value="enrolled">Apprenant (Enrolled)</MenuItem>
//               <MenuItem value="certifie">Apprenant (Certificats/Attestations)</MenuItem>
//               <MenuItem value="Nocertifie">Apprenant (Non certifié)</MenuItem>
//             </Select>
//             </FormControl>
//             <br></br> <br></br>
//         <Card>
//           <UserListToolbarDesactive
//           filterEmail={filterEmail} 
//             numSelected={selected.length}
//             filterName={filterName}
//             onFilterName={handleFilterByName}
//             handleDeleteUsers={handleDeleteUsers}

//           />
//           <Scrollbar style={{ maxHeight: 600 }}> {/* Set a max height for the scrollbar */}
//             <TableContainer sx={{ minWidth: 800 }}>
//             <Input
//             placeholder='Filtrer avec email'
//                         fullWidth
//                         id="filter-email"
//                         label="Filter by Email"
//                         value={filterEmail}
//                         onChange={(event) => handleFilterByName(event, 'email')}
//                       />
                        
//               <Table>
                
//                 <UserListHeadDesactive
//                   order={order}
//                   orderBy={orderBy}
//                   headLabel={TABLE_HEAD}
//                   rowCount={formateurs?.length}
//                   numSelected={selected?.length}
//                   onRequestSort={handleRequestSort}
//                   onSelectAllClick={handleSelectAllClick}
//         handleSelectAllPages={handleSelectAllPages}
//         handleSelectAllCurrentPage={handleSelectAllCurrentPage}
//         selectAllPages={selectAllPages}
//         selectAllCurrentPage={selectAllCurrentPage}
                  
//                 />
//                 <TableBody>
//                   <>
                

// </>
//                   {filteredUsers
//                   ?.filter((_user) =>
//                   _user.nom.toLowerCase().includes(filterName.toLowerCase()) &&
//                   _user.email.toLowerCase().includes(filterEmail.toLowerCase())
//                 )
//                     ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
//                     ?.map((row) => {
//                       const {
//                         _id,
                    
//                         nom,
//                         prenom,
//                         email,
//                     createdAt
//                       } = row;
//                       const isItemSelected = selected.indexOf(_id) !== -1;

//                       return (

//                         <TableRow
//                           hover
//                           key={_id}
//                           tabIndex={-1}
//                           role="checkbox"
//                           selected={isItemSelected}
//                           aria-checked={isItemSelected}
//                           onClick={(event) => handleClick(event, _id)} // Handle individual selection

//                         >
//                          <TableCell padding="checkbox">
//                     <Checkbox
//                       checked={isItemSelected}
//                       inputProps={{ 'aria-labelledby': _id }}
//                       onClick={(event) => handleClick(event, _id)} // Handle individual checkbox selection
//                     />
//                   </TableCell>
//                           <TableCell align="left">{nom} {prenom}</TableCell>
//                           <TableCell align="left">{email}</TableCell>
//                           <TableCell align="center">{formatDate(createdAt)}</TableCell>
//                         </TableRow>

//                       );
//                     })}

//                   {emptyRows > 0 && (
//                     <TableRow style={{ height: 53 * emptyRows }}>
//                       <TableCell colSpan={6} />
//                     </TableRow>
//                   )}
//                 </TableBody>
//                 {isUserNotFound && (
//                   <TableBody>
//                     <TableRow>
//                       <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
//                         <SearchNotFound searchQuery={filterName} />
//                       </TableCell>
//                     </TableRow>
//                   </TableBody>
//                 )}
//               </Table>
//             </TableContainer>
//           </Scrollbar>

//           <TablePagination
//             rowsPerPageOptions={[300]}
//             component="div"
//             count={formateurs?.length}
//             rowsPerPage={rowsPerPage}
//             page={page}
//             onPageChange={handleChangePage}
//             onRowsPerPageChange={handleChangeRowsPerPage}

//           />

//         </Card>


//       </Container>

//     </Page>

//   );
// }

import { filter } from 'lodash';
import { useState, useEffect } from 'react';
import axios from "axios"
import {
  Card,
  Table,
  Stack,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
} from '@mui/material';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
// import { UserListHeadDesactive, UserListToolbarDesactive } from '../../components/_dashboard/user';
 import UserListToolbarDesactive from '../../components/_dashboard/user/UserListToolbarDesactive';
 import UserListHeadDesactive from '../../components/_dashboard/user/UserListHeadDesactive';
import { Input } from 'antd';
import { format } from 'date-fns'; 

const TABLE_HEAD = [
  { id: "", label: "" },

  { id: "nomPrenom", label: "Nom et prénom" },
  { id: "email", label: "Email" },
  { id: "createdAt", label: "date de création" },
];

export default function User() {

  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('nom');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(300);
  const [formateurs, setformateurs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedProprietaire, setSelectedProprietaire] = useState('');
  const [filterEmail, setFilterEmail] = useState('');
  const [formations, setFormations] = useState([]);
  const [selectedFormation, setSelectedFormation] = useState('');
  const [filterStatus, setFilterStatus] = useState('all'); 

  useEffect(() => {
    if (filterStatus === 'enrolled' && !selectedFormation) {
      fetchFormations();

      fetchEnrolledStudents();
    } else if ( selectedFormation) {
      fetchStudentsInFormation(selectedFormation);
    }
  }, [filterStatus, selectedFormation]);
const fetchFormations = async () => {
  try {
    const res = await axios.get(process.env.REACT_APP_URL_BACKEND_DEV + '/getAllFormationsApprenantFilter', {
      params: {
        filterStatus: filterStatus
      }
    });
    console.log('res', res)
    setFormations(res.data.formations);
  } catch (error) {
    console.error(error);
  }
};


  const handleFilterByName = (event, filterType) => {
    const { value } = event.target;
    if (filterType === 'name') {
      setFilterName(value);
    } else if (filterType === 'email') {
      setFilterEmail(value);
    }
  };
  
  function formatDate(dateString) {
    const date = new Date(dateString);
    return format(date, 'dd/MM/yyyy HH:mm', { timeZone: 'Africa/Tunis' }); 
  }
  useEffect(() => {

    getAllStudent();
  }, []);
  const getAllStudent = async () => {
    try {
      const res = await axios.get(
        process.env.REACT_APP_URL_BACKEND_DEV + `/etudiant/getAllStudent`
      );

      const sortedVouchers = res.data.students.sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
  
      setformateurs(sortedVouchers);
    } catch (error) {
      console.error(error);
    }


  };
  const getAllStudentDesactive = async () => {
    try {
      const res = await axios.get(
        process.env.REACT_APP_URL_BACKEND_DEV + `/etudiant/getStudentDesactive`
      );

      const sortedVouchers = res.data.students.sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
  
      setformateurs(sortedVouchers);
    } catch (error) {
      console.error(error);
    }


  };

  const getStudentsCertif = async () => {
    try {
      const res = await axios.get(
        process.env.REACT_APP_URL_BACKEND_DEV + `/etudiant/getStudentsCertif`
      );

      const sortedVouchers = res.data.students.sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
  
      setformateurs(sortedVouchers);
    } catch (error) {
      console.error(error);
    }


  };
  const getStudentsNotCertif = async () => {
    try {
      const res = await axios.get(
        process.env.REACT_APP_URL_BACKEND_DEV + `/etudiant/getStudentsNotCertif`
      );

      const sortedVouchers = res.data.students.sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
  
      setformateurs(sortedVouchers);
    } catch (error) {
      console.error(error);
    }


  };


  const getAllStudentActive = async () => {
    try {
      const res = await axios.get(
        process.env.REACT_APP_URL_BACKEND_DEV + `/etudiant/getStudentActive`
      );

      const sortedVouchers = res.data.students.sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
  
      setformateurs(sortedVouchers);
    } catch (error) {
      console.error(error);
    }


  };
  const getActiveStudentsEnrolled = async () => {
    try {
      const res = await axios.get(
        process.env.REACT_APP_URL_BACKEND_DEV + `/etudiant/getActiveStudentsEnrolled`
      );

      setformateurs(res.data.students);
    } catch (error) {
      console.error(error);
    }


  };


  const handleFilterStatusChange = (event) => {
    setFilterStatus(event.target.value);
    if (event.target.value === 'inactive') {
      getAllStudentDesactive(); 
    }
   else if (event.target.value === 'active') {
      getAllStudentActive(); 
    } 
  
    else if (event.target.value === 'certifie') {
      getStudentsCertif(); 
    }
    else if (event.target.value === 'enrolled') {
      getActiveStudentsEnrolled(); 
    }  
    else if (event.target.value === 'Nocertifie') {
      getStudentsNotCertif(); 
    }  
   
    setSelectedFormation(''); // Reset selected formation when filter status changes
  };
  function applySortFilter(array, query, proprietaireFilter,nameQuery, emailQuery) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
 
    if (nameQuery) {
      return filter(array, (_user) => _user.nom.toLowerCase().indexOf(nameQuery.toLowerCase()) !== -1);
    }
    if (emailQuery) {
      return filter(array, (_user) => _user.email.toLowerCase().indexOf(emailQuery.toLowerCase()) !== -1);
    }
    if (query) {
      return filter(array, (_user) => _user.nom.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    if (proprietaireFilter) {
      return filter(array, (_user) => _user.nomProp.titre.toLowerCase() === proprietaireFilter.toLowerCase());
    }
    return stabilizedThis?.map((el) => el[0]);
  }


  const handleDeleteUsers = async (emailSubject, emailBody) => {
    setLoading(true);
    try {
      const res = await axios.post(process.env.REACT_APP_URL_BACKEND_DEV + '/etudiant/SendEmailForNotActiveCompte', {
        data: {
          userIds: selected,
          emailSubject: emailSubject,
          emailBody: emailBody
        }
      });
      setSelected([]);
      console.log("res", res);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  const [selectAllCurrentPage, setSelectAllCurrentPage] = useState(false);
  const [selectAllPages, setSelectAllPages] = useState(false);

  // Function to handle selecting/deselecting all students on the current page
  const handleSelectAllCurrentPage = (event) => {
    if (event.target.checked) {
      const newSelected = filteredUsers
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((user) => user._id);
      setSelected(newSelected);
    } else {
      setSelected([]);
    }
    setSelectAllCurrentPage(event.target.checked);
  };

  // Function to handle selecting/deselecting all students across all pages
  const handleSelectAllPages = (event) => {
    if (event.target.checked) {
      const allStudentsIds = formateurs.map((user) => user._id);
      setSelected(allStudentsIds);
    } else {
      setSelected([]);
    }
    setSelectAllPages(event.target.checked);
    setSelectAllCurrentPage(false); // Uncheck the current page select all checkbox
  };
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = formateurs?.map((n) => n._id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };
  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 5));
    setPage(0);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - formateurs?.length) : 0;

  const filteredUsers = applySortFilter(formateurs, filterName, selectedProprietaire);
  const isUserNotFound = filteredUsers?.length === 0;
  useEffect(() => {
    // Update the selected items based on the filtered list of students when the status filter changes
    if (filterStatus === 'inactive') {
      const allInactiveIds = formateurs
        .filter((user) => user.status === 'inactive')
        .map((user) => user._id);
      setSelected(allInactiveIds);
    } else if (filterStatus === 'active') {
      const allActiveIds = formateurs
        .filter((user) => user.status === 'active')
        .map((user) => user._id);
      setSelected(allActiveIds);
    } else if (filterStatus === 'enrolled') {
      const allEnrolledIds = formateurs
        .filter((user) => user.status === 'enrolled')
        .map((user) => user._id);
      setSelected(allEnrolledIds);
    }
    else if (filterStatus === 'certifie') {
      const allCertifieIds = formateurs
        .filter((user) => user.status === 'certifie')
        .map((user) => user._id);
      setSelected(allCertifieIds);
    }
    else if (filterStatus === 'Nocertifie') {
      const allNocertifieIds = formateurs
        .filter((user) => user.status === 'Nocertifie')
        .map((user) => user._id);
      setSelected(allNocertifieIds);
    }
    else {
      setSelected([]); // Set selected to an empty array for 'All' status
    }
  }, [filterStatus, formateurs]);
console.log("selected",selected)


const fetchEnrolledStudents = async () => {
  try {
    const res = await axios.get(process.env.REACT_APP_URL_BACKEND_DEV + '/etudiant/getActiveStudentsEnrolled');
    setformateurs(res.data.students);
  } catch (error) {
    console.error(error);
  }
};
const fetchStudentsInFormation = async (formationId) => {
  try {
    const res = await axios.get(process.env.REACT_APP_URL_BACKEND_DEV + '/getStudentsInFormation', {
      params: {
        formationId: formationId
      }
    });

    // Create a map to store unique student IDs
    const uniqueStudentsMap = new Map();

    // Iterate through the students and add them to the map
    res.data.students.forEach((student) => {
      // Check if the student ID already exists in the map
      if (!uniqueStudentsMap.has(student._id)) {
        // If not, add the student to the map
        uniqueStudentsMap.set(student._id, student);
      }
    });

    // Convert the map values (unique students) back to an array
    const uniqueStudents = Array.from(uniqueStudentsMap.values());

    // Update the state with unique students
    setformateurs(uniqueStudents);
  } catch (error) {
    console.error(error);
  }
};


const handleFormationChange = async (event) => {
  const formationId = event.target.value;
  setSelectedFormation(formationId);
  if (formationId) {
    try {
      const res = await axios.get(process.env.REACT_APP_URL_BACKEND_DEV + '/getStudentsInFormation', {
        params: {
          formationId: formationId
        }
      });
      setformateurs(res.data.students);
    } catch (error) {
      console.error(error);
    }
  }
};




  return (
    <Page title="Dashboard | Fastuz">
      <Container>
        
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
          Envoyer Email
          </Typography>
       
         
        </Stack>
       
        <FormControl sx={{ minWidth:"100%" }}>
            <InputLabel id="filter-status-label">Filter Status</InputLabel>
            <Select
              labelId="filter-status-label"
              id="filter-status"
              value={filterStatus}
              label="Filter Status"
              onChange={handleFilterStatusChange}
            >
              <MenuItem value="all">All</MenuItem>
              <MenuItem value="inactive">Inactif (Compte desactivé)</MenuItem>
              <MenuItem value="active">Inscrit (Compte Activé)</MenuItem>
              <MenuItem value="enrolled">Apprenant (Enrolled)</MenuItem>
              <MenuItem value="certifie">Apprenant (Certificats/Attestations)</MenuItem>
              <MenuItem value="Nocertifie">Apprenant (Non certifié)</MenuItem>
            </Select>
            </FormControl>
            <br></br> <br></br>
            {filterStatus === 'enrolled' && (
        <FormControl sx={{ minWidth:"100%" }}>
          <InputLabel id="filter-status-label">Select Formation</InputLabel>
          <Select
             labelId="filter-status-label"
             id="filter-status"
            value={selectedFormation}
            onChange={handleFormationChange}
          >
            <MenuItem value="">Select Formations</MenuItem>
            {formations.map((formation) => (
              <MenuItem key={formation._id} value={formation._id}>
                {formation.titre}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      <br></br><br></br>
        <Card>
          <UserListToolbarDesactive
          filterEmail={filterEmail} 
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
            handleDeleteUsers={handleDeleteUsers}

          />
          <Scrollbar style={{ maxHeight: 600 }}> {/* Set a max height for the scrollbar */}
            <TableContainer sx={{ minWidth: 800 }}>
            <Input
            placeholder='Filtrer avec email'
                        fullWidth
                        id="filter-email"
                        label="Filter by Email"
                        value={filterEmail}
                        onChange={(event) => handleFilterByName(event, 'email')}
                      />
                        
              <Table>
                
                <UserListHeadDesactive
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={formateurs?.length}
                  numSelected={selected?.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
        handleSelectAllPages={handleSelectAllPages}
        handleSelectAllCurrentPage={handleSelectAllCurrentPage}
        selectAllPages={selectAllPages}
        selectAllCurrentPage={selectAllCurrentPage}
                  
                />
                <TableBody>
                  <>
                

</>
                  {filteredUsers
                  ?.filter((_user) =>
                  _user.nom.toLowerCase().includes(filterName.toLowerCase()) &&
                  _user.email.toLowerCase().includes(filterEmail.toLowerCase())
                )
                    ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    ?.map((row) => {
                      const {
                        _id,
                    
                        nom,
                        prenom,
                        email,
                    createdAt
                      } = row;
                      const isItemSelected = selected.indexOf(_id) !== -1;

                      return (

                        <TableRow
                          hover
                          key={_id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                          onClick={(event) => handleClick(event, _id)} // Handle individual selection

                        >
                         <TableCell padding="checkbox">
                    <Checkbox
                      checked={isItemSelected}
                      inputProps={{ 'aria-labelledby': _id }}
                      onClick={(event) => handleClick(event, _id)} // Handle individual checkbox selection
                    />
                  </TableCell>
                          <TableCell align="left">{nom} {prenom}</TableCell>
                          <TableCell align="left">{email}</TableCell>
                          <TableCell align="center">{formatDate(createdAt)}</TableCell>
                        </TableRow>

                      );
                    })}

                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[300]}
            component="div"
            count={formateurs?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}

          />

        </Card>


      </Container>

    </Page>

  );
}








