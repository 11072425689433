// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toolbar {
  display: flex;
  align-items: center;

  background-color: white;
  font-weight: 500;
  border-bottom: 1px solid #eeeef1;

  position: sticky;
  top: 0px;
}

@supports (backdrop-filter: blur(20px)) {
  .toolbar {
    border: none;
    background-color: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(20px);
  }
}

.toolbar-title {
  margin: 0;
  font-size: 16px;
  font-weight: 800;
}

.left-items, .right-items {
  flex: 1 1;
  padding: 10px;
  display: flex;
}

.right-items {
  flex-direction: row-reverse;
}

.left-items .toolbar-button {
  margin-right: 20px;
}

.right-items .toolbar-button {
  margin-left: 20px;
}

.left-items .toolbar-button:last-child,
.right-items .toolbar-button:last-child {
  margin: 0;
}`, "",{"version":3,"sources":["webpack://./src/pages/Toolbar/Toolbar.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;;EAEnB,uBAAuB;EACvB,gBAAgB;EAChB,gCAAgC;;EAEhC,gBAAgB;EAChB,QAAQ;AACV;;AAEA;EACE;IACE,YAAY;IACZ,0CAA0C;IAC1C,2BAA2B;EAC7B;AACF;;AAEA;EACE,SAAS;EACT,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,SAAO;EACP,aAAa;EACb,aAAa;AACf;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;;EAEE,SAAS;AACX","sourcesContent":[".toolbar {\n  display: flex;\n  align-items: center;\n\n  background-color: white;\n  font-weight: 500;\n  border-bottom: 1px solid #eeeef1;\n\n  position: sticky;\n  top: 0px;\n}\n\n@supports (backdrop-filter: blur(20px)) {\n  .toolbar {\n    border: none;\n    background-color: rgba(255, 255, 255, 0.8);\n    backdrop-filter: blur(20px);\n  }\n}\n\n.toolbar-title {\n  margin: 0;\n  font-size: 16px;\n  font-weight: 800;\n}\n\n.left-items, .right-items {\n  flex: 1;\n  padding: 10px;\n  display: flex;\n}\n\n.right-items {\n  flex-direction: row-reverse;\n}\n\n.left-items .toolbar-button {\n  margin-right: 20px;\n}\n\n.right-items .toolbar-button {\n  margin-left: 20px;\n}\n\n.left-items .toolbar-button:last-child,\n.right-items .toolbar-button:last-child {\n  margin: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
