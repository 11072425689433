
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Alert, Spinner } from 'reactstrap';
import Link from 'next/link';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import { getAllFormations } from "../../redux/formationSlice";
import Select from "react-select";
import { ajoutFormationAPI, ResetFormationAPI, UploadeFileAPI } from '../../utils/api';

import { Stack, TextField, IconButton, InputAdornment } from '@mui/material';

import { LoadingButton } from '@mui/lab';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Page from "../../components/Page"
import { Row, Col, FormGroup,Button } from "reactstrap"; 
import FileUploader from "../../assets/FileUploader"
import getBase64 from "../../utils/getBase64"
import { AddPartnerTechAPI } from '../../utils/api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const INITIAL_USER = {
  nom: ''
};
const validationSchema = yup.object({
  nom: yup.string().required('nom obligatoire'),

});
const RegisterFormateur = ({handleClose, getPartners}) => {
  const dispatch = useDispatch();
const [selectedFormation, setSelectedFormation]= useState("")
const [users, setUsers]= useState([])
const [logo, setlogo] = useState(null);
const [coverPreview, setCoverPreview] = useState(null);
const navigate = useNavigate();
const [showPassword, setShowPassword] = useState(false);
const [showPassword1, setShowPassword1] = useState(false);
const [partners, setPartners] = useState([]);


const [user, setUser] = React.useState(INITIAL_USER);
const [disabled, setDisabled] = React.useState(true);
const [loading, setLoading] = React.useState(false);
const [error, setError] = React.useState('');
const onDismiss = () => setError(false);
const [listFormation, setListFormations] = useState([]);

React.useEffect(() => {
  const isUser = Object.values(user).every((el) => Boolean(el));
  isUser ? setDisabled(false) : setDisabled(true);
}, [user]);






    useEffect(() => {
      if (logo) getBase64(logo).then((url) => setCoverPreview(url));
      else setCoverPreview(null);
    }, [logo]);

  const formik = useFormik({
    

        initialValues: {
        nom: "",
        
        
      },
    validationSchema: validationSchema,
         onSubmit: async (values) => {
           const obj = {
           ...values,
         };
          if (logo) {
          const coverRes = await UploadeFileAPI(logo, "coverFormation");
          obj.logo = coverRes.data.location;;
       }
          if (formik.isValid) {
            try {
              setLoading(true);
              setError('');
    
              const response = await  AddPartnerTechAPI(obj) 
              if ( response.data.status) {
                toast.error('Erreur de saisie !', {
                  position: 'top-right',
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                });   
              } else {
                toast.success('Partenaire ajouté avec succées!', {
                  position: 'top-right',
                  autoClose: 10000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                });
                setTimeout(() => {
                  handleClose();
                }, 1000);                

              }
            } catch (error) {
              setError(error.response.data.message);
            } finally {
              getPartners()
              setLoading(false);
            }
          }

      },
  });

return (
    <Page title="User | Minimal-UI">
    <div>
      <Alert color="danger" isOpen={error ? true : false} toggle={onDismiss}>
        {error}
      </Alert>
      <ToastContainer />

      <form>
        <Stack spacing={3}>


                 <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} justifyContent="center" >
        <Col md={12}>
            {alert && (
              <Alert color="danger">
                {alert === "photo" && "Merci d'inserer une image"}
                {alert === "description" && "Merci d'inserer une description"}
              </Alert>
            )}
          </Col>
          <Col md={12} >
            {!logo && (
              <FileUploader
                fileTypes={["image/png", "image/jpg", "image/jpeg"]}
                setFileToUpload={setlogo}
                title="Couverture"
                original=""
                
              ></FileUploader>
            )}
            {logo && (
              <div className="cover-preview" onClick={() => setlogo(null)}>
                <img src={coverPreview} width="100%" alt='' />
                <i className="lnr-trash"></i>
              </div>
            )}
          </Col>
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              type="text"
              label="Nom"
              name="nom"
              error={formik.touched.nom && formik.errors.nom}
              helperText={formik.errors.nom}
              {...formik.getFieldProps('nom')}
            />

          </Stack>
    
           

        
         <LoadingButton
            fullWidth
            size="large"
            type="button"
            disabled={!formik.isValid}
            variant="contained"
            onClick={async () => {
              await formik.handleSubmit();
            }}
          >
            Ajouter Partenaire Technologique
            {loading ? <Spinner color="success" /> : ''}
          </LoadingButton>
        </Stack>
      </form>
    </div>
    </Page>
  );
};

export default RegisterFormateur;