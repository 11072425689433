import React, { useState, useEffect } from "react";
import { Card, Container, Typography, Stack } from "@mui/material";
import { CardBody, Col, Row } from "reactstrap";
import Page from "../../components/Page";
import axios from "axios";
import ReactTable from "react-table-v6";
import { Link } from "react-router-dom";
import "react-table-v6/react-table.css";
import Cookies from "js-cookie";
import Select from "react-select";
import moment from "moment";

export default function Formations() {
  const token = Cookies.get("tokenAdmin");
  const [listFormations, setListFormations] = useState([]);
  const [formationOptions, setFormationOptions] = useState([]);
  const [selectedFormation, setSelectedFormation] = useState("");
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth() + 1; // Les mois sont indexés à partir de zéro, donc on ajoute 1
  const currentYear = currentDate.getFullYear();

  const [selectedMonth, setSelectedMonth] = useState(currentMonth.toString());
  const [selectedYear, setSelectedYear] = useState(currentYear.toString());
  const [selectedReduction, setSelectedReduction] = useState("");
  const [valeurTVA, setValeurTVA] = useState(0);
  const [valeurRS, setValeurRS] = useState(0);
  const [selectedDiscount, setSelectedDiscount] = useState("");
  const [selectedPurchaseType, setSelectedPurchaseType] = useState("");

  useEffect(() => {
    getMoreData();
    getImpots();
  }, []);

  const getImpots = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_URL_BACKEND_DEV + `/getImpots`
      );
      setValeurTVA(response.data.result[0].valeurTVA);
      setValeurRS(response.data.result[0].valeurRS);
    } catch (error) {
      console.error(error);
    }
  };

  const uniqueDiscounts = [
    ...new Set(listFormations.map((formation) => formation.reduction)),
  ];

  const uniqueMonths = [
    ...new Set(listFormations.map((formation) => formation.date.split("/")[0])),
  ];
  const uniqueYears = [
    ...new Set(listFormations.map((formation) => formation.date.split("/")[1])),
  ];
  const months = [
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Août",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre",
  ];
  const getMoreData = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_URL_BACKEND_DEV + `/getRevenusEtudiantList`,
        {
          headers: { Authorization: `Bearer ${token}` },
          withCredentials: true,
        }
      );
      setListFormations(response.data.data);
      // Extract unique formation options
      const uniqueFormations = [
        ...new Set(response.data.data.map((item) => item.formation.titre)),
      ];
      setFormationOptions(uniqueFormations);
    } catch (error) {
      console.error(error);
    }
  };

  const handleFormationSelect = (formationId) => {
    setSelectedFormation(formationId);
  };
  const filteredFormations = listFormations.filter((formation) => {
    const selectedMonthValue = selectedMonth !== "" ? selectedMonth : null;
    const formationMonth = formation.date.split("/")[0]; // Assuming the date format is "month/year"

    return (
      (!selectedFormation || formation.formation.titre === selectedFormation) &&
      (!selectedYear || formation.date.includes(selectedYear)) &&
      (!selectedMonthValue || formationMonth === selectedMonthValue) &&
      ((selectedDiscount === "" && selectedDiscount !== 0) ||
        (parseInt(formation.reduction) === parseInt(selectedDiscount))) &&
      (!selectedPurchaseType ||
        (selectedPurchaseType === "Carte Bancaire" && parseInt(formation.reduction) === 0) ||
        (selectedPurchaseType === "Voucher" && parseInt(formation.reduction) > 0))
    );
  });



  const columns = [
    {
      Header: "Date d'achat",
      accessor: "date",
      Cell: ({ original }) => (
        <span style={{ wordBreak: "break-all" }}>{original.date}</span>
      ),
    },
    {
      Header: <div style={{ whiteSpace: "pre-line" }}>Titre formation</div>,
      accessor: "formation.titre",
      width: 220,
      filterMethod: (filter, row) =>
        (row[filter.id] || "").toLowerCase().includes(filter.value.toLowerCase()),
      Cell: ({ original }) => {
        return (
          <span style={{ wordBreak: "break-all", whiteSpace: "pre-line" }}>
            <Link
              to={"/contenuformations/" + original.formation._id}
              onClick={() => handleFormationSelect(original.formation.titre)}
            >
              {original.formation.titre}
            </Link>
          </span>
        );
      },
    },
    {
      Header: "Nom et Prénom",
      accessor: "etudiant",
      Cell: ({ original }) => {
        return (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ marginBottom: "5px" }}>
              {original.etudiant?.nom} {original.etudiant?.prenom}
            </div>
          </div>
        );
      },
      filterable: true,
    },
    {
      Header: "Type d'achat",
      accessor: "reduction",
      Cell: ({ original }) => {
        return (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ marginBottom: "5px" }}>
              {original.reduction === 0 ? " Carte Bancaire" : "Voucher"}
            </div>
          </div>
        );
      },
      filterable: true,
    },
    {
      Header: <div style={{ whiteSpace: "pre-line" }}>Montant de la formation (TTC)</div>,
      accessor: "formation.prix",
      Cell: ({ original }) => {
        return (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div>{original.formation?.prix} TND</div>
          </div>
        );
      },
    },
    {
      Header: <div style={{ whiteSpace: "pre-line" }}>Discount (%)</div>,
      accessor: "reduction",
      width: 100,
      Cell: ({ original }) => {
        return (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div>{original?.reduction} %</div>
          </div>
        );
      },
    },
  ];

  return (
    <Page title="Dashboard | Fastuz">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Revenus détaillés
          </Typography>
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <div style={{ display: "flex" }}>
            <Select
              options={moment.months().map((month, index) => ({
                label: month,
                value: (index + 1).toString(), // Make sure to convert the value to a string
              }))}
              value={{ value: selectedMonth, label: moment().month(selectedMonth - 1).format("MMMM") }}

              onChange={(selectedOption) => setSelectedMonth(selectedOption.value)}
              placeholder="Mois"
            />
            <div style={{ marginLeft: "5px" }}>

              <Select
                options={uniqueYears.map((year, index) => ({

                  label: year,
                  value: year,
                }))}
                value={{ value: selectedYear, label: selectedYear }}
                onChange={(selectedOption) => setSelectedYear(selectedOption.value)}
                placeholder="Années"
                styles={{ marginLeft: "5px" }}
              />
            </div>

          </div>
          <div>
            <Select
              options={formationOptions.map((formation, index) => ({
                label: formation,
                value: formation,
              }))}
              onChange={(selectedOption) => setSelectedFormation(selectedOption.value)}
              placeholder="Formations"
              styles={{
                control: (provided) => ({
                  ...provided,
                  width: "400px", // Adjust the width here as per your requirement
                }),
              }}
            />

            <br />
            <div>
              <Select
                options={[
                  { label: "Type d'achat", value: "" },
                  { label: "Carte Bancaire", value: "Carte Bancaire" },
                  { label: "Voucher", value: "Voucher" },
                ]}
                onChange={(selectedOption) => setSelectedPurchaseType(selectedOption.value)}
                placeholder="Type d'achat"
              />
              <br></br>
              {selectedPurchaseType === "Voucher" && (
                <Select
                  options={[
                    { label: "Discount", value: "" }, // Additional option for "Discount"
                    ...uniqueDiscounts.map((discount, index) => ({
                      label: `${discount} %`,
                      value: discount,
                    }))
                  ]}
                  onChange={(selectedOption) => setSelectedDiscount(selectedOption.value)}
                  placeholder="Discount"
                  styles={{ marginLeft: "5px" }}
                />

              )}
            </div>
          </div>
        </Stack>
        <Row>
          <Col md="12">
            <Card className="main-card mb-3">
              <CardBody>
                <ReactTable
                  data={filteredFormations}
                  nextText={"Suivant"}
                  previousText={"Précedent"}
                  rowsText={"Lignes"}
                  columns={columns}
                  defaultPageSize={5}
                  filterable={true}
                  noDataText="No data is available"
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Page>
  );
}
