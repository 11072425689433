
import cookie from "js-cookie";

import Router from "next/router";

// remove from cookie////
export const getCookie = (key) => {
  if (window !== "undefined") {
    return cookie.get(key, {
      expires: 1,
    });
  }
};

// remove from cookie
export const setCookie = (key,value) => {
  if (window !== "undefiend") {
    cookie.set(key, value, {
      // 1 Day
      expires: 1,
    });
  }
};

// remove from cookie
export const removeCookie = (key) => {
    if (window !== "undefined") {
      cookie.remove(key, {
        expires: 1,
      });
    }
  };

  export const signout = async (next) => {
    await removeCookie("tokenAdmin");
    next();
  };

  // Access user info from localstorage
export const isAuth = () => {
  if (window !== "undefined") {
    const cookieChecked = getCookie("tokenAdmin");
    if (cookieChecked) {
      return true;
    } else {
      return false;
    }
  }
};



  export const handleLogin = (token) => {
    cookie.set("tokenAdmin", token);
    Router.push("/");
  };


  
  export const setLocalStorage = (key, value) => {
    if (window !== "undefined") {
      localStorage.setItem(key, value);
    }
  };
  
  // get in localstorage
  export const getLocalStorage = (key) => {
    if (window !== "undefined") {
      return localStorage.getItem(key);
    }
  };
  
  // Remove from localstorage
  export const removeLocalStorage = (key) => {
    if (window !== "undefined") {
      localStorage.removeItem(key);
    }
  };