import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import { Stack, TextField, IconButton, InputAdornment } from '@mui/material';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { LoadingButton } from '@mui/lab';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Page from '../../Page';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const INITIAL_USER = {
  nom: '',
  prenom: '',
  email: '',
  password: '',
  role:'',
  confirmPassword: '', 
  patente:''

};
const validationSchema = yup.object({
  nom: yup.string().required('nom obligatoire'),
  prenom: yup.string().required('Prenom obligatoire'),
  email: yup.string().email('Tapez une adresse email valide').required('Adresse email obligatoire'),
  password: yup.string().required('Password is required'),
  patente: yup.string().required('Patente obligatoire'),

});
const RegisterBusiness = ({ handleClose , getUsers, formateurs}) => {
 
const [selectedFormation, setSelectedFormation]= useState("")

  


useEffect(() => {
  getUsers();

}, []);

  const formik = useFormik({
    initialValues: {
      nom: '',
      prenom: '',
      email: '',
      password: '',
      FormationEnrolled :'', 
      patente:''

    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      if (formik.isValid) {
        try {
          setLoading(true);
          setError('');

          const response = await axios
            .post(process.env.REACT_APP_URL_BACKEND_DEV + '/formateur/register', {
              nom: values.nom,
              prenom: values.prenom,
              email: values.email,
              role: 'business',
              password: values.password,
              patente:values.patente

            });
          if (response.data.isEmailUsed) {
            toast.error('Email utilisé !', {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });          } else {
            toast.success('Formateur ajouté avec succées!', {
              position: 'top-right',
              autoClose: 10000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
            setTimeout(() => {
              handleClose();
            }, 1000);
          }
        } catch (error) {
          setError(error.response.data.message);
        } finally {
          getUsers();
          setLoading(false);
        }
      }
    }
  });

  const [showPassword, setShowPassword] = useState(false);




  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const [user, setUser] = React.useState(INITIAL_USER);
  const [disabled, setDisabled] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState('');


  React.useEffect(() => {
    const isUser = Object.values(user).every((el) => Boolean(el));
    isUser ? setDisabled(false) : setDisabled(true);
  }, [user]);



return (
    <Page title="Dashboard | Fastuz">

    <div>
     
    <ToastContainer />

      <form>
        <Stack spacing={3}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              type="text"
              label="Nom"
              name="nom"
              error={formik.touched.nom && formik.errors.nom}
              helperText={formik.errors.nom}
              {...formik.getFieldProps('nom')}
            />

            <TextField
              fullWidth
              type="text"
              label="Prénom"
              name="prenom"
              error={formik.touched.prenom && formik.errors.prenom}
              helperText={formik.errors.prenom}
              {...formik.getFieldProps('prenom')}
            />
          </Stack>
          <TextField
            label="Email "
            name="email"
            type="email"
            error={formik.touched.email && formik.errors.email}
            helperText={formik.errors.email}
            {...formik.getFieldProps('email')}
          />
           

          <TextField
            type={showPassword ? 'text' : 'password'}
            label="Mot de passe"
            name="password"
            error={formik.touched.password && formik.errors.password}
            helperText={formik.errors.password}
            {...formik.getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        
        
        <TextField
              fullWidth
              type="text"
              label="Patente"
              name="patente"

              error={formik.touched.patente && formik.errors.patente}
              helperText={formik.errors.patente}
              {...formik.getFieldProps('patente')}
            />
        
          <LoadingButton
            fullWidth
            size="large"
            type="button"
            disabled={!formik.isValid}
            variant="contained"
            onClick={async () => {
              await formik.handleSubmit();
            }}
          >

            Ajouter Business
          
          </LoadingButton>
        </Stack>
      </form>

    </div>
 
    </Page>
    
  );
};

export default RegisterBusiness;