import { useState, useEffect } from "react";
import axios from "axios";

// material
import {

  Button,
  Container,

} from "@mui/material";
// components
import Page from "../../components/Page";
import moment from "moment";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';


import { CardBody, Row } from "reactstrap";
import ReactTable from "react-table-v6";

import { Stack, Typography } from "@mui/material";
import ImportFile  from "./ImportFile.js";



export default function User() {

  const [loading, setLoading] = useState(false);
  const [apprenants, setApprenants] = useState([]);
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    getApprenants();
  });

  const getApprenants = async () => {
    setLoading(true);
    const res = await axios.get(
      process.env.REACT_APP_URL_BACKEND_DEV + `/etudiant/getActiveStudents`
    );
    setApprenants(res.data.students);
    console.log(res.data.formation)
  };

  const columns = [
    {
      Header: "Nom et Prénom",
      id: "nomPrenom",
      accessor: row => `${row.nom} ${row.prenom}`,
      filterMethod: (filter, row) =>
        row.nomPrenom.toLowerCase().includes(filter.value.toLowerCase()),
      Cell: ({ original }) => {
        return (
          <span style={{ wordBreak: "break-all" }}>
            {original.nom} {original.prenom}
          </span>
        );
      },
    },
    {
      Header: "Email",
      accessor: "email",
      Cell: ({ original }) => {
        return <div>{original?.email} </div>
      },

    },
    {
      Header: "Date d'inscription",
      accessor: "createdAt",
      width: 150,
      Cell: ({ value }) => {
        return (
          <span style={{ wordBreak: "break-all" }}>
            {moment(value).locale("fr").format("DD/MM/YYYY")}
          </span>
        );
      },
      filterMethod: (filter, row) => {
        const date = moment(row[filter.id]).format("DD/MM/YYYY");
        return date.includes(filter.value);
      },
      defaultSortDesc: true,

    },
    {
      Header: "Status du compte",
      accessor: "active",
      Cell: ({ original }) => {
        return <div style={{display: "flex", justifyContent: "center"}}>{original?.active ? "True" : "False"} </div>
      },
      filterMethod: (filter, row) => {
        if (filter.value === "all") {
          return true;
        }
        if (filter.value === "true") {
          return row[filter.id] === true;
        }
        if (filter.value === "false") {
          return row[filter.id] === false;
        }
        return false;
      },
      Filter: ({ filter, onChange }) => {
        return (
          <select
            style={{ width: "100%", fontSize: "1rem", display: "flex", justifyContent: "center" }}

            onChange={(event) => onChange(event.target.value)}
            value={filter ? filter.value : "all"}
          >
            <option value="all">Filtrer</option>
            <option value="true">Compte activé</option>
            <option value="false">Compte désactivé</option>

          </select>
        );
      },
    },

    {
      Header: "Enrolled / Not Enrolled",
      accessor: "enrolled",
      Cell: ({ original }) => {
        const enrolledText = original.enrolled.length > 0 ? "Enrolled" : "Not Enrolled";
        const textColor = original.enrolled.length > 0 ? "green" : "red";
        return <div style={{ color: textColor }}>{enrolledText}</div>;
      },
      filterMethod: (filter, row) => {
        if (filter.value === "all") {
          return true;
        }
        if (filter.value === "true") {
          return row[filter.id].length > 0;
        }
        if (filter.value === "false") {
          return row[filter.id].length === 0;
        }
        return false;
      },
      Filter: ({ filter, onChange }) => {
        return (
          <select
            style={{ width: "100%", fontSize: "1rem" }}
            onChange={(event) => onChange(event.target.value)}
            value={filter ? filter.value : "all"}
          >
            <option value="all">Filtrer</option>
            <option value="true">Enrolled</option>
            <option value="false">Not Enrolled</option>
          </select>
        );
      },
    }




  ];



  return (
    <Page title="Dashboard | Fastuz">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Inscrits
          </Typography>
          <Button
            variant="contained"
            onClick={handleClickOpen}          >
           
            Ajouter Inscrits
          </Button>
        </Stack>

   
        <CardBody>
          <Row>

            <ReactTable
              data={apprenants}
              nextText={"Suivant"}
              previousText={"Précedent"}
              rowsText={"Lignes"}
              columns={columns}
              defaultPageSize={10}
              filterable={true}
              noDataText="No data is available"
              defaultSorted={[{id :"createdAt" , desc : true}]}

            />


          </Row>
        </CardBody>
        <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >

        <DialogTitle id="alert-dialog-title">
          {"Ajouter un fichier Excel"}
        </DialogTitle>
       
        <DialogContent>
          <ImportFile handleClose={handleClose} />
        </DialogContent>

      </Dialog>
      </Container>
    </Page>
  );
}
