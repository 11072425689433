import plusFill from '@iconify/icons-eva/plus-fill';
import {
  Card,
  Stack,
  Button,
  Container,
  Typography,
} from '@mui/material';
import { CardBody, Col, Row } from "reactstrap";
import Page from '../../components/Page';
import {  useNavigate } from 'react-router-dom';
import axios from "axios";
import { useEffect, useState } from "react";
import ReactTable from 'react-table-v6'
import { Link } from "react-router-dom";
import "react-table-v6/react-table.css";
import Swal from "sweetalert2";
import { Icon } from '@iconify/react';
import { SupprimerFormationAPI } from '../../utils/api';
import { LoadingButton } from '@mui/lab';
import Cookies from 'js-cookie';
import moment from "moment";

export default function Formations() {

  const token= Cookies.get("tokenAdmin")
const[listFormations, setListFormations]= useState([])
const sanitizeRoomName = (name) => {
  if (typeof name === 'string') {
    // Remove special characters and limit the length
    return name.replace(/[^a-zA-Z0-9\s]/g, '').substring(0, 30);
  }
  return 'defaultRoomName';
};

  
useEffect(() => {
   
    getMoreData();
  }, []);
  const getMoreData = async () => {
 
    await axios.get(process.env.REACT_APP_URL_BACKEND_DEV + `/getAllFormationsFormateur`, 
      {
        headers: { Authorization: `Bearer ${token}`, 
        withCredentials: true
      },
    })
      .then(response => {
setListFormations(response.data.formations)  
  })
      .catch(error => {
        console.error(error);
      });
  }

  const generateOptions = (formations) => {
    // Get unique typeFormation values
    const uniqueTypeFormations = [...new Set(formations.map(item => item.typeFormation))];
    
    // Generate options based on unique typeFormation values
    const options = uniqueTypeFormations.map((typeFormation) => (
      <option key={typeFormation} value={typeFormation}>
        {typeFormation}
      </option>
    ));
    
    return options;
  };

  const columns = [
    {
      Header: "Titre formation",
      accessor: "titre",
      width: 300,

      filterMethod: (filter, row) =>
    row[filter.id].toLowerCase().includes(filter.value.toLowerCase()),
      Cell: ({ original }) => {
        return (
          <span style={{ wordBreak: "break-all" }}>
            <Link to={"/contenuformations/" + original._id}>
       
               {original.titre}
               </Link>
          </span>
        );
      },
    },
    {
      Header: "Date de création",
      accessor: "createdAt",
      width: 150,
      Cell: ({ value }) => {
        return (
          <span style={{ wordBreak: "break-all" }}>
            {moment(value).locale("fr").format("DD/MM/YYYY")}
          </span>
        );
      },
      
      filterMethod: (filter, row) => {
        const date = moment(row[filter.id]).format("DD/MM/YYYY");
        return date.includes(filter.value);
      },
      defaultSortDesc: true,

    }, 
 

    {
      Header: "Type Formation",
      width: 150,
      accessor: "typeFormation",
      filterMethod: (filter, row) => {
        const TypeFormatinName = row[filter.id]?.titre || ""; // Access the "nom" property of the "categorie" object
        return TypeFormatinName.toLowerCase().includes(filter.value.toLowerCase());
      },
      Cell: ({ original }) => {
        return <div style={{ display: "flex", justifyContent: "center" }}>
  {original?.typeFormation?.titre ? (
    <div>{original?.typeFormation?.titre}</div>
  ) : (
""  )}
</div>
      },
    },
    {
      Header: "Lien conférence",
      width: 150,
      accessor: "urlMeet",
      Cell: ( row ) => {
        const original = row;
  
        return (
          <div style={{ display: "flex", justifyContent: "center" }}>
              {row.original?.typeFormation?.titre==="Webinaire"?
                <div className="d-block w-100 text-center">
                <Link to={"/formateur/conference/" + row.original._id}>
                  <LoadingButton
                  >
                    Lien Conférence
                  </LoadingButton>
                </Link>
              </div>
            //   Lien de la conférence
            // </a>
            :
            <p>{original?.typeFormation?.titre}</p>
        }
            {/* <a
              href={`https://8x8.vc/vpaas-magic-cookie-e9c561d3141d4046b681f68d02c3c08d/${sanitizeRoomName(row?.titre)}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Lien de la conférence
            </a> */}
  
  
          </div>
        );
      }
    },
    {
      Header: "Prix formation",
      accessor: "prix",
      Cell: ({original}) => {
        return <div style={{ display: "flex", justifyContent: "center" }}>
        {original.prix > 0 ? (
          <div>{original.prix} TND</div>
        ) : (
          <div>Free </div>
        )}
        </div>
      },
    }, 

    {
      Header: "Apprenants",
      accessor: "enrolledBy",
      width: 150,
      Cell: ({ original }) => {
     
        const enrolledBy = ["Webinaire", "Présentielle"].includes(original?.typeFormation?.titre)
        ? original?.enrolledByPres // Use enrolledByPres for Webinaire or Présentielle
        : original?.enrolledBy; // Use enrolledBy for EnLigne
  
        return (
          <div style={{ display: "flex", justifyContent: "center" }}>
            {enrolledBy && enrolledBy.length > 0 ? (
              <div>{enrolledBy.length}</div>
            ) : (
              <div>Pas d'apprenants</div>
            )}
          </div>
        );
      },
    },
      {
        Header: "Actions",
        filterable: false,
        sortable: false,
        Cell: (row) => {
          return (
            <>
              <div className="d-block w-100 text-center">
                <Link to={"/detailFormation/" + row.original._id}>
                  <LoadingButton
                  >
                    Details Formations
                  </LoadingButton>
                </Link>
              </div>
           
             <LoadingButton
                  
                  onClick={ async() => {

                    Swal.fire({
                          title: "Êtes-vous sûr de vouloir supprimer cette formation?",
                          icon: "warning",
                          showCancelButton: true,
                          cancelButtonText: "Annuler",

                          confirmButtonColor: "#0d3077",
                          cancelButtonColor: "#d33",
                          confirmButtonText: "Supprimer formation!",
                        }).then(async (result) => {
                          if (result.isConfirmed) {
                            await     SupprimerFormationAPI(row.original._id);
                    
                            await axios.get(process.env.REACT_APP_URL_BACKEND_DEV + `/getAllFormationsFormateur`, 
                            {
                              headers: { Authorization: `Bearer ${token}`, 
                              withCredentials: true
                            },
                          })                            .then(async (res) => {
                                if (res.status === 200) {
                    
                                  Swal.fire("Formation supprimé!");
                    
                                } else {
                                  Swal.fire(
                                    "Formation supprimé!"
                                  );
                                }
                              });
                          }
                        });


         

                  }}
                >
                  Supprimer 
                </LoadingButton>
              
            </>
          );
        },
      },

  ];

const navigate = useNavigate();


    
  return (
    <Page title="Dashboard | Fastuz">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Formations
          </Typography>
          <Button
            variant="contained"
            onClick={() => navigate("/AddFormations")}
            startIcon={<Icon icon={plusFill} />}
          >
            Ajouter Formation
          </Button>
        </Stack>

        <Row>
        <Col md="12">
          <Card className="main-card mb-3">
            <CardBody>
              <ReactTable
                data={listFormations}
                nextText={"Suivant"}
                previousText={"Précedent"}
                rowsText={"Lignes"}
                columns={columns}
                defaultPageSize={5}
                filterable={true}
                noDataText="No data is available"
                defaultSorted={[{id :"createdAt" , desc : true}]}

              />
            </CardBody>
          </Card>
        </Col>
      </Row>
      </Container>

    </Page>

  );
}

