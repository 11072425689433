import { filter } from 'lodash';
import { useState, useEffect } from 'react';
import axios from "axios"
import {
  Card,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
} from '@mui/material';

import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import UserListHeadEmails from '../../components/_dashboard/user/UserListHeadEmails';
import UserListToolbarDesactive from '../../components/_dashboard/user/UserListToolbarDesactive';
import { Input } from 'antd';
import { format } from 'date-fns'; // Import date-fns for date formatting
const TABLE_HEAD = [
  
  { id: "emailSubject", label: "Objet Email" },
  { id: "createdAt", label: "date d'envoi" },
  { id: "count", label: "Emails envoyés" }, // Add a column for the total number of email logs

];

export default function User() {

  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('nom');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [formateurs, setformateurs] = useState([]);
  const [selectedProprietaire, setSelectedProprietaire] = useState('');
  const [filterEmail, setFilterEmail] = useState('');
  const handleFilterByName = (event, filterType) => {
    const { value } = event.target;
    if (filterType === 'name') {
      setFilterName(value);
    } else if (filterType === 'email') {
      setFilterEmail(value);
    }
  };
  
  function formatDate(dateString) {
    const date = new Date(dateString);
    return format(date, 'dd/MM/yyyy HH:mm', { timeZone: 'Africa/Tunis' }); // Use Tunisian timezone
  }
  useEffect(() => {

    getVouchers();
  }, []);
  const getVouchers = async () => {
    try {
      const res = await axios.get(
        process.env.REACT_APP_URL_BACKEND_DEV + `/etudiant/getListEmails`
      );
      const sortedVouchers = res.data.students.sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
      setformateurs(sortedVouchers);
      // setformateurs(res.data.students);
    } catch (error) {
      console.error(error);
    }
  };
  console.log("form",formateurs)
  function applySortFilter(array, query, proprietaireFilter,nameQuery, emailQuery) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
 
    if (nameQuery) {
      return filter(array, (_user) => _user.nom.toLowerCase().indexOf(nameQuery.toLowerCase()) !== -1);
    }
    if (emailQuery) {
      return filter(array, (_user) => _user.email.toLowerCase().indexOf(emailQuery.toLowerCase()) !== -1);
    }
    if (query) {
      return filter(array, (_user) => _user.nom.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    if (proprietaireFilter) {
      return filter(array, (_user) => _user.nomProp.titre.toLowerCase() === proprietaireFilter.toLowerCase());
    }
    return stabilizedThis?.map((el) => el[0]);
  }



  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = formateurs?.map((n) => n._id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };
 

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 5));
    setPage(0);
  };
 
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - formateurs?.length) : 0;

  const filteredUsers = applySortFilter(formateurs, filterName, selectedProprietaire);
  const isUserNotFound = filteredUsers?.length === 0;


  return (
    <Page title="Dashboard | Fastuz">
      <Container>
        
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
          Liste des emails envoyés
          </Typography>

        </Stack>

        <Card>
          <UserListToolbarDesactive
          filterEmail={filterEmail} 
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}

          />
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
            <Input
            placeholder='Filtrer avec email'
                        fullWidth
                        id="filter-email"
                        label="Filter by Email"
                        value={filterEmail}
                        onChange={(event) => handleFilterByName(event, 'email')}
                      />
              <Table>
                
                <UserListHeadEmails
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={formateurs?.length}
                  numSelected={selected?.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  <>
                

</>
                  {filteredUsers
                  ?.filter((_user) =>
                  _user.userName.toLowerCase().includes(filterName.toLowerCase()) &&
                  _user.userEmail.toLowerCase().includes(filterEmail.toLowerCase())
                )
                    ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    ?.map((row) => {
                      const {
                        _id,
                        
                        emailSubject,
                        count,
                      
                    createdAt
                      } = row;
                      const isItemSelected =
                        selected.indexOf(_id) !== -1;
                      return (

                        <TableRow
                          hover
                          key={_id}
                          tabIndex={-1}
                         
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                         

                          <TableCell align="left">{emailSubject}</TableCell>
                          <TableCell align="center">{formatDate(createdAt)}</TableCell>
                          <TableCell align="center">{count}</TableCell> {/* Display count */}

                      

                        </TableRow>

                      );
                    })}
                   

                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={formateurs?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}

          />

        </Card>
 

      </Container>

    </Page>

  );
}







