import { useFormik } from 'formik';

import { UploadeFileAPI } from '../../utils/api';

import { Stack, Container } from '@mui/material';
import Select from "react-select";
import { TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, FormGroup, Alert } from "reactstrap";
import * as yup from "yup";
import FileUploader from "../../assets/FileUploader";
import { useNavigate } from 'react-router-dom';
import getBase64 from '../../utils/getBase64';
import { CKEditor } from "ckeditor4-react";
import { styled } from '@mui/material/styles';
import SweetAlert from 'react-bootstrap-sweetalert';
import { getAllLicences } from "../../redux/formationSlice";
import Cookies from "js-cookie"
import { LoadingButton } from '@mui/lab';
import axios from "axios"
import Swal from "sweetalert2";
import EditIcon from '@mui/icons-material/Edit';
import { getCategorie } from '../../redux/categorieSlice';
import { getTypeFormation } from '../../redux/typeFormationSlice';

import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import DatePicker, { DateObject } from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";


const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 950,
  margin: 'auto',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

const validationSchema = yup.object({
  titre: yup.string().required("Titre obligatoire"),
  prix: yup.number().required("Prix obligatoire"),

});
const currencies = [
  {
    value: 'USD',
    label: '$',
  },
  {
    value: 'EUR',
    label: '€',
  },
];
const typeFormation = [
  {
    value: 'EnLigne',
    label: 'En Ligne',
  },
  {
    value: 'Presentiel',
    label: 'Présentiel',
  },
  {
    value: 'Webinaire',
    label: 'Webinaire',
  },
];
export default function AddFormations() {
  const token = Cookies.get("tokenAdmin")
  let [showSuccess, setShowSuccess] = useState(false);
  let [showError, setShowError] = useState(false);
  const navigate = useNavigate();
  const userConnected = useSelector((state) => state.auth.CurrentUser);
  const [logoProduit, setlogoProduit] = useState(null);

  const [photo, setPhoto] = useState(null);
  const [video, setVideo] = useState(null);
  const [videoPreview, setVideoPreview] = useState(null);
  const [cover, setCover] = useState(null);
  const [description, setDescription] = useState("");
  const [selectedLicence, setSelectedLicence] = useState("");
  const [selectedDuration, setselectedDuration] = useState("");
  const [selectedDurationFormation, setselectedDurationFormation] = useState("");
  const [selectedCategorie, setSelectedCategorie] = useState("");
  const [selectedTypeFormation, setSelectedTypeFormation] = useState("");
  const [selectedDepartements, setSelectedDepartements] = useState("");

  const [formationCertifie, setFormationCertifie] = useState(false);
  const [selectedDevise, setselectedDevise] = useState("");
  const [selectedType, setselectedType] = useState("");

  const [values, setValues] = useState(
    [new DateObject().set({ hour: 10, minute: 0, second: 0 })] // Set default value to today's date
  );

  const handleChange = (event) => {
    setFormationCertifie(event.target.checked);
  };
  const handleDescription = (e) => {
    let data = e.editor.getData();

    setDescription(data);
  };
  const [logoProduitPreview, setLogoProduitPreview] = useState(null);

  const [coverPreview, setCoverPreview] = useState(null);
  const [alert, setAlert] = useState(false);

  const currentUser = useSelector((state) => state.auth.CurrentUser);
  const licences = useSelector((state) => state.formations.listeLicences);
  const categorie = useSelector((state) => state.categorie.listCategorie);
  const typeFormation = useSelector((state) => state.TypeFormation.listTypeFormation)
  console.log("typeFormationn", typeFormation)
  const dispatch = useDispatch();
  useEffect(() => {
    if (video) getBase64(video).then((url) => setVideoPreview(url));
    else setVideoPreview(null);
  }, [video]);
  useEffect(() => {
    if (cover) getBase64(cover).then((url) => setCoverPreview(url));
    else setCoverPreview(null);
  }, [cover]);

  useEffect(() => {
    if (logoProduit) getBase64(logoProduit).then((url) => setLogoProduitPreview(url));
    else setLogoProduitPreview(null);
  }, [logoProduit]);
  console.log("currentUser", userConnected)

  useEffect(() => {
    if (photo && description) setAlert(false);
  }, [photo, description]);

  useEffect(() => {
    dispatch(getAllLicences());
    dispatch(getCategorie());
    dispatch(getTypeFormation())

  }, [dispatch]);

  useEffect(() => {
    // Set the initial value of selectedTypeFormation to the value of the first option
    setSelectedTypeFormation(optionsTypeFormations[1]?.value || "");
  }, [typeFormation]);

  const handleChangeDuration = async (selectedOption) => {
    setselectedDuration(selectedOption.value);
  };
  const handleChangeDurationFormation = async (selectedOption) => {
    setselectedDurationFormation(selectedOption.value);
  };

  const handleChangeLicence = async (selectedOption) => {
    setSelectedLicence(selectedOption.value);

    try {
      const res = await axios.get(process.env.REACT_APP_URL_BACKEND_DEV + `/getLicenceById/${selectedOption.value}`);
    } catch (error) {
      console.error(error);
    }
  };

  const handleChangeDevise = async (selectedOption) => {
    setselectedDevise(selectedOption.value);
  };

  const options = [
    { value: null, label: "Pas de licence" },
    ...licences?.map((licence) => ({
      value: licence._id,
      label: licence.titre,
    }))
  ];


  const handleChangeCategorie = async (selectedOption) => {
    setSelectedCategorie(selectedOption.value);

    try {
      const res = await axios.get(process.env.REACT_APP_URL_BACKEND_DEV + `/categorie_details/${selectedOption.value}`);
      console.log(res.data.result);
    } catch (error) {
      console.error(error);
    }
  };

  const optionsCategorie = [
    { value: '', label: 'Clear' }, // Option to clear the selection
    ...categorie?.map((categories) => ({
      value: categories._id,
      label: categories.nom,
    })),
  ];

  const handleChangeTypeFormation = async (selectedOption) => {
    setSelectedTypeFormation(selectedOption.value);
    setselectedType(selectedOption.label)

    try {
      const res = await axios.get(process.env.REACT_APP_URL_BACKEND_DEV + `/typeFormation_details/${selectedOption.value}`);
      console.log(res.data.result);
    } catch (error) {
      console.error(error);
    }
  };
  console.log("selectedTypeFormation", selectedType)

  const optionsTypeFormations = [
    { value: '', label: 'Clear' }, // Option to clear the selection
    ...typeFormation?.map((formations) => ({
      value: formations._id,
      label: formations.titre,
    })),
  ];
  console.log("optionsTypeFormations", optionsTypeFormations)
  const formattedDates = values?.map((dateObject) =>
    dateObject.format("DD/MM/YYYY HH:mm"));

  const optionsDuration = [
    { value: '1', label: '1 ans' },
    { value: '2', label: '2 ans' },
    { value: '3', label: '3 ans' }
  ]
  const formik = useFormik({
    initialValues: {
      titre: "",
      licence: null,
      duration: "",
      categorie: null,
      prix: 0,
      prixExterne: 0,
      nombrePlace: 0,
      devise: "",
      typeFormation: "",
      durationFormation: "",
      urlMeet: ""


    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const obj = {
        ...values,
        description,
        licence: selectedLicence || null,
        categorie: selectedCategorie || null,
        formateur: currentUser,
        duration: selectedDuration,
        formationCertifie: formationCertifie,
        devise: selectedDevise,
        // typeFormation:selectedType,
        // dateFormation: formattedDates
        // typeFormation: selectedType,
        typeFormation: selectedTypeFormation,
        dateFormation: selectedType === 'En ligne' ? null : formattedDates, // Set dateFormation to null if type is EnLigne
        nombrePlace: selectedType === 'En ligne' ? null : values.nombrePlace, // Set nombrePlace to null if type is EnLigne


      };
      if (cover) {
        const coverRes = await UploadeFileAPI(cover, "coverFormation");
        obj.cover = coverRes.data.location;
      }

      if (logoProduit) {
        const logoRes = await UploadeFileAPI(logoProduit, "logoProduit");
        obj.logoProduit = logoRes.data.location;
      }

      if (video) {
        const videoRes = await UploadeFileAPI(video, "videoFormation");
        obj.videoIntro = videoRes.data.location;
      }

      const data = await axios.post(
        process.env.REACT_APP_URL_BACKEND_DEV + "/formation",

        obj,

        {
          headers: {
            Authorization: `Bearer ${token}`,
            withCredentials: true
          },
        }
      );

      data.data.status ? setShowError(true) : setShowSuccess(true);

      if (userConnected.role === "admin") {
        await navigate("/dashboard/formationAdmin");
      }
      else if (userConnected.role === "business") {
        await navigate("/business/formations");

      }
      else {
        await navigate("/formateur/formations");
      }
    },

  });


  return (
    <Container maxWidth="l">
      <ContentStyle>
        <div className="form-wizard-content">
          <Row form>
            <Stack spacing={2}>
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} justifyContent="center" >
                <Col md={12}>
                  {alert && (
                    <Alert color="danger">
                      {alert === "photo" && "Merci d'inserer une image"}
                      {alert === "description" && "Merci d'inserer une description"}
                    </Alert>
                  )}
                </Col>
                <Col md={12} >
                  {!cover && (
                    <FileUploader
                      fileTypes={["image/png", "image/jpg", "image/jpeg"]}
                      setFileToUpload={setCover}
                      title="Couverture"
                      original=""

                    ></FileUploader>
                  )}
                  {cover && (
                    <div className="cover-preview" onClick={() => setCover(null)}>
                      <img src={coverPreview} width="50%" />
                      <EditIcon />
                    </div>
                  )}
                </Col>

                <Col md={12} >
                  {!logoProduit && (
                    <FileUploader
                      fileTypes={["image/png", "image/jpg", "image/jpeg"]}
                      setFileToUpload={setlogoProduit}
                      title="Logo Produit"
                      original=""

                    ></FileUploader>
                  )}
                  {logoProduit && (
                    <div className="cover-preview" onClick={() => setlogoProduit(null)}>

                      <EditIcon
                      />
                      <img src={logoProduitPreview} width="50%" />       </div>
                  )}
                </Col>
                <Col md={6} className="mb-3">
                  {!video && !videoPreview && (
                    <FileUploader

                      fileTypes={["video/mp4", "video/webm"]}
                      setFileToUpload={setVideo}
                      title="Vidéo introductive"
                      original=""
                    ></FileUploader>
                  )}
                  {videoPreview && (
                    <div >

                      <video controls src={videoPreview} width="100%"></video>
                      <LoadingButton

                        onClick={() => {
                          setVideoPreview(null);
                          setVideo(null);
                        }}
                      >
                        Réinitialiser Video
                      </LoadingButton>
                    </div>
                  )}
                </Col>
              </Stack>
              <Col md={6}>
                <FormGroup>
                  <TextField
                    fullWidth
                    name="titre"
                    label="Titre"
                    id="titre"
                    placeholder="Titre"
                    error={!!(formik.touched.titre && formik.errors.titre)}
                    helperText={formik.touched.titre && formik.errors.titre}
                    {...formik.getFieldProps("titre")}
                  />
                </FormGroup>
              </Col>
              {currentUser.role === "admin" || currentUser.role === "formateur" ? (
                <>
                  <Col md={6}>
                    <FormGroup>
                      <TextField
                        fullWidth
                        type="number"
                        name="prix"
                        id="prix"
                        label="Prix du formation"
                        placeholder="500 D"
                        error={!!(formik.touched.prix && formik.errors.prix)}
                        helperText={formik.touched.prix && formik.errors.prix}
                        {...formik.getFieldProps("prix")}
                      />
                    </FormGroup>
                  </Col>
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                    <TextField
                      fullWidth
                      type="number"
                      label="prix Externe"
                      name="prixExterne"
                      error={formik.touched.prixExterne && formik.errors.prixExterne}
                      helperText={formik.errors.prixExterne}
                      {...formik.getFieldProps('prixExterne')}
                    />
                    <FormGroup>

                      <Select
                        placeholder="Devise"
                        options={currencies}
                        onChange={handleChangeDevise}
                      />

                    </FormGroup>

                  </Stack>
                </>
              )
                : ("")}

              <Col md={6} >

                <FormControlLabel
                  control={
                    <Switch
                      checked={formationCertifie}
                      onChange={handleChange}
                      inputProps={{ 'aria-label': 'Formation certifiée' }}
                    />
                  }
                  label="Formation certifiée"
                  labelPlacement="start"

                />

              </Col>
              {currentUser.role === "admin" || currentUser.role === "formateur" ? (
                <>
                  <Col md={6} >
                    <FormGroup>

                      <Select
                        placeholder="Choisir catégorie"
                        options={optionsCategorie}
                        onChange={handleChangeCategorie}


                      />

                    </FormGroup>
                  </Col>
                  <Col md={6} >
                    <FormGroup>

                      <Select
                        placeholder="Choisir Licence"
                        options={options}
                        onChange={handleChangeLicence}
                      />

                    </FormGroup>
                  </Col>
                </>
              )
                : ("")}
              <Col md={6} >
                <FormGroup>

                  <Select
                    placeholder="Duration"
                    options={optionsDuration}
                    onChange={handleChangeDuration}
                  />

                </FormGroup>
              </Col>
           


                <>

                  <Col md={6} >
                    <FormGroup>

                      <Select
                        placeholder="Choisir Type Formation"
                        options={optionsTypeFormations}
                        onChange={handleChangeTypeFormation}


                      />

                    </FormGroup>
                  </Col>
                  {(selectedType === 'Présentielle' || selectedType === 'Webinaire') && (
                    <Col md={6}>
                      <FormGroup >

                        <DatePicker
                          value={values}
                          onChange={setValues}
                          format="DD/MM/YYYY HH:mm"
                          multiple
                          minDate={new DateObject().set({ hour: 0, minute: 0, second: 0 })} // Set minimum date to today
                          plugins={[
                            <TimePicker position="bottom" />,
                            <DatePanel
                              markFocused
                              style={{ minWidth: "350px" }}
                            />,
                          ]}
                        />

                      </FormGroup>
                      <br></br>
                      <Col md={6}>
                        <FormGroup>
                          <TextField
                            fullWidth
                            type="number"
                            name="nombrePlace"
                            id="nombrePlace"
                            label="Nombre de place"
                            placeholder="Nombre Place"
                            error={!!(formik.touched.nombrePlace && formik.errors.nombrePlace)}
                            helperText={formik.touched.nombrePlace && formik.errors.nombrePlace}
                            {...formik.getFieldProps("nombrePlace")}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <TextField
                            fullWidth
                            name="durationFormation"
                            label="Durée de la formation"
                            id="durationFormation"
                            placeholder="Durée de la formation"
                            error={!!(formik.touched.durationFormation && formik.errors.durationFormation)}
                            helperText={formik.touched.durationFormation && formik.errors.durationFormation}
                            {...formik.getFieldProps("durationFormation")}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <TextField
                            fullWidth
                            name="urlMeet"
                            label="Code conférence"
                            id="urlMeet"
                            placeholder="Code conférence"
                            error={!!(formik.touched.urlMeet && formik.errors.urlMeet)}
                            helperText={formik.touched.urlMeet && formik.errors.urlMeet}
                            {...formik.getFieldProps("urlMeet")}
                          />
                        </FormGroup>
                      </Col>
                    </Col>
                  )}
                </>
        

              <Col md={12} className="mb-3">
                <FormGroup>
                  <label>Description</label>
                  <CKEditor initData={description} onChange={handleDescription} />
                </FormGroup>
              </Col>

            </Stack>

          </Row>
        </div>
        <br></br>
        <LoadingButton
          fullWidth
          size="medium"
          type="button"
          disabled={!formik.isValid}
          variant="contained"
          onClick={async () => {
            try {
              await formik.handleSubmit();

              Swal.fire("Formation ajoutée!");

            } catch (error) {
              Swal.fire("Erreur!");
            }
          }}
        >
          Ajouter Formation

        </LoadingButton>

        <SweetAlert
          show={showSuccess}
          title="Ajout avec succées"
          type="success"
          onConfirm={() => {
            setShowSuccess(false);
          }}
        />

        <SweetAlert
          show={showError}
          title="Un problème est survenue !"
          type="error"
          onConfirm={() => {
            setShowError(false);
          }}
        />
      </ContentStyle>

    </Container>








  );
}