// import React, {  useState } from "react";
// import {
//   Card,
//   CardBody,
//   Col,
//   Row,
//   Badge,
//   Container,
// } from "reactstrap";
// import { v4 as uuidv4 } from "uuid";
// import { FormGroup } from "reactstrap";
// import { TextField } from "@material-ui/core";
// import { LoadingButton } from "@mui/lab";
// import "react-widgets/styles.css";
// import Grid from "@mui/material/Grid";
// import Multiselect from "react-widgets/Multiselect";
// import {
//   ajoutQuestionAPI,
//   ajoutQuizzAPI,
//   modifierFormationAPI,
// } from "../../utils/api";
// import { styled } from "@mui/material/styles";
// import Paper from "@mui/material/Paper";
// import { updateArrayQuestions } from "../../redux/QuizzSlice";
// import Select from "react-select";
// import SweetAlert from "react-bootstrap-sweetalert";
// import ToggleButton from "@mui/material/ToggleButton";
// import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
// import { useDispatch } from "react-redux";
// import { useNavigate } from "react-router-dom";
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// const Item = styled(Paper)(({ theme }) => ({
//   backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
//   ...theme.typography.body2,
//   padding: theme.spacing(1),
//   textAlign: "center",
//   color: theme.palette.text.secondary,
// }));

// const options = [
//   { value: "A", label: "A" },
//   { value: "B", label: "B" },
//   { value: "C", label: "C" },
//   { value: "D", label: "D" },
//   { value: "E", label: "E" },
// ];
// const Quizz = (props) => {
//   const navigate = useNavigate();

//   const dispatch = useDispatch();
//   const [show, setShow] = useState(false);
//   const [typeAlerte, setTypeAlerte] = useState(false);
//   const [titleAlerte, setTitleAlerte] = useState(false);
//   const [texteAlerte, setTexteAlerte] = useState("");
//   const [name, setName] = useState("");

//   const [inputFields, setInputFields] = useState([
//     {
//       id: uuidv4(),
//       titreQuestion: "",
//       optionA: "",
//       optionB: "",
//       optionC: "",
//       optionD: "",
//       optionE: "",
//       choixMultiple: false,
//       choixCorrecte: [],
//     },
//   ]);

//   const [duration, setDuration] = useState({ min: "", sec: "" });

//   const handleInput = (event) => {
//     const { id, value } = event.target;
//     if (id === "duration-minutes") {
//       setDuration({ ...duration, min: value });
//     } else if (id === "duration-seconds") {
//       setDuration({ ...duration, sec: value });
//     }
//   };

  
//   const handleChangeChecked = (id) => {
//     const values = [...inputFields];
//     for (let index = 0; index < values.length; index++) {
//       if (values[index].id == id) {
//         values[index].choixMultiple = !values[index].choixMultiple;
//       }
//     }
//     setInputFields(values);
//   };
//   const idFormation = window.location.pathname.substring(
//     9,
//     window.location.pathname.length
//   );

//   const addQuiiz = async (array) => {
//     const response = await ajoutQuizzAPI(
//       {
//         ...array,
//         duration: duration,
//         name: name,
//       },
//       idFormation
//     );
//     await navigate(`/contenuformations/${idFormation}`);
//     if (props.type === "entre") {
//       await modifierFormationAPI({
//         _id: props._id,
//         quizzEntre: response.data.data._id,
//       });
//     }
//     if (props.type === "sortie") {
//       await modifierFormationAPI({
//         _id: props._id,
//         quizzSortie: response.data.data._id,
//       });
//     }
//     if (props.type === "evaluation") {
//       await modifierFormationAPI({
//         _id: props._id,
//         quizzEvaluation: response.data.data._id,
//       });
//     }
//   };

//   const sweetAlertValidation = (numeroQuestion, status) => {
//     if (status === "champs vides") {
//       setShow(true);
//       setTypeAlerte("error");
//       setTitleAlerte("verifier les champes du question numéro " + numeroQuestion);
//       setTexteAlerte(
//         "verifier les champes du question numéro " + numeroQuestion
//       );
//     }
//     if (status === "correcte manquant") {
//       setShow(true);
//       setTypeAlerte("error");
//       setTitleAlerte("verifier la réponse correcte du question " + numeroQuestion);
//       setTexteAlerte(
//         "selectionner au moin une reponse correcte au niveau du question numéro " +
//           numeroQuestion
//       );
//     }
//     if (status === "valide") {
//       setShow(true);
//       setTypeAlerte("success");
//       setTitleAlerte("Félicitations !");
//       setTexteAlerte("Ajout avec succées");
//     }
//   };

//   const handleAddQuestionButton = async () => {
//     if (!name) {
//       toast.error("Le titre du quiz est obligatoire.");
//       return;
//     }
  
//     if (!duration.min || !duration.sec) {
//       toast.error("La durée du quiz est obligatoire.");
//       return;
//     }
//     const arrayIdsQuestion = [];
//     for (let i = 0; i < inputFields.length; i++) {
//       const numeroQuestion = i + 1;
//       if (
//         inputFields[i].titreQuestion === "" ||
//         inputFields[i].optionA === "" ||
//         inputFields[i].optionB === ""
//       ) {
//         sweetAlertValidation(numeroQuestion, "champs vides");
//         break;
//       } else if (inputFields[i].choixCorrecte.length == 0) {
//         sweetAlertValidation(numeroQuestion, "correcte manquant");
//         break;
//       } else {
//         const response = await ajoutQuestionAPI(inputFields[i]);
//         arrayIdsQuestion.push(response.data.data._id);
//       }
//       if (i + 1 == inputFields.length) {
//         dispatch(updateArrayQuestions(arrayIdsQuestion));
//         await addQuiiz({
//           questions: arrayIdsQuestion,
//           duration: duration,
//           name: name,
//         });
//         sweetAlertValidation(numeroQuestion, "valide");
//       }
//     }
//   };

//   const handleChange = (event) => {
//     setName(event.target.value);
//   };
//   const handleChangeInput = (id, event) => {
//     const newInputFields = inputFields.map((i) => {
//       if (id === i.id) {
//         i[event.target.name] = event.target.value;
//       }

//       return i;
//     });

//     setInputFields(newInputFields);
//   };
//   const handleChangeSelectMultiple = (e, id) => {
//     const values = [...inputFields];
//     for (let index = 0; index < values.length; index++) {
//       if (values[index].id == id) {
//         values[index].choixCorrecte = e;
//       }
//     }
//     setInputFields(values);
//   };
//   const handleChangeSelectSingle = (e, id) => {
//     const array = [];
//     array.push(e.value);
//     const values = [...inputFields];
//     for (let index = 0; index < values.length; index++) {
//       if (values[index].id == id) {
//         values[index].choixCorrecte = array;
//       }
//     }
//     setInputFields(values);
//   };
//   const handleAddQuestion = () => {
//     setInputFields([
//       ...inputFields,
//       {
//         id: uuidv4(),
//         titreQuestion: "",
//         optionA: "",
//         optionB: "",
//         optionC: "",
//         optionD: "",
//         optionE: "",
//         choixMultiple: false,
//         choixCorrecte: [],
//       },
//     ]);
//   };

//   const handleRemoveQuestion = (id) => {
//     const values = [...inputFields];
//     values.splice(
//       values.findIndex((value) => value.id == id),
//       1
//     );
//     setInputFields(values);
//   };
//   return (
//     <>
//       <Row>
//         <Col md="8">
//           <Container>
//             <Card>
//               <CardBody>
//                 <Grid
//                   container
//                   direction="row"
//                   justifyContent="flex-start"
//                   alignItems="center"
//                 >
//                   {" "}
//                   <Grid item xs={7}>
//                     <label for="titreQuestion">Titre: </label>
//                   </Grid>
//                   <Grid item xs={5}>
//                     <label style={{paddingLeft: "70px"
//                     }} htmlFor="duration-minutes">Durée: </label>
//                   </Grid>
//                 </Grid>
//                 <Grid container>
//                   <Grid item xs={7}>
//                     <Item>
//                       <h2>
//                         <TextField
//                           fullWidth
//                           required
//                           onChange={handleChange}
//                           type="text"
//                           name="name"
//                           id="name"
//                           placeholder="titre quiz"
//                         />
//                       </h2>
//                     </Item>
//                   </Grid>
//                   <Grid item xs={5}>
//                     <Item>
//                       <h2>
//                         <TextField
//                           required
//                           onChange={handleInput}
//                           id="duration-minutes"
//                           type="number"
//                           max="60"
//                           placeholder="MM"
//                         />{" "}
//                         :
//                         <TextField
//                           required
//                           onChange={handleInput}
//                           id="duration-seconds"
//                           type="number"
//                           max="59"
//                           placeholder="SS"
//                         />
//                       </h2>
//                     </Item>
//                   </Grid>
//                 </Grid>

//                 {inputFields.map((inputField, index) => (
//                   <Card>
                   
//                     <CardBody>
//                       <div key={inputField.id}>
//                         <Row form style={{ marginTop: "20px" }}>
//                           <Grid
//                             container
//                             direction="row"
//                             justifyContent="flex-end"
//                             alignItems="center"
//                           >
                          
//                             <Grid item>
//                               <Item>
//                                 <LoadingButton
//                                   size="medium"
//                                   type="button"
//                                   variant="contained"
//                                   color="inherit"
//                                   disabled={inputFields.length === 1}
//                                   onClick={() =>
//                                     handleRemoveQuestion(inputField.id)
//                                   }
//                                 >
//                                   Supprimer Question
//                                 </LoadingButton>{" "}
//                               </Item>
//                             </Grid>
//                           </Grid>

//                           <br />
//                           <h3>
//                             Question <Badge color="info">{index + 1}</Badge>
//                           </h3>
//                           <Col md={8}>
//                             <FormGroup>
//                               <label for="titreQuestion">Titre Question</label>
//                               <br></br>
//                               <TextField
//                                 fullWidth
//                                 required
//                                 onChange={(event) =>
//                                   handleChangeInput(inputField.id, event)
//                                 }
//                                 type="text"
//                                 name="titreQuestion"
//                                 id="titreQuestion"
//                                 variant="outlined"
//                                 placeholder="votre question ici ..."
//                               />
//                             </FormGroup>
//                           </Col>
//                           <br />
//                           <Col md={8}>
//                             <div>
//                               <ToggleButtonGroup
//                                 fullWidth
//                                 size="small"
//                                 style={{
//                                   width: "100%",
//                                 }}
//                                 value={inputField.choixMultiple}
//                                 exclusive
//                                 onChange={() =>
//                                   handleChangeChecked(inputField.id)
//                                 }
//                               >
//                                 <ToggleButton
//                                   value={true}
//                                   style={{ width: "50%" }}
//                                 >
//                                   <span>Choix Multiple</span>{" "}
//                                 </ToggleButton>
//                                 <ToggleButton
//                                   value={false}
//                                   style={{ width: "50%" }}
//                                 >
//                                   <span>Choix Unique</span>
//                                 </ToggleButton>
//                               </ToggleButtonGroup>
//                             </div>
//                           </Col>
//                           <br />
//                         </Row>

//                         <Row form>
//                           <Col md={6}>
//                             <FormGroup>
//                               <label for="exampleAddress">Choix A</label>
//                               <TextField
//                                 onChange={(event) =>
//                                   handleChangeInput(inputField.id, event)
//                                 }
//                                 fullWidth
//                                 type="text"
//                                 name="optionA"
//                                 id="optionA"
//                                 placeholder="votre choix de réponce ici ..."
//                                 variant="outlined"
//                               />
//                             </FormGroup>
//                           </Col>
//                           <Col md={6}>
//                             <FormGroup>
//                               <label for="examplePassword">Choix B</label>
//                               <TextField
//                                 onChange={(event) =>
//                                   handleChangeInput(inputField.id, event)
//                                 }
//                                 fullWidth
//                                 type="text"
//                                 name="optionB"
//                                 id="optionB"
//                                 variant="outlined"
//                                 placeholder="votre choix de réponce ici ..."
//                               />
//                             </FormGroup>
//                           </Col>
//                         </Row>
//                         <Row form>
//                           <Col md={6}>
//                             <FormGroup>
//                               <label for="exampleAddress">Choix C</label>
//                               <TextField
//                                 onChange={(event) =>
//                                   handleChangeInput(inputField.id, event)
//                                 }
//                                 fullWidth
//                                 type="text"
//                                 name="optionC"
//                                 id="optionC"
//                                 variant="outlined"
//                                 placeholder="votre choix de réponce ici ..."
//                               />
//                             </FormGroup>
//                           </Col>
//                           <Col md={6}>
//                             <FormGroup>
//                               <label for="examplePassword">Choix D</label>
//                               <TextField
//                                 onChange={(event) =>
//                                   handleChangeInput(inputField.id, event)
//                                 }
//                                 fullWidth
//                                 type="text"
//                                 name="optionD"
//                                 id="optionD"
//                                 variant="outlined"
//                                 placeholder="votre choix de réponce ici ..."
//                               />
//                             </FormGroup>
//                           </Col>
//                         </Row>
//                         <Row form>
//                           <Col md={6}>
//                             <FormGroup>
//                               <label for="examplePassword">Choix E</label>
//                               <TextField
//                                 onChange={(event) =>
//                                   handleChangeInput(inputField.id, event)
//                                 }
//                                 fullWidth
//                                 type="text"
//                                 name="optionE"
//                                 id="optionE"
//                                 variant="outlined"
//                                 placeholder="votre choix de réponce ici ..."
//                               />
//                             </FormGroup>
//                           </Col>
//                           <br></br>
//                           <Col md={6}>
//                             {inputField.choixMultiple ? (
//                               <FormGroup>
//                                 <label for="examplePassword">
//                                   Choix correcte
//                                 </label>
//                                 <Multiselect
//                                   onChange={(e) =>
//                                     handleChangeSelectMultiple(e, inputField.id)
//                                   }
//                                   data={["A", "B", "C", "D", "E"]}
//                                 />
//                               </FormGroup>
//                             ) : (
//                               <FormGroup>
//                                 <label for="Choixcorrecte">
//                                   Choix correcte
//                                 </label>
//                                 <Select
//                                   placeholder="choisissez la correcte réponse ..."
//                                   onChange={(e) =>
//                                     handleChangeSelectSingle(e, inputField.id)
//                                   }
//                                   options={options}
//                                 />
//                               </FormGroup>
//                             )}
//                           </Col>
//                           <br></br>
//                         </Row>
//                         <Row form></Row>
//                         <Grid item>
//                               <Item>
//                                 <LoadingButton
//                                   size="medium"
//                                   type="button"
//                                   variant="contained"
//                                   color="info"
//                                   onClick={handleAddQuestion}
//                                 >
//                                   <i>+ </i>
//                                   Ajouter Question
//                                 </LoadingButton>{" "}
//                               </Item>{" "}
//                             </Grid>
//                       </div>
//                     </CardBody>
//                   </Card>
//                 ))}
//               </CardBody>
//               <br />
//               <Grid
//                 container
//                 direction="row"
//                 justifyContent="center"
//                 alignItems="center"
//               >
//                 <Grid item>
//                   <Item>
//                     <LoadingButton
//                       size="medium"
//                       type="button"
//                       variant="contained"
//                       loadingPosition="center"
//                       onClick={handleAddQuestionButton}

//                       style={{ paddingLeft: "80px", paddingRight: "80px" }}
//                     >
//                       <i className="pe-7s-notebook btn-icon-wrapper"> </i>
//                       Créér quiz
//                     </LoadingButton>
//                   </Item>
//                 </Grid>
//               </Grid>
//             </Card>
//           </Container>
//         </Col>
//         <Col md="2"></Col>
//       </Row>
//       <ToastContainer />

//       <SweetAlert
//         show={show}
//         title={titleAlerte}
//         text={texteAlerte}
//         type={typeAlerte}
//         onConfirm={async () => {
//           setShow(false);
//         }}
//       />
//     </>
//   );
// };
// export default Quizz;


import React, {  useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  Badge,
  Container,
} from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import { FormGroup } from "reactstrap";
import { TextField } from "@material-ui/core";
import { LoadingButton } from "@mui/lab";
import "react-widgets/styles.css";
import Grid from "@mui/material/Grid";
import Multiselect from "react-widgets/Multiselect";
import {
  ajoutQuestionAPI,
  ajoutQuizzAPI,
  modifierFormationAPI,
} from "../../utils/api";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { updateArrayQuestions } from "../../redux/QuizzSlice";
import Select from "react-select";
import SweetAlert from "react-bootstrap-sweetalert";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CKEditor } from "ckeditor4-react";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const options = [
  { value: "A", label: "A" },
  { value: "B", label: "B" },
  { value: "C", label: "C" },
  { value: "D", label: "D" },
  { value: "E", label: "E" },
];
const Quizz = (props) => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [typeAlerte, setTypeAlerte] = useState(false);
  const [titleAlerte, setTitleAlerte] = useState(false);
  const [texteAlerte, setTexteAlerte] = useState("");
  const [name, setName] = useState("");
  const [toggleType, setToggleType] = useState("type1"); // Added state for toggle button
  const [typeQuiz, setTypeQuiz] = useState("type1");
  const [descriptionA, setDescriptionA] = useState("");
  const [descriptionB, setDescriptionB] = useState("");
  const [descriptionC, setDescriptionC] = useState("");
  const [descriptionD, setDescriptionD] = useState("");
  const [descriptionE, setDescriptionE] = useState("");


  const handleToggleChange = (event, newType) => {
    if (newType !== null) {
      setToggleType(newType);
      setTypeQuiz(newType);

    }
  };

  const [inputFields, setInputFields] = useState([
    {
      id: uuidv4(),
      titreQuestion: "",
      optionA: "",
      optionB: "",
      optionC: "",
      optionD: "",
      optionE: "",
      choixMultiple: false,
      choixCorrecte: [],
    },
  ]);

  const [duration, setDuration] = useState({ min: "", sec: "" });

  const handleInput = (event) => {
    const { id, value } = event.target;
    if (id === "duration-minutes") {
      setDuration({ ...duration, min: value });
    } else if (id === "duration-seconds") {
      setDuration({ ...duration, sec: value });
    }
  };

  
  const handleChangeChecked = (id) => {
    const values = [...inputFields];
    for (let index = 0; index < values.length; index++) {
      if (values[index].id == id) {
        values[index].choixMultiple = !values[index].choixMultiple;
      }
    }
    setInputFields(values);
  };
  const idFormation = window.location.pathname.substring(
    9,
    window.location.pathname.length
  );

  const addQuiiz = async (array) => {
    const response = await ajoutQuizzAPI(
      {
        ...array,
        duration: duration,
        name: name,
        typeQuiz:typeQuiz,
        descriptionA:descriptionA,
        descriptionB:descriptionB,
        descriptionC:descriptionC,
        descriptionD:descriptionD,
        descriptionE:descriptionE,


      },
      idFormation
    );
    await navigate(`/contenuformations/${idFormation}`);
    if (props.type === "entre") {
      await modifierFormationAPI({
        _id: props._id,
        quizzEntre: response.data.data._id,
      });
    }
    if (props.type === "sortie") {
      await modifierFormationAPI({
        _id: props._id,
        quizzSortie: response.data.data._id,
      });
    }
    if (props.type === "evaluation") {
      await modifierFormationAPI({
        _id: props._id,
        quizzEvaluation: response.data.data._id,
      });
    }
  };

  const sweetAlertValidation = (numeroQuestion, status) => {
    if (status === "champs vides") {
      setShow(true);
      setTypeAlerte("error");
      setTitleAlerte("verifier les champes du question numéro " + numeroQuestion);
      setTexteAlerte(
        "verifier les champes du question numéro " + numeroQuestion
      );
    }
   
    if (status === "valide") {
      setShow(true);
      setTypeAlerte("success");
      setTitleAlerte("Félicitations !");
      setTexteAlerte("Ajout avec succées");
    }
  };

  const handleAddQuestionButton = async () => {
    if (!name) {
      toast.error("Le titre du quiz est obligatoire.");
      return;
    }
  
    if (!duration.min || !duration.sec) {
      toast.error("La durée du quiz est obligatoire.");
      return;
    }
    const arrayIdsQuestion = [];
    for (let i = 0; i < inputFields.length; i++) {
      const numeroQuestion = i + 1;
      if (
        inputFields[i].titreQuestion === "" ||
        inputFields[i].optionA === "" ||
        inputFields[i].optionB === ""
      ) {
        sweetAlertValidation(numeroQuestion, "champs vides");
        break;
      } 
      // else if (inputFields[i].choixCorrecte.length == 0) {
      //   sweetAlertValidation(numeroQuestion, "correcte manquant");
      //   break;
      // }
       else {
        const response = await ajoutQuestionAPI(inputFields[i]);
        arrayIdsQuestion.push(response.data.data._id);
      }
      if (i + 1 == inputFields.length) {
        dispatch(updateArrayQuestions(arrayIdsQuestion));
        await addQuiiz({
          questions: arrayIdsQuestion,
          duration: duration,
          name: name,
          typeQuiz:typeQuiz,
        descriptionA:descriptionA,
        descriptionB:descriptionB,
        descriptionC:descriptionC,
        descriptionD:descriptionD,
        descriptionE:descriptionE,


        });
        sweetAlertValidation(numeroQuestion, "valide");
      }
    }
  };

  const handleChange = (event) => {
    setName(event.target.value);
  };
  const handleChangeDescriptionA = (event) => {
    let data = event.editor.getData();

    setDescriptionA(data);  
  };
  const handleChangeDescriptionB = (event) => {
    let data = event.editor.getData();

    setDescriptionB(data); 
   };
  const handleChangeDescriptionC = (event) => {
    let data = event.editor.getData();

    setDescriptionC(data);
  };
  const handleChangeDescriptionD = (event) => {
    let data = event.editor.getData();

    setDescriptionD(data);
  };
  const handleChangeDescriptionE = (event) => {
    let data = event.editor.getData();

    setDescriptionE(data);
  };
  const handleChangeInput = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
      }

      return i;
    });

    setInputFields(newInputFields);
  };
  const handleChangeSelectMultiple = (e, id) => {
    const values = [...inputFields];
    for (let index = 0; index < values.length; index++) {
      if (values[index].id == id) {
        values[index].choixCorrecte = e;
      }
    }
    setInputFields(values);
  };
  const handleChangeSelectSingle = (e, id) => {
    const array = [];
    array.push(e.value);
    const values = [...inputFields];
    for (let index = 0; index < values.length; index++) {
      if (values[index].id == id) {
        values[index].choixCorrecte = array;
      }
    }
    setInputFields(values);
  };
  const handleAddQuestion = () => {
    setInputFields([
      ...inputFields,
      {
        id: uuidv4(),
        titreQuestion: "",
        optionA: "",
        optionB: "",
        optionC: "",
        optionD: "",
        optionE: "",
        choixMultiple: false,
        choixCorrecte: [],
      },
    ]);
  };

  const handleRemoveQuestion = (id) => {
    const values = [...inputFields];
    values.splice(
      values.findIndex((value) => value.id == id),
      1
    );
    setInputFields(values);
  };
  return (
    <>
      <Row>
        <Col md="8">
          <Container>
            <Card>
            <Col md="2">
          <ToggleButtonGroup
            value={toggleType}
            exclusive
            onChange={handleToggleChange}
            aria-label="toggle type"
          >
            <ToggleButton value="type1">Simple Quizz</ToggleButton>
            <ToggleButton value="type2">Complex Quizz</ToggleButton>
          </ToggleButtonGroup>
        </Col>
        <br></br>
        {toggleType === "type1" && (
         <CardBody>
         <Grid
           container
           direction="row"
           justifyContent="flex-start"
           alignItems="center"
         >
           {" "}
           <Grid item xs={7}>
             <label for="titreQuestion">Titre: </label>
           </Grid>
           <Grid item xs={5}>
             <label style={{paddingLeft: "70px"
             }} htmlFor="duration-minutes">Durée: </label>
           </Grid>
         </Grid>
         <Grid container>
           <Grid item xs={7}>
             <Item>
               <h2>
                 <TextField
                   fullWidth
                   required
                   onChange={handleChange}
                   type="text"
                   name="name"
                   id="name"
                   placeholder="titre quiz"
                 />
               </h2>
             </Item>
           </Grid>
           <Grid item xs={5}>
             <Item>
               <h2>
                 <TextField
                   required
                   onChange={handleInput}
                   id="duration-minutes"
                   type="number"
                   max="60"
                   placeholder="MM"
                 />{" "}
                 :
                 <TextField
                   required
                   onChange={handleInput}
                   id="duration-seconds"
                   type="number"
                   max="59"
                   placeholder="SS"
                 />
               </h2>
             </Item>
           </Grid>
         </Grid>

         {inputFields.map((inputField, index) => (
           <Card>
            
             <CardBody>
               <div key={inputField.id}>
                 <Row form style={{ marginTop: "20px" }}>
                   <Grid
                     container
                     direction="row"
                     justifyContent="flex-end"
                     alignItems="center"
                   >
                   
                     <Grid item>
                       <Item>
                         <LoadingButton
                           size="medium"
                           type="button"
                           variant="contained"
                           color="inherit"
                           disabled={inputFields.length === 1}
                           onClick={() =>
                             handleRemoveQuestion(inputField.id)
                           }
                         >
                           Supprimer Question
                         </LoadingButton>{" "}
                       </Item>
                     </Grid>
                   </Grid>

                   <br />
                   <h3>
                     Question <Badge color="info">{index + 1}</Badge>
                   </h3>
                   <Col md={8}>
                     <FormGroup>
                       <label for="titreQuestion">Titre Question</label>
                       <br></br>
                       <TextField
                         fullWidth
                         required
                         onChange={(event) =>
                           handleChangeInput(inputField.id, event)
                         }
                         type="text"
                         name="titreQuestion"
                         id="titreQuestion"
                         variant="outlined"
                         placeholder="votre question ici ..."
                       />
                     </FormGroup>
                   </Col>
                   <br />
                   <Col md={8}>
                     <div>
                       <ToggleButtonGroup
                         fullWidth
                         size="small"
                         style={{
                           width: "100%",
                         }}
                         value={inputField.choixMultiple}
                         exclusive
                         onChange={() =>
                           handleChangeChecked(inputField.id)
                         }
                       >
                         <ToggleButton
                           value={true}
                           style={{ width: "50%" }}
                         >
                           <span>Choix Multiple</span>{" "}
                         </ToggleButton>
                         <ToggleButton
                           value={false}
                           style={{ width: "50%" }}
                         >
                           <span>Choix Unique</span>
                         </ToggleButton>
                       </ToggleButtonGroup>
                     </div>
                   </Col>
                   <br />
                 </Row>

                 <Row form>
                   <Col md={6}>
                     <FormGroup>
                       <label for="exampleAddress">Choix A</label>
                       <TextField
                         onChange={(event) =>
                           handleChangeInput(inputField.id, event)
                         }
                         fullWidth
                         type="text"
                         name="optionA"
                         id="optionA"
                         placeholder="votre choix de réponce ici ..."
                         variant="outlined"
                       />
                     </FormGroup>
                   </Col>
                   <Col md={6}>
                     <FormGroup>
                       <label for="examplePassword">Choix B</label>
                       <TextField
                         onChange={(event) =>
                           handleChangeInput(inputField.id, event)
                         }
                         fullWidth
                         type="text"
                         name="optionB"
                         id="optionB"
                         variant="outlined"
                         placeholder="votre choix de réponce ici ..."
                       />
                     </FormGroup>
                   </Col>
                 </Row>
                 <Row form>
                   <Col md={6}>
                     <FormGroup>
                       <label for="exampleAddress">Choix C</label>
                       <TextField
                         onChange={(event) =>
                           handleChangeInput(inputField.id, event)
                         }
                         fullWidth
                         type="text"
                         name="optionC"
                         id="optionC"
                         variant="outlined"
                         placeholder="votre choix de réponce ici ..."
                       />
                     </FormGroup>
                   </Col>
                   <Col md={6}>
                     <FormGroup>
                       <label for="examplePassword">Choix D</label>
                       <TextField
                         onChange={(event) =>
                           handleChangeInput(inputField.id, event)
                         }
                         fullWidth
                         type="text"
                         name="optionD"
                         id="optionD"
                         variant="outlined"
                         placeholder="votre choix de réponce ici ..."
                       />
                     </FormGroup>
                   </Col>
                 </Row>
                 <Row form>
                   <Col md={6}>
                     <FormGroup>
                       <label for="examplePassword">Choix E</label>
                       <TextField
                         onChange={(event) =>
                           handleChangeInput(inputField.id, event)
                         }
                         fullWidth
                         type="text"
                         name="optionE"
                         id="optionE"
                         variant="outlined"
                         placeholder="votre choix de réponce ici ..."
                       />
                     </FormGroup>
                   </Col>
                   <br></br>
                   <Col md={6}>
                     {inputField.choixMultiple ? (
                       <FormGroup>
                         <label for="examplePassword">
                           Choix correcte
                         </label>
                         <Multiselect
                           onChange={(e) =>
                             handleChangeSelectMultiple(e, inputField.id)
                           }
                           data={["A", "B", "C", "D", "E"]}
                         />
                       </FormGroup>
                     ) : (
                       <FormGroup>
                         <label for="Choixcorrecte">
                           Choix correcte
                         </label>
                         <Select
                           placeholder="choisissez la correcte réponse ..."
                           onChange={(e) =>
                             handleChangeSelectSingle(e, inputField.id)
                           }
                           options={options}
                         />
                       </FormGroup>
                     )}
                   </Col>
                   <br></br>
                 </Row>
                 <Row form></Row>
                 <Grid item>
                       <Item>
                         <LoadingButton
                           size="medium"
                           type="button"
                           variant="contained"
                           color="info"
                           onClick={handleAddQuestion}
                         >
                           <i>+ </i>
                           Ajouter Question
                         </LoadingButton>{" "}
                       </Item>{" "}
                     </Grid>
               </div>
             </CardBody>
           </Card>
         ))}
       </CardBody>
      )}
      {toggleType === "type2" && (
        <CardBody>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          {" "}
          <Grid item xs={7}>
            <label for="titreQuestion">Titre: </label>
          </Grid>
          <Grid item xs={5}>
            <label style={{paddingLeft: "70px"
            }} htmlFor="duration-minutes">Durée: </label>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={7}>
            <Item>
              <h2>
                <TextField
                  fullWidth
                  required
                  onChange={handleChange}
                  type="text"
                  name="name"
                  id="name"
                  placeholder="titre quiz"
                />
              </h2>
            </Item>
          </Grid>
          <Grid item xs={5}>
            <Item>
              <h2>
                <TextField
                  required
                  onChange={handleInput}
                  id="duration-minutes"
                  type="number"
                  max="60"
                  placeholder="MM"
                />{" "}
                :
                <TextField
                  required
                  onChange={handleInput}
                  id="duration-seconds"
                  type="number"
                  max="59"
                  placeholder="SS"
                />
              </h2>
            </Item>
          </Grid>
        </Grid>

        {inputFields.map((inputField, index) => (
          <Card>
           
            <CardBody>
              <div key={inputField.id}>
                <Row form style={{ marginTop: "20px" }}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                  >
                  
                    <Grid item>
                      <Item>
                        <LoadingButton
                          size="medium"
                          type="button"
                          variant="contained"
                          color="inherit"
                          disabled={inputFields.length === 1}
                          onClick={() =>
                            handleRemoveQuestion(inputField.id)
                          }
                        >
                          Supprimer Question
                        </LoadingButton>{" "}
                      </Item>
                    </Grid>
                  </Grid>

                  <br />
                  <h3>
                    Question <Badge color="info">{index + 1}</Badge>
                  </h3>
                  <Col md={8}>
                    <FormGroup>
                      <label for="titreQuestion">Titre Question</label>
                      <br></br>
                      <TextField
                        fullWidth
                        required
                        onChange={(event) =>
                          handleChangeInput(inputField.id, event)
                        }
                        type="text"
                        name="titreQuestion"
                        id="titreQuestion"
                        variant="outlined"
                        placeholder="votre question ici ..."
                      />
                    </FormGroup>
                  </Col>
                  <br />
                
                </Row>

                <Row form>
                  <Col md={6}>
                    <FormGroup>
                      <label for="exampleAddress">Choix A</label>
                      <TextField
                        onChange={(event) =>
                          handleChangeInput(inputField.id, event)
                        }
                        fullWidth
                        type="text"
                        name="optionA"
                        id="optionA"
                        placeholder="votre choix de réponce ici ..."
                        variant="outlined"
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <label for="examplePassword">Choix B</label>
                      <TextField
                        onChange={(event) =>
                          handleChangeInput(inputField.id, event)
                        }
                        fullWidth
                        type="text"
                        name="optionB"
                        id="optionB"
                        variant="outlined"
                        placeholder="votre choix de réponce ici ..."
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row form>
                  <Col md={6}>
                    <FormGroup>
                      <label for="exampleAddress">Choix C</label>
                      <TextField
                        onChange={(event) =>
                          handleChangeInput(inputField.id, event)
                        }
                        fullWidth
                        type="text"
                        name="optionC"
                        id="optionC"
                        variant="outlined"
                        placeholder="votre choix de réponce ici ..."
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <label for="examplePassword">Choix D</label>
                      <TextField
                        onChange={(event) =>
                          handleChangeInput(inputField.id, event)
                        }
                        fullWidth
                        type="text"
                        name="optionD"
                        id="optionD"
                        variant="outlined"
                        placeholder="votre choix de réponce ici ..."
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row form>
                  <Col md={6}>
                    <FormGroup>
                      <label for="examplePassword">Choix E</label>
                      <TextField
                        onChange={(event) =>
                          handleChangeInput(inputField.id, event)
                        }
                        fullWidth
                        type="text"
                        name="optionE"
                        id="optionE"
                        variant="outlined"
                        placeholder="votre choix de réponce ici ..."
                      />
                    </FormGroup>
                  </Col>
                 
                </Row>
                <Row form></Row>
                <Grid item>
                      <Item>
                        <LoadingButton
                          size="medium"
                          type="button"
                          variant="contained"
                          color="info"
                          onClick={handleAddQuestion}
                        >
                          <i>+ </i>
                          Ajouter Question
                        </LoadingButton>{" "}
                      </Item>{" "}
                    </Grid>
                  
              </div>
              
            </CardBody>
          </Card>
        ))}
        <br></br>
          <Col md={12} className="mb-3">
                <FormGroup>
                  <label>Rapport A</label>
                  <CKEditor initData={descriptionA} onChange={handleChangeDescriptionA} />
                </FormGroup>
              </Col>
              <br></br>
              <Col md={12} className="mb-3">
                <FormGroup>
                  <label>Rapport B</label>
                  <CKEditor initData={descriptionB} onChange={handleChangeDescriptionB} />
                </FormGroup>
              </Col>
              <br></br>
              <Col md={12} className="mb-3">
                <FormGroup>
                  <label>Rapport C</label>
                  <CKEditor initData={descriptionC} onChange={handleChangeDescriptionC} />
                </FormGroup>
              </Col>
              <br></br>
              <Col md={12} className="mb-3">
                <FormGroup>
                  <label>Rapport D</label>
                  <CKEditor initData={descriptionD} onChange={handleChangeDescriptionD} />
                </FormGroup>
              </Col>

              <Col md={12} className="mb-3">
                <FormGroup>
                  <label>Rapport E</label>
                  <CKEditor initData={descriptionE} onChange={handleChangeDescriptionE} />
                </FormGroup>
              </Col>
        
      </CardBody>
      )}
              <br />
           
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item>
                  <Item>
                    <LoadingButton
                      size="medium"
                      type="button"
                      variant="contained"
                      loadingPosition="center"
                      onClick={handleAddQuestionButton}

                      style={{ paddingLeft: "80px", paddingRight: "80px" }}
                    >
                      <i className="pe-7s-notebook btn-icon-wrapper"> </i>
                      Créér quiz
                    </LoadingButton>
                  </Item>
                </Grid>
              </Grid>
            </Card>
          </Container>
        </Col>
        <Col md="2"></Col>
      </Row>
      <ToastContainer />

      <SweetAlert
        show={show}
        title={titleAlerte}
        text={texteAlerte}
        type={typeAlerte}
        onConfirm={async () => {
          setShow(false);
        }}
      />
    </>
  );
};
export default Quizz;


