import { merge } from "lodash";
import ReactApexChart from "react-apexcharts";

import { Box, Card, CardHeader } from "@mui/material";

import { fNumber } from "../../../utils/formatNumber";

import { BaseOptionChart } from "../../charts";
import { useEffect, useState } from "react";
import axios from "axios";


export default function AppConversionRates() {
  const [makingApiCall, setMakingApiCall] = useState(true);
  const [fetchedData, setFetchedData] = useState([]);

  useEffect(() => {
    let mount = true;
    if (mount) {
      setMakingApiCall(true);
      axios
        .get(process.env.REACT_APP_URL_BACKEND_DEV + "/ageRange")
        .then((response) => {
          setFetchedData(response.data.lengthArray);
        })
        .finally(() => {
          setMakingApiCall(false);
        });
    }
    return () => (mount = false);
  }, []);

  const CHART_DATA = [{ data: fetchedData.map((f) => f?.number) }];

  const chartOptions = merge(BaseOptionChart(), {
    tooltip: {
      marker: { show: false },

      y: {
        formatter: (seriesName) => fNumber(seriesName),
        title: {
          formatter: () => "Number: ", 
        },
        value: {
          formatter: (val) => val.toFixed(2), 
        },
      },
    
    },
    plotOptions: {
      bar: { horizontal: true, barHeight: "28%", borderRadius: 2 },
    },
    xaxis: {
      categories: fetchedData.map((f) => f.titre),
    },
  });


  return (
    <Card>
      <CardHeader title="Nombre des inscrits par tranche d'âge" subheader="" />
      <Box sx={{ mx: 3 }} dir="ltr">
        {!makingApiCall && (
          <ReactApexChart
            type="bar"
            series={CHART_DATA}
            options={chartOptions}
            height={392}
          />
        )}
      </Box>
    </Card>
  );
}