import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import {
  AjouterLeconAPI,
  ModifierLeconApi,
  getLeconByIdAPI,
  disableLeconAPI,
  restoreLeconAPI,
} from "../utils/api";

export const toggleCoursState = createAsyncThunk(
  "cours/toggleCoursState",
  async (cours) => {
    if (cours.deleted) {
      try {
        await restoreLeconAPI(cours._id);
        return false;
      } catch (err) {
      }
    } else {
      try {
        await disableLeconAPI(cours._id);
        return true;
      } catch (err) {
      }
    }
  }
);
export const createCours = createAsyncThunk(
  "cours/createCours",
  async (cours) => {
    try {
      const response = await AjouterLeconAPI(cours);
      return response.data.result;
    } catch (err) {
    }
  }
);
export const selectCours = createAsyncThunk(
  "cours/selectCours",
  async (cours) => {
    if (!cours) return null;
    try {
      const response = await getLeconByIdAPI(cours._id);
      return response.data.result;
    } catch (err) {
    }
  }
);
export const updateCours = createAsyncThunk(
  "cours/updateCours",
  async (cours) => {
    let listWithOrder = cours.list.map((item, idx) => ({
      ...item,
      ordre: idx,
    }));
    let lecon = {
      _id: cours._id,
      contenueGraphique: listWithOrder
        .filter((item) => item.type === "video")
        .map((item) => {
          delete item._id;
          return item;
        }),
      texte: listWithOrder
        .filter((item) => item.type === "text")
        .map((item) => {
          delete item._id;
          return item;
        }),
      documents: listWithOrder
        .filter((item) => item.type === "document")
        .map((item) => {
          delete item._id;
          return item;
        }),
      quizzes: listWithOrder
        .filter((item) => item.type === "quizz")
        .map((item) => {
          delete item._id;
          return item;
        }),
    };
    try {
      const response = await ModifierLeconApi(lecon);
      return response.data.result;
    } catch (err) {
    }
  }
);
const initialState = {
  coursSelected: null,
  coursSelectedList: [],
  coursItemSelected: null,
  view: null,
};

export const coursSlice = createSlice({
  name: "cours",
  initialState,
  reducers: {
    setView(state, action) {
      if (action.payload) {
        state.view = action.payload.type;
        state.coursItemSelected = action.payload._id ? action.payload : null;
      } else {
        state.view = null;
        state.coursItemSelected = null;
      }
    },

    updateCoursSelectedList(state, action) {
      state.coursSelectedList = action.payload;
    },
  },
  extraReducers: {
    [toggleCoursState.fulfilled]: (state, action) => {
      state.coursSelected.deleted = action.payload;
    },
    [selectCours.fulfilled]: (state, action) => {
      let cours = action.payload;
      state.coursItemSelected = null;
      state.view = null;
      state.coursSelected = cours;
      if (cours)
        state.coursSelectedList = [
          ...cours.contenueGraphique.map((d) => ({ ...d, type: "video" })),
          ...cours.texte.map((d) => ({ ...d, type: "text" })),
          ...cours.documents.map((d) => ({ ...d, type: "document" })),
          ...cours.quizzes.map((d) => ({ ...d, type: "quizz" })),
        ].sort((a, b) => a.ordre - b.ordre);
      else state.coursSelectedList = [];
    },
  },
});
export const { updateCoursSelectedList, setView } = coursSlice.actions;

export default coursSlice.reducer;
