import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import { Stack, TextField, IconButton, InputAdornment } from '@mui/material';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { LoadingButton } from '@mui/lab';
import { useFormik } from 'formik';
import * as yup from 'yup';
// import Page from '../../Page';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FileUploader from "../../assets/FileUploader";
import Page from "../../components/Page";
import Cookies from 'js-cookie';



export default function ImportFile({handleClose, selectedFormation, selectedDept,getApprenants}) {
    const [file, setFile] = useState(null);
    const token= Cookies.get("tokenAdmin")

    const handleFileUpload = async () => {
        try {

            const formData = new FormData();
            formData.append('file', file);
            formData.append('formationId', selectedFormation.value); // Add selected formationId
            // formData.append('departementId', selectedDept.value); // Add selected departementId

            const response = await axios.post(process.env.REACT_APP_URL_BACKEND_DEV + '/registerFromExcel', formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                withCredentials: true,
            });
            console.log("respooonse", response)

            if (response.status === 200) {
                toast.success('Fichier importé avec succès ! Redondance d\'emails supprimée.', {
                  position: 'top-right',
                  autoClose: 3000, // Close the toast after 3000 milliseconds (3 seconds)
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
                getApprenants()
                setTimeout(() => {
                    handleClose();
                  }, 1000);
              }
               else 
               
               {
                // Handle error response
                toast.error('Erreur lors de l\'importation du fichier. Veuillez réessayer.', {
                  position: 'top-right',
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
                setTimeout(() => {
                    handleClose();
                  }, 1000);
              }
        } catch (error) {
            console.error('Error:', error.response); // Log the error response
        }
    };

    return (
        <Page title="Dashboard | Fastuz">

            <div>

                <ToastContainer />

                <form>
                    <Stack spacing={3}>
                        <Stack
                            direction={{ xs: 'column', sm: 'row' }}
                            spacing={2}
                            alignItems="center"  // Center the Stack horizontally
                            justifyContent="center"  // Center the Stack vertically
                        >                            <FileUploader
                            fileTypes={[
                                'application/vnd.ms-excel', // for .xls files
                                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // for .xlsx files
                            ]}
                            setFileToUpload={setFile}
                            title="file"
                            original=""

                        ></FileUploader>
                        </Stack>






                        <LoadingButton
                            fullWidth
                            size="large"
                            type="button"
                            // disabled={!formik.isValid}
                            variant="contained"
                            onClick={handleFileUpload} // Trigger the file upload function

                        >
                            Importer le fichier Excel
                        </LoadingButton>
                    </Stack>
                </form>

            </div>

        </Page>

    );
};