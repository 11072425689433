// import { filter } from 'lodash';
// import { Icon } from '@iconify/react';
// import { useState, useEffect } from 'react';
// import plusFill from '@iconify/icons-eva/plus-fill';
// import axios from "axios"
// import {
//   Card,
//   Table,
//   Stack,
//   Button,
//   Checkbox,
//   TableRow,
//   TableBody,
//   TableCell,
//   Container,
//   Typography,
//   TableContainer,
//   TablePagination
// } from '@mui/material';
// import Dialog from '@mui/material/Dialog';
// import DialogContent from '@mui/material/DialogContent';
// import DialogTitle from '@mui/material/DialogTitle';
// import Page from '../../components/Page';
// import Scrollbar from '../../components/Scrollbar';
// import SearchNotFound from '../../components/SearchNotFound';
// import { UserListHead, UserListToolbar } from '../../components/_dashboard/user';
// import RegisterBusiness from '../../components/authentication/register/RegisterBusiness';



// const TABLE_HEAD = [
//   { id: 'nom', label: 'Nom', alignRight: false },
//   { id: 'prenom', label: 'Prenom', alignRight: false },
//   { id: 'email', label: 'Email', alignRight: false },

//   { id: '' }
// ];


// function descendingComparator(a, b, orderBy) {
//   if (b[orderBy] < a[orderBy]) {
//     return -1;
//   }
//   if (b[orderBy] > a[orderBy]) {
//     return 1;
//   }
//   return 0;
// }

// function getComparator(order, orderBy) {
//   return order === 'desc'
//     ? (a, b) => descendingComparator(a, b, orderBy)
//     : (a, b) => -descendingComparator(a, b, orderBy);
// }

// function applySortFilter(array, comparator, query) {
//   const stabilizedThis = array?.map((el, index) => [el, index]);
//   stabilizedThis?.sort((a, b) => {
//     const order = comparator(a[0], b[0]);
//     if (order !== 0) return order;
//     return a[1] - b[1];
//   });
//   if (query) {
//     return filter(array, (_user) => _user.nom.toLowerCase().indexOf(query.toLowerCase()) !== -1);
//   }
//   return stabilizedThis?.map((el) => el[0]);
// }

// export default function User() {


//   let [modal, setModal] = useState(false);

//   const [page, setPage] = useState(0);
//   const [order, setOrder] = useState('asc');
//   const [selected, setSelected] = useState([]);
//   const [orderBy, setOrderBy] = useState('nom');
//   const [filterName, setFilterName] = useState('');
//   const [rowsPerPage, setRowsPerPage] = useState(5);
//   const [formateurs, setformateurs] = useState([]);

//   const [loading, setLoading] = useState(false);
//   const [callback, setCallback] = useState(false);
//   const [result, setResult] = useState(0);
//   const [modalVoucher, setModalVoucher] = useState(false);
//   const [selectedStudent, setselectedStudent] = useState("");
//   const [open, setOpen] = useState(false);

//   const handleClickOpen = () => {
//     setOpen(true);
//   };

//   const handleClose = () => {
//     setOpen(false);
//   };
 
//   const getUsers = async () => {
//     setLoading(true);
//     const res = await axios.get(
//       process.env.REACT_APP_URL_BACKEND_DEV +`/business`
//     );
//     setformateurs(res.data.formateurs);
//     setResult(formateurs);
//     setLoading(false);
//   };

//   useEffect(() => {
//     getUsers();

//   }, [callback, page]);
//   const handleDeleteUsers = async () => {
//     setLoading(true);
//     try {
//       await axios.delete(process.env.REACT_APP_URL_BACKEND_DEV + '/formateurs/deleteall', { data: { userIds: selected } });
//       setSelected([]);
//     } catch (error) {
//       console.error(error);
//     } finally {
//        getUsers()
//       setLoading(false);
//     }
//   }; 

//   const handleRequestSort = (event, property) => {
//     const isAsc = orderBy === property && order === 'asc';
//     setOrder(isAsc ? 'desc' : 'asc');
//     setOrderBy(property);
//   };

//   const handleSelectAllClick = (event) => {
//     if (event.target.checked) {
//       const newSelecteds = formateurs?.map((n) => n._id);
//       setSelected(newSelecteds);
//       return;
//     }
//     setSelected([]);
//   };
//   const handleClick = (event, nom) => {
//     const selectedIndex = selected.indexOf(nom);
//     let newSelected = [];
//     if (selectedIndex === -1) {
//       newSelected = newSelected.concat(selected, nom);
//     } else if (selectedIndex === 0) {
//       newSelected = newSelected.concat(selected.slice(1));
//     } else if (selectedIndex === selected?.length - 1) {
//       newSelected = newSelected.concat(selected.slice(0, -1));
//     } else if (selectedIndex > 0) {
//       newSelected = newSelected.concat(
//         selected.slice(0, selectedIndex),
//         selected.slice(selectedIndex + 1)
//       );
//     }
//     setSelected(newSelected);
//   };


//   const handleChangePage = (event, newPage) => {
//     setPage(newPage);
//   };

//   const handleChangeRowsPerPage = (event) => {
//     setRowsPerPage(parseInt(event.target.value, 5));
//     setPage(0);
//   };

//   const handleFilterByName = (event) => {
//     setFilterName(event.target.value);
//   };

//   const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - formateurs?.length) : 0;

//   const filteredUsers = applySortFilter(formateurs, getComparator(order, orderBy), filterName);

//   const isUserNotFound = filteredUsers?.length === 0;
//   return (
//     <Page title="Dashboard | Fastuz">
//       <Container>
//         <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
//           <Typography variant="h4" gutterBottom>
//             Business
//           </Typography>
//           <Button
//             variant="contained"
//             startIcon={<Icon icon={plusFill} />}
//             onClick={handleClickOpen}          >
//             Ajouter business
//           </Button>
        
//         </Stack>

//         <Card>
//           <UserListToolbar
//             numSelected={selected?.length}
//             filterName={filterName}
//             handleDeleteUsers= {handleDeleteUsers}
//             onFilterName={handleFilterByName}
//           />

//           <Scrollbar>
//             <TableContainer sx={{ minWidth: 800 }}>
//               <Table>
//                 <UserListHead
//                   order={order}
//                   orderBy={orderBy}
//                   headLabel={TABLE_HEAD}
//                   rowCount={formateurs?.length}
//                   numSelected={selected?.length}
//                   onRequestSort={handleRequestSort}
//                   onSelectAllClick={handleSelectAllClick}
//                 />
//                 <TableBody>
//                   {filteredUsers
//                     ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
//                     ?.map((row) => {
//                       const { _id, nom, prenom, email,FormationEnrolled,  Actions} = row;
//                       const isItemSelected = selected.indexOf(_id) !== -1;
                 
//                       return (
                       
//                            <TableRow
//                            hover
//                            key={_id}
//                            tabIndex={-1}
//                            role="checkbox"
//                            selected={isItemSelected}
//                            aria-checked={isItemSelected}
//                          >
//                            <TableCell padding="checkbox">
//                              <Checkbox
//                                checked={isItemSelected}
//                                onChange={(event) => handleClick(event, _id)}
//                              />
//                            </TableCell>
                         
//                            <TableCell component="th" scope="row" padding="none">
//                              <Stack direction="row" alignItems="center" spacing={2}>
//                                <Typography variant="subtitle2" noWrap>
//                                  {nom}
//                                </Typography>
//                              </Stack>
//                            </TableCell>
//                            <TableCell align="left">{prenom}</TableCell>
//                            <TableCell align="left">{email}</TableCell>
 
                      
                         
 
                        
//                          </TableRow>
                     
                      
//                       );
//                     })}
//                   {emptyRows > 0 && (
//                     <TableRow style={{ height: 53 * emptyRows }}>
//                       <TableCell colSpan={6} />
//                     </TableRow>
//                   )}
//                 </TableBody>
//                 {isUserNotFound && (
//                   <TableBody>
//                     <TableRow>
//                       <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
//                         <SearchNotFound searchQuery={filterName} />
//                       </TableCell>
//                     </TableRow>
//                   </TableBody>
//                 )}
//               </Table>
//             </TableContainer>
//           </Scrollbar>

//           <TablePagination
//             rowsPerPageOptions={[5, 10, 25]}
//             component="div"
//             count={formateurs?.length}
//             rowsPerPage={rowsPerPage}
//             page={page}
//             onPageChange={handleChangePage}
//             onRowsPerPageChange={handleChangeRowsPerPage}
//           />
//         </Card>

//         <Dialog
//         open={open}
//         onClose={handleClose}
//         aria-labelledby="alert-dialog-title"
//         aria-describedby="alert-dialog-description"
//       >

//         <DialogTitle id="alert-dialog-title">
//           {"Ajouter un business"}
//         </DialogTitle>
        
//         <DialogContent>
//           <RegisterBusiness handleClose={handleClose} getUsers={getUsers} formateurs={formateurs}/>
//         </DialogContent>

//       </Dialog>

//       </Container>
     
//     </Page>
   

//   );
// }

import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { useState, useEffect } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import axios from "axios"
import {
  Card,
  Table,
  Stack,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import { UserListHead, UserListToolbar } from '../../components/_dashboard/user';
import RegisterBusiness from '../../components/authentication/register/RegisterBusiness';
import { Link } from 'react-router-dom';



const TABLE_HEAD = [
  { id: 'nom', label: 'Nom Business', alignRight: false },
  // { id: 'prenom', label: 'Prenom', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'listeInscrit', label: 'Liste des inscrits', alignRight: false },


  { id: '' }
];


function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.nom.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis?.map((el) => el[0]);
}

export default function User() {


  let [modal, setModal] = useState(false);

  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('nom');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [formateurs, setformateurs] = useState([]);

  const [loading, setLoading] = useState(false);
  const [callback, setCallback] = useState(false);
  const [result, setResult] = useState(0);
  const [modalVoucher, setModalVoucher] = useState(false);
  const [selectedStudent, setselectedStudent] = useState("");
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
 
  const getUsers = async () => {
    setLoading(true);
    const res = await axios.get(
      process.env.REACT_APP_URL_BACKEND_DEV +`/business`
    );
    setformateurs(res.data.formateurs);
    setResult(formateurs);
    setLoading(false);
  };

  useEffect(() => {
    getUsers();

  }, [callback, page]);
  const handleDeleteUsers = async () => {
    setLoading(true);
    try {
      await axios.delete(process.env.REACT_APP_URL_BACKEND_DEV + '/formateurs/deleteall', { data: { userIds: selected } });
      setSelected([]);
    } catch (error) {
      console.error(error);
    } finally {
       getUsers()
      setLoading(false);
    }
  }; 

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = formateurs?.map((n) => n._id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };
  const handleClick = (event, nom) => {
    const selectedIndex = selected.indexOf(nom);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, nom);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected?.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 5));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - formateurs?.length) : 0;

  const filteredUsers = applySortFilter(formateurs, getComparator(order, orderBy), filterName);

  const isUserNotFound = filteredUsers?.length === 0;
  return (
    <Page title="Dashboard | Fastuz">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Business
          </Typography>
          <Button
            variant="contained"
            startIcon={<Icon icon={plusFill} />}
            onClick={handleClickOpen}          >
            Ajouter business
          </Button>
        
        </Stack>

        <Card>
          <UserListToolbar
            numSelected={selected?.length}
            filterName={filterName}
            handleDeleteUsers= {handleDeleteUsers}
            onFilterName={handleFilterByName}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={formateurs?.length}
                  numSelected={selected?.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers
                    ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    ?.map((row) => {
                      const { _id, nom, prenom, email,FormationEnrolled,  Actions,listeInscrit} = row;
                      const isItemSelected = selected.indexOf(_id) !== -1;
                 
                      return (
                       
                           <TableRow
                           hover
                           key={_id}
                           tabIndex={-1}
                           role="checkbox"
                           selected={isItemSelected}
                           aria-checked={isItemSelected}
                         >
                           <TableCell padding="checkbox">
                             <Checkbox
                               checked={isItemSelected}
                               onChange={(event) => handleClick(event, _id)}
                             />
                           </TableCell>
                         
                           <TableCell component="th" scope="row" padding="none">
                             <Stack direction="row" alignItems="center" spacing={2}>
                               <Typography variant="subtitle2" noWrap>
                               {/* <Link to={`/dashboard/Listbusiness/userbusiness/${_id}`}> */}
                               {nom} {prenom}
            {/* </Link> */}
                                 
                               </Typography>
                             </Stack>
                           </TableCell>
                           {/* <TableCell align="left">{prenom}</TableCell> */}
                           <TableCell align="left">{email}</TableCell>
                           <TableCell align="left">{listeInscrit.length}</TableCell>

                      
                         
 
                        
                         </TableRow>
                     
                      
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={formateurs?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>

        <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >

        <DialogTitle id="alert-dialog-title">
          {"Ajouter un business"}
        </DialogTitle>
        
        <DialogContent>
          <RegisterBusiness handleClose={handleClose} getUsers={getUsers} formateurs={formateurs}/>
        </DialogContent>

      </Dialog>

      </Container>
     
    </Page>
   

  );
}