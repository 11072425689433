import { useState, useEffect } from "react";
import axios from "axios";
import { Container } from "@mui/material";
import Page from "../../components/Page";
import { CardBody, Row } from "reactstrap";
import ReactTable from "react-table-v6";
import { Stack, Typography } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import moment from "moment";
import Cookies from 'js-cookie';

export default function User() {
  // Get the formateur ID from the URL parameters
  const { id } = useParams();

  const [apprenants, setApprenants] = useState([]);
  const token = Cookies.get("tokenAdmin");

  useEffect(() => {
    if (id) {
      getApprenants(id); // Pass the formateurId to getApprenants function
    }
  }, [id]);

  // Function to get the apprenants based on formateurId
  const getApprenants = async (id) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_URL_BACKEND_DEV}/getAllApprenantsListsBusiness/${id}`, // Include formateurId in the URL
        {
          headers: { Authorization: `Bearer ${token}`, withCredentials: true },
        }
      );

      const { listeInscrit } = res.data.data;
      const sortedApprenants = Array.isArray(listeInscrit)
        ? listeInscrit.sort((a, b) => new Date(a.dateInscrit) - new Date(b.dateInscrit))
        : [];

      setApprenants(sortedApprenants);
    } catch (error) {
      console.error('Error fetching apprenants:', error);
      setApprenants([]);
    }
  };

  const columns = [
    {
      Header: "Nom et Prénom",
      id: "nomPrenom",
      accessor: row => `${row.nom} ${row.prenom}`,
      filterMethod: (filter, row) =>
        row.nomPrenom.toLowerCase().includes(filter.value.toLowerCase()),
      Cell: ({ original }) => (
        <span style={{ wordBreak: "break-all" }}>
          <Link to={`/detailsUser/${original._id}`}>
            {original.nom} {original.prenom}
          </Link>
        </span>
      ),
    },
    {
      Header: "Email",
      accessor: "email",
      Cell: ({ original }) => <div>{original?.email}</div>,
    },
    {
      Header: "Date Enrolled",
      accessor: "enrolled",
      id: "firstEnrollmentDate",
      Cell: ({ original }) => {
        const firstEnrollmentDateTime = original.enrolled.length > 0 ? original.enrolled[0].dateEnrolled : null;
        return (
          <span style={{ wordBreak: "break-all" }}>
            {firstEnrollmentDateTime ? moment(firstEnrollmentDateTime).locale("fr").format("DD/MM/YYYY") : "No Enrollment"}
          </span>
        );
      },
    },
    {
      Header: "Nombre de formations",
      accessor: "enrolled",
      Cell: ({ original }) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          {original?.enrolled.length > 0 ? <div>{original?.enrolled.length}</div> : <div>Pas de formations</div>}
        </div>
      ),
    },
    {
      Header: "Nombre de certificats",
      accessor: "mesCertificats",
      Cell: ({ original }) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          {original?.mesCertificats.length > 0 ? <div>{original?.mesCertificats.length}</div> : <div>Pas de certificats</div>}
        </div>
      ),
    },
  ];

  return (
    <Page title="Dashboard | Fastuz">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Apprenants
          </Typography>
        </Stack>

        <CardBody>
          <Row>
            <ReactTable
              data={apprenants}
              nextText={"Suivant"}
              previousText={"Précedent"}
              rowsText={"Lignes"}
              columns={columns}
              defaultPageSize={10}
              filterable={true}
              noDataText="No data is available"
              defaultSorted={[{ id: "createdAt", desc: true }]}
            />
          </Row>
        </CardBody>
      </Container>
    </Page>
  );
}
