import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import {
  GetAllNomPropAPI, getNomPropByIdAPI,
} from "../utils/api";


const initialState = {
  listNomProp: [],
  status: "idle",
  NomPropselected: {},
};
export const getNomProp = createAsyncThunk("Type/getNomProp", async () => {
  try {
    const response = await GetAllNomPropAPI();
    console.log("response",response)
    return response.data.categorie;
  } catch (err) {
    console.log("Erreur : ", err.response);
  }
});
export const getNomPropById = createAsyncThunk(
  "Type/getNomPropByIdAPI",
  async (idCategorie) => {
    try {
      const response = await getNomPropByIdAPI(idCategorie);
      return response.data.result;
    } catch (err) {
      console.log("Erreur : ", err.response);
    }
  }
);
export const TypeProdSlice = createSlice({
  name: "nomProp",
  initialState,
  reducers: {},
  extraReducers: {
   
    [getNomProp.pending]: (state, action) => {
      state.status = "loading";
    },
    [getNomProp.fulfilled]: (state, action) => {
      state.status = "success";
      state.listNomProp = action.payload;
    },
    [getNomPropById.pending]: (state, action) => {
      state.statusUpdate = "loading";
    },
    [getNomPropById.fulfilled]: (state, action) => {
      state.statusUpdate = "success";
      state.NomPropselected = action.payload;
    },
  },
});

export default TypeProdSlice.reducer;
