import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getUnseenMessageFormateur } from "../utils/api";

const initialState = {
  listeMessageUnseen: [],
  status: "idle",
};

export const getMessageUnseen = createAsyncThunk(
  "notifications/getMessageUnseen",
  async (idFormateur) => {
    try {
      const response = await getUnseenMessageFormateur(idFormateur);
      return response.data.messages;
    } catch (err) {
    }

  }
);

export const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getMessageUnseen.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getMessageUnseen.fulfilled, (state, action) => {
        state.status = "success";
        state.listeMessageUnseen = action.payload;
      });
  },
});

export default notificationsSlice.reducer;
