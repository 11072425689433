import React, { useEffect } from "react";
import { useState } from "react";
import { Card, CardBody, Row } from "reactstrap";
import { Icon } from "@iconify/react";
import moment from "moment";
import { Stack, Typography } from "@mui/material";
import ReactTable from "react-table-v6";
import axios from "axios";
function Partenaire() {
  const [partners, setPartners] = useState([]);
  const [formations, setFormations] = useState([]);
  const [formateur, setFormateurs] = useState([]);
  console.log(formateur);
  const getTyperec = async () => {
    const res = await axios.get(
      process.env.REACT_APP_URL_BACKEND_DEV + `/formation`
    );
    setFormations(res.data.formations);
  };
  useEffect(() => {
    getTyperec();
  }, []);
  const [loading, setLoading] = React.useState(false);
  const statsColumns = [
    {
      Header: "Order Number",
      accessor: "OrderNumber",
      width: 150,
      style: { wordBreak: "break-all" },
    },
    {
      Header: "Date d'achat",
      accessor: "createdAt",
      Cell: ({ value }) => {
        return (
          <span style={{ wordBreak: "break-all" }}>
            {moment(value).locale("fr").format("DD/MM/YYYY")}
          </span>
        );
      },
      filterMethod: (filter, row) => {
        const date = moment(row[filter.id]).format("DD/MM/YYYY");
        return date.includes(filter.value);
      },
      defaultSortDesc: true,

    },
    {
      Header: "Formations",
      accessor: "formation",
      width: 200,
      Cell: ({ original }) => {
        return <div>{original?.formation?.titre}</div>;
      },
      filterMethod: (filter, row) => {
        if (filter.value === "all") {
          return true;
        }
        return row.formation.titre === filter.value;
      },
      Filter: ({ filter, onChange }) => {
        return (
          <select
            style={{ width: "100%", fontSize: "1rem" }}
            onChange={(event) => onChange(event.target.value)}
            value={filter ? filter.value : "all"}
          >
            <option value="all">Tous</option>
            {formations.map((item) => (
              <option key={item._id} value={item.titre}>
                {item.titre}
              </option>
            ))}
          </select>
        );
      },
    },
    {
      Header: "Formateurs",
      id: "nomPrenomF",
      accessor: (row) => `${row.nom} ${row.prenom}`,
      style: { textAlign: "center" },
      width: 200,
      Cell: ({ original }) => {
        return (
          <span style={{ wordBreak: "break-all" }}>
            {original.formation?.formateur.nom}{" "}
            {original.formation?.formateur.prenom}
          </span>
        );
      },
      filterMethod: (filter, row) => {
        if (filter.value === "all") {
          return true;
        }
        return (
          `${row.formation?.formateur?.nom} ${row.formation?.formateur?.prenom}` ===
          filter.value
        );
      },
      Filter: ({ filter, onChange }) => {
        return (
          <select
            style={{ width: "100%", fontSize: "1rem" }}
            onChange={(event) => onChange(event.target.value)}
            value={filter ? filter.value : "all"}
          >
            <option value="all">Tous</option>
            {Array.from(
              new Set(
                formations.map(
                  (item) => `${item.formateur?.nom} ${item.formateur?.prenom}`
                )
              )
            ).map((name, index) => (
              <option key={index} value={name}>
                {name}
              </option>
            ))}
          </select>
       
        );
      },
    },

    {
      Header: "Prix formation",
      id: "prix",
      width: 150,
      style: { textAlign: "center" },
      accessor: (row) => `${row.formation?.prix}`,
      filterMethod: (filter, row) =>
        row.prix.toLowerCase().includes(filter.value.toLowerCase()),
      Cell: ({ original }) => {
        return (
          <span style={{ wordBreak: "break-all" }}>
            {original.formation?.prix} TND
          </span>
        );
      },
    },
    {
      Header: "Prix d'achat",
      id: "prix",
      width: 150,
      style: { textAlign: "center" },
      accessor: (row) => `${row.formation?.prix}`,
      filterMethod: (filter, row) =>
        row.prix.toLowerCase().includes(filter.value.toLowerCase()),
      Cell: ({ original }) => {
        return (
          <div style={{ display: "flex", justifyContent: "center" }}>
            {original.amount !== 0 ? (
              <span style={{ wordBreak: "break-all" }}>
                {original.amount} TND
              </span>
            ) : (
              "0 TND"
            )}
          </div>
        );
      },
    },
    {
      Header: "Apprenants",
      id: "nomPrenom",
      style: { textAlign: "center" },
      width: 200,
      accessor: (row) => `${row.user?.nom} ${row.user?.prenom}`,
      filterMethod: (filter, row) =>
        row.nomPrenom.toLowerCase().includes(filter.value.toLowerCase()),
      Cell: ({ original }) => {
        return (
          <span style={{ wordBreak: "break-all" }}>
            {original.user?.nom} {original.user?.prenom}
          </span>
        );
      },
    },
    {
      Header: "Type de paiement",
      accessor: "type",
      style: { textAlign: "center" },
      width: 150,
      Cell: ({ original }) => {
        const typeText = original?.type === "CB" ? "Carte Bancaire" : "Voucher";
        const textColor = original?.type === "CB" ? "#04AA6D" : "red";
        return <b>{typeText}</b>;
      },
      filterMethod: (filter, row) => {
        if (filter.value === "all") {
          return true;
        }
        if (filter.value === "CB") {
          return row[filter.id] === "CB";
        }
        if (filter.value === "voucher") {
          return row[filter.id] === "voucher";
        }
        return false;
      },
      Filter: ({ filter, onChange }) => {
        return (
          <select
            style={{ width: "100%", fontSize: "1rem" }}
            onChange={(event) => onChange(event.target.value)}
            value={filter ? filter.value : "all"}
          >
            <option value="all">Filtrer</option>
            <option value="CB">Carte Bancaire</option>
            <option value="voucher">Voucher</option>
          </select>
        );
      },
    },
    {
      Header: "Etat",
      accessor: "etat",
      style: { textAlign: "center" },
      width: 150,
      Cell: ({ original }) => {
        const enrolledText = original?.etat === "Success" ? "Succès" : "Échec";
        const textColor = original?.etat === "Success" ? "#04AA6D" : "red";
        return <b style={{ color: textColor }}>{enrolledText}</b>;
      },
      filterMethod: (filter, row) => {
        if (filter.value === "all") {
          return true;
        }
        if (filter.value === "Success") {
          return row[filter.id] === "Success";
        }
        if (filter.value === "Payment is declined") {
          return row[filter.id] !== "Success";
        }
        return false;
      },
      Filter: ({ filter, onChange }) => {
        return (
          <select
            style={{ width: "100%", fontSize: "1rem" }}
            onChange={(event) => onChange(event.target.value)}
            value={filter ? filter.value : "all"}
          >
            <option value="all">Filtrer</option>
            <option value="Success">Succès</option>
            <option value="Payment is declined">Échec</option>
          </select>
        );
      },
    },
    {
      Header: "PDF",
      width: 150,
      filterable: false,
      style: { textAlign: "center" },
      Cell: ({ original }) => {
        return (
          <div className="d-block w-100 text-center">
            {original?.file ? (
              <a href={original?.file} download>
                <Icon width="40" icon="iwwa:file-pdf" />
              </a>
            ) : (
              "-"
            )}
          </div>
        );
      },
    },
  ];
  const getPartners = async () => {
    setLoading(true);
    const res = await axios.get(
      process.env.REACT_APP_URL_BACKEND_DEV + `/allfactures`
    );
    setPartners(res.data.Factures);
    setLoading(false);
  };
  useEffect(() => {
    getPartners();
  }, []);
  return (
    <div>
      <Card className="card-hover-shadow-2x mb-3">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Factures
          </Typography>
        </Stack>
        <CardBody>
          <Row>
            <ReactTable
              data={partners}
              nextText={"Suivant"}
              previousText={"Précedent"}
              rowsText={"Lignes"}
              columns={statsColumns}
              defaultPageSize={10}
              filterable={true}
              noDataText="Pas de facture"
              defaultSorted={[{id :"createdAt" , desc : true}]}

            />
          </Row>
        </CardBody>
      </Card>
    </div>
  );
}
export default Partenaire;
