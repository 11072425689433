import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getAllFormationAPI,
  getFormationAPI,
getAllFormationsByFormateurAPI,
  getFormationByIdAPI,
  getAllFormationArchiveAPI,
  SupprimerFormationAPI,
  PusblishedAPI,
  getProgressionFormationByIdAPI,
  getAllFormationsExterneAPI,
  getAllFormationByFormateurAPI,
  getFormationByFormateurAPI,
  getAllFormationsFormateurAPI,
  getAllFormationsPublierAPI,
  getAllLicenceAPI

} from "../utils/api";

const initialState = {
  listeFormations: [],
  listeLicences: [],
  listeFormationsArchive: [],
  status: "idle",
  formationSelected: {},
};


export const getAllLicences = createAsyncThunk(
  "formations/getAllLicences",
  async () => {
    try {
      const response = await getAllLicenceAPI();
      return response.data.result;
    } catch (err) {
    }
  }
);

export const getScoresBlancFormations = createAsyncThunk(
  "formations/getScoresBlancFormations",
  async () => {
    try {
      const response = await getFormationByFormateurAPI();
      return response.data.result;
    } catch (err) {
    }
  }
);


export const getAllFormations = createAsyncThunk(
  "formations/getAllFormation",
  async () => {
    try {
      const response = await getAllFormationAPI();
      return response.data.result;
    } catch (err) {
    }
  }
);

export const getAllFormationsPublier = createAsyncThunk(
  "formations/getAllFormationsPublier",
  async () => {
    try {
      const response = await getAllFormationsPublierAPI();
      return response.data.result;
    } catch (err) {
    }
  }
);


export const getAllFormationsFormateur = createAsyncThunk(
  "formations/getAllFormationsFormateur",
  async () => {
    try {
      const response = await getAllFormationsFormateurAPI();
      return response.data.result;
    } catch (err) {
    }
  }
);
export const Pusblished = createAsyncThunk(
  "formations/Pusblished",
  async () => {
    try {
      const response = await PusblishedAPI();
      return response.data.result;
    } catch (err) {
    }
  }
);
export const getFormationsByFormateur = createAsyncThunk(
  "formations/getFormationsByFormateur",
  async () => {
    try {
      const response = await getAllFormationsByFormateurAPI();
      return response.data.result;
    } catch (err) {
    }
  }
);
export const getFormations = createAsyncThunk(
  "formations/getFormation",
  async () => {
    try {
      const response = await getFormationAPI();
      return response.data.result;
    } catch (err) {
    }
  }
);



export const getAllFormationsArchive = createAsyncThunk(
  "formations/getAllFormationsArchive",
  async () => {
    try {
      const response = await getAllFormationArchiveAPI();
      return response.data.result;
    } catch (err) {
    }
  }
);

export const getFormationsById = createAsyncThunk(
  "formations/getFormationsById",
  async (idFormation) => {
    try {
      const response = await getFormationByIdAPI(idFormation);
      return response.data.result;
    } catch (err) {
    }
  }
);
export const removeFormations = createAsyncThunk(
  "formations/removeFormations",
  async (id) => {
    const response = await SupprimerFormationAPI(id);
    return response.data;
  }
);


export const getProgressionFormationById = createAsyncThunk(
  "formations/getProgressionFormationById",
  async ({ idFormation, formation }) => {
    try {
      const response = await getProgressionFormationByIdAPI(idFormation);
      return { result: response.data.result, formation };
    } catch (err) {
    }
  }
);
export const getAllFormationsExterne = createAsyncThunk(
  "formations/getAllFormationsExterne",
  async () => {
    try {
      const response = await getAllFormationsExterneAPI();
      return response.data.result;
    } catch (err) {
    }
  }
);

export const getAllFormationsByFormateur = createAsyncThunk(
  "formations/getAllFormationsByFormateur",
  async (idFormateur) => {
    try {
      const response = await getAllFormationByFormateurAPI(idFormateur);
      return response.data.result;
    } catch (err) {
    }
  }
);

export const formationsSlice = createSlice({
  name: "formations",
  initialState,
  reducers: {},

  extraReducers: {
    [getAllFormationsFormateur.pending]: (state, action) => {
      state.status = "loading";
    },
    [getAllFormationsFormateur.fulfilled]: (state, action) => {
      state.status = "idle";
      state.listeFormations = action.payload;
    },
    [getAllFormations.pending]: (state, action) => {
      state.status = "loading";
    },
    [getAllFormations.fulfilled]: (state, action) => {
      state.status = "idle";
      state.listeFormations = action.payload;
    },
    [getAllFormationsPublier.pending]: (state, action) => {
      state.status = "loading";
    },
    [getAllFormationsPublier.fulfilled]: (state, action) => {
      state.status = "idle";
      state.listeFormations = action.payload;
    },
    [getAllLicences.pending]: (state, action) => {
      state.status = "loading";
    },
    [getAllLicences.fulfilled]: (state, action) => {
      state.status = "idle";
      state.listeLicences = action.payload;
    },
    [getFormationsById.pending]: (state, action) => {
      state.statusUpdate = "loading";
    },
    [getFormationsById.fulfilled]: (state, action) => {
      state.statusUpdate = "success";
      state.formationSelected = action.payload;
    },
    [getAllFormationsArchive.pending]: (state, action) => {
      state.status = "loading";
    },
    [getAllFormationsArchive.fulfilled]: (state, action) => {
      state.status = "success";
      state.listeFormationsArchive = action.payload;
    },
    [Pusblished.pending]: (state, action) => {
      state.status = "loading";
    },
    [Pusblished.fulfilled]: (state, action) => {
      state.status = "success";
      state.listeFormationsArchive = action.payload;
    },
    [getAllFormationsExterne.pending]: (state, action) => {
      state.status = "loading";
    },
    
    [getAllFormationsExterne.fulfilled]: (state, action) => {
      state.status = "success";
      state.listeFormationsExterne = action.payload;
    },
    [removeFormations.fulfilled]: (state, action) => {
      state.listeFormations = state.listeFormations.filter(
        (d) => d._id !== action.payload.formations._id
      );
    },
    [getAllFormationsByFormateur.pending]: (state, action) => {
      state.status = "loading";
    },
    [getAllFormationsByFormateur.fulfilled]: (state, action) => {
      state.status = "success";
      state.listeFormations = action.payload;
    },
    [getProgressionFormationById.fulfilled]: (state, action) => {
      const { result, formation } = action.payload;
      state.formationSelected = {
        ...formation,
        etudiants: formation.etudiants.map((etudiant) => {
          let progressionEtudiant = result.find(
            (e) => etudiant._id === e.etudiant
          );
          return {
            ...etudiant,
            progression: progressionEtudiant ? progressionEtudiant.step : 0,
          };
        }),
      };
    },
  
 
  },
});

export default formationsSlice.reducer;
