import { merge } from "lodash";
import ReactApexChart from "react-apexcharts";

import { useTheme, styled } from "@mui/material/styles";
import { Card, CardHeader } from "@mui/material";

import { fNumber } from "../../../utils/formatNumber";


import { BaseOptionChart } from "../../charts";

import { useEffect, useState } from "react";

import axios from "axios";

const CHART_HEIGHT = 372;
const LEGEND_HEIGHT = 72;

const ChartWrapperStyle = styled("div")(({ theme }) => ({
  height: CHART_HEIGHT,
  marginTop: theme.spacing(5),
  "& .apexcharts-canvas svg": { height: CHART_HEIGHT },
  "& .apexcharts-canvas svg,.apexcharts-canvas foreignObject": {
    overflow: "visible",
  },
  "& .apexcharts-legend": {
    height: LEGEND_HEIGHT,
    alignContent: "center",
    position: "relative !important",
    borderTop: `solid 1px ${theme.palette.divider}`,
    top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`,
  },
}));


export default function AppCurrentSubject() {
  const theme = useTheme();
  const [series, setseries] = useState([0, 0]);
  const [makingApiCall, setMakingApiCall] = useState(false);

  useEffect(() => {
    setMakingApiCall(true);
    axios
      .get(process.env.REACT_APP_URL_BACKEND_DEV + "/scoreForDashboard")
      .then((result) => {
        setseries(result.data.data);
        setMakingApiCall(false);
      });
  }, []);


  const getRandomColor = (title) => {
    var letters = "0123456789ABCDEF";
    var color = "#";
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };
  

  const chartOptions = merge(BaseOptionChart(), {
    colors: series.map((formation) => getRandomColor(series)),
    labels: ["Succès", "Echec"],
    stroke: { colors: [theme.palette.background.paper] },
    legend: { floating: true, horizontalAlign: "center" },
    dataLabels: { enabled: true, dropShadow: { enabled: false } },
    tooltip: {
      fillSeriesColor: false,
      y: {
        formatter: (seriesName) => fNumber(seriesName) + " Student",
        title: {
          formatter: (seriesName) => `#${seriesName}`,
        },
      },
    },
    plotOptions: {
      pie: { donut: { labels: { show: false } } },
    },
  });

  return (
    <Card>
      <CardHeader title="Echec/Success Certificate" />
      {!makingApiCall ? (
        <ChartWrapperStyle dir="ltr">
          <ReactApexChart
            type="donut"
            series={series}
            options={chartOptions}
            height={280}
          />
        </ChartWrapperStyle>
      ) : (
        <span>Loading...</span>
      )}
    </Card>
  );
}
