
import { Box, Card, CardHeader } from '@mui/material';

import { useEffect, useState } from "react";
import Review from "./AppConversionRates"

import axios from 'axios';
export default function AppConversionRates() {



    const [courses, setCourses] = useState([]);
    const [search, setSearch] = useState("");
    const [result, setResult] = useState(0);
    const [loading, setLoading] = useState(false);
  
    useEffect(() => {
      const getCourses = async () => {
        setLoading(true);
        const res = await axios.get(
          process.env.REACT_APP_URL_BACKEND_DEV +`/allformationfiltered
          }&titre[regex]=${search}`
        );
        setCourses(res.data.formations);
        setResult(res.data.result);
        setLoading(false);
      };
      getCourses();
    }, [ search]);
  
  return (
    <>
       <Card>
      <CardHeader title="Conversion Rates" subheader="(+43%) than last year" />
      <Box sx={{ mx: 3 }} dir="ltr">
        {courses.map((item)=>{
          <Review item={item} key={item._id} />

        })}
      </Box>
    </Card>
  </>
  );
}