import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import {
  GetAllImpotsAPI, getImpotByIdAPI,
} from "../utils/api";


const initialState = {

  listImpots: [],
  status: "idle",
  Impotselected: {},
};


export const getImpots = createAsyncThunk("Impot/getImpots", async () => {
  try {
    const response = await GetAllImpotsAPI();
    console.log("response",response)
    return response.data.result;
  } catch (err) {
    console.log("Erreur : ", err.response);
  }

});



export const getImpotById = createAsyncThunk(
  "Impot/getImpotById",
  async (idImpot) => {
    try {
      const response = await getImpotByIdAPI(idImpot);
      return response.data.result;
    } catch (err) {
      console.log("Erreur : ", err.response);
    }
  }
);

export const ImpotSlice = createSlice({
  name: "type",
  initialState,
  reducers: {},

  extraReducers: {
   
    [getImpots.pending]: (state, action) => {
      state.status = "loading";
    },
    [getImpots.fulfilled]: (state, action) => {
      state.status = "success";
      state.listImpots = action.payload;
    },
    [getImpotById.pending]: (state, action) => {
      state.statusUpdate = "loading";
    },
    [getImpotById.fulfilled]: (state, action) => {
      state.statusUpdate = "success";
      state.Impotselected = action.payload;
    },
  },
});

export default ImpotSlice.reducer;
