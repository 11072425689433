import { merge } from "lodash";
import ReactApexChart from "react-apexcharts";
// material
import { Card, CardHeader, Box } from "@mui/material";
//
import { BaseOptionChart } from "../../charts";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getAllFormations } from "../../../redux/formationSlice";
import { getAllApprenantsByFormateur } from "../../../redux/authSlice";
import Cookies from 'js-cookie';
import axios from "axios";
// ----------------------------------------------------------------------

export default function AppWebsiteVisits({ item, type }) {
  const [arraymonths] = useState([
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "May",
    "Juin",
    "Juillet",
    "Aout",
    "September",
    "Octobre",
    "Novembre", 
    "Décembre"

  ]);

  const [arrayDateFormation, setarrayDateFormation] = useState([]);
  const [arrayDateStudents, setarrayDateStudents] = useState([]);

  const token= Cookies.get("tokenAdmin");



  const PrepareNumberPerArrayOfMonth = async (
    arrayNameMonth,
    arrayFormation,
    type
  ) => {
    let array = [];
    arrayNameMonth?.forEach((element, index) => {
      var number = PrepareNumberPerMonth(arrayFormation, index);

      array.push(number);
    });
    type === "formation"
      ? setarrayDateFormation(array)
      : setarrayDateStudents(array);

    return array;
  };
  const PrepareNumberPerMonth = (array, month) => {
    let number = 0;
    array?.forEach((formation) => {
      const date = new Date(formation.createdAt);
      if (date.getMonth() === month) {
        number++;
      }
    });

    return number;
  };

  const [makingApiCall, setMakingApiCall] = useState(false);


  useEffect(() => {

    PrepareNumberPerMonth();
  }, []);

  useEffect(() => {

    const getAllFormationsFormateur = async () => {
      const result = await axios.get(process.env.REACT_APP_URL_BACKEND_DEV + `/getAllFormationsFormateur`, {
        headers: { Authorization: `Bearer ${token}` }, 
        withCredentials: true
      });
      await PrepareNumberPerArrayOfMonth(
        arraymonths,
        result.data.formations,
        "formation"
      );    
    };
    getAllFormationsFormateur();
  }, []);
  useEffect(() => {
  
    const getAllApprenantsByFormateur = async () => {
      const res = await axios.get(process.env.REACT_APP_URL_BACKEND_DEV + `/getAllApprenantsByFormateur`, {
        headers: { Authorization: `Bearer ${token}` }, 
        withCredentials: true
      });

      PrepareNumberPerArrayOfMonth(arraymonths, res.data.EtudiantEnrolled, "students");

    
    };
    getAllApprenantsByFormateur();
  
  }, []);




  const chartOptions = merge(BaseOptionChart(), {
    stroke: { width: [0, 2, 3] },
    plotOptions: { bar: { columnWidth: "11%", borderRadius: 4 } },
    fill: { type: ["solid", "gradient", "solid"] },
    labels: [
      "2023-01-01",
      "2023-02-01",
      "2023-03-01",
      "2023-04-01",
      "2023-05-01",
      "2023-06-01",
      "2023-07-01",
      "2023-08-01",
      "2023-09-01",
      "2023-10-01",
      "2023-11-01",
    ],
    xaxis: { type: "datetime" },
    tooltip: {
      shared: true,
      intersect: false,
      x: {
        format: "MMM",
      },
      y: {
        formatter: (y) => {
          if (typeof y !== "undefined") {
            return `${y.toFixed(0)} item(s)`;
          }
          return y;
        },
      },
    },
  });

  return (
    <Card>
      <CardHeader
        subheader="Formations ajoutées / Nombre d'inscription par mois"
      />
      <Box sx={{ p: 3, pb: 1 }} dir="ltr">
        {!makingApiCall ? (
          <ReactApexChart
            type="line"
            series={[
              {
                name: "Nombre d'inscription",
                type: "column",
                data: arrayDateStudents,
              },
              
              {
                name: "Formations ajoutées",
                type: "area",
                data: arrayDateFormation,
              },
            ]}
            options={chartOptions}
            height={364}
          />
        ) : (
          <span>Loading...</span>
        )}
      </Box>

    </Card>
  );
}
