// import React, { useEffect, useState } from "react";
// import ReactApexChart from "react-apexcharts";
// import { useDispatch } from "react-redux";
// import { getListeOfStudents } from "../../../redux/authSlice";
// import { getAllFormations } from "../../../redux/formationSlice";
// import { Card, CardHeader, List } from "@mui/material";

// export default function LineChartStudent(props) {
//   const [data, setData] = useState({
//     options: {
//       chart: {
//         id: "line-chart",
//       },
//       xaxis: {
//         categories: [
//           "Lundi",
//           "Mardi",
//           "Mercredi",
//           "Jeudi",
//           "Vendredi",
//           "Samedi",
//           "Dimanche",
//         ],
//       },
//     },
//     series: [
//       {
//         name: "Etudiants",
//         data: [],
//       },
//       {
//         name: "Formations",
//         data: [],
//       },
//     ],
//   });

//   const dispatch = useDispatch();

//   useEffect(() => {
//     const fetchData = async () => {
//       const studentsResult = await dispatch(getListeOfStudents());
//       const formationsResult = await dispatch(getAllFormations());

//       const currentWeekStart = getWeekStart(new Date());

//       const arrayDateFormation = PrepareNumberPerArrayOfDay(
//         data.options.xaxis.categories,
//         studentsResult.payload,
//         currentWeekStart
//       );

//       const arrayDateStudent = PrepareNumberFormationPerArrayOfDay(
//         data.options.xaxis.categories,
//         formationsResult.payload,
//         currentWeekStart
//       );

//       setData((prevData) => ({
//         ...prevData,
//         series: [
//           {
//             ...prevData.series[0],
//             data: arrayDateFormation,
//           },
//           {
//             ...prevData.series[1],
//             data: arrayDateStudent,
//           },
//         ],
//       }));
//     };

//     fetchData();
//   }, [dispatch, data.options.xaxis.categories]);

//   const getWeekStart = (date) => {
//     const day = date.getDay();
//     const diff = date.getDate() - day + (day === 0 ? -6 : 1); // Adjust for Sunday (0)
//     return new Date(date.setDate(diff));
//   };

//   const PrepareNumberPerArrayOfDay = (arrayNameMonth, arrayFormation, weekStart) => {
//     let array = [];
//     arrayNameMonth.forEach((element, index) => {
//       var number = PrepareNumberPerDay(arrayFormation, index, weekStart);
//       array.push(number);
//     });
//     return array;
//   };
  
//   const PrepareNumberPerDay = (array, day, weekStart) => {
//     const targetDate = new Date(weekStart);
//     targetDate.setDate(targetDate.getDate() + day);
//     let number = 0;
//     array.forEach((formation) => {
//       const date = new Date(formation.createdAt);
//       if (isSameDay(date, targetDate)) {
//         number++;
//       }
//     });
//     return number;
//   };
  
//   const PrepareNumberFormationPerArrayOfDay = (arrayNameMonth, arrayFormation, weekStart) => {
//     let array = [];
//     arrayNameMonth.forEach((element, index) => {
//       var number = PrepareNumberFormationPerDay(arrayFormation, index, weekStart);
//       array.push(number);
//     });
//     return array;
//   };
  
//   const PrepareNumberFormationPerDay = (array, day, weekStart) => {
//     const targetDate = new Date(weekStart);
//     targetDate.setDate(targetDate.getDate() + day);
  
//     let number = 0;
//     array.forEach((formation) => {
//       const date = new Date(formation.createdAt);
//       if (isSameDay(date, targetDate)) {
//         number++;
//       }
//     });

//     return number;
//   };
  
//   const isSameDay = (date1, date2) => {
//     return (
//       date1.getFullYear() === date2.getFullYear() &&
//       date1.getMonth() === date2.getMonth() &&
//       date1.getDate() === date2.getDate()
//     );
//   };

//   return (
//     <Card>
//     <CardHeader subheader="Formations ajoutées / Nombre d'inscription par jour" />
//       <ReactApexChart
//         options={data.options}
//         series={data.series}
//         type="line"
//         height={350}
//       />
//     </Card>
//   );
// }
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useDispatch } from "react-redux";
import { getListeOfStudentEnrolled, getListeOfStudents } from "../../../redux/authSlice";
import { getAllFormations } from "../../../redux/formationSlice";
import { Card, CardHeader } from "@mui/material";

export default function LineChartStudent(props) {
  const [data, setData] = useState({
    options: {
      chart: {
        id: "line-chart",
      },
      xaxis: {
        categories: [
          "Lundi",
          "Mardi",
          "Mercredi",
          "Jeudi",
          "Vendredi",
          "Samedi",
          "Dimanche",
        ],
      },
    },
    series: [
      { name: "Inscrits", data: [] },
      { name: "Formations", data: [] },
      { name: "Apprenants", data: [] },
    ],
  });

  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const studentsResult = await dispatch(getListeOfStudents());
        const formationsResult = await dispatch(getAllFormations());
        const enrolledResult = await dispatch(getListeOfStudentEnrolled());

        const currentWeekStart = getWeekStart(new Date());

        const arrayDateFormation = PrepareNumberPerArrayOfDay(
          data.options.xaxis.categories,
          studentsResult.payload,
          currentWeekStart
        );

        const arrayDateStudent = PrepareNumberFormationPerArrayOfDay(
          data.options.xaxis.categories,
          formationsResult.payload,
          currentWeekStart
        );

        const arrayDateStudentEnrolled = PrepareNumberPerArrayOfDayEnrolled(
          data.options.xaxis.categories,
          enrolledResult.payload,
          currentWeekStart
        );

        setData((prevData) => ({
          ...prevData,
          series: [
            { ...prevData.series[0], data: arrayDateFormation },
            { ...prevData.series[1], data: arrayDateStudent },
            { ...prevData.series[2], data: arrayDateStudentEnrolled },
          ],
        }));
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false); // Stop loading once data is fetched
      }
    };

    fetchData();
  }, [dispatch, data.options.xaxis.categories]);

  const getWeekStart = (date) => {
    const day = date.getDay();
    const diff = date.getDate() - day + (day === 0 ? -6 : 1); // Adjust for Sunday (0)
    return new Date(date.setDate(diff));
  };

  const PrepareNumberPerArrayOfDay = (arrayNameMonth, arrayFormation, weekStart) => {
    return arrayNameMonth.map((_, index) => PrepareNumberPerDay(arrayFormation, index, weekStart));
  };

  const PrepareNumberPerDay = (array, day, weekStart) => {
    const targetDate = new Date(weekStart);
    targetDate.setDate(targetDate.getDate() + day);
    return array.filter(formation => isSameDay(new Date(formation.createdAt), targetDate)).length;
  };

  const PrepareNumberFormationPerArrayOfDay = (arrayNameMonth, arrayFormation, weekStart) => {
    return arrayNameMonth.map((_, index) => PrepareNumberFormationPerDay(arrayFormation, index, weekStart));
  };

  const PrepareNumberFormationPerDay = (array, day, weekStart) => {
    const targetDate = new Date(weekStart);
    targetDate.setDate(targetDate.getDate() + day);
    return array.filter(formation => isSameDay(new Date(formation.createdAt), targetDate)).length;
  };

  const PrepareNumberPerArrayOfDayEnrolled = (arrayNameMonth, arrayFormation, weekStart) => {
    return arrayNameMonth.map((_, index) => PrepareNumberPerDayEnrolled(arrayFormation, index, weekStart));
  };

  const PrepareNumberPerDayEnrolled = (array, day, weekStart) => {
    const targetDate = new Date(weekStart);
    targetDate.setDate(targetDate.getDate() + day);
    return array.reduce((count, formation) => {
      return count + formation.enrolled.filter(enrollment => isSameDay(new Date(enrollment.dateEnrolled), targetDate)).length;
    }, 0);
  };

  const isSameDay = (date1, date2) => {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  };

  return (
    <Card>
      <CardHeader subheader="Formations ajoutées / Nombre d'inscrits / Nombre d'apprenants par jour" />
      {loading ? (
        <div>Loading...</div>
      ) : (
        <ReactApexChart
          options={data.options}
          series={data.series}
          type="line"
          height={350}
        />
      )}
    </Card>
  );
}
