import DeleteIcon from '@mui/icons-material/Delete';
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import PlayLessonOutlinedIcon from "@mui/icons-material/PlayLessonOutlined";
import { IconButton } from "@mui/material";
import parse from "html-react-parser";
import ShowMoreText from 'react-show-more-text' ;
import React from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { useStyle } from "./styles";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import { SupprimerCourseAPI } from '../../../utils/api';

const Course = (props) => {
  const classes = useStyle();
  const { titre_cours, videos, file, description,  _id } = props.courses;
 


  const handleDeleteLesson = async () => {
    Swal.fire({
      title: "Êtes-vous sûr?",
      text: "pour supprimer ce cours",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0d3077",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui, supprimez-le !",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await SupprimerCourseAPI(_id)
          .then(async (res) => {
            if (res.status === 200) {

              Swal.fire("Supprimé!", "Votre fichier a été supprimé.", "succès");
              window.location.reload();
            } else {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
              });
            }
          });
      }
    });
  };

  return (
    <div className={classes.lessonWrapper}>
      <div className={classes.headingWrapper}>
        <h2 className={classes.lessonhead}>
          <PlayLessonOutlinedIcon /> {titre_cours}
        </h2>
        <div>
          <IconButton component={Link} to={`/updateCourse/${_id}`}>
            <ModeEditIcon color="primary" />
          </IconButton>
          <IconButton
              onClick={() => {
                handleDeleteLesson();
              }}
            color="error"
          >
            <DeleteIcon  color="primary"/>
          </IconButton>
        </div>
      </div>
      <div className={classes.lessoncontent} >
       <div className={classes.root} >
      <p>Vous trouverz un pdf <a href={file}  > ici </a></p> 
       <video src={videos} controls controlsList="nodownload"   width="50%" height="50%"   display= "block" margin= "auto"></video>
         <span style={{paddingTop :"20px"}}>  
         <ShowMoreText
            lines={3}>{description&& parse(description)}</ShowMoreText>
            
            </span>      
       </div>
     
      </div> 
    </div>
  );
};

export default Course;
