import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  GetAllPartnersInstitutionnelsAPI, getPartnerInstitutionnelByIdAPI,
} from "../utils/api";


const initialState = {

  listPartners: [],
  status: "idle",
  partnerSelected: {},
};


export const getPartnersInstitutionnels = createAsyncThunk("Reglage/getPartners", async () => {
  try {
    const response = await GetAllPartnersInstitutionnelsAPI();
    return response.data.partners;
  } catch (err) {
  }

});



export const getPartnerInstitutionnelById = createAsyncThunk(
  "Reglage/getPartnerById",
  async (idPartner) => {
    try {
      const response = await getPartnerInstitutionnelByIdAPI(idPartner);
      return response.data.result;
    } catch (err) {
    }
  }
);

export const reglageSlice = createSlice({
  name: "Reglage",
  initialState,
  reducers: {},

  extraReducers: {
   
    [getPartnersInstitutionnels.pending]: (state, action) => {
      state.status = "loading";
    },
    [getPartnersInstitutionnels.fulfilled]: (state, action) => {
      state.status = "success";
      state.listPartners = action.payload;
    },
    [getPartnerInstitutionnelById.pending]: (state, action) => {
      state.statusUpdate = "loading";
    },
    [getPartnerInstitutionnelById.fulfilled]: (state, action) => {
      state.statusUpdate = "success";
      state.partnerSelected = action.payload;
    },
  },
});

export default reglageSlice.reducer;
