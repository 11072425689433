import React, { useEffect, useRef, useState } from "react";
import './Messenger.css';
import { useSelector } from "react-redux";
import Toolbar from '../pages/Toolbar';
import ToolbarButton from '../pages/ToolbarButton';
import axios from 'axios';
import { useParams } from "react-router-dom";
import './MessageList.css';
import Cookies from 'js-cookie';
import {  FileUpload } from "@mui/icons-material";
import Dropzone from "./Dropzone/Dropzone";
import Card from '@mui/material/Card';
import './ConversationList.css';
import './ConversationListItem.css';
import { useDispatch } from "react-redux";
import { getEtudiantById } from "../redux/authSlice";
import socketIOClient from "socket.io-client";
import "./App.scss"
import { Error } from "@material-ui/icons";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import Grid from '@material-ui/core/Grid';

import { Container } from "@material-ui/core";

export default function Messenger(props) {
 const [selectedEtudiant, setselectedEtudiant] = useState(null);
 const [messages, setMessages] = useState([])
 const [formateur, setFormateur] = useState("")
  const [isFetching, setisFetching] = useState(false);
  const userId= selectedEtudiant?._id
  const [stopFetch, setstopFetch] = useState(false);
  const dispatch = useDispatch();
  const ps = useRef();
 const [apprenants, setApprenants] = useState([]);
 const { idFormation } = useParams();
  const [socket, setsocket] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [isModal, setIsModal] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
const token= Cookies.get("tokenAdmin")

const formationSelected = useSelector(
  (state) => state.formations.formationSelected
);

  useEffect(() => {
    if (selectedEtudiant === null) {
      dispatch(getEtudiantById(userId)).then((res) => {
        setselectedEtudiant(res.payload);

        setisFetching(true);
        setstopFetch(false);
        getMoreData();
      });
    }
    if (selectedEtudiant !== null) {
      setisFetching(true);
      setstopFetch(false);
      getMoreData();
    }
  }, [selectedEtudiant]);
const [message, setMessage] = useState("");
const [sending, setSending] = useState(false);
const [files, setFiles] = useState([]);
const [imgPreviewUrl, setimgPreviewUrl] = useState(null);
const uploadFile = async (file) => {
  var formData = new FormData();
  formData.append("file-upload", file?.file);
  const response = await axios
    .post(process.env.REACT_APP_URL_BACKEND_DEV + `/uploade_image`  , formData)
    .catch((err) => console.log("try again :", err.response.status));
  return {
    link: response?.data?.data?.location,
    mimetype: file?.file?.type,
    name:response?.data?.data?.originalname,

  };
};
const handleClose = () => {
  setOpen(false);
};
const handleSubmit = async (e) => {
  e.preventDefault();
  e.stopPropagation();
  if (sending) return;
  if (files?.some((f) => !f.valid)) return;

  if (socket) {
    const filesArr = [];
    

    if (files?.length) {
      setSending(true);
      for (const file of files) {
        let res = await uploadFile(file);
        filesArr.push(res);

      }

      setSending(false);
    }
    if (!filesArr.length && !message) return;

    socket.emit("message", {
      formation: idFormation,
      etudiant: userId,
      isFormateur: true,
      contenu: message,
       files: filesArr,
    });
    setMessage("");
    getMoreData();
    setFiles([]);
    
  }
};

const [view, setView] = React.useState(false);

const handleClickOpenView = (link) => {

  setView(true);
  setimgPreviewUrl(link)
};

const handleCloseView = () => {
  setView(false);
};

  useEffect(() => {
   
    getMoreData();
  }, []);
  const getMoreData = async () => {
 if(userId){
    await axios
      .get(
        process.env.REACT_APP_URL_BACKEND_DEV + `/allmessages/${idFormation}/${userId}`, 
        {
          headers: { Authorization: `Bearer ${token}`, 
          withCredentials: true
        },
        }
      )
    
      .then((res) => {
        if (res.status === 200) {
          setMessages(res.data.messages);
          setFormateur(res.data.user)
      
        }
      });
    }
};

  useEffect(() => {
    const socketIO = socketIOClient("https://fastuz.com");
    socketIO.emit("join", formationSelected._id);
    socketIO.on("message", (data) => {
 
      if (data.etudiant === selectedEtudiant._id) {
        setMessages((curr) => [data, ...curr]);
        // ps.current?.scrollTop = ps.current?.scrollHeight;
      }
    });
    setsocket(socketIO);
    return () => {
      socketIO.emit("leave", formationSelected._id);
      socketIO.close();
    };
  }, [selectedEtudiant,messages]);

  useEffect(() => {
   
    getApprenants();
  }, []);
  const getApprenants = async () => {
    const res = await axios.get(
      process.env.REACT_APP_URL_BACKEND_DEV + `/getAllApprenantsFormateur/${idFormation}`, 
        {
          headers: { Authorization: `Bearer ${token}`, 
          withCredentials: true
        },
        }
    );
    setApprenants(res.data.EtudiantEnrolled);
 
  };
  const handleUserClick = (user) => {
    setselectedEtudiant(user);
  };

    return (
      <>

<Dialog
         open={view}
         onClose={handleCloseView}
        toggle={() => setimgPreviewUrl(null)}
        centered
      >
        <img
          className="img-fluid"
          src={imgPreviewUrl}
          alt="image preview"
        ></img>
        
      </Dialog>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Use Google's location service?"}
        </DialogTitle>
        <DialogContent>
        <Dropzone files={files} setFiles={setFiles}></Dropzone>

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Disagree</Button>
          <Button onClick={handleClose} autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
      <br></br><br></br>
      <div className="messenger">

        <div className="scrollable sidebar">
        <div className="conversation-list">
        <Toolbar
          title="Liste des apprenants"
          leftItems={[
            <ToolbarButton key="cog" icon="ion-ios-cog" />
          ]}
          rightItems={[
            <ToolbarButton key="add" icon="ion-ios-add-circle-outline" />
          ]}
        />
        <br/>
        {
          apprenants?.map(conversation =>
            <div className="conversation-list-item" key={conversation._id} onClick={() => handleUserClick(conversation)} >
            <img className="conversation-photo" src={conversation.photo} alt="cover" />
            <div className="conversation-info">
              <h1 className="conversation-title">{ conversation?.nom } { conversation?.prenom }</h1>
              <p className="conversation-snippet"></p>
            </div>
          </div>
          )
        }
      </div>
        </div>
        {selectedEtudiant ? (
        <div className="scrollable content">
          

       
          <div className="message-list">
        <Toolbar
          title=  {selectedEtudiant.prenom + " " + selectedEtudiant.nom}
          rightItems={[
            <ToolbarButton key="info" icon="ion-ios-information-circle-outline" />,
            <ToolbarButton key="video" icon="ion-ios-videocam" />,
            <ToolbarButton key="phone" icon="ion-ios-call" />
          ]}
        />
          <>

 
          <Grid item xs={12}>

        <Container>

        {messages?.map((message)=>
        (
          
           <div className={`chat-message ${message?.isFormateur ? "chat-message--right" : ""}`}>

      <span className="chat-message__avatar-frame">
        <img
          src={ (message?.isFormateur ? formateur?.photo : message?.etudiant?.photo)}
          alt="avatar"
          className="chat-message__avatar"
        />
     </span>

<>
  {message.contenu ==="" ? 
 
  <ul >
    {message?.files?.map((file, idx) => (
                                <>
                                  {file?.mimetype?.includes("image") && (
                                    <img
                                      src={file.link}
                                      style={{
                                       
                                        width: "100px",
                                        height: "100px",
                                        objectFit: "cover",
                                        marginBottom:"10px",
                                        marginTop:"10px",

                                        cursor: "pointer",
                                      }}
                                      onClick={() => handleClickOpenView(file?.link)}
                                    />
                                  )}

                                  {file?.mimetype?.includes("pdf") && (
                                    <a
                                      href={file.link}
                                    
                                    
                                      target="_blank"
                                    >
                                      {file?.name}
                                    </a>
                                  )}
                                </>
                              ))}
  </ul>:
  < >
  <ul>
   <p  className="chat-message__text" style={{  marginBottom:"5px",
                                    }}>{message.contenu} </p>
   
  
    {message?.files?.map((file, idx) => (
        <div   key={idx} >
                            

            {file?.mimetype?.includes("image") && (
                <div >
                    <img
                        src={file.link}
                        style={{
                         
                            width: "100px",
                            height: "100px",
                            marginBottom:"10px",
                            marginTop:"10px",

                            objectFit: "cover",
                            cursor: "pointer",
                        }}
                        className="shadow rounded "
                        onClick={() =>
                            setimgPreviewUrl(file.link)
                        }
                    />
                </div>
            )}
            {file?.mimetype?.includes("pdf") && (
                <div style={{display: "block"}}>
                    <a
                        href={file.link}
                        className="border rounded p-1 px-3"
                        target="_blank"
                    >
                        {file?.name}
                    </a>
                </div>
            )}
        </div>
        
    ))}
   
    </ul>
</>

}
                          
</>

    </div>
            )   )}      
      </Container>




                    {files?.length !== 0 && (
                      <a
                        href="#"
                        className="p-2 d-flex align-items-center"
                        onClick={(e) => {
                          e.preventDefault();
                          setIsModal(true);
                        }}
                      >
                        {files?.length} fichier(s)
                        {files?.some((f) => !f.valid) && (
                          <span className="ml-1 text-danger d-inline-flex align-items-center">
                            <Error className="mr-1" /> il y a des fichiers
                            invalides
                          </span>
                        )}
                      </a>
                    )}
                   </Grid>
                   {messages?.length>=5 ?
                      <Card sx={{ border: 0 }} >
                      <form   onSubmit={handleSubmit}>
                        <div  >
                         
                      <Button
                         outline
                         onClick={handleClickOpen}
                          className="chat-input-area__submit-buttonn"
                          >
                         <FileUpload></FileUpload>
                          </Button>
                      
                      
                            </div>
                            <input
                              type="text"
                              placeholder="Write something..."
                              value={message}
                              onChange={(e) => setMessage(e.target.value)}
                              className="chat-input-area__input"
                            />
                      
                      
                            <button type="submit" className="chat-input-area__submit-button" />
                            
                          </form>
                          </Card>:
    <Card style={{marginBottom:"0px", marginTop:"49vh", border: "5px solid transparent"}}>
<form   onSubmit={handleSubmit}>
  <div  >
   
<Button
   outline
   onClick={handleClickOpen}
    className="chat-input-area__submit-buttonn"
    >
   <FileUpload></FileUpload>
    </Button>

      </div>
      <input
        type="text"
        placeholder="Write something..."
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        className="chat-input-area__input"
      />

      <button type="submit" className="chat-input-area__submit-button" />
      
    </form>
    </Card>

    
                   }

                
   
    
  </>
    
          
          
          
          
  </div>
      
    
                  </div>
                  
                  
                  )
        : <p>
          <br/><br/>
          Sélectionner la discussion de l'apprenants
          </p>} 
      </div>
     
      </>
    );
}
