import React, { useState } from "react";
import {
  InputLabel,
  Input,
  TextField,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
} from "@material-ui/core";
import {  useNavigate } from 'react-router-dom';

import { styled } from '@mui/material/styles';
import { MHidden } from '../components/@material-extend';
import { Box, Card, Link, Container, Typography } from '@mui/material';
import Page from '../components/Page';
import { Link as RouterLink } from 'react-router-dom';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// import LaddaButton, { EXPAND_RIGHT } from "react-ladda";
import SweetAlert from "sweetalert-react";
import { useFormik } from "formik";
import * as yup from "yup";
import { Col, Form, Row } from "reactstrap";

import {
  removeLocalStorage,
  setLocalStorage,
  getLocalStorage,
} from "../utils/AuthStorage";
// import ImageSide from "./ImageSide";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import {
  SendCodeResetPasswordD,
  UpdatePassword,
  VerifyCodePassword,
} from "../utils/api";
import { LoadingButton } from "@mui/lab";
import AuthLayout from "../layouts/AuthLayout";
// import { useHistory } from "react-router-dom";
const validationSchemaEmailPage = yup.object({
  email: yup
    .string()
    .email("Entrer une adresse email valide")
    .required("Adresse email obligatoire"),
});

const validationSchemaVerifPage = yup.object({
  code: yup
    .string()
    .min(6, "Entrer un code valide de 6 characters")
    .max(6, "Entrer un code valide de 6 characters")
    .required("Code obligatoire"),
});

const validationSchemaChangePasswordPage = yup.object({
  newPassword: yup
    .string("Enter your password")
    .min(8, "Le mot de passe doit être au minimum 8 characters")
    .required("Mot de passe obligatoire"),
  confirmePassword: yup
    .string("Enter your password")
    .min(8, "Le mot de passe doit être au minimum 8 characters")
    .required("Mot de passe obligatoire")
    .oneOf(
      [yup.ref("newPassword"), null],
      "mot de passe ne sont pas identiques"
    ),
});


const RootStyle = styled(Page)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
      display: 'flex'
    }
  }));
const ContentStyle = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(12, 0)
  }));
const configToastr = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  newestOnTop: false,
  closeOnClick: true,
  rtl: false,
  pauseOnFocusLoss: true,
  draggable: true,
  pauseOnHover: true,
};

const SectionStyle = styled(Card)(({ theme }) => ({
    width: '100%',
    maxWidth: 464,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: theme.spacing(2, 0, 2, 2)
  }));
  
const ForgetPassword = () => {
  const [loading] = useState(false);
  const [emailPage, setEmailPage] = useState(true);
  const [VerifPage, setVerifPage] = useState(false);
  const [changePasswordPage, setChangePasswordPage] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmePassword, setShowConfirmePassword] = useState(false);
  const [disabledEmail, setdisabledEmail] = useState(false);
  //Sweet Alerte Params
  const navigate = useNavigate();

  const [show, setShow] = useState(false);
  const [typeAlerte, setTypeAlerte] = useState(false);
  const [titleAlerte, setTitleAlerte] = useState(false);
  const [texteAlerte, setTexteAlerte] = useState("");
//   const history = useHistory();
  const GoToVerifPage = () => {
    setEmailPage(!emailPage);
    setVerifPage(!changePasswordPage);
  };

  const GoToChangePasswordPage = () => {
    setVerifPage(!VerifPage);
    setChangePasswordPage(!changePasswordPage);
  };
  const formikEmailPage = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchemaEmailPage,
    onSubmit: async (values) => {
      const response = await SendCodeResetPasswordD(values.email);
console.log("response",response)
      if (
        (response.data.statue =
          false || response.data.message === "Utilisateur non enregistré")
      ) {
        toast.error("Utilisateur non enregistré")
        //formikEmailPage.resetForm();
      } else {
        setLocalStorage("email", values.email);
        setdisabledEmail(true);
        await GoToVerifPage();
      }
    },
  });

  const formikVerifPage = useFormik({
    initialValues: {
      code: "",
    },
    validationSchema: validationSchemaVerifPage,
    onSubmit: async (values) => {
      const response = await VerifyCodePassword({
        email: getLocalStorage("email"),
        code: values.code,
      });
      if (
        (response.data.statue =
          true && response.data.message === "Code correcte !")
      ) {
        setLocalStorage("code", values.code);
        await GoToChangePasswordPage();
      } else {
        toast.error("code incorrecte !")
        formikVerifPage.resetForm();
      }
    },
  });

  const formikPasswordPage = useFormik({
    initialValues: {
      newPassword: "",
      confirmePassword: "",
    },
    validationSchema: validationSchemaChangePasswordPage,
    onSubmit: async (values) => {
      const response = await UpdatePassword({
        code: getLocalStorage("code"),
        newPassword: values.newPassword,
        email: getLocalStorage("email"),
        confirmePassword: values.confirmePassword,
      });
      if (response.data.statue === true) {
        removeLocalStorage("email");
        removeLocalStorage("code");

        formikPasswordPage.resetForm();

        toast.success("Mot de passe modifie avec succées")
        navigate("/login")
      } else {
        toast.error("Vous avez entrer un ancien mot de passe")
      }
    },
  });
  return (
    <>

        <RootStyle title="Register | Fastuz">
        <AuthLayout>
      Vous avez déjà un compte? &nbsp;
        <Link underline="none" variant="subtitle2" component={RouterLink} to="/login">
          Login
        </Link>
      </AuthLayout>
    <MHidden width="mdDown">

        <SectionStyle>



          <img alt="register" src="/static/illustrations/illustration_login.jpg" />
        </SectionStyle>
      </MHidden>
<Container>
        <ContentStyle>

          {/* <Col lg="4" className="d-none d-lg-block">
            <ImageSide />
          </Col> */}
          <Col
            lg="8"
            md="12"
            className="h-100 d-flex bg-white justify-content-center align-items-center"
          >
            {emailPage && (
              <Col lg="9" md="1" sm="12" className="mx-auto app-login-box">
                <Typography className="justify-content-center">
                  <h1                     style={{ textAlign: "center" }}
>Récupération du mot de passe</h1>
                </Typography>
                <Row className="justify-content-center mb-4">
                  <h3
                    className="mb-0"
                    style={{  textAlign: "center" }}
                  >
                    <span style={{ color: "grey" }}>
                      Etape 1 : Entrer votre adresse mail pour réinitialiser
                      votre mot de passe.
                    </span>
                  </h3>
                </Row>

                <div>
                  <Form>
                    <Row form className="justify-content-center">
                      <Col md={3} style={{ textAlign: "center" }}>
                        <TextField
                          disabled={disabledEmail}
                          error={
                            formikEmailPage.touched.email &&
                            formikEmailPage.errors.email
                          }
                          helperText={formikEmailPage.errors.email}
                          id="email"
                          label="Email"
                          style={{ marginBottom: "5%" }}
                          fullWidth 
                          {...formikEmailPage.getFieldProps("email")}
                        />
                      </Col>

                      <div className="d-flex align-items-center">
                        <LoadingButton
                          disabled={disabledEmail}
                          tabIndex="1"
                          className=" btn  btn-primary btn-lg "
                          loading={loading}
                          onClick={async () => {
                            await formikEmailPage.handleSubmit();
                          }}
                          data-color="#43425D"
                          type="button"
                          fullWidth
                        >
                          Envoyer Code
                        </LoadingButton>

                        <ToastContainer {...configToastr} />
                      </div>
                    </Row>
                  </Form>
                </div>
              </Col>
            )}
            {VerifPage && (
              <Col lg="9" md="10" sm="12" className="mx-auto app-login-box">
                <Row className="justify-content-center mb-4">
                  <h3
                    className="mb-0"
                    style={{  textAlign: "center" }}
                  >
                    <span style={{ color: "grey" }}>
                      Etape 2 : Un code de vérification a été envoyé à votre boîte de réception.
                    </span>
                  </h3>
                </Row>

                <div>
                  <Form>
                    <Row form className="justify-content-center">
                      <Col md={6} style={{ textAlign: "center" }}>
                        <TextField
                          error={
                            formikVerifPage.touched.code &&
                            formikVerifPage.errors.code
                          }
                          fullWidth
                          helperText={formikVerifPage.errors.code}
                          id="code"
                          label="code de vérification"
                          style={{ marginBottom: "5%" }}
                          {...formikVerifPage.getFieldProps("code")}
                        />
                      </Col>

                      <div className="d-flex align-items-center">
                        <LoadingButton
                          tabIndex="1"
                          className=" btn  btn-primary btn-lg "
                          loading={loading}
                          onClick={async () => {
                            await formikVerifPage.handleSubmit();
                          }}
                          data-color="#43425D"
                          type="button"
                          fullWidth
                        >
                          Verifier Code
                        </LoadingButton>

                        <ToastContainer {...configToastr} />
                      </div>
                    </Row>
                  </Form>
                </div>
              </Col>
            )}

            {changePasswordPage && (
              <Col lg="9" md="10" sm="12" className="mx-auto app-login-box">
                <Row className="justify-content-center mb-4">
                  <h3
                    className="mb-0"
                    style={{ textAlign: "center" }}
                  >
                    <span style={{ color: "grey" }}>
                      Etape 3 : Modifier votre mot de passe.
                    </span>
                  </h3>
                </Row>

                <div>
                  <Form>
                    <Row
                      form
                      className="justify-content-center"
                      style={{ textAlign: "center" }}
                    >
                      <Col md={6} style={{ textAlign: "center" }}>
                        <FormControl fullWidth>
                          <InputLabel
                            htmlFor="newPassword"
                            error={
                              formikPasswordPage.touched.newPassword &&
                              formikPasswordPage.errors.newPassword
                            }
                          >
                            Nouveau mot de passe
                          </InputLabel>
                          <Input
                            id="newPassword"
                            type={showNewPassword ? "text" : "password"}
                            error={
                              formikPasswordPage.touched.newPassword &&
                              formikPasswordPage.errors.newPassword
                            }
                            //disable auto-complete
                            inputProps={{
                              autocomplete: "new-password",
                              form: {
                                autocomplete: "off",
                              },
                            }}
                            {...formikPasswordPage.getFieldProps("newPassword")}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={() =>
                                    setShowNewPassword(!showNewPassword)
                                  }
                                  onMouseDown={(e) => e.preventDefault()}
                                >
                                  {showNewPassword ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                          />
                          {formikPasswordPage.touched.newPassword &&
                            formikPasswordPage.errors.newPassword && (
                              <FormHelperText id="my-helper-text" error>
                                {formikPasswordPage.errors.newPassword}
                              </FormHelperText>
                            )}
                        </FormControl>
                      </Col>
                    </Row>
                    <Row
                      form
                      className="justify-content-center"
                      style={{ textAlign: "center" }}
                    >
                      <Col md={6} style={{ textAlign: "center" }}>
                        <FormControl fullWidth>
                          <InputLabel
                            htmlFor="confirmePassword"
                            error={
                              formikPasswordPage.touched.confirmePassword &&
                              formikPasswordPage.errors.confirmePassword
                            }
                          >
                            Confirmer mot de passe
                          </InputLabel>
                          <Input
                            id="confirmePassword"
                            type={showConfirmePassword ? "text" : "password"}
                            error={
                              formikPasswordPage.touched.confirmePassword &&
                              formikPasswordPage.errors.confirmePassword
                            }
                            //disable auto-complete
                            inputProps={{
                              autocomplete: "new-password",
                              form: {
                                autocomplete: "off",
                              },
                            }}
                            {...formikPasswordPage.getFieldProps(
                              "confirmePassword"
                            )}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={() =>
                                    setShowConfirmePassword(
                                      !showConfirmePassword
                                    )
                                  }
                                  onMouseDown={(e) => e.preventDefault()}
                                >
                                  {showConfirmePassword ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                          />
                          {formikPasswordPage.touched.confirmePassword &&
                            formikPasswordPage.errors.confirmePassword && (
                              <FormHelperText id="my-helper-text" error>
                                {formikPasswordPage.errors.confirmePassword}
                              </FormHelperText>
                            )}
                        </FormControl>
                      </Col>
                    </Row>

                    <Row
                      className="justify-content-center"
                      style={{ marginTop: "30px" }}
                    >
                        
                      <div className="d-flex align-items-center">
                        <LoadingButton
                          data-color="#43425D"
                          tabIndex="1"
                          className=" btn  btn-primary btn-lg "
                          loading={loading}
                          onClick={async () => {
                            await formikPasswordPage.handleSubmit();
                          }}
                          type="button"
                          fullWidth
                        >
                          Envoyer
                        </LoadingButton>

                        <ToastContainer {...configToastr} />
                      </div>
                    </Row>
                  </Form>
                </div>
              </Col>
            )}
          </Col>
          </ContentStyle>
          </Container>
          </RootStyle>
        {/* <SweetAlert
          show={show}
          title={titleAlerte}
          text={texteAlerte}
          type={typeAlerte}
        //   onConfirm={async () => {
        //     await setShow(false);
        //     if (typeAlerte === "success") history.push("/");
        //   }}
        /> */}
           {/* <SweetAlert
        show={show}
        title="Modification avec succées"
        type={typeAlerte}
        onConfirm={async () => {
            await setShow(false);
            if (typeAlerte === "success")  navigate('/login')
            ;
          }}
      /> */}
     
    </>
  );
};
export default ForgetPassword;
