
import { useState, useEffect } from "react";
import axios from "axios";

// material
import {

  Button,
  Container,
  FormGroup,

} from "@mui/material";
import Select from "react-select";

// components
import Page from "../../components/Page.js";
import moment from "moment";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';


import { CardBody, Col, Row } from "reactstrap";
import ReactTable from "react-table-v6";

import { Stack, Typography } from "@mui/material";
import ImportFileBusiness  from "./ImportFileBusiness.js";
import Cookies from 'js-cookie';
import { Link } from "react-router-dom";



export default function UserBusiness() {

  const [loading, setLoading] = useState(false);
  const [apprenants, setApprenants] = useState([]);
  const [formations, setFormations] = useState([]);

  const [open, setOpen] = useState(false);
  const token= Cookies.get("tokenAdmin")
  const[listFormations, setListFormations]= useState([])
  const [selectedFormation, setSelectedFormation] = useState({
    value: null,
    label: "All formations"
  });
  
  const [selectedDept, setSelectedDept] = useState(null);

  const handleFormationChange = (selectedOption) => {
    setSelectedFormation(selectedOption);
  };


  useEffect(() => {
    getApprenants();
    getMoreData();
  }, [selectedFormation]);

    const getMoreData = async () => {
   
      await axios.get(process.env.REACT_APP_URL_BACKEND_DEV + `/getAllFormationsFormateur`, 
        {
          headers: { Authorization: `Bearer ${token}`, 
          withCredentials: true
        },
      })
        .then(response => {
  setListFormations(response.data.formations)  
  console.log("form",response)

    })
        .catch(error => {
          console.error(error);
        });
    }

  const handleClose = () => {
    setOpen(false);
  };


  const getApprenants = async () => {
    setLoading(true);
    try {
      let res;
      if (selectedFormation.value === null) {
        res = await axios.get(
          process.env.REACT_APP_URL_BACKEND_DEV + `/getAllApprenantsLists`, 
          {
            headers: { Authorization: `Bearer ${token}`, 
            withCredentials: true
          },
        });
        console.log("rees", res.data);
        if (Array.isArray(res.data.data)) {
          const flattenedData = res.data.data.flatMap(({ formation, departement, listeInscrit }) => {
            return listeInscrit.map(apprenant => ({
              formation: formation?.titre,
              departement: departement?.titre,
              nom: apprenant.nom,
              prenom: apprenant.prenom,
              email: apprenant.email,
              enrolled: apprenant.enrolled,
              dateInscrit: apprenant.createdAt
            }));
          });
          const sortedApprenants = flattenedData.sort((a, b) => new Date(a.dateInscrit) - new Date(b.dateInscrit));
          setApprenants(sortedApprenants);
          console.log("eee",apprenants)
        } else {
          setApprenants([]); 
        }
      } else {
        res = await axios.get(
          process.env.REACT_APP_URL_BACKEND_DEV + `/getApprenantsByFormation/${selectedFormation.value}`, 
          {
            headers: { Authorization: `Bearer ${token}`, 
            withCredentials: true
          },
        });
        if (Array.isArray(res.data.data.listeInscrit)) {
          const flattenedData = res.data.data.listeInscrit.flatMap(apprenant => ({
            nom: apprenant.nom,
            prenom: apprenant.prenom,
            email: apprenant.email,
            dateInscrit: apprenant.createdAt
          }));
          const sortedApprenants = flattenedData.sort((a, b) => new Date(a.dateInscrit) - new Date(b.dateInscrit));
          setApprenants(sortedApprenants);
          console.log("flattenedData", flattenedData);
        } else {
          setApprenants([]); // Fallback to an empty array if data is not an array
        }
      }
    } catch (error) {
      console.error('Error fetching apprenants:', error);
      setApprenants([]); // Fallback to an empty array on error
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      Header: "Nom et Prénom",
      id: "nomPrenom",
      accessor: row => `${row.nom} ${row.prenom}`,
      filterMethod: (filter, row) =>
        row.nomPrenom.toLowerCase().includes(filter.value.toLowerCase()),
      Cell: ({ original }) => {
  console.log("orr",original)
        return (
        
              <span>{original.nom} {original.prenom}</span>
          
        );
      },
    },
    {
      Header: "Email",
      accessor: "email",
      Cell: ({ original }) => {
        return <div>{original?.email} </div>
      },

    },
    {
      Header: "Formation",
      accessor: "formation",
      Cell: ({ original }) => {
        return <div>{original?.formation} </div>
      },

    },
    {
      Header: "Date d'inscription",
      accessor: "createdAt",
      width: 150,
      Cell: ({ value }) => {
        return (
          <span style={{ wordBreak: "break-all" }}>
            {moment(value).locale("fr").format("DD/MM/YYYY")}
          </span>
        );
      },
      filterMethod: (filter, row) => {
        const date = moment(row[filter.id]).format("DD/MM/YYYY");
        return date.includes(filter.value);
      },
      defaultSortDesc: true,

    },
  

    // {
    //   Header: "Actif / Not Actif",
    //   accessor: "enrolled",
    //   Cell: ({ original }) => {
    //     const enrolledText = original.enrolled.length > 0 ? "Actif" : "Not Actif";
    //     const textColor = original.enrolled.length > 0 ? "green" : "red";
    //     return <div style={{ color: textColor }}>{enrolledText}</div>;
    //   },
    //   filterMethod: (filter, row) => {
    //     if (filter.value === "all") {
    //       return true;
    //     }
    //     if (filter.value === "true") {
    //       return row[filter.id].length > 0;
    //     }
    //     if (filter.value === "false") {
    //       return row[filter.id].length === 0;
    //     }
    //     return false;
    //   },
    //   Filter: ({ filter, onChange }) => {
    //     return (
    //       <select
    //         style={{ width: "100%", fontSize: "1rem" }}
    //         onChange={(event) => onChange(event.target.value)}
    //         value={filter ? filter.value : "all"}
    //       >
    //         <option value="all">Filtrer</option>
    //         <option value="true">Actif</option>
    //         <option value="false">Not Actif</option>
    //       </select>
    //     );
    //   },
    // },
    // {
    //   Header: "Certificats",
    //   accessor: "mesCertificats",
    //   Cell: ({ original }) => {
    //     // Display if they are enrolled and have certificates
    //     const hasCertificates = original?.enrolled?.length > 0 && original?.mesCertificats?.length > 0;
    //     return (
    //       <div>
    //         {original.enrolled?.length > 0 ? (hasCertificates ? "Yes" : "No") : "Not Available"}
    //       </div>
    //     );
    //   },
    //   filterMethod: (filter, row) => {
    //     const hasCertificates = row.mesCertificats?.length > 0;
    //     if (filter.value === "all") {
    //       return true;
    //     }
    //     if (filter.value === "yes") {
    //       return hasCertificates;
    //     }
    //     if (filter.value === "no") {
    //       return !hasCertificates;
    //     }
    //     return false;
    //   },
    //   Filter: ({ filter, onChange }) => {
    //     return (
    //       <select
    //         style={{ width: "100%", fontSize: "1rem" }}
    //         onChange={(event) => onChange(event.target.value)}
    //         value={filter ? filter.value : "all"}
    //       >
    //         <option value="all">Filtrer</option>
    //         <option value="yes">Yes</option>
    //         <option value="no">No</option>
    //       </select>
    //     );
    //   },
    // }




  ];


  return (
    <Page title="Dashboard | Fastuz">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
          Inscrits 
          </Typography>
      
         

        </Stack>

        <Select
          placeholder="Choisir Formation"
          value={selectedFormation}
          onChange={(selectedOption) => setSelectedFormation(selectedOption)}
          options={[
            { value: null, label: "All formations" }, // Add an option for All
            ...(listFormations?.map((formation) => ({
              value: formation._id,
              label: formation.titre,
            })) || []),
          ]}
        />
        <br></br>
        <CardBody>
          <Row>

            <ReactTable
              data={apprenants}
              nextText={"Suivant"}
              previousText={"Précedent"}
              rowsText={"Lignes"}
              columns={columns}
              defaultPageSize={10}
              filterable={true}
              noDataText="No data is available"
              defaultSorted={[{id :"createdAt" , desc : true}]}

            />


          </Row>
        </CardBody>
     
      </Container>
    </Page>
  );
}