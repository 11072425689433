
import React, { useEffect, useState } from "react";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { Paper, Typography } from '@mui/material';
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import Courses from "../Modules/modules"
import TestBlanc from "../Quiz/QuizzesTestBlanc"
import Certificat from "../Quiz/QuizzesCertificat"
import User from "../UserAdmin/User"
import Chat from "../Chat"
import { getFormationsById } from "../../redux/formationSlice"

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
export default function TabsWrappedLabel() {
  const [value, setValue] = React.useState(0);

  const { idFormation } = useParams();
  const [formationDetails, setFormationDetails] = useState({});

  const dispatch = useDispatch();

  const getData = async () => {
    if (idFormation) {
      const formation = await dispatch(getFormationsById(idFormation))
      setFormationDetails(formation);
    };

  }
  useEffect(() => {

    getData();
  }, [idFormation]);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

console.log("formationDetails.formationCertifie",formationDetails?.payload?.formationCertifie)

  return (
    <div>
          {formationDetails?.payload?.formationCertifie ? 
<>
    <Box sx={{ width: '100%' }}>
      <Tabs
        centered
        value={value}
        onChange={handleChange}
        variant="fullWidth"
        TabIndicatorProps={{
          style: {
            backgroundColor: "#0C001F",
          },
        }}
        aria-label="wrapped label tabs example"
      >
        <Tab label="Chapitres" {...a11yProps(0)} />
        <Tab label="Tests Blancs" {...a11yProps(1)} />
      
          <Tab label="Tests Certificats" {...a11yProps(2)} />
    
        <Tab label="Apprenants" {...a11yProps(3)} />
        <Tab label="Chat" {...a11yProps(4)} />
      </Tabs>
    </Box>


    <TabPanel value={value} index={0}>
      <Paper>
        <Courses />
      </Paper>
    </TabPanel>
    <TabPanel value={value} index={1}>
      <Paper>
        <TestBlanc />
      </Paper>
    </TabPanel>
      <TabPanel value={value} index={2}>
        <Paper>
          <Certificat />
        </Paper>
      </TabPanel>
    
    <TabPanel value={value} index={3}>
      <Paper>
        <User />
      </Paper>
    </TabPanel>
    <TabPanel value={value} index={4}>
      <Paper>
        <Chat />
      </Paper>
    </TabPanel>
</>
:
<>
<Box sx={{ width: '100%' }}>
      <Tabs
        centered
        value={value}
        onChange={handleChange}
        variant="fullWidth"
        TabIndicatorProps={{
          style: {
            backgroundColor: "#0C001F",
          },
        }}
        aria-label="wrapped label tabs example"
      >
        <Tab label="Chapitres" {...a11yProps(0)} />
        <Tab label="Tests Blancs" {...a11yProps(1)} />
      
          {/* <Tab label="Tests Certificats" {...a11yProps(2)} /> */}
    
        <Tab label="Apprenants" {...a11yProps(2)} />
        <Tab label="Chat" {...a11yProps(3)} />
      </Tabs>
    </Box>
    <TabPanel value={value} index={0}>
      <Paper>
        <Courses />
      </Paper>
    </TabPanel>
    <TabPanel value={value} index={1}>
      <Paper>
        <TestBlanc />
      </Paper>
    </TabPanel>
    <TabPanel value={value} index={2}>
      <Paper>
        <User />
      </Paper>
    </TabPanel>
    <TabPanel value={value} index={3}>
      <Paper>
        <Chat />
      </Paper>
    </TabPanel>

    </>

   }
  </div>
  
  );
}
