
import PropTypes from 'prop-types';
import { Link as RouterLink, useLocation } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Box, Link, Drawer, Typography, Avatar } from '@mui/material';
// components
import Logo from '../../components/Logo';
import Scrollbar from '../../components/Scrollbar';
import NavSection from '../../components/NavSection';
import { MHidden } from '../../components/@material-extend';
//
import sidebarConfig from './SidebarConfig';
import sidebarConfigFormateur from './SidebarConfigFormateur';
import sidebarConfigBusiness from './sidebarConfigBusiness';

import { useSelector } from 'react-redux';
import { useEffect, useState } from "react";
import axios from "axios";
import Cookies from 'js-cookie';
import { Rating } from "@mui/material";
// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH
  }
}));

const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: theme.shape.borderRadiusSm,
  backgroundColor: theme.palette.grey[200]
}));

// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const { pathname } = useLocation();
  const { CurrentUser} = useSelector((state) => state.auth);
  const userconnected = useSelector((state) => state.auth.CurrentUser);
  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
  }, [pathname]);

  const [makingApiCall, setMakingApiCall] = useState(true);
  const [fetchedData, setFetchedData] = useState([]);
  const token= Cookies.get("tokenAdmin")


  useEffect(() => {
    let mount = true;
    if (mount && userconnected.role==="formateur") {
      setMakingApiCall(true);
      axios
        .get(process.env.REACT_APP_URL_BACKEND_DEV + "/getAllCommentsFormateur", 
        {
          headers: { Authorization: `Bearer ${token}`, 
          withCredentials: true
        },}
        )
        .then((response) => {
          if (response.data.rating) setFetchedData(response.data.rating);
        })
        .finally(() => {
          setMakingApiCall(false);
        });
    }
    return () => (mount = false);
  }, []);
  const averageRating =
  fetchedData.reduce((total, formation) => total + formation.rating, 0) /
  fetchedData.length;


  const renderContent = (
    <Scrollbar
      sx={{
        height: '100%',
        '& .simplebar-content': { height: '100%', display: 'flex', flexDirection: 'column' }
      }}
    >
  {CurrentUser?.role === "admin" ? (
      <Box sx={{ px: 2.5, py: 3 }}>
        <Box component={RouterLink} to="/dashboard" sx={{ display: 'inline-flex' }}>
          <Logo />
        </Box>
      </Box>
    ) : CurrentUser?.role === "formateur" ? (
      <Box sx={{ px: 2.5, py: 3 }}>
        <Box component={RouterLink} to="/formateur/dashboard" sx={{ display: 'inline-flex' }}>
          <Logo />
        </Box>
      </Box>
    ) : CurrentUser?.role === "business" ? (
      // Add content specific for users with the "business" role
      <Box sx={{ px: 2.5, py: 3 }}>
        <Box component={RouterLink} to="/business/dashboard" sx={{ display: 'inline-flex' }}>
          <Logo />
        </Box>
      </Box>
    ) : null}

      <Box sx={{ mb: 5, mx: 2.5 }}>
        <Link underline="none" component={RouterLink} to="#">
          <AccountStyle>
          {CurrentUser && CurrentUser?.photo ? (
          <Avatar src={ CurrentUser?.photo } alt="photo" />
        ) : ("")} 
            <Box sx={{ ml: 2 }}>
              <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                {CurrentUser?.nom + " " + CurrentUser?.prenom}
              </Typography>
             
              {CurrentUser?.role === "formateur" && (
  <Rating
    name="read-only"
    value={averageRating}
    precision={0.5}
    readOnly
    sx={{ display: "flex", justifyContent: "left", fontSize: "19px" }}
  />
)}

            
            </Box>
          </AccountStyle>
        </Link>
      </Box>

      <NavSection
  navConfig={
    CurrentUser?.role === "business"
      ? sidebarConfigBusiness
      : userconnected?.role === "formateur"
      ? sidebarConfigFormateur
      : sidebarConfig
  }
/>

      <Box sx={{ flexGrow: 1 }} />

      <Box sx={{ px: 2.5, pb: 3, mt: 10 }}>
      
      </Box>
    </Scrollbar>
  );

  return (
    <RootStyle>
      <MHidden width="lgUp">
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>

      <MHidden width="lgDown">
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: 'background.default'
            }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>
    </RootStyle>
  );
}
