import { useFormik } from 'formik';
import { UploadeFileAPI } from '../../utils/api';
import {  Stack, Container } from '@mui/material';
import {  TextField} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Row, Col, FormGroup } from "reactstrap";
import * as yup from "yup";
import {  useNavigate } from 'react-router-dom';
import getBase64 from '../../utils/getBase64';
import { styled } from '@mui/material/styles';
import SweetAlert from 'react-bootstrap-sweetalert';
import { LoadingButton } from '@mui/lab';
import FileUploader from "../../assets/FileUploader"
import { Alert, Spinner } from 'reactstrap';

import axios from "axios"
const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 950,
  margin: 'auto',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

const validationSchema = yup.object({
    titre: yup.string().required("Titre obligatoire"),
    prix: yup.number().required("Prix obligatoire"),

  });
  const API_BASE = process.env.REACT_APP_URL_BACKEND_DEV;

export default function AddFormations( ) {
  let [showSuccess, setShowSuccess] = useState(false);
  let [showError, setShowError] = useState(false);
  const navigate = useNavigate();
    const [file, setFile] = useState(null);
    const [filePreview, setFilePreview] = useState(null);
    const [logo, setlogo] = useState(null);
    const [coverPreview, setCoverPreview] = useState(null);

    useEffect(() => {
      if (file) getBase64(file).then((url) => setFilePreview(url));
      else setFilePreview(null);
    }, [file]);    

        


    useEffect(() => {
      if (logo) getBase64(logo).then((url) => setCoverPreview(url));
      else setCoverPreview(null);
    }, [logo]);

    const formik = useFormik({
      initialValues: {
        titre: "",
        prix: 0,

      },
      validationSchema: validationSchema,
      onSubmit: async (values) => {
 
        const obj = {
          ...values,
        };
 
        if (logo) {
          const coverRes = await UploadeFileAPI(logo, "coverFormation");
          obj.logo = coverRes.data.location;;
       }
        if (file) {
          const fileRes = await UploadeFileAPI(file, "fileFormation");
         
          obj.file = fileRes.data.location;
        }
     
        const  data  =  await axios.post(
          API_BASE +`/addLicence`,obj);
        
        data.data.status ? setShowError(true)  : setShowSuccess(true);

     
        await navigate("/dashboard/licence")

        },
    });
 
     
  return (

     
  


  <Container maxWidth="l">
    <ContentStyle>

   

   
      <div className="form-wizard-content">
        <Row form>
        <Stack spacing={2}>
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} justifyContent="center" >
        <Col md={12}>
            {alert && (
              <Alert color="danger">
                {alert === "photo" && "Merci d'inserer une image"}
                {alert === "description" && "Merci d'inserer une description"}
              </Alert>
            )}
          </Col>
          <Col md={12} >
            {!logo && (
              <FileUploader
                fileTypes={["image/png", "image/jpg", "image/jpeg"]}
                setFileToUpload={setlogo}
                title="Icon"
                original=""
                
              ></FileUploader>
            )}
            {logo && (
              <div className="cover-preview" onClick={() => setlogo(null)}>
                <img src={coverPreview} width="100%" alt='' />
                <i className="lnr-trash"></i>
              </div>
            )}
          </Col>
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} justifyContent="center" >
          
          <Col md={6} className="mb-3">
<input
  type="file"
  accept=".zip"
  onChange={(e) => setFile(e.target.files[0])}
/>

{file && (
  <div>
    {file.name} 
    <LoadingButton
      onClick={() => {
        setFile(null);
      }}
    >
      Supprimer
    </LoadingButton>
  </div>
)}

          
          </Col>
      

      
 
          </Stack>
          <Col md={6}>
                <FormGroup>
                  <TextField
                    fullWidth
                    type="number"
                    name="prix"
                    id="prix"
                    label="Prix du logiciel"
                    placeholder="TND"
                    error={!!(formik.touched.prix && formik.errors.prix)}
                    helperText={formik.touched.prix && formik.errors.prix}
                    {...formik.getFieldProps("prix")}
                  />
                </FormGroup>
              </Col>
          <Col md={6}>
            <FormGroup>
              <TextField
                fullWidth
                name="titre"
                label="Titre"
                id="titre"
                placeholder="Titre"
                error={!!(formik.touched.titre && formik.errors.titre)}
                helperText={formik.touched.titre && formik.errors.titre}
                {...formik.getFieldProps("titre")}
              />
            </FormGroup>
          </Col>
       
    
        
    
          </Stack>
      
        </Row>
      </div>
      <br></br>
      <LoadingButton
            fullWidth
            size="medium"
            type="button"
            disabled={!formik.isValid}
            variant="contained"
            onClick={async () => {
              await formik.handleSubmit();
            }}
          >
            Ajouter logiciels
          </LoadingButton>
    
          <SweetAlert
        show={showSuccess}
        title="Ajout avec succées"
        type="success"
        onConfirm={() => {
          setShowSuccess(false);
        }}
      />

      <SweetAlert
        show={showError}
        title="Un problème est survenue !"
        type="error"
        onConfirm={() => {
          setShowError(false);
        }}
      />
          </ContentStyle>
     
          </Container>





       
    
 
  );
}
