import { Icon } from '@iconify/react';
import appleFilled from '@iconify/icons-ant-design/apple-filled';
// material
import { alpha, styled } from '@mui/material/styles';
import { Card, Typography } from '@mui/material';
// utils
import { fShortenNumber } from '../../../utils/formatNumber';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getAllApprenantsByFormateur } from '../../../redux/authSlice';
import Cookies from 'js-cookie';
import axios from "axios"
// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  padding: theme.spacing(5, 0),
  color: theme.palette.info.darker,
  backgroundColor: theme.palette.info.lighter
}));

const IconWrapperStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
  color: theme.palette.info.dark,
  backgroundImage: `linear-gradient(135deg, ${alpha(theme.palette.info.dark, 0)} 0%, ${alpha(
    theme.palette.info.dark,
    0.24
  )} 100%)`
}));



export default function AppNewUsers() {
  const [users, setusers] = useState(0);
  const token= Cookies.get("tokenAdmin");


  useEffect(() => {
   
    getApprenants();
  }, []);
  const getApprenants = async () => {
    const res = await axios.get(
      process.env.REACT_APP_URL_BACKEND_DEV + `/getAllApprenantsByFormateur`,  {
        headers: { Authorization: `Bearer ${token}`, 
        withCredentials: true
      },}
    );
    setusers(res.data.EtudiantEnrolled.length);
  
  };

  return (
    <RootStyle>
      <IconWrapperStyle>
        <Icon  icon="ant-design:user-add-outlined" width={24} height={24} />
      </IconWrapperStyle>
      <Typography variant="h3">{fShortenNumber(users)}</Typography>
      <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
       Apprenants
      </Typography>
    </RootStyle>
  );
}
