import {
  Stack,
  Container,
  Typography,
} from '@mui/material';
import Page from '../../components/Page';
import axios from 'axios';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllFormationsFormateur
} from "../../redux/formationSlice";
import {  useParams} from "react-router-dom";
import "react-table-v6/react-table.css";
import { useMemo } from "react";
import { LoadingButton } from '@mui/lab';
import ReactTableUI from 'react-table-ui';
import Cookies from "js-cookie";

export default function Formations() {
  const [page, setPage] = useState(0);
  const pageSize = 10;
  const token = Cookies.get("tokenAdmin");

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };
const {idQuiz}= useParams();
  const [scores, setScores]= useState([])
  const pageCount = Math.ceil(scores.length / pageSize);
  const currentPageData = scores.slice(page * pageSize, (page + 1) * pageSize);
  useEffect(() => {
    const getData = async () => {
      if (idQuiz) {
      const res =  await axios
          .get(
            process.env.REACT_APP_URL_BACKEND_DEV +`/getBestScorebyId/${idQuiz}`
          );

          const data = Object.values(res.data.result);

          setScores(data);


      }

    };
    getData();
  }, [idQuiz]);
  const dispatch = useDispatch();
  const columns: Column<Passenger>[] = useMemo(
    () => [
        {
            Header: "Nom Apprenants",
            accessor: "nom",
        
          },
          {
            Header: "Prenom Apprenants",
            accessor: "prenom",
       
          },
          {
            Header: "Scores",
            accessor: "score",
       
            Cell: ({ cell: { value } }) => {
              
                return (
                  <>
                    {value} %
                    
                  </>
                );
              }
          
          },
    ],
    []
  );


useEffect(() => {
  if(token) {
    dispatch(getAllFormationsFormateur());

  }
    }, [dispatch, token]);
    
  return (
    <Page title="Dashboard | Fastuz">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Scores des apprenants
          </Typography>
   
        </Stack>


        <ReactTableUI
      title='Scores Test Blancs'
      data={currentPageData}
     columns={columns}
    />
<div className="pagination">
    <>
        <LoadingButton
          onClick={() => handlePageChange(page - 1)}
          disabled={page === 0}
        >
          Précedent
        </LoadingButton>
        <LoadingButton
          onClick={() => handlePageChange(page + 1)}
          disabled={page === pageCount - 1}
        >
          Suivant
        </LoadingButton>
        </>
      </div>
      </Container>

    </Page>

  );
}