import React, { useEffect, useState } from "react";
import { CardFooter, Row } from "reactstrap";
import "antd/dist/antd.css"; 
import {  Typography } from "antd";
import {  Stack, Container, Card } from '@mui/material';
import axios from "axios";

export default function DetailsFormations({selectedContact}) {
   const idContact= selectedContact?._id
  const [loading, setloading] = useState(true);
    const [contact, setContact] = useState([]);

  
  
useEffect(() => {
  const getData = async () => {
    if (idContact) {
      setloading(true);
      await axios
        .get(
          process.env.REACT_APP_URL_BACKEND_DEV + `/contactbyid/${idContact}`
        )
        .then((res) => {
          if (res.status === 200) {
            
           
            setContact(res?.data);
            setloading(false);
          }
        });
    }
    
  };
  getData();
}, [idContact]);

  return (
    <div>
    

     <Container maxWidth="l">
    

        <Row>

        <Stack spacing={2}>
        

          <Typography
              variant="h6" 
          
            >Nom contact : {contact?.contact?.lastname}</Typography>

           <Typography
              variant="h6" 
          
            >Prenom contact : {contact?.contact?.firstname}</Typography>
            <Typography
              variant="h6" 
          
            >Email contact : {contact?.contact?.email}</Typography>
            <Typography
              variant="h6" 
          
            >Numéro contact : {contact?.contact?.phone}</Typography>

            <Typography
              variant="h6" 
          
            >Message : {contact?.contact?.message}</Typography>
          </Stack>

        </Row>
    
      
      <CardFooter className="d-block text-center">
     
      </CardFooter>
  

     
     </Container>
    </div>
  
  );
}