import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
 getAllContactAPI,
 getContactByIdAPI,
 
} from "../utils/api";

const initialState = {
  listecontacts: [],
  status: "idle",
  contactSelected: {},
};

export const getAllContacts = createAsyncThunk(
  "contact/getAllContacts",
  async (idJob) => {
     try {
       const response = await getAllContactAPI();
       return response.data.result;
     } catch (err) {
     }
   }
);

export const getContactById = createAsyncThunk(
  "contact/getcontactById",
  async (idContact) => {
    try {
      const response = await getContactByIdAPI(idContact);
      return response.data.result;
    } catch (err) {
    }
  }
);


export const candidatSlice = createSlice({
  name: "contacts",
  initialState,
  reducers: {},

  extraReducers: {
    [getAllContacts.pending]: (state, action) => {
      state.status = "loading";
    },
    [getAllContacts.fulfilled]: (state, action) => {
      state.status = "idle";
      state.listecontacts = action.payload;
    },
     [getContactById.pending]: (state, action) => {
       state.statusUpdate = "loading";
     },
     [getContactById.fulfilled]: (state, action) => {
       state.statusUpdate = "success";
       state.contactSelected = action.payload;
     },

 
 
  },
});

export default candidatSlice.reducer;
