import React from "react";
import CSSTransitionGroup from "react-transition-group/TransitionGroup";
import { Col, Row } from "reactstrap";
import TableauConnexionsFormateurs from "./TableauConnexionsFormateurs";
import { useState } from "react";
import { useEffect } from "react";
import { GetAllConnexionsFormateursAPI } from "../../utils/api";

const Connexions = () => {
  const [idUser, setIdUser] = useState("");
  const [rangeDate, setRangeDate] = useState([null, null]);
  const [typeUser, setTypeUser] = useState("notSelected");
  const [dureeTotale, setDureeTotale] = useState(0);
  const [arrayConnexions, setArrayConnexions] = useState([]);
  useEffect(() => {
 
        GetAllConnexionsFormateursAPI().then((res) => {
        setArrayConnexions(res.data.formateurConnexions );

      });
    
  }, [idUser, rangeDate]);


  return (
    <>
   
      <div className="app-main">
       
        <div className="app-main__outer">
          <div className="app-main__inner">
            <CSSTransitionGroup
              component="div"
              transitionName="TabsAnimation"
              transitionAppear={true}
              transitionAppearTimeout={0}
              transitionEnter={false}
              transitionLeave={false}
            >
             

              <Row>
              
                <Col md="12" style={{ marginTop: "3%" }}>
                  <TableauConnexionsFormateurs
                  dureeTotale={dureeTotale}
                    arrayConnexions={arrayConnexions}
                    idUser={idUser}
                    typeUser={typeUser}
                  />
                </Col>
              </Row>
            </CSSTransitionGroup>

          </div>
        </div>
      </div>
    </>
  );
};
export default Connexions;
