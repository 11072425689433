import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
 getAllJobsAPI, 
 getJobByIdAPI,
 SupprimerJobAPI

} from "../utils/api";

const initialState = {
  listeJobs: [],
  status: "idle",
  jobSelected: {},
};

export const getAllJobs = createAsyncThunk(
  "jobs/getAllJobs",
  async () => {
    try {
      const response = await getAllJobsAPI();
      return response.data.result;
    } catch (err) {
    }
  }
);

export const getJobsById = createAsyncThunk(
  "jobs/getJobsById",
  async (idJob) => {
    try {
      const response = await getJobByIdAPI(idJob);
      return response.data.result;
    } catch (err) {
    }
  }
);

export const removeJob = createAsyncThunk(
  "jobs/removeJob",
  async (id) => {
    const response = await SupprimerJobAPI(id);
    return response.data;
  }
);

export const jobsSlice = createSlice({
  name: "jobs",
  initialState,
  reducers: {},

  extraReducers: {
    [getAllJobs.pending]: (state, action) => {
      state.status = "loading";
    },
    [getAllJobs.fulfilled]: (state, action) => {
      state.status = "idle";
      state.listeJobs = action.payload;
    },
    [getJobByIdAPI.pending]: (state, action) => {
      state.statusUpdate = "loading";
    },
    [getJobByIdAPI.fulfilled]: (state, action) => {
      state.statusUpdate = "success";
      state.jobSelected = action.payload;
    },
  
    [removeJob.fulfilled]: (state, action) => {
      state.listeJobs = state.listeJobs.filter(
        (d) => d._id !== action.payload.jobs._id
      );
    },
  
 
  },
});

export default jobsSlice.reducer;
