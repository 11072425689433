// import { merge } from "lodash";
// import ReactApexChart from "react-apexcharts";
// // material
// import { useTheme, styled } from "@mui/material/styles";
// import { Card, CardHeader, List } from "@mui/material";
// // utils
// import { fNumber } from "../../../utils/formatNumber";
// //
// import Cookies from "js-cookie";

// import { BaseOptionChart } from "../../charts";
// import { useEffect, useState } from "react";
// import axios from "axios";
// // ----------------------------------------------------------------------

// const CHART_HEIGHT = 372;
// const LEGEND_HEIGHT = 72;

// const ChartWrapperStyle = styled("div")(({ theme }) => ({
//   height: CHART_HEIGHT,
//   marginTop: theme.spacing(5),
//   "& .apexcharts-canvas svg": { height: CHART_HEIGHT },
//   "& .apexcharts-canvas svg,.apexcharts-canvas foreignObject": {
//     overflow: "visible",
//   },
//   "& .apexcharts-legend": {
//     height: LEGEND_HEIGHT,
//     alignContent: "center",
//     position: "relative !important",
//     borderTop: `solid 1px ${theme.palette.divider}`,
//     top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`,
//   },
// }));

// // ----------------------------------------------------------------------

// export default function AppCurrentVisits() {
//   const theme = useTheme();

//   const [formations, setformations] = useState([]);
//   const [students, setStudents] = useState([]);
//   const [enrolledNumber, setenrolledNumber] = useState([]);
//   const [makingApiCall, setMakingApiCall] = useState(false);
//   const token = Cookies.get("tokenAdmin");

//   const getNumberOfStudentsEnrolled = async () => {
//     let arrayOfData = [];
//     formations.forEach((formation) => {
//       students.forEach((student) => {
//         student.enrolled.forEach((enrolled) => {
//           const findIndexOfEnrolled = arrayOfData.findIndex(
//             (enrolled) => enrolled.formationId === formation?._id
//           );

//           if (enrolled.formations?._id === formation?._id) {
//             findIndexOfEnrolled === -1
//               ? arrayOfData.push({ formationId: formation?._id, number: 1 })
//               : arrayOfData[findIndexOfEnrolled].number++;
//           }
//         });
//       });
//     });

//     setenrolledNumber(arrayOfData);
//     return arrayOfData;
//   };
//   useEffect(() => {
//     if (formations?.length !== 0 && students?.length !== 0) {
//       getNumberOfStudentsEnrolled();
//     }
//   }, [formations, students]);

  
//   useEffect(() => {

//   const getAllFormationsFormateur = async () => {
//     const result = await axios.get(process.env.REACT_APP_URL_BACKEND_DEV + `/getAllFormationsFormateur`, {
//       headers: { Authorization: `Bearer ${token}` }, 
//       withCredentials: true
//     });
//     setformations(result.data?.formations);
  
//   };
//   getAllFormationsFormateur();
// }, []);
// useEffect(() => {

//   const getAllApprenantsByFormateur = async () => {
//     const res = await axios.get(process.env.REACT_APP_URL_BACKEND_DEV + `/getAllApprenantsByFormateur`, {
//       headers: { Authorization: `Bearer ${token}` }, 
//       withCredentials: true
//     });

//     setStudents(res.data.EtudiantEnrolled);
  
//   };
//   getAllApprenantsByFormateur();

// }, []);
  

//   // get random color
//   const getRandomColor = (title) => {
//     var letters = "0123456789ABCDEF";
//     var color = "#";
//     for (var i = 0; i < 6; i++) {
//       color += letters[Math.floor(Math.random() * 16)];
//     }
//     return color;
//   };
//   // get random color from string

//   const chartOptions = merge(BaseOptionChart(), {
//     colors: formations?.map((formation) => getRandomColor(formation.titre)),
//     labels: formations?.map((formation) => formation.titre),
//     stroke: { colors: [theme.palette.background.paper] },
//     legend: { floating: true, horizontalAlign: "center" },
//     dataLabels: { enabled: true, dropShadow: { enabled: false } },
//     tooltip: {
//       fillSeriesColor: false,
//       y: {
//         formatter: (seriesName) => fNumber(seriesName) + " inscrits",
//         title: {
//           formatter: (seriesName) => `#${seriesName}`,
//         },
//       },
//     },
//     plotOptions: {
//       pie: { donut: { labels: { show: false } } },
//     },
//   });

//   return (
//     <Card>
//       <CardHeader title="Pourcentage apprenants inscrits" />
//       {!makingApiCall ? (
//         <ChartWrapperStyle dir="ltr">
//           <ReactApexChart
//             type="pie"
//             series={
//               enrolledNumber?.length
//                 ? enrolledNumber.map((enrolled) => enrolled.number)
//                 : [0, 0]
//             }
//             options={chartOptions}
//             height={280}
//           />
//         </ChartWrapperStyle>
//       ) : (
//         <span>Loading...</span>
//       )}
//     </Card>
//   );
// }
import { merge } from "lodash";
import ReactApexChart from "react-apexcharts";
// material
import { useTheme, styled } from "@mui/material/styles";
import { Card, CardHeader, List } from "@mui/material";
// utils
import { fNumber } from "../../../utils/formatNumber";
//
import Cookies from "js-cookie";

import { BaseOptionChart } from "../../charts";
import { useEffect, useState } from "react";
import axios from "axios";
// ----------------------------------------------------------------------

const CHART_HEIGHT = 372;
const LEGEND_HEIGHT = 72;

const ChartWrapperStyle = styled("div")(({ theme }) => ({
  height: CHART_HEIGHT,
  marginTop: theme.spacing(5),
  "& .apexcharts-canvas svg": { height: CHART_HEIGHT },
  "& .apexcharts-canvas svg,.apexcharts-canvas foreignObject": {
    overflow: "visible",
  },
  "& .apexcharts-legend": {
    height: LEGEND_HEIGHT,
    alignContent: "center",
    position: "relative !important",
    borderTop: `solid 1px ${theme.palette.divider}`,
    top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`,
  },
}));

// ----------------------------------------------------------------------

export default function AppCurrentVisits() {
  const theme = useTheme();

  const [formations, setformations] = useState([]);
  const [students, setStudents] = useState([]);
  const [enrolledNumber, setenrolledNumber] = useState([]);
  const [makingApiCall, setMakingApiCall] = useState(false);
  const token = Cookies.get("tokenAdmin");

  const getNumberOfStudentsEnrolled = async () => {
    let arrayOfData = [];
  
    formations.forEach((formation) => {
      console.log("Formation:", formation);
  
      students.forEach((student) => {
        console.log("Student:", student);
  
        student.enrolled.forEach((enrolled) => {
          console.log("Enrolled:", enrolled);
  
          // Check if the enrolled formationId is equal to formation id
          if (enrolled.formationId === formation._id) {
            // Find if the formationId already exists in arrayOfData
            const findIndexOfEnrolled = arrayOfData.findIndex(
              (data) => data.formationId === formation._id
            );
  
            // If formationId doesn't exist in arrayOfData, add it
            if (findIndexOfEnrolled === -1) {
              arrayOfData.push({ formationId: formation._id, number: 1 });
            } else {
              // If formationId already exists, increment the count
              arrayOfData[findIndexOfEnrolled].number++;
            }
          }
        });
      });
    });
  
    setenrolledNumber(arrayOfData);
    return arrayOfData;
  };
  
  useEffect(() => {
    if (formations?.length !== 0 && students?.length !== 0) {
      getNumberOfStudentsEnrolled();
    }
  }, [formations, students]);

  console.log("enrrrr",enrolledNumber)
  useEffect(() => {

  const getAllFormationsFormateur = async () => {
    const result = await axios.get(process.env.REACT_APP_URL_BACKEND_DEV + `/getAllFormationsFormateur`, {
      headers: { Authorization: `Bearer ${token}` }, 
      withCredentials: true
    });
    setformations(result.data?.formations);
  console.log("result",result.data)
  };
  getAllFormationsFormateur();
}, []);
useEffect(() => {

  const getAllApprenantsByFormateur = async () => {
    const res = await axios.get(process.env.REACT_APP_URL_BACKEND_DEV + `/getAllApprenantsByFormateur`, {
      headers: { Authorization: `Bearer ${token}` }, 
      withCredentials: true
    });

    setStudents(res.data.EtudiantEnrolled);
  };
  getAllApprenantsByFormateur();

}, []);
  
console.log("students",students)

  // get random color
  const getRandomColor = (title) => {
    var letters = "0123456789ABCDEF";
    var color = "#";
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };
  // get random color from string

  const chartOptions = merge(BaseOptionChart(), {
    colors: formations?.map((formation) => getRandomColor(formation.titre)),
    labels: formations?.map((formation) => formation.titre),
    stroke: { colors: [theme.palette.background.paper] },
    legend: { floating: true, horizontalAlign: "center" },
    dataLabels: { enabled: true, dropShadow: { enabled: false } },
    tooltip: {
      fillSeriesColor: false,
      y: {
        formatter: (seriesName) => fNumber(seriesName) + " inscrits",
        title: {
          formatter: (seriesName) => `#${seriesName}`,
        },
      },
    },
    plotOptions: {
      pie: { donut: { labels: { show: false } } },
    },
  });
  console.log("enrolledNumber",enrolledNumber)

  return (
    <Card>
      <CardHeader title="Pourcentage apprenants inscrits" />
      {!makingApiCall ? (
        <ChartWrapperStyle dir="ltr">
          <ReactApexChart
            type="pie"
            series={
              enrolledNumber?.length
                ? enrolledNumber.map((enrolled) => enrolled.number)
                : [0, 0]
            }
            options={chartOptions}
            height={280}
          />
        </ChartWrapperStyle>
      ) : (
        <span>Loading...</span>
      )}
    </Card>
  );
}




