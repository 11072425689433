import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { useState, useEffect } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import axios from "axios"
import {
  Card,
  Table,
  Stack,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination
} from '@mui/material';
import { format } from 'date-fns'; // Import date-fns for date formatting

import Cookies from 'js-cookie';
import Badge from '@material-ui/core/Badge';
import { makeStyles } from '@material-ui/core/styles';
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import { UserListHead, UserListToolbar } from '../../components/_dashboard/user';
import AffecterVoucherModal from "../Vouchers/AffecterVoucherModal";
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  dot: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    borderRadius: '50%',
  },
  green: {
    backgroundColor: 'green',
  },
  red: {
    backgroundColor: 'red',
  },
}));

const TABLE_HEAD = [

  { id: "voucherCode", label: "Code du voucher" },
  { id: "createdAt", label: "Date de création" },
  { id: "formation", label: "Formation " },
  // { id: "utilisateur", label: "Apprenant" },
  { id: "updatedAt", label: "Date de consommation" },
  { id: "typeProd", label: "Type Produit " },
  // { id: "nomProp", label: "Nom propriétaire " },
  { id: "reduction", label: "Reduction" },
  { id: "expired", label: "Status Voucher " },

];


function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
const token= Cookies.get("tokenAdmin");

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.nom.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis?.map((el) => el[0]);
}

export default function User() {

  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('nom');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [formateurs, setformateurs] = useState([]);
  const [vouchersF, setVoucherF] = useState([]);

  const [loading, setLoading] = useState(false);
  const [callback, setCallback] = useState(false);
  const [result, setResult] = useState(0);
  const [modalVoucher, setModalVoucher] = useState(false);
  const [selectedStudent, setselectedStudent] = useState("");

  useEffect(() => {
    getAllApprenantsByFormateur();
  }, [callback, page]);
  function formatDate(dateString) {
    const date = new Date(dateString);
    return format(date, 'dd/MM/yyyy HH:mm', { timeZone: 'Africa/Tunis' }); // Use Tunisian timezone
  }
  const openModalVoucher = (id) => {
    setselectedStudent(id);
    setModalVoucher(true);
  };
  const closeModalVoucher = () => {
    setModalVoucher(false);
  };


  
    const getAllApprenantsByFormateur = async () => {
      const res = await axios.get(process.env.REACT_APP_URL_BACKEND_DEV + `/getVoucherByBuisnessAffectAdmin`, {
        headers: { Authorization: `Bearer ${token}` }, 
        withCredentials: true
      });
      const sortedVouchers = res.data.result.sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
      setVoucherF(sortedVouchers);
      // setVoucherF(res.data.result);

console.log("voucheersF",vouchersF)
    
    };
    useEffect(() => {
    getAllApprenantsByFormateur();
  
  }, []);
  const handleDeleteUsers = async () => {
    setLoading(true);
    try {
      await axios.delete(process.env.REACT_APP_URL_BACKEND_DEV + '/deleteallVoucher', { data: { userIds: selected } });
      setSelected([]);
    } catch (error) {
      console.error(error);
    } finally {
      getAllApprenantsByFormateur();
      setLoading(false);
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = formateurs?.map((n) => n._id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };
  const handleClick = (event, nom) => {
    const selectedIndex = selected.indexOf(nom);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, nom);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected?.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 5));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - vouchersF?.length) : 0;

  const filteredUsers = applySortFilter(vouchersF, getComparator(order, orderBy), filterName);
  const isUserNotFound = filteredUsers?.length === 0;
  return (
   <Page title="Dashboard | Fastuz">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Liste des Vouchers
          </Typography>


        </Stack>

        <Card>
          <UserListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
            handleDeleteUsers={handleDeleteUsers}

          />
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={vouchersF?.length}
                  numSelected={selected?.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers
                    ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    ?.map((row) => {
                      const {
                        _id,
                        utilisateur,
                        formation,
                        expired,
                       typeProd,
                       nomProp,
                        createdAt,
                        updatedAt,
                        formateur,
                        partenaires,
                        reduction,
                        voucherCode,
                      } = row;
                      const isItemSelected =
                        selected.indexOf(_id) !== -1;
                      return (

                        <TableRow
                          hover
                          key={_id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          <TableCell align="center">
                        
                          </TableCell>

                          <TableCell component="th" scope="row" padding="none">
                            <Stack direction="row" alignItems="center" >
                              <Typography variant="subtitle2" noWrap>
                                {voucherCode}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell align="center">{formatDate(createdAt)}</TableCell>
                          <TableCell align="left">{formation?.titre}</TableCell>

                          {/* <TableCell align="left">{utilisateur?.nom} {utilisateur?.prenom}</TableCell> */}
                          <TableCell align="center">
  {expired
    ? formatDate(updatedAt)
    : '-'
  }
</TableCell>
                          <TableCell align="left">{typeProd?.titre}</TableCell>
                         


                          <TableCell align="left">{reduction} %</TableCell>

                          {expired === true ?
                            <TableCell align="left">
                              <div className={classes.root}>

                                <Badge
                                  className={`${classes.dot} ${classes.red}`}
                                  overlap="circle"
                                  variant="dot"
                                />
                                <p style={{ marginTop: "5px", marginLeft: "5px" }}> Expirée </p>

                              </div></TableCell> :

                            <TableCell align="left">
                              <div className={classes.root}>
                                <Badge
                                  className={`${classes.dot} ${classes.green}`}
                                  overlap="circle"
                                  variant="dot"

                                />
                                <p style={{ marginTop: "5px", marginLeft: "5px" }}> Non expirée </p>

                              </div></TableCell>}




                        </TableRow>


                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={vouchersF?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
     

      </Container>

    </Page>


  );
}
