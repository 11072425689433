import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import axios from "axios";
import React, {  useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Course from "../../components/courses/course/course";
import { useStyle } from "./styles";

const SingleCourse = (props) => {
  const classes = useStyle();
  const { _id } = props.modules;
console.log(_id)
  const [formation, setFormation] = useState([]);
  const [courses, setCourses] = useState([]);
  const [modules, setModules] = useState([]);
  const [task, setTask] = useState([]);
  const [loading, setLoading] = useState(false);


  const getData = async () => {
    if(_id){
      setLoading(true);
      await axios
        .get(process.env.REACT_APP_URL_BACKEND_DEV +`/moduleDetails/${_id}`)
        .then((res) => {
          if (res.status === 200) {
            setFormation(res.data);
            setModules(res.data.modules);
            setCourses(res.data.courses);    
            setLoading(false);
          }
        });
    }
    
  };
  useEffect(() => {
       getData();
   
  }, [_id]);

  const [value, setValue] = React.useState("lesson");



  return (
    <div className={classes.root}>
      {loading ? (
        <div className="loading">Loading&#8230;</div>
      ) : (
        <div>
          <Container className={classes.contains} maxWidth="xl">
            <div className={classes.top}>
              <span className={classes.link}>
                <Link to={`/addCourse/${_id}`}>
                  <h3 className={classes.linktxt}>
                    <Button
                      style={{
                        textTransform: "none",
                      }}
                      color="secondary"
                    >
                      Ajouter un cours
                    </Button>
                  </h3>
                </Link>
              </span>
            </div>
            <Grid container></Grid>

            {task.length !== 0 ? (
              <Box sx={{ width: "100%", typography: "body1" }}>
                <TabContext value={value}>
                  <TabPanel>
                    <div className={classes.lessonWrapper}>
                      {courses?.map((course) => (
                        <Course courses={course} key={course._id} />
                      ))}
                    </div>
                  </TabPanel>
                </TabContext>
              </Box>
            ) : (
              <div className={classes.lessonWrapper}>
                {courses?.map((course) => (
                  <Course courses={course} key={course._id} />
                ))}
              </div>
            )}
          </Container>
        </div>
      )}
    </div>
  );
};

export default SingleCourse;
