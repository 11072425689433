export { default as AppBugReports } from './AppBugReports';
export { default as AppConversionRates } from './AppConversionRates';
export { default as AppCurrentSubject } from './AppCurrentSubject';
export { default as AppCurrentVisits } from './AppCurrentVisits';
export { default as AppItemOrders } from './AppItemOrders';
export { default as AppNewsUpdate } from './AppNewsUpdate';
export { default as AppNewUsers } from './AppNewUsers';
export { default as AppOrderTimeline } from './AppOrderTimeline';
export { default as AppTasks } from './AppTasks';
export { default as AppTrafficBySite } from './AppTrafficBySite';
export { default as AppWebsiteVisits } from './AppWebsiteVisits';
export { default as AppWeeklySales } from './AppWeeklySales';
export { default as AppApprenantsRates } from './AppApprenantsRates';
export { default as AppNumberCertifFormations } from './AppNumberCertifFormations';

export {default as Rates} from "./rates";
