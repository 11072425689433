import {  Container, Grid } from '@mui/material';
import React, { useState } from "react";
import SaveIcon from "@mui/icons-material/Save";
import SweetAlert from 'react-bootstrap-sweetalert';
import { useFormik } from "formik";
import * as yup from "yup";
import { useSelector } from "react-redux";
import {
    InputLabel,
    Input,
    FormControl,
    FormHelperText,
  } from "@material-ui/core";
  import { LoadingButton } from '@mui/lab';
  import { styled } from '@mui/material/styles';
  import { UpdatePassword} from "../../utils/api";


  const ContentStyle = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(12, 0)
  }));

const validationSchema = yup.object({
  newPassword: yup
    .string("Enter your password")
    .min(5, "Password must be at least 5 characters")
    .required("Password is required"),
  confirmePassword: yup
    .string("Enter your password")
    .min(5, "Password must be at least 5 characters")
    .required("Password is required")
    .oneOf(
      [yup.ref("newPassword"), null],
      "Password must match"
    ),
});
const CodeVerification = (props) => {

    const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmePassword, setShowConfirmePassword] = useState(false);

  const { CurrentUser } = useSelector((state) => state.auth);
  const [show, setShow] = useState(false);
  const [typeAlerte, setTypeAlerte] = useState(false);
  const [titleAlerte, setTitleAlerte] = useState(false);
  const [texteAlerte, setTexteAlerte] = useState("");
  const formik = useFormik({
    initialValues: {
      newPassword: "",
      confirmePassword: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {

        const response = await UpdatePassword({
          code: props.code,
          newPassword: values.newPassword,
          email: CurrentUser.email,
          confirmePassword: values.confirmePassword,
        });
        if (response.data.statue === true) {
          formik.resetForm();
  
          setShow(true);
          setTypeAlerte("success");
          setTitleAlerte("Congrats !");
          setTexteAlerte("Password updated successfully ");
        } else {
          setShow(true);
          setTypeAlerte("warning");
          setTitleAlerte("You enter an old password !");
          setTexteAlerte(response.data.message);
        }
 
    
    },
  });

    return (
      <div>
            <Container>
            <ContentStyle>
            <div md={12} style={{ textAlign: "center" }}>                </div>
                <br></br>
                <br></br>
                <Grid container spacing={4} alignItems="center" >
                  
                    <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel
                        htmlFor="newPassword"
                        error={
                          formik.touched.newPassword &&
                          formik.errors.newPassword
                        }
                      >
                        Nouveau mot de passe
                      </InputLabel>
                      <Input
                        id="newPassword"
                        type={showNewPassword ? "text" : "password"}
                        error={
                          formik.touched.newPassword &&
                          formik.errors.newPassword
                        }
                        inputProps={{
                          autocomplete: "new-password",
                          form: {
                            autocomplete: "off",
                          },
                        }}
                        {...formik.getFieldProps("newPassword")}
                     
                        
                      />
                      {formik.touched.newPassword && formik.errors.newPassword && (
                        <FormHelperText id="my-helper-text" error>
                          {formik.errors.newPassword}
                        </FormHelperText>
                      )}
                    </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel
                        htmlFor="confirmePassword"
                        error={
                          formik.touched.confirmePassword &&
                          formik.errors.confirmePassword
                        }
                      >
                        Confirmer mot de passe
                      </InputLabel>
                      <Input
                      
                        id="confirmePassword"
                        type={showConfirmePassword ? "text" : "password"}
                        error={
                          formik.touched.confirmePassword &&
                          formik.errors.confirmePassword
                        }
                        inputProps={{
                          autocomplete: "new-password",
                          form: {
                            autocomplete: "off",
                          },
                        }}
                        {...formik.getFieldProps("confirmePassword")}
                       
                      
                        fullWidth
                      />
                      {formik.touched.confirmePassword &&
                        formik.errors.confirmePassword && (
                          <FormHelperText id="my-helper-text" error>
                            {formik.errors.confirmePassword}
                          </FormHelperText>
                        )}
                    </FormControl>
                    </Grid>
                </Grid>

                <LoadingButton
                onClick={async () => {
                        await formik.handleSubmit();
                      }}
                    fullWidth
                    size="large"
                    variant="contained"
                    style={{
                        backgroundColor: "#0d3077",
                        textTransform: "none",
                    }}
                    sx={{ mt: 5 }}
                >
                    <SaveIcon /> Modifier mot de passe
                </LoadingButton>
                </ContentStyle>

            </Container>
            <SweetAlert
        show={show}
        title={titleAlerte}
        text={texteAlerte}
        type={typeAlerte}
        onConfirm={async () => {
          setShow(false);
        }}
      />
      
      </div>
    );
};

export default CodeVerification;