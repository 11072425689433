import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
 getAllCandidatAPI,
 getCandidatByIdAPI,
 
} from "../utils/api";

const initialState = {
  listeCandidates: [],
  status: "idle",
  candidatSelected: {},
};

export const getAllCandidates = createAsyncThunk(
  "course/getAllCandidates",
  async (idJob) => {
     try {
       const response = await getAllCandidatAPI(idJob);
       return response.data.result;
     } catch (err) {
     }
   }
);

export const getCandidatById = createAsyncThunk(
  "course/getCourseById",
  async (idCandidat) => {
    try {
      const response = await getCandidatByIdAPI(idCandidat);
      return response.data.result;
    } catch (err) {
    }
  }
);


export const candidatSlice = createSlice({
  name: "courses",
  initialState,
  reducers: {},

  extraReducers: {
    [getAllCandidates.pending]: (state, action) => {
      state.status = "loading";
    },
    [getAllCandidates.fulfilled]: (state, action) => {
      state.status = "idle";
      state.listeCandidates = action.payload;
    },
     [getCandidatById.pending]: (state, action) => {
       state.statusUpdate = "loading";
     },
     [getCandidatById.fulfilled]: (state, action) => {
       state.statusUpdate = "success";
       state.candidatSelected = action.payload;
     },

  
  
 
  },
});

export default candidatSlice.reducer;
