import * as React from 'react';
import Cookies from 'js-cookie';
import { useState, useEffect } from "react";
import axios from "axios"
import {
  Card,
  Stack,
  Container,
  Typography,
} from '@mui/material';
import Page from '../../components/Page';
import { CardBody, Col, Row } from "reactstrap";
import ReactTable from 'react-table-v6'
function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

export default function BasicTable() {
  const [apprenants, setApprenants]= useState([])
  const token= Cookies.get("tokenAdmin")
  const [loading, setLoading] = useState(false);
  const columns = [
    {
      Header: "Nom et Prénom",
      id: "nomPrenom",
  accessor: row => `${row.nom} ${row.prenom}`,
  filterMethod: (filter, row) =>
    row.nomPrenom.toLowerCase().includes(filter.value.toLowerCase()),
      Cell: ({ original }) => {
        return (
          <span style={{ wordBreak: "break-all" }}>
            {original.nom} {original.prenom}
          </span>
        );
      },
    },
    {
      Header: "Email",
      accessor: "email",

      Cell: ({ original }) => {
        return (
          <span style={{ wordBreak: "break-all" }}>
            {original.email} 
          </span>
        );
      },
    },
    {
      Header: "Status du compte",
      accessor: "active",
      Cell: ({original}) => {
        return <div>{original?.active  ? "True" : "False"} </div>
      },
      filterMethod: (filter, row) => {
        if (filter.value === "all") {
          return true;
        }
        if (filter.value === "true") {
          return row[filter.id] === true;
        }
        if (filter.value === "false") {
          return row[filter.id] === false;
        }
        return false;
      },
      Filter: ({ filter, onChange }) => {
        return (
          <select
          style={{ width: "100%", fontSize: "1rem" }}

            onChange={(event) => onChange(event.target.value)}
            value={filter ? filter.value : "all"}
          >
            <option value="all">Filtrer</option>
            <option value="true">Compte activé</option>
            <option value="false">Compte désactivé</option>
          </select>
        );
      },
    }
    
   

  ];

  useEffect(() => {
   
    getApprenants();
  }, []);
  const getApprenants = async () => {
    setLoading(true);
    const res = await axios.get(
      process.env.REACT_APP_URL_BACKEND_DEV + `/getAllApprenantsByFormateur`,  {
        headers: { Authorization: `Bearer ${token}`, 
        withCredentials: true
      },}
    );
    setApprenants(res.data.EtudiantEnrolled);
  
  };
  return (
    <Page title="Dashboard | Fastuz">
    <Container>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom>
          Apprenants
        </Typography>
       
      </Stack>

      <Row>
      <Col md="12">
        <Card className="main-card mb-3">
          <CardBody>
            <ReactTable
              data={apprenants}
              nextText={"Suivant"}
              previousText={"Précedent"}
              rowsText={"Lignes"}
              columns={columns}
              defaultPageSize={5}
              filterable={true}
              noDataText="No data is available"
            />
          </CardBody>
        </Card>
      </Col>
    </Row>
    </Container>

  </Page>
  );
}

