
import React from "react";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { Paper,  Typography } from '@mui/material';
import PropTypes from "prop-types";
import TestHistoriqueApprenants from "./testHistoriqueApprenants";
import TestHistoriqueFormateur from "./testHistoriqueFormateur";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
export default function TabsWrappedLabel() {
  const [value, setValue] = React.useState(0);
  
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <div>
    <Box sx={{ width: '100%' }}>
      <Tabs
       
        centered

        value={value}
        onChange={handleChange}
        variant="fullWidth"
        TabIndicatorProps={{
          style: {
            backgroundColor: "#0C001F",
          },
        }}
        aria-label="wrapped label tabs example"
      >
        <Tab
         
          label="Accès Fastuz.com"
          {...a11yProps(0)}
        />
        <Tab label="Accès admin Fastuz" {...a11yProps(1)} />
  
      

      </Tabs>
    </Box>
    <TabPanel value={value} index={0}>
                  <TestHistoriqueApprenants/> 
  </TabPanel>
  <TabPanel value={value} index={1}>
                  <Paper >
<TestHistoriqueFormateur/>
    </Paper>
  </TabPanel>
  
  </div>
  );
}


