import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import trash2Fill from '@iconify/icons-eva/email-fill';
import {

  TextField
} from '@mui/material';
import Swal from "sweetalert2";

import { styled } from '@mui/material/styles';
import {
  Toolbar,
  Tooltip,
  IconButton,
  Typography,
  OutlinedInput,
} from '@mui/material';
import { useState } from 'react';

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3)
}));

UserListToolbarDesactive.propTypes = {
  numSelected: PropTypes.number,
  handleDeleteUsers: PropTypes.func,
};

export default function UserListToolbarDesactive({ numSelected, handleDeleteUsers }) {
  const sendEmail = async () => {
    if (numSelected > 0) {
      const { value: formValues } = await Swal.fire({
        title: 'Envoyer un e-mail',
        html:
          '<input id="swal-input-subject" class="swal2-input" placeholder="Sujet de l\'e-mail">' +
          '<textarea id="swal-textarea-body" class="swal2-textarea" placeholder="Corps de l\'e-mail"></textarea>',
        focusConfirm: false,
        preConfirm: () => {
          return {
            emailSubject: document.getElementById('swal-input-subject').value,
            emailBody: document.getElementById('swal-textarea-body').value,
          };
        },
      });

      if (formValues && formValues.emailSubject && formValues.emailBody) {
        try {
          await handleDeleteUsers(formValues.emailSubject, formValues.emailBody);
          Swal.fire('Mail Envoyé avec succès!');
        } catch (error) {
          console.error(error);
          Swal.fire('Erreur lors de l\'envoi du mail', '', 'error');
        }
      } else {
        Swal.fire('Veuillez remplir tous les champs!', '', 'error');
      }
    } else {
      Swal.fire('Veuillez sélectionner au moins un élément!', '', 'warning');
    }
  };

  return (
   
       <RootStyle
       sx={{
         ...(numSelected > 0 && {
           color: 'primary.main',
           bgcolor: 'primary.lighter'
         })
       }}
     >
       {numSelected > 0 ? (
         <Typography component="div" variant="subtitle1">
           {numSelected} selected
         </Typography>
       ) : (
         ""
       )}
 
       {numSelected > 0 ? (
         <Tooltip title="Envoyer">
           <IconButton onClick={sendEmail}>
             <Icon icon={trash2Fill} />
           </IconButton>
         </Tooltip>
       ) : (
         ""
       )}
     </RootStyle>
  );
}
