import { configureStore } from '@reduxjs/toolkit';
import authSlice from "./authSlice.js";
import formationSlice from "./formationSlice";
import courseSlice from "./courseSlice"
import voucherSlice from './voucherSlice.js';
import partnerSlice from './partnerSlice.js';
import formateurSlice from './formateurSlice.js';
import reviewSlice from './reviewSlice.js';
import candidatSlice from './candidatSlice.js';
import jobsSlice from './jobsSlice.js';
import discussionSlice from './discussionSlice.js';
import moduleSlice from './moduleSlice.js';
import contactSlice from "./contactSlice";
import NotificationSlice from "./notificationSlice";
import SocketSlice from "./SocketSlice";
import themeOptionsReducer from "./themeOptionsSlice";
import Certification from "./CertificationSlice";
import CertifFormation from "./Certif-FormationSlice";
import coursSlice from "./coursSlice";
import formateursReducer from "./formateursSlice";
import categoriesReducer from "./categoriesSlice";
import SouscategoriesReducer from "./sousCategorieSlice";
import quizzSlice from "./QuizzSlice";
import categorieSlice from './categorieSlice';
import contratVSlice from './contratVSlice.js';
import conditionUSlice from './conditionUSlice.js';
import contratVFormateurSlice from './contratVSlice.js';
import conditionUFormateurSlice from './conditionUSlice.js';
import typeProdSlice from './typeProdSlice.js';
import nomPropSlice from './nomPropSlice.js';
import publiciteSlice from './publiciteSlice.js';
import TypeFormationSlice from "./typeFormationSlice.js"
import ImpotSlice from "./impotSlice.js"



export const store = configureStore({
  reducer: {
    auth: authSlice,
    formations: formationSlice,
    courses: courseSlice,
    vouchers: voucherSlice,
    contrat: contratVSlice,
    condition: conditionUSlice,
    contratF: contratVFormateurSlice,
    conditionF :conditionUFormateurSlice,
    categorie: categorieSlice,
    TypeFormation: TypeFormationSlice,
    reglage: partnerSlice,
    type: typeProdSlice,
    nomProp: nomPropSlice,
    formateurs: formateurSlice,
    comments: reviewSlice,
    jobs: jobsSlice,
    publicite: publiciteSlice,
    candidat: candidatSlice,
    discus: discussionSlice,
    module: moduleSlice,
    notification: NotificationSlice,
    contact: contactSlice,
    socket: SocketSlice,
    ThemeOptions: themeOptionsReducer,
    Certification: Certification,
    CertifFormation: CertifFormation,
    cours: coursSlice,
    formateurs: formateursReducer,
    categories: categoriesReducer,
    quizz: quizzSlice,
    sousCategorie: SouscategoriesReducer,
    impot:ImpotSlice, 

  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});




