import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import moment from "moment";
import { DeleteTypeFormationAPI } from "../../utils/api";
import { getPartners } from "../../redux/partnerSlice";
import { Icon } from "@iconify/react";
import plusFill from '@iconify/icons-eva/plus-fill';
import { useNavigate } from 'react-router-dom';
import { Button, Stack, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import ReactTable from 'react-table-v6';
import Swal from "sweetalert2";
import AddImpot from "./addImpot"
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import axios from "axios";
import UpdateValeurImpot from "./DetailsImpot"
function Partenaire() {
  let [show, setShow] = useState(false);
  const [page, setPage] = useState(0);
  const pageSize = 10;
  const [partners, setPartners] = useState([])
  const [selectedContact, setSelectedContact] = useState({});

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [update, setUpdate] = React.useState(false);

  const handleClickOpenUpdate = (partner) => {
    setSelectedContact(partner);

    setUpdate(true);
  };

  const handleCloseUpdate = () => {
    setUpdate(false);
  };
  let [modal, setModal] = useState(false);
  let [type, setType] = useState("");
  const toggleModal = () => setModal(!modal);
  let [selectedPartner, setSelectedPartner] = useState({});
  const listPartners = useSelector((state) => state.reglage.listPartners);
  const [loading, setLoading] = React.useState(false);
  console.log(partners)
  console.log(listPartners)
  const pageCount = Math.ceil(partners.length / pageSize);
  const currentPageData = partners.slice(page * pageSize, (page + 1) * pageSize);
  const dispatch = useDispatch();
  const navigate = useNavigate();



  const statsColumns = [

    {
      Header: "Valeur TVA",
      accessor: "valeurTVA",
      style: { wordBreak: "break-all" },
    },
    {
      Header: "Valeur RS",
      accessor: "valeurRS",
      style: { wordBreak: "break-all" },
    },


    {
      Header: "Actions",
      style: { textAlign: "center" },
      Cell: ({ original }) => {
        return (
          <div className="d-block w-100 text-center">
            <LoadingButton
              onClick={() => handleClickOpenUpdate(original)}                    >
              Modifier
            </LoadingButton>

          </div>
        );
      },
    },

  ];

  const getPartners = async () => {
    setLoading(true);
    const res = await axios.get(
      process.env.REACT_APP_URL_BACKEND_DEV + `/getImpots`
    );
    console.log(res)
    setPartners(res.data.result);
    setLoading(false);
  };
  useEffect(() => {

    getPartners();
  }, []);


  return (
    <div>
      <Card className="card-hover-shadow-2x mb-3">

        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>

            impots

          </Typography>

          {partners.length > 1 ?
            <Button
              variant="contained"
              onClick={handleClickOpen}
              startIcon={<Icon icon={plusFill} />}
            >
              Ajouter les valeurs d'impots
            </Button> : ("")
          }

        </Stack>
        <CardBody>

          <Row>
            <ReactTable
              data={partners}
              nextText={"Suivant"}
              previousText={"Précedent"}
              rowsText={"Lignes"}
              columns={statsColumns}
              defaultPageSize={1}
              filterable={true}
              noDataText="No data is available"
            />
          </Row>
        </CardBody>
      </Card>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Ajouter les valeurs d'impots"}
        </DialogTitle>
        <DialogContent>
          <AddImpot handleClose={handleClose} getPartners={getPartners} />
        </DialogContent>

      </Dialog>

      <Dialog
        open={update}
        onClose={handleCloseUpdate}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Modifier les valeurs d'impots"}
        </DialogTitle>
        <DialogContent>
          <UpdateValeurImpot selectedContact={selectedContact} handleCloseUpdate={handleCloseUpdate} getPartners={getPartners} />
        </DialogContent>

      </Dialog>

    </div>
  );
}

export default Partenaire;


