// import {
//   Button,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogTitle,
// } from "@mui/material";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import {
//   Input,
// } from "@material-ui/core";
// import React, { useEffect, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { AddVoucher } from "../../redux/voucherSlice";
// import axios from "axios"
// import Select from "react-select";
// import { Row, Col, FormGroup, Alert } from "reactstrap";
// import { getTypeProd } from '../../redux/typeProdSlice';
// import { getNomProp } from '../../redux/nomPropSlice';
// import Switch from '@mui/material/Switch';
// import FormControlLabel from '@mui/material/FormControlLabel';

// const configToastr = {
//   position: "top-right",
//   autoClose: 5000,
//   hideProgressBar: false,
//   newestOnTop: false,
//   closeOnClick: true,
//   rtl: false,
//   pauseOnFocusLoss: true,
//   draggable: true,
//   pauseOnHover: true,
// };

// const AffecterVoucherModal = ({ modal, toggleModal, getUsers }) => {

//   const [numCodes, setNumCodes] = useState("");
//   const [reduction, setReduction] = useState("");
//   const [buyByUser, setBuyByUser] = useState(false);

//   const [selectedProdLabel, setSelectedProdLabel] = useState("");
//   const [selectedProd, setSelectedProd] = useState("");
//   const categorie = useSelector((state) => state.type.listTypeProd);
//   const [selectedFormation, setSelectedFormation] = useState("");
//   const [business, setBusiness] = useState("");
//   const [selectedPartner, setSelectedPartner] = useState("");

//   const [selectedBusiness, setSelectedBusiness] = useState("");
//   const [selectedFormateur, setSelectedFormateur] = useState("");
//   const [partners, setPartners] = useState("");
//   const [formations, setFormations] = useState([]);
//   const [typeFormations, setTypeFormation] = useState([]);
//   const [selectedTypeFormation, setSelectedTypeFormation] = useState(null);
//   const [formationsfiltered, setformationsfiltered] = useState([]);


//   const nom = useSelector((state) => state.nomProp.listNomProp);
//   // const formateur = useSelector((state) => state);
//   const [formateur, setformateur] = useState([]); // New state for formateurs
//   const getUserss = async () => {
//     const res = await axios.get(
//       process.env.REACT_APP_URL_BACKEND_DEV + `/formateurs`
//     );
//     setformateur(res.data.formateurs);
//   };
//   const handleChange = (event) => {
//     setBuyByUser(event.target.checked);
//   };
//   const getPartners = async () => {
//     const res = await axios.get(
//       process.env.REACT_APP_URL_BACKEND_DEV + `/partner`
//     );
//     setPartners(res.data.partners);
//   };
//   const getBusiness = async () => {
//     const res = await axios.get(
//       process.env.REACT_APP_URL_BACKEND_DEV + `/allUsersBuisness`
//     );
//     setBusiness(res.data.users);
//   };

//   const getFormation = async () => {
//     const res = await axios.get(
//       process.env.REACT_APP_URL_BACKEND_DEV + `/getAllFormationsApprenant`
//     );
//     setFormations(res.data.formations);
//   };
//   console.log("form", formations)
//   const getVouchers = async () => {
//     const res = await axios.get(process.env.REACT_APP_URL_BACKEND_DEV + `/voucherr`)

//   };

//   const getTypeFormations = async () => {
//     const res = await axios.get(
//       process.env.REACT_APP_URL_BACKEND_DEV + `/typeFormation`
//     );
//     console.log(res)
//     setTypeFormation(res.data.categorie);
//   };
//   console.log(formations)
//   useEffect(() => {
//     getUserss();
//     getTypeFormations();
//     getPartners();
//     getFormation();
//     getBusiness()
//   }, []);
//   const dispatch = useDispatch();
//   const optionsNom = [
//     { value: '', label: 'Clear' },
//     ...(Array.isArray(nom)
//       ? nom.map((categories) => ({
//         value: categories._id,
//         label: categories.titre,
//       }))
//       : [])
//   ];
//   const optionsCategorie = [
//     { value: '', label: 'Clear' },
//     ...(Array.isArray(categorie)
//       ? categorie.map((categories) => ({
//         value: categories._id,
//         label: categories.titre,
//       }))
//       : [])
//   ];
//   useEffect(() => {
//     dispatch(getTypeProd());
//     dispatch(getNomProp());

//   }, [dispatch]);

//   // const handleAffecterVoucher = async () => {
//   //   let formData = {
//   //     numCodes: numCodes,
//   //     categorie: selectedProd,
//   //     reduction: reduction,
//   //     nom: selectedNomValue,
//   //     formateur: selectedNomLabel === 'Formateur' ? selectedFormateur : null,
//   //     partenairesBusiness: selectedNomLabel === 'Business' ? selectedBusiness : null,
//   //     formation: selectedProdLabel === 'formation' ? selectedFormation : null,
//   //     buyByUser: buyByUser, // Ensure this is passed correctly
//   //   };

//   //   dispatch(AddVoucher(formData))
//   //     .then((res) => {
//   //       toast("Affectation avec succès", {
//   //         position: "top-right",
//   //         autoClose: 3000,
//   //         hideProgressBar: false,
//   //         closeOnClick: true,
//   //         pauseOnHover: false,
//   //         draggable: true,
//   //         progress: undefined,
//   //         type: "success",
//   //       });
//   //       // Clear input fields
//   //       setNumCodes("");
//   //       setReduction("");
//   //       setSelectedProd("");
//   //       setSelectedNomValue("");
//   //       setSelectedNomLabel("");
//   //       setSelectedProdLabel("");
//   //       setSelectedProd("");
//   //       setBuyByUser(false);
//   //       setSelectedFormateur("");
//   //       setSelectedPartner("");
//   //       setSelectedBusiness("");
//   //     })
//   //     .then(() => {
//   //       // Close the modal after the AddVoucher action is completed
//   //       toggleModal();
//   //       getUsers(); // Assuming this function fetches users
//   //     });
//   // };

//   const handleAffecterVoucher = async () => {
//     // Perform validation checks
//     if (!numCodes || !selectedProd || !reduction || !selectedNomValue) {
//       toast("Veuillez remplir tous les champs requis.", {
//         position: "top-right",
//         autoClose: 3000,
//         hideProgressBar: false,
//         closeOnClick: true,
//         pauseOnHover: false,
//         draggable: true,
//         progress: undefined,
//         type: "error", // Change to error type for validation feedback
//       });
//       return; // Exit the function if validation fails
//     }

//     // Prepare form data
//     let formData = {
//       numCodes: numCodes,
//       categorie: selectedProd,
//       reduction: reduction,
//       nom: selectedNomValue,
//       formateur: selectedNomLabel === 'Formateur' ? selectedFormateur : null,
//       partenairesBusiness: selectedNomLabel === 'Business' ? selectedBusiness : null,
//       formation: selectedProdLabel === 'formation' ? selectedFormation : null,
//       buyByUser: buyByUser, // Ensure this is passed correctly
//     };

//     // Dispatch the AddVoucher action
//     dispatch(AddVoucher(formData))
//       .then((res) => {
//         toast("Affectation avec succès", {
//           position: "top-right",
//           autoClose: 3000,
//           hideProgressBar: false,
//           closeOnClick: true,
//           pauseOnHover: false,
//           draggable: true,
//           progress: undefined,
//           type: "success",
//         });
//         // Clear input fields
//         setNumCodes("");
//         setReduction("");
//         setSelectedProd("");
//         setSelectedNomValue("");
//         setSelectedNomLabel("");
//         setSelectedProdLabel("");
//         setBuyByUser(false);
//         setSelectedFormateur("");
//         setSelectedPartner("");
//         setSelectedBusiness("");
//       })
//       .then(() => {
//         // Close the modal after the AddVoucher action is completed
//         toggleModal();
//         getUsers(); // Assuming this function fetches users
//       });
//   };

//   // const handleAffecterVoucher = async () => {
//   //   let formData = {
//   //     numCodes: numCodes,
//   //     categorie: selectedProd,
//   //     reduction: reduction,
//   //     nom: selectedNomValue,
//   //     formateur: selectedNomLabel === 'Formateur' ? selectedFormateur : null,
//   //     partenairesBusiness: selectedNomLabel === 'Business' ? selectedBusiness : null,
//   //     // partenaires: null,

//   //     formation: selectedProdLabel === 'formation' ? selectedFormation : null,
//   //     buyByUser,
//   //     //  formation :  null,

//   //   };

//   //   dispatch(AddVoucher(formData))
//   //     .then((res) => {
//   //       toast("Affectation avec succès", {
//   //         position: "top-right",
//   //         autoClose: 3000,
//   //         hideProgressBar: false,
//   //         closeOnClick: true,
//   //         pauseOnHover: false,
//   //         draggable: true,
//   //         progress: undefined,
//   //         type: "success",
//   //       });

//   //       // Clear input fields
//   //       setNumCodes("");
//   //       setReduction("");
//   //       setSelectedProd("");
//   //       setSelectedNomValue(""); // Clear selectedNomValue
//   //       setSelectedNomLabel(""); // Clear selectedNomLabel
//   //       setSelectedProdLabel(""); // Clear selectedNomLabel
//   //       setSelectedProd(""); // Clear selectedNomLabel
//   //       setBuyByUser(false)
//   //       setSelectedFormateur(""); // Clear selectedFormateur
//   //       setSelectedPartner(""); // Clear selectedPartner
//   //       setSelectedBusiness("")
//   //     })
//   //     .then(() => {
//   //       // Close the modal after the AddVoucher action is completed
//   //       toggleModal();
//   //       getUsers(); // Assuming this function fetches users
//   //     });
//   // };
//   const clearInputAndClose = () => {
//     setNumCodes("");
//     setReduction("");
//     setBuyByUser(false)

//     toggleModal();
//     getUsers()
//   };
//   const handleTypeFormationChange = (selectedOption) => {
//     setSelectedTypeFormation(selectedOption.value);
//     // Filtrez les formations en fonction du type sélectionné
//     const filteredFormations = formations.filter(
//       (formation) => formation.typeFormation?._id === selectedOption?.value
//     );
//     setformationsfiltered(filteredFormations);
//   };

//   const handleChangeCategorie = async (selectedOption) => {
//     setSelectedProd(selectedOption.value);
//     setSelectedProdLabel(selectedOption.label);
//     try {
//       const res = await axios.get(process.env.REACT_APP_URL_BACKEND_DEV + `/typeProd_details/${selectedOption.value}`);
//       if (selectedOption.value === 'formation') {
//         // Fetch the list of partenaires here
//         getFormation(); // Call getPartners to update the partenaires state
//       }
//     } catch (error) {
//       console.error(error);
//     }
//   };
//   const [selectedNomValue, setSelectedNomValue] = useState("");
//   const [selectedNomLabel, setSelectedNomLabel] = useState("");
//   const handleChangeNom = async (selectedOption) => {
//     setSelectedNomValue(selectedOption.value);
//     setSelectedNomLabel(selectedOption.label);

//     try {
//       const res = await axios.get(process.env.REACT_APP_URL_BACKEND_DEV + `/nomProp_details/${selectedOption.value}`);
//       if (selectedOption.value === 'Formateur') {
//         // Fetch the list of formateurs here
//         getUserss(); // Call getUserss to update the formateurs state
//       }
//       if (selectedOption.value === 'Buisness') {
//         // Fetch the list of partenaires here
//         getBusiness(); // Call getPartners to update the partenaires state
//       }

//     } catch (error) {
//       console.error(error);
//     }
//   };


//   return (
//     <>


//       <Dialog
//         open={modal}
//         onClose={clearInputAndClose}
//         aria-labelledby="alert-dialog-title"
//         aria-describedby="alert-dialog-description"
//       >
//         <DialogTitle id="alert-dialog-title">
//           {"Affectation Voucher"}
//         </DialogTitle>
//         <br></br>
//         <DialogContent style={{ height: "380px", width: "600px" }}>
//           <Input
//             placeholder="Choisir un nombre de voucher"
//             style={{ marginTop: "1em" }}
//             fullWidth
//             value={numCodes}
//             onChange={e => setNumCodes(e.target.value)}

//           />
//           <br></br>
//           <br></br>
//           <Input
//             placeholder="Reduction voucher"
//             style={{ marginTop: "1em" }}
//             fullWidth
//             value={reduction}
//             onChange={e => setReduction(e.target.value)}
//             disabled={!numCodes}

//           />
//           <br />
//           <br />

//           {reduction === "100" && (
//             <FormControlLabel
//               control={
//                 <Switch
//                   checked={buyByUser}
//                   onChange={handleChange}
//                   inputProps={{ 'aria-label': 'Formation certifiée' }}
//                 />
//               }
//               label="Voucher acheté par l'utilisateur"
//               labelPlacement="start"

//             />
//           )}
//           {console.log(!numCodes)
//           }          <FormGroup>
//             <div style={{ overflowY: 'auto' }}>
//               <Select
//                 placeholder="Choisir type produit"
//                 options={optionsCategorie}
//                 onChange={handleChangeCategorie}
//                 // disabled={!numCodes.trim()}
//                 isDisabled={!numCodes}
//                 menuPosition='fixed'
//               />
//             </div>

//           </FormGroup>
//           {selectedProdLabel === "formation" && (
//             <br></br>
//           )}
//           {selectedProdLabel === "formation" && (

//             <Select
//               placeholder="Choisir type formation"
//               options={typeFormations.map(type => ({
//                 value: type._id,
//                 label: type.titre
//               }))}
//               onChange={handleTypeFormationChange}
//               menuPosition="fixed"
//               disabled={!numCodes}
//             />)}
//           <br></br>

//           {selectedTypeFormation && (
//             <FormGroup>
//               <div style={{ overflowY: "auto" }}>
//                 <Select
//                   placeholder="Choisir une formation"
//                   options={formationsfiltered.map(formation => ({
//                     value: formation._id,
//                     label: formation.titre
//                   }))}
//                   onChange={(selectedOption) => setSelectedFormation(selectedOption.value)}
//                   menuPosition="fixed"

//                 />
//               </div>
//             </FormGroup>
//           )}



//           <br></br>

//           <FormGroup>
//             <div style={{ overflowY: 'auto' }}>
//               <Select
//                 placeholder="Choisir nom propriétaire"
//                 options={optionsNom}
//                 onChange={handleChangeNom}
//                 isDisabled={!numCodes}
//                 menuPosition='fixed'
//               />
//             </div>

//           </FormGroup>
//           <br></br>
//           {selectedNomLabel === 'Business' && (
//             <FormGroup>
//               <div style={{ overflowY: 'auto' }}>
//                 <Select
//                   placeholder="Choisir partenaires buisness"
//                   options={business.map(partenaire => ({ value: partenaire._id, label: partenaire.nom }))}
//                   onChange={selectedOption => setSelectedBusiness(selectedOption.value)}
//                   menuPosition='fixed'
//                 />
//               </div>
//             </FormGroup>
//           )}

//           {selectedNomLabel === 'Formateur' && (
//             <FormGroup>
//               <div style={{ overflowY: 'auto' }}>
//                 <Select
//                   placeholder="Choisir Formateur"
//                   options={formateur.map(formateur => ({
//                     value: formateur._id,
//                     label: `${formateur.nom} ${formateur.prenom}`
//                   }))}
//                   onChange={selectedOption =>

//                     setSelectedFormateur(selectedOption.value)

//                   }
//                   menuPosition='fixed'
//                 />
//               </div>
//             </FormGroup>
//           )}

  

//         </DialogContent>
//         <DialogActions>
//           <Button onClick={clearInputAndClose}>annuler</Button>
//           <Button
//             onClick={handleAffecterVoucher}

//             type="button"
//           >
//             Ajouter vouchers
//           </Button>
//         </DialogActions>
//       </Dialog>
//       <ToastContainer

//         {...configToastr}
//       />
//     </>
//   );
// };

// export default AffecterVoucherModal;



import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Input,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AddVoucher } from "../../redux/voucherSlice";
import axios from "axios"
import Select from "react-select";
import { Row, Col, FormGroup, Alert } from "reactstrap";
import { getTypeProd } from '../../redux/typeProdSlice';
import { getNomProp } from '../../redux/nomPropSlice';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
// import DatePicker from "react-datepicker";

// import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./DatePickerStyles.css"; // Import your custom styling for the DatePicker
const configToastr = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  newestOnTop: false,
  closeOnClick: true,
  rtl: false,
  pauseOnFocusLoss: true,
  draggable: true,
  pauseOnHover: true,
};

const AffecterVoucherModal = ({ modal, toggleModal, getUsers }) => {

  const [numCodes, setNumCodes] = useState("");
  const [reduction, setReduction] = useState("");
  const [buyByUser, setBuyByUser] = useState(false);

  const [selectedProdLabel, setSelectedProdLabel] = useState("");
  const [selectedProd, setSelectedProd] = useState("");
  const categorie = useSelector((state) => state.type.listTypeProd);
  const [selectedFormation, setSelectedFormation] = useState("");
  const [business, setBusiness] = useState("");
  const [selectedPartner, setSelectedPartner] = useState("");

  const [selectedBusiness, setSelectedBusiness] = useState("");
  const [selectedFormateur, setSelectedFormateur] = useState("");
  const [partners, setPartners] = useState("");
  const [formations, setFormations] = useState([]);
  const [typeFormations, setTypeFormation] = useState([]);
  const [selectedTypeFormation, setSelectedTypeFormation] = useState(null);
  const [formationsfiltered, setformationsfiltered] = useState([]);
  const [expiredAt, setExpiredAt] = useState(null); // New state for the expiry date


  const nom = useSelector((state) => state.nomProp.listNomProp);
  // const formateur = useSelector((state) => state);
  const [formateur, setformateur] = useState([]); // New state for formateurs
  const getUserss = async () => {
    const res = await axios.get(
      process.env.REACT_APP_URL_BACKEND_DEV + `/formateurs`
    );
    setformateur(res.data.formateurs);
  };
  const handleChange = (event) => {
    setBuyByUser(event.target.checked);
  };
  const getPartners = async () => {
    const res = await axios.get(
      process.env.REACT_APP_URL_BACKEND_DEV + `/partner`
    );
    setPartners(res.data.partners);
  };
  const getBusiness = async () => {
    const res = await axios.get(
      process.env.REACT_APP_URL_BACKEND_DEV + `/allUsersBuisness`
    );
    setBusiness(res.data.users);
  };

  const getFormation = async () => {
    const res = await axios.get(
      process.env.REACT_APP_URL_BACKEND_DEV + `/getAllFormationsApprenant`
    );
    setFormations(res.data.formations);
  };
  console.log("form", formations)
  const getVouchers = async () => {
    const res = await axios.get(process.env.REACT_APP_URL_BACKEND_DEV + `/voucherr`)

  };

  const getTypeFormations = async () => {
    const res = await axios.get(
      process.env.REACT_APP_URL_BACKEND_DEV + `/typeFormation`
    );
    console.log(res)
    setTypeFormation(res.data.categorie);
  };
  console.log(formations)
  useEffect(() => {
    getUserss();
    getTypeFormations();
    getPartners();
    getFormation();
    getBusiness()
  }, []);
  const dispatch = useDispatch();
  const optionsNom = [
    { value: '', label: 'Clear' },
    ...(Array.isArray(nom)
      ? nom.map((categories) => ({
        value: categories._id,
        label: categories.titre,
      }))
      : [])
  ];
  const optionsCategorie = [
    { value: '', label: 'Clear' },
    ...(Array.isArray(categorie)
      ? categorie.map((categories) => ({
        value: categories._id,
        label: categories.titre,
      }))
      : [])
  ];
  useEffect(() => {
    dispatch(getTypeProd());
    dispatch(getNomProp());

  }, [dispatch]);



  const handleAffecterVoucher = async () => {
    // Perform validation checks
    if (!numCodes || !selectedProd || !reduction || !selectedNomValue || !expiredAt) {
      toast("Veuillez remplir tous les champs requis.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        type: "error", // Change to error type for validation feedback
      });
      return; // Exit the function if validation fails
    }

    // Prepare form data
    let formData = {
      numCodes: numCodes,
      categorie: selectedProd,
      reduction: reduction,
      nom: selectedNomValue,
      formateur: selectedNomLabel === 'Formateur' ? selectedFormateur : null,
      partenairesBusiness: selectedNomLabel === 'Business' ? selectedBusiness : null,
      formation: selectedProdLabel === 'formation' ? selectedFormation : null,
      buyByUser: buyByUser, // Ensure this is passed correctly
      expiredAt: expiredAt ? expiredAt.toISOString() : null, // Converting Date to ISO

    };

    // Dispatch the AddVoucher action
    dispatch(AddVoucher(formData))
      .then((res) => {
        toast("Affectation avec succès", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          type: "success",
        });
        // Clear input fields
        setNumCodes("");
        setReduction("");
        setSelectedProd("");
        setSelectedNomValue("");
        setSelectedNomLabel("");
        setSelectedProdLabel("");
        setBuyByUser(false);
        setSelectedFormateur("");
        setSelectedPartner("");
        setSelectedBusiness("");
        setExpiredAt(null); // Clear expiration date

      })
      .then(() => {
        // Close the modal after the AddVoucher action is completed
        toggleModal();
        getUsers(); // Assuming this function fetches users
      });
  };


  const clearInputAndClose = () => {
    setNumCodes("");
    setReduction("");
    setBuyByUser(false)

    toggleModal();
    getUsers()
  };
  const handleTypeFormationChange = (selectedOption) => {
    setSelectedTypeFormation(selectedOption.value);
    // Filtrez les formations en fonction du type sélectionné
    const filteredFormations = formations.filter(
      (formation) => formation.typeFormation?._id === selectedOption?.value
    );
    setformationsfiltered(filteredFormations);
  };

  const handleChangeCategorie = async (selectedOption) => {
    setSelectedProd(selectedOption.value);
    setSelectedProdLabel(selectedOption.label);
    try {
      const res = await axios.get(process.env.REACT_APP_URL_BACKEND_DEV + `/typeProd_details/${selectedOption.value}`);
      if (selectedOption.value === 'formation') {
        // Fetch the list of partenaires here
        getFormation(); // Call getPartners to update the partenaires state
      }
    } catch (error) {
      console.error(error);
    }
  };
  const [selectedNomValue, setSelectedNomValue] = useState("");
  const [selectedNomLabel, setSelectedNomLabel] = useState("");
  const handleChangeNom = async (selectedOption) => {
    setSelectedNomValue(selectedOption.value);
    setSelectedNomLabel(selectedOption.label);

    try {
      const res = await axios.get(process.env.REACT_APP_URL_BACKEND_DEV + `/nomProp_details/${selectedOption.value}`);
      if (selectedOption.value === 'Formateur') {
        // Fetch the list of formateurs here
        getUserss(); // Call getUserss to update the formateurs state
      }
      if (selectedOption.value === 'Buisness') {
        // Fetch the list of partenaires here
        getBusiness(); // Call getPartners to update the partenaires state
      }

    } catch (error) {
      console.error(error);
    }
  };


  return (
    <>


      <Dialog
        open={modal}
        onClose={clearInputAndClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Affectation Voucher"}
        </DialogTitle>
        <br></br>
        <DialogContent style={{ height: "380px", width: "600px" }}>
          <Input
            placeholder="Choisir un nombre de voucher"
            style={{ marginTop: "1em" }}
            fullWidth
            value={numCodes}
            onChange={e => setNumCodes(e.target.value)}

          />
          <br></br>
          <Input
            placeholder="Reduction voucher"
            style={{ marginTop: "1em" }}
            fullWidth
            value={reduction}
            onChange={e => setReduction(e.target.value)}
            disabled={!numCodes}

          />
          <br />

          {reduction === "100" && (
            <FormControlLabel
              control={
                <Switch
                  checked={buyByUser}
                  onChange={handleChange}
                  inputProps={{ 'aria-label': 'Formation certifiée' }}
                />
              }
              label="Voucher acheté par l'utilisateur"
              labelPlacement="start"

            />
          )}
<br></br>
                  <FormGroup>
            <div style={{ overflowY: 'auto' }}>
              <Select
                placeholder="Choisir type produit"
                options={optionsCategorie}
                onChange={handleChangeCategorie}
                // disabled={!numCodes.trim()}
                isDisabled={!numCodes}
                menuPosition='fixed'
              />
            </div>

          </FormGroup>
          {selectedProdLabel === "formation" && (
            <br></br>
          )}
          {selectedProdLabel === "formation" && (

            <Select
              placeholder="Choisir type formation"
              options={typeFormations.map(type => ({
                value: type._id,
                label: type.titre
              }))}
              onChange={handleTypeFormationChange}
              menuPosition="fixed"
              disabled={!numCodes}
            />)}

          {selectedTypeFormation && (
            <FormGroup>
              <div style={{ overflowY: "auto" }}>
                <Select
                  placeholder="Choisir une formation"
                  options={formationsfiltered.map(formation => ({
                    value: formation._id,
                    label: formation.titre
                  }))}
                  onChange={(selectedOption) => setSelectedFormation(selectedOption.value)}
                  menuPosition="fixed"

                />
              </div>
            </FormGroup>
          )}

<br></br>


          <FormGroup>
            <div style={{ overflowY: 'auto' }}>
              <Select
                placeholder="Choisir nom propriétaire"
                options={optionsNom}
                onChange={handleChangeNom}
                isDisabled={!numCodes}
                menuPosition='fixed'
              />
            </div>

          </FormGroup>
          {selectedNomLabel === 'Business' && (
            <FormGroup>
              <div style={{ overflowY: 'auto' }}>
                <Select
                  placeholder="Choisir partenaires buisness"
                  options={business.map(partenaire => ({ value: partenaire._id, label: partenaire.nom }))}
                  onChange={selectedOption => setSelectedBusiness(selectedOption.value)}
                  menuPosition='fixed'
                />
              </div>
            </FormGroup>
          )}

          {selectedNomLabel === 'Formateur' && (
            <FormGroup>
              <div style={{ overflowY: 'auto' }}>
                <Select
                  placeholder="Choisir Formateur"
                  options={formateur.map(formateur => ({
                    value: formateur._id,
                    label: `${formateur.nom} ${formateur.prenom}`
                  }))}
                  onChange={selectedOption =>

                    setSelectedFormateur(selectedOption.value)

                  }
                  menuPosition='fixed'
                />
              </div>
            </FormGroup>
          )}

  


<FormGroup>
  <label>Sélectionner la date d'expiration </label> 
  
            <DatePicker
              selected={expiredAt}
              onChange={(date) => setExpiredAt(date)}
              minDate={new Date()} // Ensure the user selects today's or future dates
              dateFormat="yyyy-MM-dd"
              placeholderText="Date d'expiration"
              className="custom-datepicker" // Apply custom class
              fullWidth
            />
          </FormGroup>


        </DialogContent>
        <DialogActions>
          <Button onClick={clearInputAndClose}>annuler</Button>
          <Button
            onClick={handleAffecterVoucher}

            type="button"
          >
            Ajouter vouchers
          </Button>
        </DialogActions>
      </Dialog>
      <ToastContainer

        {...configToastr}
      />
    </>
  );
};

export default AffecterVoucherModal;
