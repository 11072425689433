import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import moment from "moment";
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { useParams } from "react-router-dom";

import { useNavigate } from 'react-router-dom';
import { Button, Stack, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import ReactTable from 'react-table-v6'
import axios from "axios";
function Partenaire() {
  const { idUser } = useParams();
  const [formation, setFormation] = useState([]);

  const listPartners = useSelector((state) => state.reglage.listPartners);
  const [loading, setLoading] = React.useState(false);

  const statsColumns = [
    {
      Header: "Order Number",
      accessor: "formation",
      width: 150,
      Cell: ({ original }) => {
        return (
          <span style={{ wordBreak: "break-all" }}>
            {original.OrderNumber}
          </span>
        );
      },
      style: { wordBreak: "break-all" },
    },

    {
      Header: "Formation",
      id: "formation",
      style: { textAlign: "center" },
      accessor: row => `${row.formation?.titre}`,
      filterMethod: (filter, row) =>
        row.formation.toLowerCase().includes(filter.value.toLowerCase()),
      Cell: ({ original }) => {
        return (
          <span style={{ wordBreak: "break-all" }}>
            {original.formation?.titre}
          </span>
        );
      },
    },
    {
      Header: "Prix",
      id: "prix",
      width: 150,
      style: { textAlign: "center" },


      accessor: row => `${row.formation?.prix}`,
      filterMethod: (filter, row) =>
        row.prix.toLowerCase().includes(filter.value.toLowerCase()),
      Cell: ({ original }) => {
        return (
          <span style={{ wordBreak: "break-all" }}>
            {original.formation?.prix} TND
          </span>
        );
      },
    },


    {
      Header: "Etat",
      accessor: "etat",
      style: { textAlign: "center" },

      width: 150,
      Cell: ({ original }) => {
        const enrolledText = original?.etat === "Success" ? "Succès" : "Échec";
        const textColor = original?.etat === "Success" ? "#04AA6D" : "red";
        return <b style={{ color: textColor }}>{enrolledText}</b>;
      },
      filterMethod: (filter, row) => {
        if (filter.value === "all") {
          return true;
        }
        if (filter.value === "Success") {
          return row[filter.id] === "Success";
        }
        if (filter.value === "Payment is declined") {
          return row[filter.id] !== "Success";
        }
        return false;
      },
      Filter: ({ filter, onChange }) => {
        return (
          <select
            style={{ width: "100%", fontSize: "1rem" }}
            onChange={(event) => onChange(event.target.value)}
            value={filter ? filter.value : "all"}
          >
            <option value="all">Filtrer</option>
            <option value="Success">Succès</option>
            <option value="Payment is declined">Échec</option>
          </select>
        );
      },
    },
    {
      Header: "PDF",
      width: 150,
      filterable: false, // Disable filtering for this column

      style: { textAlign: "center" },
      Cell: ({ original }) => {
        return (
          <div className="d-block w-100 text-center">
            {original?.file ?
              <a href={original?.file} download>
                <Icon width="40" icon="iwwa:file-pdf" />
              </a>
              : ("-")}



          </div>
        );
      },
    },

  ];

  //   const getPartners = async () => {
  //     setLoading(true);
  //     const res = await axios.get(
  //       process.env.REACT_APP_URL_BACKEND_DEV + `/allfactures`
  //     );
  //     setPartners(res.data.Factures);
  //     setLoading(false);
  //   };
  //   useEffect(() => {

  //     getPartners();
  //   }, []);
  const getMoreData = async () => {

    // await axios.get(process.env.REACT_APP_URL_BACKEND_DEV + `/getUserByid/${idUser}`, 
    await axios.get(process.env.REACT_APP_URL_BACKEND_DEV+`/getUserByid/${idUser}`,

    )

      .then(response => {
        setFormation(response.data.users.mesFactures)
      })
      .catch(error => {
        console.error(error);
      });
  }
  useEffect(() => {

    getMoreData();
  }, []);
  console.log('formation', formation)
  return (
    <div>
      <Card className="card-hover-shadow-2x mb-3">

        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>

        </Stack>
        <CardBody>
          <Row>

            <ReactTable
              data={formation}
              nextText={"Suivant"}
              previousText={"Précedent"}
              rowsText={"Lignes"}
              columns={statsColumns}
              defaultPageSize={10}
              filterable={true}
              noDataText="No data is available"
            />

          </Row>
        </CardBody>
      </Card>



    </div>
  );
}

export default Partenaire;


