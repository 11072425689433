import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
 getAllModuleAPI,
 getLicenceByIdAPI,
 SupprimerModuleAPI
} from "../utils/api";

const initialState = {
  listeLicences: [],
  status: "idle",
  licenceSelected: {},
};

export const getAllModules = createAsyncThunk(
  "module/getAllModules",
  async (idFormation) => {
     try {
       const response = await getAllModuleAPI(idFormation);
       return response.data.result;
     } catch (err) {
     }
   }
);

export const getLicenceById = createAsyncThunk(
  "module/getLicenceById",
  async (idLicence) => {
    try {
      const response = await getLicenceByIdAPI(idLicence);
      return response.data.result;
    } catch (err) {
    }
  }
);

export const removeModule = createAsyncThunk(
  "module/removeModule",
  async (id) => {
    const response = await SupprimerModuleAPI(id);
    return response.data;
  }
);

export const moduleSlice = createSlice({
  name: "module",
  initialState,
  reducers: {},

  extraReducers: {
    [getAllModules.pending]: (state, action) => {
      state.status = "loading";
    },
    [getAllModules.fulfilled]: (state, action) => {
      state.status = "idle";
      state.listeLicences = action.payload;
    },
     [getLicenceById.pending]: (state, action) => {
       state.statusUpdate = "loading";
     },
     [getLicenceById.fulfilled]: (state, action) => {
       state.statusUpdate = "success";
       state.licenceSelected = action.payload;
     },

 
  
 
  },
});

export default moduleSlice.reducer;
