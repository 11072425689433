import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
  socket: {},
  status: "idle",
};

export const getSocket = createAsyncThunk(
  "socket/getSocket",
  async (socket) => {
    try {
      return socket;
    } catch (err) {
    }

  }
);

export const deleteSocket = createAsyncThunk(
  "socket/deleteSocket",
  async () => {
    try {
      return true;
    } catch (err) {
    }

  }
);

export const socketSlice = createSlice({
  name: "socket",
  initialState,
  reducers: {},

  extraReducers: {
    [getSocket.pending]: (state, action) => {
      state.status = "loading";
    },
    [getSocket.fulfilled]: (state, action) => {
      state.status = "success";
      state.socket = action.payload;
    },

    [deleteSocket.pending]: (state, action) => {
      state.status = "loading";
    },
    [deleteSocket.fulfilled]: (state, action) => {
      state.status = "idle";
      state.socket = {};
    },
  },
});

export default socketSlice.reducer;
