import { styled } from '@mui/material/styles';
import {  Stack, Container,Typography } from '@mui/material';
import Page from '../../components/Page';
import React, { useEffect, useState } from "react";
import {  Col, Row } from "reactstrap";
import "antd/dist/antd.less";
import { Upload, Alert } from "antd";
import {  useSelector } from "react-redux";
import { CloudUploadOutlined } from "@ant-design/icons";
import { useFormik } from "formik";
import * as yup from "yup";
import SweetAlert from 'react-bootstrap-sweetalert';
import { FormHelperText, TextField } from "@material-ui/core";
import { UpdateProfile, UploadeFileAPI } from "../../utils/api";
import LoadingOverlay from "../../assets/Overlay/Overlay";
import { LoadingButton } from '@mui/lab';
import MuiPhoneNumber from 'material-ui-phone-number';
import {useDispatch} from 'react-redux';
import { getCurrentUserByToken } from '../../redux/authSlice';
import Cookies from 'js-cookie';

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));


const validationSchema = yup.object({
  nom: yup.string().required("Nom obligatoire"),
  prenom: yup.string().required("Prenom obligatoire"),
  email: yup
    .string()
    .email("Format d'Email invalide")
    .required("Email Obligatoire"),
});

function FormulairePorfile() {
 
let [imageProfile, SetImageProfile] = useState("");
let [alerteShow, setAlerteShow] = useState(false);
const { CurrentUser } = useSelector((state) => state.auth);
const [alerteNoImage, setalerteNoImage] = useState(false);
let [fileList, setFileList] = useState([]);
let [showSuccess, setShowSuccess] = useState(false);
let [showError, setShowError] = useState(false);
const [isLoading, setIsLoading] = useState(false);
const [tel, setTel] = useState("");
const [description, setDescription] = useState("");
const [specialite, setSpecialite] = useState("");

const dispatch = useDispatch();

useEffect(() => {
  setFileList([
    {
      uid: "-1",
      name: CurrentUser?.photo,
      status: "done",
      url:  CurrentUser?.photo,
    },
  ]);
  SetImageProfile( CurrentUser?.photo);
  setTel(CurrentUser?.telephone);
  setDescription(CurrentUser?.description);
  setSpecialite(CurrentUser?.specialite);

}, [CurrentUser]);

const uploadButton = (
  <div>
    <p className="ant-upload-drag-icon">
      <CloudUploadOutlined />
    </p>
    <div style={{ marginTop: 8 }}>Add image</div>
  </div>
);


const formik = useFormik({
  initialValues: {
    nom: CurrentUser?.nom,
    prenom: CurrentUser?.prenom,
    email: CurrentUser?.email,
    telephone: CurrentUser?.telephone,
    description:CurrentUser?.description,
    specialite:CurrentUser?.specialite
  },
  enableReinitialize: true,
  validationSchema: validationSchema,
  onSubmit: async (values) => {
    setIsLoading(true);
    let response = "loading";

    if (imageProfile !== CurrentUser.photo) {
      const res = await UploadeFileAPI(imageProfile, "imageProfile");
      const token = Cookies.get("tokenAdmin");

      response = await 
        UpdateProfile({
        nom: values.nom,
        prenom: values.prenom,
        id: CurrentUser._id,
        photo: res.data.location,
        telephone: tel,
        description:values.description,
        specialite:values.specialite
      })
    

     await dispatch(getCurrentUserByToken(token))

  
}else {

  response = await UpdateProfile({
    nom: values.nom,
    prenom: values.prenom,
    id: CurrentUser._id,
    photo: imageProfile,
    telephone: tel,
    description:values.description,
    specialite:values.specialite

  })
  const token = Cookies.get("tokenAdmin");
await dispatch(getCurrentUserByToken(token))

}
      response.data.statue ? setShowSuccess(true) : setShowError(true);
setIsLoading(false);
},
})

const params = {
  beforeUpload: (file) => {
    if (
      (file.type !== "image/png") |
      (file.type !== "image/jpg") |
      (file.type !== "image/jpeg")
    ) {
      setAlerteShow(true);
    }

    if (
      (file.type === "image/png") |
      (file.type === "image/jpg") |
      (file.type === "image/jpeg")
    ) {
      setAlerteShow(false);
    }
    if (file) {
      setalerteNoImage(false);
    }
    return (file.type === "image/png") |
      (file.type === "image/jpg") |
      (file.type === "image/jpeg")
      ? true
      : Upload.LIST_IGNORE;
  },
  onChange: (info) => {
    const nextState = {};
    switch (info.file.status) {
      case "uploading":
        nextState.selectedFileList = [info.file];
        break;
      case "done":
        nextState.selectedFileList = [info.file];
        break;

      default:

        nextState.selectedFileList = [];
    }

    setFileList(nextState);
  },
};
const dummyRequest = ({ file, onSuccess }) => {
  setTimeout(() => {
    onSuccess("ok");
  }, 0);
};
const handleChange = ({ fileList }) => setFileList(fileList);
const handleSubmitImage = (file) => {
  SetImageProfile(file);
};

  return (
    
                   


<RootStyle title="Login | Fastuz">
  


      <Container maxWidth="sm">
        <ContentStyle>
   
       

        <Col md={12} style={{ textAlign: "center" }}>
                  <LoadingOverlay enabled={isLoading}>
                    <Upload
                      {...params}
                      action={handleSubmitImage}
                      customRequest={dummyRequest}
                      listType="picture-card"
                      fileList={fileList}
                      onChange={handleChange}
                    >
                      {fileList.length >= 1 ? null : uploadButton}
                    </Upload>
                  </LoadingOverlay>
                  {alerteShow ? (
                    <Alert
                      message="Seulement des fichiers images !"
                      type="error"
                    />
                  ) : (
                    <></>
                  )}
                  
                  {alerteNoImage ? (
                    <Alert message="L'icon est necessaire !" type="error" />
                  ) : (
                    <></>
                  )}
                </Col>
        <Stack spacing={3}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label="Prenom"
              error={formik.touched.nom && formik.errors.nom}
              helperText={formik.touched.nom && formik.errors.nom}
              inputProps={{
              autoComplete: "new-password",
                        form: {
                          autoComplete: "off",
                        },
                      }}
              {...formik.getFieldProps("nom")}
            
            />

            <TextField
                      label="Nom"
                      error={formik.touched.prenom && formik.errors.prenom}
                      helperText={formik.touched.prenom && formik.errors.prenom}
                      fullWidth
                      inputProps={{
                        autoComplete: "new-password",
                        form: {
                          autoComplete: "off",
                        },
                      }}
                      {...formik.getFieldProps("prenom")}
             
            />
          </Stack>
          
          <TextField
                      aria-readonly={true}
                      id="standard-basic"
                      label="Email"
                      error={formik.touched.email && formik.errors.email}
                      helperText={formik.touched.email && formik.errors.email}
                      fullWidth

                      inputProps={{
                        readOnly: true,
                        autoComplete: "new-password",
                        form: {
                          autoComplete: "off",
                        },
                      }}
                      {...formik.getFieldProps("email")}
        
          />

                  {CurrentUser?.role !== "admin" ? (
                  <Row style={{ marginTop: 20 }}>
                   <Typography level="body3" sx={{ ml: 'auto' }}>
                Spécialite
              </Typography>
                    <TextField
             fullWidth
            
             error={formik.touched.specialite && formik.errors.specialite}
             helperText={formik.touched.specialite && formik.errors.specialite}
             inputProps={{
             autoComplete: "new-password",
                       form: {
                         autoComplete: "off",
                       },
                     }}
             {...formik.getFieldProps("specialite")}
      
           
             multiline
             rows={2}
             maxRows={4}
         
            />
                 
                    <Typography level="body3" sx={{ ml: 'auto' }}>
                Description
              </Typography>
                    <TextField
             fullWidth
            
             error={formik.touched.description && formik.errors.description}
             helperText={formik.touched.description && formik.errors.description}
             inputProps={{
             autoComplete: "new-password",
                       form: {
                         autoComplete: "off",
                       },
                     }}
             {...formik.getFieldProps("description")}
      
           
             multiline
             rows={2}
             maxRows={4}
         
            />

            <br></br>       <br></br>
          <Col md={12}>
                      <MuiPhoneNumber
                        inputStyle={{
                          height: "50px",
                          width: "100%",
                          borderColor:
                            formik.errors.telephone && formik.touched.telephone
                              ? "#f44336"
                              : "",
                        }}
                        buttonStyle={{
                          borderColor:
                            formik.errors.telephone && formik.touched.telephone
                              ? "#f44336"
                              : "",
                        }}
                        inputProps={{
                          required: true,
                        }}
                        defaultCountry={"tn"}
                        name="telephone"
                        value={tel}
                        onChange={(phone) => {
                          setTel("" + phone);
                        }}
              
                      />
                      {formik.errors.telephone && formik.touched.telephone ? (
                        <FormHelperText id="my-helper-text" error>
                          {formik.errors.telephone}
                        </FormHelperText>
                      ) : null}
                    </Col>
                  </Row>
                ) : (
                  <></>
                )}

          <LoadingButton
          onClick={async () => {
                        await formik.handleSubmit();
                      }}
            fullWidth
            size="large"
            type="submit"
            variant="contained"
          >
            Enregistrer
          </LoadingButton>
        </Stack>

        </ContentStyle>
   

   
        <SweetAlert
        show={showSuccess}
        title="Modification avec succès"
        type="success"
        onConfirm={() => {
          setShowSuccess(false);
        }}
      />

      <SweetAlert
        show={showError}
        title="Error !"
        type="error"
        onConfirm={() => {
          setShowError(false);
        }}
      />


</Container>
    </RootStyle>
 
   
  );
}

export default FormulairePorfile;
