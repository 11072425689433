import { Icon } from '@iconify/react';

import { alpha, styled } from '@mui/material/styles';
import { Card, Typography } from '@mui/material';
import { fShortenNumber } from '../../../utils/formatNumber';


import { useEffect, useState } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  padding: theme.spacing(5, 0),
  color: theme.palette.error.darker,
  backgroundColor: theme.palette.error.lighter
}));

const IconWrapperStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
  color: theme.palette.error.dark,
  backgroundImage: `linear-gradient(135deg, ${alpha(theme.palette.error.dark, 0)} 0%, ${alpha(
    theme.palette.error.dark,
    0.24
  )} 100%)`
}));



export default function AppBugReports() {
  const [formateurs, setFormateurs] = useState(0);


  const token= Cookies.get("tokenAdmin")
  useEffect(() => {
   
    getApprenants();
  }, []);
  const getApprenants = async () => {
    const res = await axios.get(
      process.env.REACT_APP_URL_BACKEND_DEV + "/getAllCertifsFormateur",  {
        headers: { Authorization: `Bearer ${token}`, 
        withCredentials: true
      },}
    );
    setFormateurs(res.data.data);
  
  };
  return (
    <RootStyle>
      <IconWrapperStyle>
        <Icon icon="ant-design:safety-certificate-filled" width={24} height={24} />
      </IconWrapperStyle>
      <Typography variant="h3">{fShortenNumber(formateurs)}</Typography>
      <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
      Bons d'achats expirés
      </Typography>
    </RootStyle>
  );
}
