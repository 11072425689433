import React, { useEffect, useState } from "react";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';

import axios from 'axios';

import { Button, Grid, Paper } from "@mui/material";
import { useParams } from "react-router-dom";
import Stack from '@mui/material/Stack';
import SnackbarContent from '@mui/material/SnackbarContent';

// const action = (
//   <Button color="secondary" size="small">
//     lorem ipsum dolorem
//   </Button>
// );
export default function SimpleAccordion({idFormation}) {

  const { idUser } = useParams();
  console.log("props", idFormation)
  const [formation, setFormation] = useState([]);

  useEffect(() => {

    getMoreData();
  }, []);
  const getMoreData = async () => {
    // await axios.get(process.env.REACT_APP_URL_BACKEND_DEV + `/getUserByid/${idUser}`, 
    await axios.get( process.env.REACT_APP_URL_BACKEND_DEV +`/getcertifbyUser/${idUser}/${idFormation?._id}`,
    )
      .then(response => {
        setFormation(response.data.result)
      })
      .catch(error => {
        console.error(error);
      });
  }
console.log("formation", formation)
  return (
    <div>
      <br />
      <Stack sx={{ justifyContent: "center" }}>
  {formation.map((user) => (
    <>
    {user?.score<80 ?
      <SnackbarContent style={{ backgroundColor: "#d40707" }} message={user?.certiff?.name} action={parseFloat(user?.score).toFixed(2) + "% " + "(Failed)"} />
      :     <SnackbarContent style={{ backgroundColor: "#0e660e" }} message={user?.certiff?.name} action={parseFloat(user?.score).toFixed(2) + "% " + "(Success)"} />

  
  }
    </>
  ))}
</Stack>

      {/* {formation?.map((user) => (
        <Accordion key={user._id}>
          <AccordionSummary
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid xs={11}>
                <Typography>{user?.formations?.titre}</Typography>
              </Grid>
              <Grid xs>
              </Grid>
            </Grid>
          </AccordionSummary>
        </Accordion>
      ))} */}
    </div>
  );
}


