import React, { useState } from "react";
import { Upload, Alert } from "antd";
import "antd/dist/antd.css";
import { CloudUploadOutlined } from "@ant-design/icons";

export default function FileUploader({
  fileTypes,
  setFileToUpload,
  title,
  original,
  nopreview,
  isViewOnly,
}) {
  const [fileList, setFileList] = useState(
    original
      ? [
          {
            url: original,
            uid: original,
            name: title,
            status: "done",
          },
        ]
      : []
  );

  const SIZE_LIMIT = 1024 * 2; //en mb
  const [alerteShow, setAlerteShow] = useState(false);
  const [sizeAlerteShow, setSizeAlerteShow] = useState(false);
  const params = {
    beforeUpload: (file) => {
      //file size limit 2gb
      if (file.size / (1024 * 1024) > SIZE_LIMIT) {
        setSizeAlerteShow(true);
        return Upload.LIST_IGNORE;
      } else setSizeAlerteShow(false);

      if (!fileTypes.includes(file.type)) {
        setAlerteShow(true);
      }

      if (fileTypes.includes(file.type)) {
        setAlerteShow(false);
      }

      return fileTypes.includes(file.type) ? true : Upload.LIST_IGNORE;
    },
    onChange: (info) => {
      const nextState = {};
      switch (info.file.status) {
        case "uploading":
          nextState.selectedFileList = [info.file];
          break;
        case "done":
          nextState.selectedFileList = [info.file];
          break;
        default:
          nextState.selectedFileList = [];
      }

      setFileList(nextState);
    },
    showUploadList: {
      showDownloadIcon: !nopreview,
      showPreviewIcon: false,
    },
  };
  const handleChange = ({ fileList }) =>{ setFileList(fileList) ;console.log(fileList)};
  const dummyRequest = ({ onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };
  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  const UploadButton = () => (
    <span>
      <p className="ant-upload-drag-icon">
        <CloudUploadOutlined />
      </p>
      <div style={{ marginTop: 8 }}> {title}</div>
    </span>
  );
  const handlePreview = async (file) => {
    if (file.url) {
      window.open(file.url);
      return;
    }
    let url = await getBase64(file.originFileObj);

    let img =
      '<iframe src="' +
      url +
      '" width="100%" height="100%" style="border:none;padding:0;margin:0">';
    let popup = window.open();
    popup.document.write(img);
    popup.document.head.insertAdjacentHTML(
      "beforeend",
      `<style>body{margin:0}</style>`
    );
  };
  return (
    <div className="w-100 d-flex flex-column align-items-center">
      <div className="ml-2">
        <Upload
          {...params}
          action={(file) => setFileToUpload(file)}
          customRequest={dummyRequest}
          listType="picture-card"
          fileList={fileList}
          accept={fileTypes}
          onPreview={(file) => handlePreview(file)}
          onChange={handleChange}
          onDownload={(file) => handlePreview(file)}
          onRemove={() => setFileToUpload()}
          disabled={isViewOnly}
           multiple
        >
          {fileList.length >= 1 ? null : <UploadButton />}
        </Upload>
      </div>
      {alerteShow && (
        <Alert
          message={
            "fichier doit etre de type: " +
            fileTypes.reduce((acc, val, idx) => {
              let sep =
                fileTypes.length - 1 === idx
                  ? "."
                  : fileTypes.length - 2 === idx
                  ? " ou "
                  : ", ";
              return acc + val.split("/")[1] + sep;
            }, "")
          }
          type="error"
        />
      )}
      {sizeAlerteShow && (
        <Alert
          message={
            "La taille du fichier doit être inférieure à" +
            SIZE_LIMIT / 1024 +
            "GB"
          }
          type="error"
        />
      )}
    </div>
  );
}
