
import {
  Stack,
  Container,
  Typography,
} from '@mui/material';
import Page from '../../components/Page';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from "react";
import ReactTable from 'react-table-v6'
import { useDispatch, useSelector } from "react-redux";
import {
  getAllFormations

} from "../../redux/formationSlice";
import { SupprimerReviewAPI } from '../../utils/api';
import "react-table-v6/react-table.css";
import Swal from "sweetalert2";
import { LoadingButton } from '@mui/lab';
import {
  getAllComments

} from "../../redux/reviewSlice";


export default function Formations() {
  
  const [page, setPage] = useState(0);
  const pageSize = 10;
  const[review, setReview]=useState([])

  
  const listComments = useSelector(
   (state) => state.comments.listereviews
 );
 const dispatch = useDispatch();

   useEffect(() => {
    dispatch(getAllComments());

    }, [dispatch]);



  const columns = [
    {
      Header: "Apprenants",
      id: "nomPrenom",
      accessor: row => `${row.user_name} ${row.user_lastname}`,
      filterMethod: (filter, row) =>
        row.nomPrenom.toLowerCase().includes(filter.value.toLowerCase()),
      width:160,
      Cell: ({original}) => {
        return <div>{original?.user_name}  {original?.user_lastname}</div>
      },
    },
    {
      Header: "Titre formation",
      id: "titreA",
      accessor: "formation_id.titre",
      filterMethod: (filter, row) =>
        row.titreA.toLowerCase().includes(filter.value.toLowerCase()),
      width: 400,
      Cell: ({ original }) => {
        return (
          <span style={{ whiteSpace: "pre-wrap" }}>
       
               {original?.formation_id?.titre}
          </span>
        );
      },
    },
    {
      Header: "Contenu",
      accessor: "content",
      filterMethod: (filter, row) =>
      row[filter.id].toLowerCase().includes(filter.value.toLowerCase()),
      width:400,
      headerProps: {
        style: {
          textAlign: "center"
        }
      },
     
      Cell: ({original}) => {
        return <div style={{ whiteSpace: "pre-wrap" }}>{original?.content} </div>
      },
      
    
    },

    {
      Header: "Rating",
      accessor: "rating",
      width:80,
      headerProps: {
        style: {
          textAlign: "center"
        }
      },
     
      Cell: ({original}) => {
        return <div>{original?.rating} </div>
      },
      
    
    },

    
      {
        Header: "Actions",
        width:100,
        Cell: ({original}) => {
          return <div>         

          <LoadingButton
                  
                  onClick={ async() => {

                    Swal.fire({
                          title: "Êtes-vous sûr de vouloir supprimer ce commentaire?",
                          icon: "warning",
                          showCancelButton: true,
                          cancelButtonText: "Annuler",

                          confirmButtonColor: "#0d3077",
                          cancelButtonColor: "#d33",
                          confirmButtonText: "Supprimer commentaire!",
                        }).then(async (result) => {
                          if (result.isConfirmed) {
                            await     SupprimerReviewAPI(original?._id);
                    
                            dispatch(getAllComments())                             
                            .then(async (res) => {
                                if (res.status === 200) {
                    
                                  Swal.fire("Commentaires supprimé!");
                    
                                } else {
                                  Swal.fire(
                                    "Comentaire supprimé!"
                                  );
                                }
                              });
                          }
                        });


         

                  }}
                >
                  Supprimer 
                </LoadingButton>
            
          </div>
        },
      },
    

  ];


useEffect(() => {
    dispatch(getAllFormations());
    }, [dispatch]);
    
  return (
    <Page title="Dashboard | Fastuz">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Commentaires
          </Typography>
   
        </Stack>
        <ReactTable
              data={listComments}
              nextText={"Suivant"}
              previousText={"Précedent"}
              rowsText={"Lignes"}
              columns={columns}
              defaultPageSize={10}
              filterable={true}
              noDataText="No data is available"
            />
   

  
      </Container>

    </Page>

  );
}

