import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import {
  GetAllPubliciteAPI, getPubliciteByIdAPI,
} from "../utils/api";


const initialState = {
  listPublicite: [],
  status: "idle",
  Publiciteselected: {},
};
export const publicite = createAsyncThunk("Type/publicite", async () => {
  try {
    const response = await GetAllPubliciteAPI();
    console.log("response",response)
    return response.data.publicite;
  } catch (err) {
    console.log("Erreur : ", err.response);
  }
});
export const publiciteById = createAsyncThunk(
  "Type/getNomPropByIdAPI",
  async (idCategorie) => {
    try {
      const response = await getPubliciteByIdAPI(idCategorie);
      return response.data.result;
    } catch (err) {
      console.log("Erreur : ", err.response);
    }
  }
);
export const PubliciteSlice = createSlice({
  name: "publicite",
  initialState,
  reducers: {},
  extraReducers: {
   
    [publicite.pending]: (state, action) => {
      state.status = "loading";
    },
    [publicite.fulfilled]: (state, action) => {
      state.status = "success";
      state.listPublicite = action.payload;
    },
    [publiciteById.pending]: (state, action) => {
      state.statusUpdate = "loading";
    },
    [publiciteById.fulfilled]: (state, action) => {
      state.statusUpdate = "success";
      state.Publiciteselected = action.payload;
    },
  },
});

export default PubliciteSlice.reducer;
