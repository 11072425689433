import React, { useState } from "react";
import LineChartStudent from "./AppConversionInscrit"; // Import the LineChartStudent component
import AppWebsiteVisits from "./AppWebsiteVisits"; // Import the AppWebsiteVisits component
import { Button, Container } from "@mui/material";

export default function CombinedCharts() {
  const [showStudentsChart, setShowStudentsChart] = useState(true);

  const toggleChart = () => {
    setShowStudentsChart((prevShowStudentsChart) => !prevShowStudentsChart);
  };

  return (
    <Container>
      <Button onClick={toggleChart}>
        {showStudentsChart ? "Inscription/Formation par mois" : "Inscription/Formation par jour"}
      </Button>
      {showStudentsChart ? <LineChartStudent /> : <AppWebsiteVisits />}
    </Container>
  );
}
