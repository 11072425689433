
import React, { useEffect, useState, useCallback } from "react";
import styles from "./admin.module.css";
import { isAuthenticated } from "./auth";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { ToastContainer, toast } from "react-toastify";
import { Button, Stack, TextField, Typography } from "@mui/material";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import plusFill from '@iconify/icons-eva/plus-fill';
import { Icon } from '@iconify/react';
import {  useNavigate, useParams } from "react-router-dom";
import ListeStudentWithQuizz from "./listeStudentWithQuizz";
import QuizIcon from '@mui/icons-material/Quiz';
import { Multiselect } from "react-widgets";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

const API_BASE = process.env.REACT_APP_URL_BACKEND_DEV;

const QuizInfo = ({ test, setTest, quiz, setQuiz, getCertifs}) => {
  const [nameQuiz, setNameQuiz] = useState("");
  const [selectedQuestion, setSelectedQuestion] = useState([])
  const [durationQuiz, setDurationQuiz] = useState({ min: 0, sec: 0 });
  const [inputFields, setInputFields] = useState([
    {
      id: uuidv4(),
      _id: "",
      titreQuestion: "",
      optionA: "",
      optionB: "",
      optionC: "",
      optionD: "",
      optionE: "",
      choixMultiple: false,
      choixCorrecte: [],
    },
  ]);

  const UpdateQuiz = async () => {
    if (quiz._id) {
      let url = API_BASE +`/quizcourse/${quiz._id}`;
      if (selectedQuestion.length > 0) {
        url += `?questions=${selectedQuestion.join(',')}`;
      }
      await axios.put(url, quiz)
      await setTest(null)
      getCertifs()
        .then((res) => {
          if (res.status === 200) {
            alert("Good job!", "You updated this Course!", "success");
          }
        }).catch((error) => {
          toast.error(error.response.data.msg);
        });
    }
  };


  const handleChangeInput = (id, event) => {
    const newInputFields = quiz.questions?.map((question) => {
      if (question?._id === id) {
        return {
          ...question,
          [event.target.name]: event.target.value,
        };
      } else {
        return question;
      }
    });
    setQuiz((prevState) => ({ ...prevState, questions: newInputFields }));
  };


  useEffect(() => {
    if (quiz) {
      setNameQuiz(quiz.name);
      setDurationQuiz({ min: quiz.duration.min, sec: quiz.duration.sec });
    }
  }, [quiz]);

  const handleChangeMin = (e) => {
    setDurationQuiz({ ...durationQuiz, min: e.target.value });
    const newDuration = { ...quiz.duration, min: e.target.value };
    setQuiz({ ...quiz, duration: newDuration });
  };
  const handleChangeSec = (e) => {
    setDurationQuiz({ ...durationQuiz, sec: e.target.value });
    const newDuration = { ...quiz.duration, sec: e.target.value };
    setQuiz({ ...quiz, duration: newDuration });
  };



  const DeleteQuestion = (quesId, id) => {
    if (quesId) {
      const updatedQuestions = quiz.questions.filter(question => question._id !== quesId);
      setQuiz({
        ...quiz,
        questions: updatedQuestions
      });
      setSelectedQuestion([...selectedQuestion, quesId])
    }
    if (id) {


      quiz?.questions?.splice(
        quiz?.questions?.findIndex((value) => value.id === id && value._id === quesId),
        1
      );
      setInputFields(quiz?.questions);

    }
  };

  const newQuestionObject = {
    titreQuestion: "",
    optionA: "",
    optionB: "",
    optionC: "",
    optionD: "",
    optionE: "",
    choixMultiple: false,
    choixCorrecte: [],
    _id: "",
    id: uuidv4(),
  };

  const handleAjouterQuestion = () => {
    const newQuestionArray = structuredClone(quiz?.questions);

    newQuestionArray.push(newQuestionObject);

    setQuiz({ ...quiz, questions: newQuestionArray });
  };

  const handleChangeNomQuiz = (e) => {
    setNameQuiz(e.target.value);
    setQuiz({ ...quiz, name: e.target.value });
  };
  const handleChangeSelectCorrectAnswer = (selectedOptions, questionIndex) => {
    const updatedQuestions = [...quiz.questions];
    const currentQuestion = updatedQuestions[questionIndex];

    const newSelectedOptions = Array.isArray(selectedOptions) ? selectedOptions : [selectedOptions];

    currentQuestion.choixCorrecte = newSelectedOptions;

    setQuiz({
      ...quiz,
      questions: updatedQuestions,
    });
  };


  const handleChangeChecked = (_id, id) => {
    const values = quiz?.questions.slice();
    for (let index = 0; index < values.length; index++) {
      if (values[index]._id === _id && values[index].id === id) {
        values[index] = {
          ...values[index],
          choixMultiple: !values[index].choixMultiple,
        };
      }
    }
    setQuiz((prevState) => ({ ...prevState, questions: values }));
  };

  return (
    <div className={styles.quizModal}>
      <Button
        onClick={() => setTest(null)}
        style={{
          position: "absolute",
          top: "10px",
          right: "10px",
          padding: "10px",
        }}
      >
        X
      </Button>
      <br></br>
      <br></br>

      <TextField
        fullWidth
        value={nameQuiz}
        onChange={handleChangeNomQuiz}
        id="outlined-basic"
        label="Nom du quiz"
        variant="outlined"
      />
      <br></br>
      <div>
        <br></br>
        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
          <TextField
            fullWidth
            id="outlined-number"
            label="Minutes"
            value={durationQuiz.min}
            onChange={handleChangeMin}
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            fullWidth
            id="outlined-number"
            label="Secondes"
            value={durationQuiz.sec}
            onChange={handleChangeSec}
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Stack>
      </div>
      <br></br>
      <br></br>
      <center>
        <b style={{ fontSize: "18px" }}>Modifier questions</b>
      </center>
      <div className={styles?.questions}>
        {quiz?.questions?.map((question, i) => (

          <div key={question?._id}>
            <strong style={{ fontSize: "17px" }}>
              {"Question " + (i + 1)}
            </strong>
            <br></br>

            <div >
              <ToggleButtonGroup
                fullWidth
                size="small"
                style={{
                  width: "100%",
                }}
                value={question.choixMultiple}
                exclusive
                onChange={() => handleChangeChecked(question._id, question.id)}
              >

                <ToggleButton value={true} style={{ width: "50%" }}>
                  <span>Choix Multiple</span>{" "}
                </ToggleButton>
                <ToggleButton value={false} style={{ width: "50%" }}>
                  <span>Choix Unique</span>
                </ToggleButton>
              </ToggleButtonGroup>
            </div>

            <br></br>
            <br></br>
            <TextField
              fullWidth
              value={question?.titreQuestion}
              multiline
              rows={2}
              onChange={(event) =>
                handleChangeInput(


                  question?._id, event
                )
              }
              id="outlined-basic"
              name="titreQuestion"
              label="Title"
              variant="outlined"
            />
            <br></br>
            <br></br>
            <br></br>

            <p>
              <TextField
                fullWidth
                multiline
                rows={2}
                name="optionA"

                value={question?.optionA}
                onChange={(e) => handleChangeInput(question?._id, e)}
                id="outlined-basic"
                label={"Option 1 "}
              />

            </p>
            <p>
              <TextField
                fullWidth
                multiline
                rows={2}
                name="optionB"
                value={question?.optionB}
                onChange={(e) => handleChangeInput(question?._id, e)}
                id="outlined-basic"
                label={"Option 2"}
              />

            </p>
            <p>
              <TextField
                fullWidth
                multiline
                rows={2}
                name="optionC"
                value={question?.optionC}
                onChange={(e) => handleChangeInput(question?._id, e)}
                id="outlined-basic"
                label={"Option 3"}
              />

            </p>
            <p>
              <TextField
                fullWidth
                multiline
                name="optionD"
                rows={2}
                value={question?.optionD}
                onChange={(e) => handleChangeInput(question?._id, e)}
                id="outlined-basic"
                label={"Option 4"}
              />
            </p>
            <p>
              <TextField
                fullWidth
                multiline
                name="optionE"
                rows={2}
                value={question?.optionE}
                onChange={(e) => handleChangeInput(question?._id, e)}
                id="outlined-basic"
                label={"Option 5"}
              />

            </p>
            <div className="d-flex align-items-center justify-content-center">
              <p className="mx-5">
                <div>
                  <label htmlFor="answer">correct answer</label>
                  {question?.choixMultiple ? (
                    <Multiselect

                      id="answer"
                      value={question?.choixCorrecte}
                      dropDown
                      data={["A", "B", "C", "D", "E"]}
                      onChange={(e) => handleChangeSelectCorrectAnswer(e, i)}
                      className="answers"
                    >
                      <option value="A">Option A</option>
                      <option value="B">Option B</option>
                      <option value="C">Option C</option>
                      <option value="D">Option D</option>
                      <option value="E">Option E</option>
                    </Multiselect>
                  ) : (
                    <select
                      style={{
                        borderRadius: "6px",
                        padding: "7px",
                        marginLeft: "8px",
                      }}
                      id="answer"
                      value={question?.choixCorrecte}
                      onChange={(e) => handleChangeSelectCorrectAnswer(e.target.value, i)}
                      className="answers"
                    ><option value="A">Option A</option>
                      <option value="B">Option B</option>
                      <option value="C">Option C</option>
                      <option value="D">Option D</option>
                      <option value="E">Option E</option>
                    </select>)
                  }
                </div>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  mb={2}
                >
                  <h1 variant="h4" gutterBottom></h1>
                  <Button
                    variant="contained"
                    onClick={() => DeleteQuestion(question?._id, newQuestionObject.id, i)}
                  >
                    Supprimer
                  </Button>
                </Stack>
              </p>
            </div>
          </div>
        ))}

        <Button onClick={handleAjouterQuestion}>Ajouter Question</Button>

        <center>
          <Button onClick={UpdateQuiz}
          >Modifier</Button>
        </center>
      </div>
    </div>
  );
};



const Quizzes = ({ quizzess, deleteQuiz, setQuiz, id }) => {

  const navigate = useNavigate();
  return (
    <div>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom>
        </Typography>
        <Button
          variant="contained"
          onClick={() => navigate(`/addquizCourse/${id}`)}
          startIcon={<Icon icon={plusFill} />}
        >
          Ajouter test fin de module
        </Button>
 
      </Stack>
      <table className={styles.table}>
        <thead>
          <tr>
            <td>Nom Test Blancs</td>
            <td>Date de création</td>
            <td>Durée du test</td>
            <td>Supprimer</td>
            <td>Scores</td>

          </tr>
        </thead>
        <tbody>
          {quizzess?.map((quiz) => (
            <tr key={quiz._id}>
              <td>
                <Button
                  className={styles.button}
                  type="submit"
                  onClick={() => setQuiz(quiz)}
                >
                  {quiz.name}
                </Button>
              </td>
              <td>{new Date(quiz.created).toDateString()}</td>
              <td>{quiz.duration?.min + " : " + quiz.duration?.sec}</td>

              <td>
                <Button
                  className={styles.button}
                  type="submit"
                  onClick={() => { deleteQuiz(quiz) }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    width="30"
                    height="30"
                    viewBox="0 0 172 172"
                  >
                    <g
                      fill="none"
                      fillRule="nonzero"
                      stroke="none"
                      strokeWidth="1"
                      strokeLinecap="butt"
                      strokeLinejoin="miter"
                      strokeMiterlimit="10"
                      strokeDasharray=""
                      strokeDashoffset="0"
                      fontFamily="none"
                      fontWeight="none"
                      fontSize="none"
                      textAnchor="none"
                      style={{ mixBlendMode: "normal" }}
                    >
                      <path d="M0,172v-172h172v172z" fill="none"></path>
                      <g>
                        <path
                          d="M121.83333,39.41667l-21.5,-21.5h-28.66667l-21.5,21.5h-10.75v100.33333c0,7.88333 6.45,14.33333 14.33333,14.33333h64.5c7.88333,0 14.33333,-6.45 14.33333,-14.33333v-100.33333z"
                          fill="#e74c3c"
                        ></path>
                        <path
                          d="M39.41667,25.08333h93.16667c3.94167,0 7.16667,3.225 7.16667,7.16667v7.16667h-107.5v-7.16667c0,-3.94167 3.225,-7.16667 7.16667,-7.16667z"
                          fill="#e74c3c"
                        ></path>
                        <path
                          d="M59.4334,113.18215l43.00036,-43.00186l10.13537,10.13502l-43.00036,43.00186z"
                          fill="#fafafa"
                        ></path>
                        <path
                          d="M59.43283,80.31878l10.13502,-10.13537l43.00186,43.00036l-10.13502,10.13537z"
                          fill="#fafafa"
                        ></path>
                      </g>
                    </g>
                  </svg>
                </Button>
              </td>

              <td>
                <Button
                  className={styles.button}
                  type="submit"
                  onClick={() => navigate(`/dashboard/ScoreCourse/${quiz._id}`)}
                >
                  <QuizIcon />
                </Button>
              </td>

            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const Dashboard = (props) => {
const{_id}= props.modules;
  const [quizzes, setQuizzes] = useState([]);

  const [showQuiz, setShowQuiz] = useState(null);

  const [page, setPage] = useState("");
  const [quiz, setQuiz] = useState(null);
const getCertifs= async()=>{

  fetch(API_BASE +`/getAllQuizCourse/${_id}`, {
    headers: {
      Authorization: `Bearer ${isAuthenticated().token}`,
    },
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.error) {
        toast.error(data.error);
      } else {
        setQuizzes(
          data.sort((a, b) => {
            if (a.created > b.created) return -1;
            else if (a.created < b.created) return 1;
            return 0;
          })
        );
      }
    })
    .catch(() => {
    });
}
  useEffect(() => {
    getCertifs()
  }, []);

  const idQuiz = showQuiz?._id
  useEffect(() => {
    const getData = async () => {
      if (idQuiz) {
        await axios
          .get(API_BASE +`/FindQuizCourseById/${idQuiz}`)
          .then((res) => {
            if (res.status === 200) {
              setQuiz(res.data.result);


            }

          });
      }
    };
    getData();

  }, [idQuiz]);


  let removeQuiz = useCallback(
    (quiz) => {
      fetch(`${API_BASE}/deletequizcourse/${quiz}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${isAuthenticated().token}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.error) {
            toast.error(data.error);
          } else {
            setQuizzes(quizzes.filter((x) => x._id !== quiz));
            toast.success(data.message);
          }
        })
        .catch(() => {
        });
    },
    [quizzes]
  );

  let deleteItem = useCallback(
    (item) => {
      confirmAlert({
        title: "Confirmer la suppression",
        message: `Vous êtes sur de supprimer ce quiz ?`,
        buttons: [
          {
            label: "Confirmer",
            onClick: () => (item.duration ? removeQuiz(item._id) : ""),
          },
          {
            label: "Annuler",
            onClick: () => undefined,
          },
        ],
      });
    },
    [removeQuiz]
  );

  return (
    <div style={{ padding: "20px" }}>


      {page === "listStudent" && (
        <div style={{ overflowX: "auto" }}>
          <ListeStudentWithQuizz />
        </div>
      )}

      <div style={{ overflowX: "auto" }}>
        <Quizzes
          quizzess={quizzes}
          deleteQuiz={deleteItem}
          setQuiz={setShowQuiz}
          id={_id}
        />
      </div>

      {showQuiz && (
        <div
          style={{
            position: "fixed",
            width: "100vw",
            height: "100vh",
            zIndex: "3000",
            backgroundColor: "rgba(255, 255, 255, 0.9)",
            top: "0",
            left: "0",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <QuizInfo test={showQuiz} setTest={setShowQuiz} quiz={quiz} setQuiz={setQuiz} getCertifs={getCertifs} />
        </div>
      )}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
      />
    </div>
  );
};

export default Dashboard;


