import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const Url_Back_End =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_URL_BACKEND
    : process.env.REACT_APP_URL_BACKEND_DEV;
const initialState = {
  listeSousCategories: [],
  status: "idle",
  statusUpdate: "idle",
  selectedCategorie: "all",
  CostumelisteSousCategories: [],
};

export const getAllSousCategories = createAsyncThunk(
  "Souscategories/getAllSousCategories",
  async () => {
    try {
      const response = await axios.get(Url_Back_End + "/getAllSousCategorie");
      return response.data.Souscategorie;
    } catch (err) {
    }

  }
);

export const getAllSousCategoriesByCategorie = createAsyncThunk(
  "Souscategories/getAllSousCategoriesByCategorie",
  async (idCategorie) => {
    try {
      const response = await axios.get(
        Url_Back_End + "/getSousCategorieByCategorie/" + idCategorie
      );
      return response.data.Souscategorie;
    } catch (err) {
    }

  }
);

export const updateSousCategories = createAsyncThunk(
  "Souscategories/updateSousCategories",
  async (updateSousCategories) => {
    const promise = await axios
      .put(
        Url_Back_End + "/updateSousCategorie/" + updateSousCategories._id,
        updateSousCategories
      )

      .then((response) => {
        const data = response.data;
        return data;
      });

    const data = await promise;
    return data;
  }
);

export const SouscategoriesSlice = createSlice({
  name: "Souscategories",
  initialState,
  reducers: {},

  extraReducers: {
    [getAllSousCategories.pending]: (state, action) => {
      state.status = "loading";
    },
    [getAllSousCategories.fulfilled]: (state, action) => {
      state.status = "idle";
      state.listeSousCategories = action.payload;
    },
    [getAllSousCategoriesByCategorie.pending]: (state, action) => {
      state.status = "loading";
    },
    [getAllSousCategoriesByCategorie.fulfilled]: (state, action) => {
      state.status = "sucess";
      state.listeSousCategories = action.payload;
    },
    [updateSousCategories.pending]: (state, action) => {
      state.statusUpdate = "loading";
    },
    [updateSousCategories.fulfilled]: (state, action) => {
      state.statusUpdate = "sucess";
    },
  },
});

export default SouscategoriesSlice.reducer;
