import React, { useEffect } from "react";
import { useState } from "react";
import { Card, CardBody, Row } from "reactstrap";
import { Icon } from "@iconify/react";
import moment from "moment";
import { Stack, Typography } from "@mui/material";
import ReactTable from "react-table-v6";
import axios from "axios";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
function Partenaire() {
  const [open, setOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");

  const handleClickOpen = (content) => {
    setOpen(true);
    setModalContent(content);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [partners, setPartners] = useState([]);
  const [formations, setFormations] = useState([]);
  const [formateur, setFormateurs] = useState([]);
  console.log(formateur);
  const getTyperec = async () => {
    const res = await axios.get(
      process.env.REACT_APP_URL_BACKEND_DEV + `/formation`
    );
    setFormations(res.data.formations);
  };
  useEffect(() => {
    getTyperec();
  }, []);
  const [loading, setLoading] = React.useState(false);
  const statsColumns = [
 
    {
      Header: "Apprenants",
      id: "etudiant",
      style: { textAlign: "center" },
      width: 190,
      accessor: (row) => `${row.etudiant?.nom} ${row.etudiant?.prenom}`,
      filterMethod: (filter, row) =>
        row.etudiant.toLowerCase().includes(filter.value.toLowerCase()),
      Cell: ({ original }) => {
        return (
          <span style={{ wordBreak: "break-all" }}>
            {original.etudiant?.nom} {original.etudiant?.prenom}
          </span>
        );
      },
    },
    {
      Header: "Email",
      id: "email",
      style: { textAlign: "center" },
      width: 190,
      accessor: (row) => `${row.etudiant?.email} `,
      filterMethod: (filter, row) =>
        row.email.toLowerCase().includes(filter.value.toLowerCase()),
      Cell: ({ original }) => {
        return (
          <span style={{ wordBreak: "break-all" }}>
            {original.etudiant?.email}
          </span>
        );
      },
    },
    {
      Header: "Nb Certificats",
      id: "etudiant.mesCertificats",
      style: { textAlign: "center" },
      width: 100,
      accessor: (row) => row.etudiant?.mesCertificats.length,
      Cell: ({ value }) => {
        return (
          <span style={{ wordBreak: "break-all" }}>
            {value > 0 ? value : "-"}
          </span>
        );
      },
    },
    
    {
      Header: "Formations",
      accessor: "formation",
      width: 230,
      Cell: ({ original }) => {
        return <div>{original?.formation?.titre}</div>;
      },
      filterMethod: (filter, row) => {
        if (filter.value === "all") {
          return true;
        }
        return row.formation.titre === filter.value;
      },
      Filter: ({ filter, onChange }) => {
        return (
          <select
            style={{ width: "100%", fontSize: "1rem" }}
            onChange={(event) => onChange(event.target.value)}
            value={filter ? filter.value : "all"}
          >
            <option value="all">Tous</option>
            {formations.map((item) => (
              <option key={item._id} value={item.titre}>
                {item.titre}
              </option>
            ))}
          </select>
        );
      },
    },
    {
      Header: "Date d'envoi",
      accessor: "createdAt",
      width: 170,

      Cell: ({ value }) => {
        return (
          <span style={{ wordBreak: "break-all" }}>
            {moment(value).locale("fr").format("DD/MM/YYYY HH:mm")}
          </span>
        );
      },
      filterMethod: (filter, row) => {
        const date = moment(row[filter.id]).format("DD/MM/YYYY HH:mm");
        return date.includes(filter.value);
      },
      defaultSortDesc: true,

    },
  
  
    // {
    //   Header: "Contenu",
    //   accessor: "contenu",
    //   width: 150,
    //   style: { wordBreak: "break-all" },
    // },
    {
      Header: "Contenu",
      accessor: "contenu",
      width: 150,
      style: { wordBreak: "break-all" },
      Cell: ({ original }) => {
        return (
          <span
            style={{ cursor: original.contenu !== "" ? "pointer" : "default" }}
            onClick={() => {
              if (original.contenu !== "") {
                handleClickOpen(original.contenu);
              }
            }}
          >
            {original.contenu !== "" ? <b>Voir message</b> : "Pas de contenu"}
          </span>
        );
      },
    },
    
    
    

    {
      Header: "CV/Lettre de motivation",
      width: 250,
      filterable: false,
      style: { textAlign: "center" },
      Cell: ({ original }) => {
        return (
          <div className="d-block w-100 text-center">
            {original?.files && original.files.length > 0 ? (
              original.files.map((file, index) => (
                <a key={index} href={file.link} download={file.name} target="_blank" rel="noopener noreferrer">
                  <Icon width="40" icon="iwwa:file-pdf" />
                </a>
              ))
            ) : (
              <span>Pas de fichiers</span>
            )}
          </div>
        );
      },
    }
    
    
    
  ];
  const getPartners = async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        process.env.REACT_APP_URL_BACKEND_DEV + `/getAllMessages`
      );
      const sortedPartners = res.data.messages.sort((a, b) => {
        const certifCountA = a.etudiant?.mesCertificats.length ;
        const certifCountB = b.etudiant?.mesCertificats.length ;
        return certifCountA - certifCountB;
      });
      setPartners(sortedPartners);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching partners:", error);
      setLoading(false);
    }
  };
  
  useEffect(() => {
    getPartners();
  }, []);
  return (
    <div>
      <Card className="card-hover-shadow-2x mb-3">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Messages Apprenants
          </Typography>
        </Stack>
        <CardBody>
          <Row>
            <ReactTable
              data={partners}
              nextText={"Suivant"}
              previousText={"Précedent"}
              rowsText={"Lignes"}
              columns={statsColumns}
              defaultPageSize={10}
              filterable={true}
              noDataText="Pas de messages"
              defaultSorted={[{id :"createdAt" , desc : true}]}

            />
          </Row>
        </CardBody>
      </Card>
     
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Contenu</DialogTitle>
        <DialogContent>{modalContent}</DialogContent>
      </Dialog>
    </div>
  );
}
export default Partenaire;
