import React, { useState, useEffect } from 'react';
import {
    Container,
    Typography,
    Box,
    Card,
    Table,
    TableBody,
    TableCell,
    TablePagination,
    TableContainer,
    TableHead,
    TableRow,
    Checkbox,
    Button,
    Select,
    MenuItem,
} from '@mui/material';
import Cookies from 'js-cookie';
import axios from 'axios';
import { filter } from 'lodash';
import { format } from 'date-fns';
import { UserListHead, UserListToolbar } from '../../components/_dashboard/user';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const TABLE_HEAD = [
    { id: 'nom', label: 'Nom et Prénom' },
    { id: 'email', label: 'Email' },
];

export default function BulkVoucherAssignment() {
    const [apprenants, setApprenants] = useState([]);
    const [page, setPage] = useState(0);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('nom');
    const [formateurs, setFormateurs] = useState([]);
    const [vouchers, setVouchers] = useState([]);
    const [formations, setFormations] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [selectedFormation, setSelectedFormation] = useState('');
    const [selectedVouchers, setSelectedVouchers] = useState([]);
    const [filterName, setFilterName] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const token = Cookies.get('tokenAdmin');
    const handleFilterByName = (event) => {
        setFilterName(event.target.value);
    };

 
    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = formateurs.map((n) => n._id);
            setSelectedUsers(newSelecteds);
        } else {
            setSelectedUsers([]);
        }
    };

    const handleClick = (event, id) => {
        event.stopPropagation(); // Prevent row click triggering on checkbox click
        const selectedIndex = selectedUsers.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selectedUsers, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selectedUsers.slice(1));
        } else if (selectedIndex === selectedUsers.length - 1) {
            newSelected = newSelected.concat(selectedUsers.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selectedUsers.slice(0, selectedIndex),
                selectedUsers.slice(selectedIndex + 1)
            );
        }
        setSelectedUsers(newSelected);
    };
    const isAllSelected = formateurs.length > 0 && selectedUsers.length === formateurs.length;



    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };



    useEffect(() => {


        const getVouchersAndFormations = async () => {
            setLoading(true);
            try {
                const res = await axios.get(
                    `${process.env.REACT_APP_URL_BACKEND_DEV}/getVoucherByBuisnessAffectAdmin`,
                    {
                        headers: { Authorization: `Bearer ${token}` },
                        withCredentials: true,
                    }
                );

                const activeVouchers = res.data.result.filter(voucher => voucher.expired === false && voucher.sent === false);

                const distinctFormations = [
                    ...new Map(activeVouchers.map(voucher => [voucher.formation._id, voucher.formation])).values(),
                ];

                setFormations(distinctFormations);
                setVouchers(activeVouchers);
            } catch (error) {
                console.error('Error fetching vouchers and formations:', error);
            } finally {
                setLoading(false);
            }
        };

        // getApprenants();
        getVouchersAndFormations();
    }, [token]);


    useEffect(() => {
        const getUsersWithoutVouchers = async () => {
            if (!selectedFormation) return;

            setLoading(true);
            try {
                const res = await axios.get(
                    `${process.env.REACT_APP_URL_BACKEND_DEV}/getUsersWithoutVoucher/${selectedFormation}`,
                    {
                        headers: { Authorization: `Bearer ${token}` },
                        withCredentials: true,
                    }
                );
                console.log("res", res)
                setFormateurs(res.data.data);
            } catch (error) {
                console.error('Error fetching users without vouchers:', error);
            } finally {
                setLoading(false);
            }
        };

        getUsersWithoutVouchers();
    }, [selectedFormation, token]);

    // useEffect(() => {
    //     // getVouchers(); // Fetch users on component mount
    // }, [token]);

    const handleUserSelection = (userId) => {
        setSelectedUsers((prevSelected) => {
            return prevSelected.includes(userId)
                ? prevSelected.filter((id) => id !== userId)
                : [...prevSelected, userId];
        });
    };

    const handleFormationSelection = (event) => {
        setSelectedFormation(event.target.value);
        setSelectedVouchers([]); // Reset selected vouchers when formation changes
    };
    const filteredVouchers = selectedFormation
        ? vouchers.filter(voucher => voucher.formation._id === selectedFormation)
        : [];
    const handleVoucherSelection = (event) => {
        const value = event.target.value;
        if (value.includes('select_all')) {
            setSelectedVouchers(filteredVouchers.map((voucher) => voucher._id));
        } else {
            setSelectedVouchers(value);
        }
    };

  
    console.log('Selected Users:', selectedUsers);
    console.log('Selected Vouchers:', selectedVouchers);
    // Add the handleBulkAssignment function
    const handleBulkAssignment = async () => {
        if (selectedUsers.length === 0 || selectedVouchers.length === 0) {
            alert('Please select users and vouchers to assign.');
            return;
        }

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_URL_BACKEND_DEV}/assignVouchers`, // Update the endpoint
                {
                    userIds: selectedUsers,  // Use 'userIds' instead of 'users'
                    voucherIds: selectedVouchers,  // Use 'voucherIds' instead of 'vouchers'
                },
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            );
            toast.success('Vouchers assigned successfully!');
            setTimeout(() => {
                window.location.reload(); // Refresh the page after 2 seconds
            }, 2000);
            console.log(response);
            console.log('Response from assigning vouchers:', response.data);
        } catch (error) {
            console.error('Error assigning vouchers:', error.message);
        }
    };

    const descendingComparator = (a, b, orderBy) => {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    };

    const getComparator = (order, orderBy) => {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    };

    const applySortFilter = (array, comparator, query) => {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            return order !== 0 ? order : a[1] - b[1];
        });

        if (query) {
            return filter(array, (_user) => _user.nom.toLowerCase().includes(query.toLowerCase()));
        }

        return stabilizedThis.map((el) => el[0]);
    };

    const filteredUsers = applySortFilter(formateurs, getComparator(order, orderBy), filterName);
    const isUserNotFound = filteredUsers.length === 0;


    console.log("filteredVouchers", filteredVouchers)
    return (
        <Container>
            <Typography variant="h4" gutterBottom>
                Affecter voucher aux apprenants
            </Typography>
           
                {/* <Typography>Loading...</Typography> */}
                <Box>
                    <Typography variant="h6" gutterBottom>
                    Selectionner la formation
                    </Typography>
                    <Select
                        value={selectedFormation}
                        onChange={handleFormationSelection}
                        displayEmpty
                        fullWidth
                        sx={{ marginBottom: '16px' }}
                    >
                        {/* <MenuItem value="" disabled>
                            Select Formation
                        </MenuItem> */}
                        {formations.map((formation) => (
                            <MenuItem key={formation._id} value={formation._id}>
                                {formation.titre}
                            </MenuItem>
                        ))}
                    </Select>

                    <Typography variant="h6" gutterBottom>
                        Selectionner les Vouchers  
                    </Typography>
                    <Select
                        multiple
                        value={selectedVouchers}
                        onChange={handleVoucherSelection}
                        displayEmpty
                        fullWidth
                        sx={{ marginBottom: '16px' }}
                        disabled={!selectedFormation}
                    >
                        <MenuItem value="select_all">
                            Select All
                        </MenuItem>
                        {filteredVouchers.map((voucher) => (
                            <MenuItem key={voucher._id} value={voucher._id}>
                                {voucher.voucherCode}
                            </MenuItem>
                        ))}
                    </Select>
                    <Card>
                        <UserListToolbar
                            numSelected={selectedUsers.length}
                            filterName={filterName}
                            onFilterName={(e) => setFilterName(e.target.value)}
                        />
                        <TableContainer>
                            <Table>
                                <UserListHead
                                    order={order}
                                    orderBy={orderBy}
                                    headLabel={TABLE_HEAD}
                                    rowCount={formateurs.length}
                                    numSelected={selectedUsers.length}
                                    onRequestSort={(e, prop) => setOrderBy(prop)}
                                    onSelectAllClick={handleSelectAllClick}
                                    isSelected={isAllSelected}
                                />
                                <TableBody>
                                    {formateurs
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((row) => {
                                            const isItemSelected = selectedUsers.includes(row._id);
                                            return (
                                                <TableRow
                                                    key={row._id}
                                                    hover
                                                    onClick={(event) => handleUserSelection(row._id)}
                                                    selected={isItemSelected}
                                                >
                                                    <TableCell padding="checkbox">
                                                        <Checkbox
                                                            checked={isItemSelected}
                                                            onChange={(event) => handleClick(event, row._id)}
                                                        />
                                                    </TableCell>
                                                    <TableCell>{`${row.nom} ${row.prenom}`}</TableCell>
                                                    <TableCell>{row.email}</TableCell>
                                                </TableRow>
                                            );
                                        })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Card>


                    <TablePagination
                        rowsPerPageOptions={[10, 25, 50]}
                        component="div"
                        count={filteredUsers.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={(event, newPage) => setPage(newPage)}
                        onRowsPerPageChange={(event) => {
                            setRowsPerPage(parseInt(event.target.value, 10));
                            setPage(0);
                        }}
                    />

                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleBulkAssignment}
                        disabled={selectedUsers.length === 0 || selectedVouchers.length === 0}
                    >
                        Affecter les vouchers 
                    </Button>
                </Box>
                <ToastContainer position="top-right" autoClose={3000} />

        </Container>
    );
}
