import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
 getAllCourseAPI,
 getCourseByIdAPI,
 SupprimerCourseAPI
} from "../utils/api";

const initialState = {
  listeCourses: [],
  status: "idle",
  courseSelected: {},
};

export const getAllCourses = createAsyncThunk(
  "course/getAllCourse",
  async (idFormation) => {
     try {
       const response = await getAllCourseAPI(idFormation);
       return response.data.result;
     } catch (err) {
     }
   }
);

export const getCourseById = createAsyncThunk(
  "course/getCourseById",
  async (idCourse) => {
    try {
      const response = await getCourseByIdAPI(idCourse);
      return response.data.result;
    } catch (err) {
    }
  }
);


export const removeFormations = createAsyncThunk(
  "formations/removeFormations",
  async (id) => {
    const response = await SupprimerCourseAPI(id);
    return response.data;
  }
);

export const coursesSlice = createSlice({
  name: "courses",
  initialState,
  reducers: {},

  extraReducers: {
    [getAllCourses.pending]: (state, action) => {
      state.status = "loading";
    },
    [getAllCourses.fulfilled]: (state, action) => {
      state.status = "idle";
      state.listeCourses = action.payload;
    },
     [getCourseById.pending]: (state, action) => {
       state.statusUpdate = "loading";
     },
     [getCourseById.fulfilled]: (state, action) => {
       state.statusUpdate = "success";
       state.courseSelected = action.payload;
     },

  
 
  },
});

export default coursesSlice.reducer;
