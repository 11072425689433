import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import {
  GetAllPartnersAPI, getPartnerByIdAPI,
} from "../utils/api";


const initialState = {

  listPartners: [],
  status: "idle",
  partnerSelected: {},
};


export const getPartners = createAsyncThunk("Reglage/getPartners", async () => {
  try {
    const response = await GetAllPartnersAPI();
    return response.data.partners;
  } catch (err) {
    console.log("Erreur : ", err.response);
  }

  // The value we return becomes the `fulfilled` action payload
});



export const getPartnerById = createAsyncThunk(
  "Reglage/getPartnerById",
  async (idPartner) => {
    try {
      const response = await getPartnerByIdAPI(idPartner);
      return response.data.result;
    } catch (err) {
      console.log("Erreur : ", err.response);
    }
  }
);

export const reglageSlice = createSlice({
  name: "Reglage",
  initialState,
  reducers: {},

  extraReducers: {
   
    [getPartners.pending]: (state, action) => {
      state.status = "loading";
    },
    [getPartners.fulfilled]: (state, action) => {
      state.status = "success";
      state.listPartners = action.payload;
    },
    [getPartnerById.pending]: (state, action) => {
      state.statusUpdate = "loading";
    },
    [getPartnerById.fulfilled]: (state, action) => {
      state.statusUpdate = "success";
      state.partnerSelected = action.payload;
    },
  },
});

export default reglageSlice.reducer;
